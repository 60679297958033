import { useCallback, useEffect, useRef, useState } from 'react';

import { useEngine } from '../../../../../aiem/engine/hooks';
import { calculatedEngineSizes } from '../../../../../aiem/engine/services';
import { AIEngine, EngineConstraintSet } from '../../../../../aiem/engine/types';
import { H2OEngineSize } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { H2OEngineProfileOptionKeyType, defaultH2OCompressedEngineSizeRequest } from '../../../constants';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';
import EngineProfileTableRows from './EngineProfileTableRows';

const fetchEngineSize = async (basePath: string, columnsCount: number, rowsCount: number) => {
  const method = calculatedEngineSizes[H2OEngineProfileOptionKeyType.compressed].calculate;
  const size: H2OEngineSize = await method(basePath, '', {
    columnsCount: columnsCount.toString(),
    rowsCount: rowsCount.toString(),
  } as any);
  return size;
};

interface ConfigureCustomEngineSizeProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  modifyEngine: any;
}

export default function ConfigureCustomEngineSize({
  engine,
  constraintSet,
  modifyEngine,
}: ConfigureCustomEngineSizeProps) {
  const { basePath } = useEngine(),
    mostRecentRequestTime = useRef<number>(0),
    [loading, setLoading] = useState(false),
    [rowsCount, setRowsCount] = useState<string>(defaultH2OCompressedEngineSizeRequest.rowsCount!),
    [columnsCount, setColumnsCount] = useState<string>(defaultH2OCompressedEngineSizeRequest.columnsCount!);

  const calculateEngineSize = useCallback(
    async (columnsCount, rowsCount) => {
      setLoading(true);
      const thisRequestTime = Date.now();
      mostRecentRequestTime.current = thisRequestTime;
      const size = await fetchEngineSize(basePath, columnsCount, rowsCount);
      if (size && mostRecentRequestTime.current === thisRequestTime) {
        modifyEngine({
          nodeCount: size.nodeCount,
          memoryBytes: size.memoryBytes,
          cpu: constraintSet?.cpu?.default || 1,
          gpu: constraintSet?.gpu?.default || 1,
        });
      }
      setLoading(false);
    },
    [setLoading, basePath, fetchEngineSize, constraintSet]
  );

  const onChangeRowCount = useCallback(
    (_, value: string) => {
      setRowsCount(value);
      calculateEngineSize(columnsCount, value);
    },
    [calculateEngineSize, setRowsCount, columnsCount]
  );

  const onChangeColumnCount = useCallback(
    (_, value: string) => {
      setColumnsCount(value);
      calculateEngineSize(value, rowsCount);
    },
    [calculateEngineSize, setColumnsCount, rowsCount]
  );

  useEffect(() => {
    /* Do not add columnsCount and rowsCount to this dependency array. 
      This effect is supposed to occur only once after the constraints
      are loaded. */
    if (constraintSet) {
      calculateEngineSize(columnsCount, rowsCount);
    }
  }, [constraintSet]);

  return (
    <MetadataTable>
      <MetadataTableBody>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Number of Rows</MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={onChangeRowCount}
              value={+rowsCount}
              min={1}
              max={100_000_000_000_000_000}
              tooltip="How many rows are in the dataset?"
            />
          </MetadataValueCell>
        </MetadataRow>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Number of Columns</MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={onChangeColumnCount}
              value={+columnsCount}
              min={1}
              max={100000}
              tooltip="How many columns are in the dataset?"
            />
          </MetadataValueCell>
        </MetadataRow>
        <EngineProfileTableRows loading={loading || !constraintSet} engine={engine} />
      </MetadataTableBody>
    </MetadataTable>
  );
}
