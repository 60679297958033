import { SpinnerSize, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, Loader, buttonStylesGhost, buttonStylesPrimary, useTheme } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MessageText, useEntity } from '../../../aiem/entity/hooks';
import { Entity, HasName } from '../../../aiem/entity/types';
import { isNotEmpty } from '../../../utils/utils';
import { EntityModelFieldList } from './EntityModelFieldList';

export interface ILargeEntityModelFormProps<EntityModel> {
  entity: Entity<EntityModel>;
}

export function LargeEntityModelForm<EntityModel extends HasName>(props: ILargeEntityModelFormProps<EntityModel>) {
  const { semanticColors } = useTheme();
  const { entity } = props;

  const entityDataConnector = useEntity();
  const { fields, type: entityType } = entity;
  const requiredFields = useMemo(() => {
    return fields.filter((field) => field.required).map((field) => field.name as keyof EntityModel);
  }, [fields]);
  const originalModel = useRef<EntityModel>({} as EntityModel);
  const [model, setModel] = useState<EntityModel>();
  const [loading, { setTrue: hideContent, setFalse: showContent }] = useBoolean(true);
  const [valid, setValid] = useState<boolean>(true);
  const modelName = model?.name;

  const onChange = (fieldName: keyof EntityModel, value: any) => {
    if (!model) return;
    const newModel = { ...model };
    newModel[fieldName] = value;
    const isValid = Boolean(
      requiredFields.every((field) => {
        return isNotEmpty(newModel[field]);
      })
    );
    setValid(isValid);
    setModel(newModel);
  };

  const onUpdate = useCallback(async () => {
    if (!model) return;
    const action = entity.actions.Update;
    if (!action) {
      return;
    }
    const { requestNameKey, requestPayloadKey } = action || {
      requestNameKey: 'bad-action-key',
      requestPayloadKey: 'bad-action-payload',
    };

    try {
      const request = {
        [requestPayloadKey!]: { ...model },
        [requestNameKey!]: model.name,
      };
      const messageText: MessageText = {
        action: 'updated',
      };
      const newReq = { ...request, updateMask: '*' };
      const updatedModel = await entityDataConnector.update(entity, newReq, messageText);
      setModel(updatedModel as unknown as EntityModel);
    } catch (error: any) {}
  }, [model]);

  const fetchModel = useCallback(async () => {
    const freshModel = await entityDataConnector.get(entity, { name: modelName || entity.name }, true);
    originalModel.current = freshModel as unknown as EntityModel;
    setModel(freshModel as unknown as EntityModel);
    showContent();
    setValid(true);
  }, [modelName, entity]);

  useEffect(() => {
    fetchModel();
  }, []);

  return (
    <Stack horizontal style={{ width: '100%' }}>
      {loading || !model ? (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{ root: { paddingLeft: 200, paddingTop: 200, width: '100%' } }}
        >
          <Loader size={SpinnerSize.large} />
        </Stack>
      ) : (
        <>
          <Stack style={{ marginBottom: 10, width: '100%' }}>
            <Stack horizontal horizontalAlign="space-between" style={{ padding: '10px 0 20px 0' }}>
              <Text styles={{ root: { color: semanticColors!.inputText, marginTop: 16 } }} variant="medium">
                {entity.description}
              </Text>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Button
                  onClick={() => {
                    hideContent();
                    fetchModel();
                  }}
                  styles={[buttonStylesGhost]}
                  text="Reset to Default"
                />
                <Button onClick={onUpdate} styles={[buttonStylesPrimary]} disabled={!valid} text="Save Changes" />
              </Stack>
            </Stack>
            <EntityModelFieldList onChange={onChange} model={model} entityType={entityType} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
