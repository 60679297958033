import { IButtonStyles, Stack } from '@fluentui/react';
import {
  Button,
  FontSizes,
  FontWeights,
  IH2OTheme,
  IItemMetaData,
  IconName,
  LineHeights,
  buttonStylesLink,
} from '@h2oai/ui-kit';
import { useAuth } from 'react-oidc-context';

import CardList, { ICardListProps } from '../../../../components/CardList/CardList';
import { useEnv } from '../../../../utils/hooks';
import { redirectActionAppCard } from '../../AppStorePage';
import { AppCategory, BaseCategory, SeeAllProps } from '../../AppStorePage.models';

const buttonStylesAppCardList = (theme: IH2OTheme): IButtonStyles => ({
  root: {
    minHeight: 65,
    minWidth: 0,
    fontSize: FontSizes.button,
    color: theme.palette?.primary600,
  },
  menuIcon: {
    fontSize: FontSizes.xxxsmall,
    color: theme.palette?.primary600,
  },
});

type AppStoreSectionsProps = {
  categoryAppsMap: Map<string, AppCategory>;
  appsCategories: AppCategory[];
  onSeeAll: (props: SeeAllProps) => unknown;
} & Omit<ICardListProps, 'metaDatas'>;

// display all apps
export default function AppStoreSections({
  categoryAppsMap,
  onPin,
  onLike,
  columnCount,
  onSeeAll,
}: AppStoreSectionsProps) {
  const auth = useAuth();
  const env = useEnv();
  const isPublicModeEnabled = !!env?.publicModeEnabled;
  const isAuthUser = !!auth.user?.id_token;
  if (columnCount === 0) <></>;
  // App Card list by Categories always has the default sort.
  // pinned apps displays all pinned apps,
  // top rated apps displays 2 rows (app count = columnCount * 2 - 4), 4 => 2*2 occupys 4 but 1 app, 2*1 occupys 2 but 1 app,
  // others display 1 row.
  return (
    <>
      {Array.from(categoryAppsMap.values()).map(({ name, title, apps, count }) => {
        const show = name !== BaseCategory.PinnedApps;
        const showCounter = name !== BaseCategory.PinnedApps && name !== BaseCategory.MostPopular;
        const seeAllProps = { show, value: name };
        const metaDatas: IItemMetaData[] =
          name === BaseCategory.MostPopular ? [{ colSpan: 2, rowSpan: 2 }, {}, {}, { colSpan: 2 }] : [];
        const key = name.replace(/\s/g, '-').toLocaleLowerCase();
        const dataTest = `${key}--view-all`;
        let maxDisplayCount = columnCount;
        if (name === BaseCategory.MostPopular) {
          // 4(gap of 2*2, 2*1)
          maxDisplayCount =
            columnCount < 3
              ? 1
              : columnCount === 3
              ? 2
              : columnCount === 4 || columnCount === 5
              ? columnCount
              : columnCount * 2 - 4 <= apps.length
              ? columnCount * 2 - 4
              : apps.length;
        }

        const sectionApps = name === BaseCategory.PinnedApps ? apps : apps.slice(0, maxDisplayCount);

        return count === 0 && name !== BaseCategory.MostPopular ? null : (
          <section key={key} className="AppStoreSection">
            <Stack as="header" horizontal horizontalAlign="space-between" verticalAlign="end">
              <h1
                className="AppStoreSection-title"
                style={{
                  fontSize: FontSizes.xlarge,
                  fontWeight: FontWeights.semiBold,
                  lineHeight: LineHeights.section,
                  padding: 0,
                  paddingTop: 20,
                  margin: 0,
                }}
              >
                {showCounter ? `${title} (${count})` : title}
              </h1>
              {show && (
                <Button
                  styles={[buttonStylesLink, buttonStylesAppCardList]}
                  onClick={() => onSeeAll(seeAllProps)}
                  data-test={dataTest}
                  menuIconName={IconName.Forward}
                  text="See all"
                />
              )}
            </Stack>
            <CardList
              data={sectionApps}
              hideCategories
              hideUserName
              onPin={onPin}
              onLike={onLike}
              columnCount={columnCount}
              metaDatas={metaDatas}
              disableActions={!isAuthUser}
              showLoader={isPublicModeEnabled}
              getMenuItems={
                isPublicModeEnabled
                  ? (app) => [
                      {
                        key: 'view-details',
                        text: 'View details',
                        'data-test': 'view-details-button',
                        href: `/apps/${app.id}`,
                      },
                    ]
                  : undefined
              }
              onClick={(app) =>
                redirectActionAppCard(app, isPublicModeEnabled).then((path) => {
                  window.location.href = path;
                })
              }
            />
          </section>
        );
      })}
    </>
  );
}
