import { IDropdownOption, ImageFit, Stack, Text } from '@fluentui/react';
import {
  BasicList,
  Button,
  Dropdown,
  IconName,
  Image,
  Item,
  Sizes,
  buttonStylesDefaultWidth,
  dropdownStylesOptionIcon,
  itemStylesCategory,
  useTheme,
  validateEmail,
} from '@h2oai/ui-kit';
import { FormEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { TagAssignment } from '../../ai.h2o.cloud.appstore';
import { useEnv } from '../../utils/hooks';
import { OTHER_CATEGORY } from '../../utils/models';
import { isCategoryTag } from '../../utils/utils';
import * as styles from './appDetailHeader.styles';

type RequiredTagProperties = Pick<TagAssignment, `description` | `hidden` | `id` | `isCategory` | `title`>;

const otherCategory: RequiredTagProperties[] = [
  {
    description: `Unspecified`,
    hidden: false,
    id: OTHER_CATEGORY,
    isCategory: true,
    title: OTHER_CATEGORY,
  },
];

const rootTokens = { childrenGap: `1rem` },
  labelsTokens = { childrenGap: 10 };

export interface IAppDetailHeaderProps {
  actionButton: ReactNode;
  iconLocation: string;
  footer?: ReactNode;
  description: string;
  title: string;
  categories?: string[];
  app: {
    id: string;
    owner: string;
    version: string;
  };
  tags?: RequiredTagProperties[];
  versions: IDropdownOption<{ icon: IconName }>[];
}

export function AppDetailHeader({
  actionButton,
  app: { id, owner, version },
  description,
  iconLocation,
  tags,
  title,
  versions,
  footer,
}: IAppDetailHeaderProps) {
  const history = useHistory(),
    env = useEnv(),
    theme = useTheme(),
    onVersionChange = (_e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      history.push(option.key as string);
    },
    onFeedbackButtonClick = () => {
      const mailto = validateEmail(owner) ? owner : env?.defaultEmailAddress || 'support@h2o.ai';
      window.open(`
        mailto:${mailto}
        ?subject=App%20Feedback%20|%20${title}%20|%20${version}
        &body=%3Cyour%20feedback%3E%0D%0A%0D%0AApp%20Details%0D%0AID%3A%20
        ${id}%0D%0ANAME%3A%20${title}%0D%0AVERSION%3A%20${version}%0D%0A%0D%0A
      `);
    };
  return (
    <>
      <Stack
        className="AppDetailsHeader"
        tokens={rootTokens}
        styles={styles.appDetailHeaderStyles(theme)}
        horizontal
        verticalAlign="start"
        horizontalAlign="space-between"
        verticalFill
      >
        <Image
          styles={styles.imageStylesAppDetailsHeader}
          imageFit={ImageFit.centerCover}
          src={iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png'}
        />
        <Stack
          className="AppDetailHeader-body"
          tokens={{ childrenGap: 5 }}
          horizontalAlign="start"
          verticalAlign="start"
          grow={3}
          styles={{ root: { position: 'relative' } }}
        >
          <Text styles={styles.textStylesTitle(theme)}>{title}</Text>
          <Text styles={styles.textStylesDescription(theme)}>{description}</Text>
          <BasicList
            id="app-categories"
            horizontal
            data={tags}
            filterFn={isCategoryTag}
            emptyItems={otherCategory}
            styles={{ root: { rowGap: 4 } }}
            itemRenderer={(d) => (
              <Item styles={itemStylesCategory} labelField="title" titleField="description" data={d} />
            )}
          />
          <Stack className="AppDetailHeader-body-footer" horizontal verticalAlign="start" tokens={labelsTokens}>
            {footer}
          </Stack>
        </Stack>
        <Stack
          className="AppDetailHeader-footer"
          grow={1}
          verticalAlign="start"
          horizontalAlign="end"
          tokens={labelsTokens}
          styles={{ root: { maxWidth: Sizes.buttonWidth + 50 } }}
        >
          {actionButton}
          <Dropdown
            styles={[
              dropdownStylesOptionIcon,
              {
                root: {
                  width: Sizes.buttonWidth,
                },
              },
            ]}
            hasOptionIcon
            selectedKey={id}
            data-test="versions-drop-down"
            options={versions}
            onChange={onVersionChange}
          />
          <Button
            styles={buttonStylesDefaultWidth}
            data-test="send-feedback-button"
            text="Send feedback"
            iconName={IconName.Mail}
            onClick={onFeedbackButtonClick}
          />
        </Stack>
      </Stack>
    </>
  );
}
