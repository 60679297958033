import { type IStyles } from '../../utils/styles';

type IDialogStylesKey = 'root';

export type IProtectedStyles = IStyles<IDialogStylesKey>;

export const autoLogoutDialogStyles: IProtectedStyles = {
  root: {
    '.ms-Dialog-header .ms-Dialog-button--close': { display: 'none' },
  },
};
