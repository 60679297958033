import { listIconSize } from '../../utils/utils';
import { ImagePullPolicy } from '../gen/ai/h2o/engine/v1/base_types_pb';
import { InternalH2OVersion } from '../gen/ai/h2o/engine/v1/internal_h2o_version_pb';
import {
  AssignInternalH2OVersionAliasesRequest,
  AssignInternalH2OVersionAliasesResponse,
  CreateInternalH2OVersionRequest,
  CreateInternalH2OVersionResponse,
  DeleteInternalH2OVersionRequest,
  DeleteInternalH2OVersionResponse,
  GetInternalH2OVersionRequest,
  GetInternalH2OVersionResponse,
  InternalH2OVersionService_AssignInternalH2OVersionAliases,
  InternalH2OVersionService_CreateInternalH2OVersion,
  InternalH2OVersionService_DeleteInternalH2OVersion,
  InternalH2OVersionService_GetInternalH2OVersion,
  InternalH2OVersionService_ListInternalH2OVersions,
  InternalH2OVersionService_UpdateInternalH2OVersion,
  ListInternalH2OVersionsRequest,
  ListInternalH2OVersionsResponse,
  UpdateInternalH2OVersionRequest,
  UpdateInternalH2OVersionResponse,
} from '../gen/ai/h2o/engine/v1/internal_h2o_version_service_pb';
import { listInternalH2OVersionsResponseMock } from './mocks/responses.mock';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import {
  getOptionsFromEnum,
  mockItemGetter,
  onRender,
  onRenderBooleanCell,
  onRenderH2OIconCell,
  onRenderStringArrayCell,
} from './utils';

const entityType = EntityType.InternalH2OVersion,
  required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateInternalH2OVersion',
    rpc: InternalH2OVersionService_CreateInternalH2OVersion,
    type: EntityActionType.Create,
    responseKey: 'internalH2oVersion',
    requestNameKey: 'internalH2oVersionId',
    requestPayloadKey: 'internalH2oVersion',
    entityType,
  } as EntityAction<InternalH2OVersion, CreateInternalH2OVersionRequest, CreateInternalH2OVersionResponse>,
  [EntityActionType.Get]: {
    name: 'GetInternalH2OVersion',
    rpc: InternalH2OVersionService_GetInternalH2OVersion,
    type: EntityActionType.Get,
    responseKey: 'internalH2oVersion',
    mockPath: '/v1/internalH2oVersions/*',
    mockResponse: (req) =>
      mockItemGetter(listInternalH2OVersionsResponseMock.internalH2oVersions!, req, 'internalH2oVersion'),
    entityType,
  } as EntityAction<InternalH2OVersion, GetInternalH2OVersionRequest, GetInternalH2OVersionResponse>,
  [EntityActionType.List]: {
    name: 'ListInternalH2OVersion',
    rpc: InternalH2OVersionService_ListInternalH2OVersions,
    type: EntityActionType.List,
    mockResponse: listInternalH2OVersionsResponseMock,
    responseKey: 'internalH2oVersions',
    entityType,
  } as EntityAction<InternalH2OVersion, ListInternalH2OVersionsRequest, ListInternalH2OVersionsResponse>,
  [EntityActionType.Update]: {
    name: 'InternalH2OVersion',
    rpc: InternalH2OVersionService_UpdateInternalH2OVersion,
    type: EntityActionType.Update,
    entityType,
    responseKey: 'internalH2oVersion',
    requestNameKey: 'name' as unknown, // as unknow here is wrong, it should be a keyOf EntityModel
    requestPayloadKey: 'internalH2oVersion',
    mockPath: '/v1/internalH2OVersions/*',
    mockResponse: listInternalH2OVersionsResponseMock.internalH2oVersions![0],
  } as EntityAction<InternalH2OVersion, UpdateInternalH2OVersionRequest, UpdateInternalH2OVersionResponse>,
  [EntityActionType.Delete]: {
    name: 'InternalH2OVersion',
    rpc: InternalH2OVersionService_DeleteInternalH2OVersion,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<InternalH2OVersion, DeleteInternalH2OVersionRequest, DeleteInternalH2OVersionResponse>,
  [EntityActionType.Assign]: {
    name: 'AssignInternalH2OVersionAliases',
    rpc: InternalH2OVersionService_AssignInternalH2OVersionAliases,
    type: EntityActionType.Assign,
    responseKey: 'internalH2oVersions',
    requestNameKey: 'internalH2oVersion',
    requestPayloadKey: 'aliases',
    payloadKey: 'aliases',
    entityType,
  } as EntityAction<
    InternalH2OVersion,
    AssignInternalH2OVersionAliasesRequest,
    AssignInternalH2OVersionAliasesResponse
  >,
};

export const fields: EntityField<InternalH2OVersion>[] = [
  {
    name: 'name',
    type: EntityFieldType.Hidden,
    label: 'Name',
    description: `
      InternalH2OVersion resource name.
      For example: internalH2OVersions/3.42.0.3
    `,
    required,
  },
  {
    name: 'version',
    type: EntityFieldType.Semver,
    label: 'Version',
    description: 'Version identifier. For example "3.42.0.3".',
    required,
    relayValueTo: ['name'],
    readOnlyOnEdit: true,
  },
  {
    name: 'aliases',
    type: EntityFieldType.LatestAndAliases,
    label: 'Aliases',
    description: `
      Aliases for the specified H2O version.
      For example {"latest", "prerelease"}.`,
  },
  {
    name: 'deprecated',
    type: EntityFieldType.Boolean,
    label: 'Deprecated',
    description: 'Indicates whether H2OVersion is deprecated.',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    description: 'Name of the Docker image used when using this H2OVersion.',
    required,
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    description: `
      Image pull policy applied when using this H2OVersion.
      When unset, server will choose a default one.`,
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
    description: `
      List of references to k8s secrets that can be used for pulling an image of this H2OVersion
      from a private container image registry or repository.`,
  },
  {
    name: 'gpuResourceName',
    type: EntityFieldType.Hidden,
    label: 'K8s GPU Resource Name',
    description: `
      K8s GPU resource name. For example: 'nvidia.com/gpu' or 'amd.com/gpu'.
      When unset, server will choose a default value.`,
  },
  {
    name: 'annotations',
    type: EntityFieldType.KeyValuePair,
    label: 'Annotations',
    description: `
      Additional arbitrary metadata associated with the H2OVersion.

      Annotations are key/value pairs.
      The key must:
      - be 63 characters or less
      - begin and end with an alphanumeric character ([a-z0-9A-Z])
      - with dashes (-), underscores (_), dots (.), and alphanumerics between
      - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$`,
  },
];

export const columns = [
  {
    key: 'icon',
    name: '',
    className: 'entity-model-icon',
    fieldName: 'icon',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    onRender: onRenderH2OIconCell,
  },
  {
    key: 'version',
    name: 'Version',
    fieldName: 'version',
    minWidth: 180,
    maxWidth: 180,
    onRender,
  },
  {
    key: 'aliases',
    name: 'Aliases',
    fieldName: 'aliases',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderStringArrayCell,
  },
  {
    key: 'deprecated',
    name: 'Deprecated',
    fieldName: 'deprecated',
    minWidth: 150,
    maxWidth: 150,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 200,
    maxWidth: 1000,
    onRender,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const h2oVersionEntity: Entity<InternalH2OVersion> = {
  type: EntityType.InternalH2OVersion,
  name: 'InternalH2OVersion',
  createButtonLabel: 'Add Version',
  displayName: 'H2O Version',
  displayNameKey: 'version',
  requestNameKeyPrefix: 'internalH2OVersions/',
  columns,
  actions,
  fields,
  emptyModel: {
    name: '',
    version: '',
    aliases: [],
    deprecated: false,
    image: '',
    imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
    imagePullSecrets: [],
    gpuResourceName: '',
    annotations: {},
  },
  canBeReordered: false,
  filterFields: ['version', 'deprecated'],
};
