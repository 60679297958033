import { IColumn, IDropdownOption } from '@fluentui/react';
import { IconButton, defaultPalette } from '@h2oai/ui-kit';

import { BadgeListCell } from '../../components/ListPages/BadgeListCell';
import { IconCell } from '../../components/ListPages/IconCell';
import { MetadataCell } from '../../components/ListPages/MetadataCell';
import { listIconSize } from '../../utils/utils';
import { bytesToGibibytes, getIdFromName } from '../utils';

const noField = 'no-field';

export const getOptionsFromEnum = (
  enumObject: any,
  suppressingText = '',
  exceptionList: string[] = []
): IDropdownOption[] => {
  const options: IDropdownOption[] = [];

  for (const enumkey in enumObject) {
    if (Object.prototype.hasOwnProperty.call(enumObject, enumkey)) {
      if (exceptionList.includes(enumObject[enumkey])) continue;
      const key = enumObject[enumkey],
        text = String(enumObject[enumkey])
          .replaceAll(suppressingText || '', '')
          .toLowerCase()
          .split('_')
          .map((word) => {
            const firstLetter = word[0];
            return firstLetter.toUpperCase() + word.slice(1);
          })
          .join(' ');
      options.push({ key, text });
    }
  }
  return options;
};

export const validateSemVer = (version: string) => {
  const regex =
    /^v?([0-9]+(\.[0-9]+)*?)(-([0-9]+[0-9A-Za-z\-~]*(\.[0-9A-Za-z\-~]+)*)|(-?([A-Za-z\-~]+[0-9A-Za-z\-~]*(\.[0-9A-Za-z\-~]+)*)))?(\+([0-9A-Za-z\-~]+(\.[0-9A-Za-z\-~]+)*))??$/;
  return regex.test(version);
};

export const onRender: ((item?: any, index?: number | undefined, column?: IColumn | undefined) => any) | undefined = <
  EntityModel,
>(
  item: EntityModel,
  _i?: number | undefined,
  column?: IColumn
) => <MetadataCell title={column?.name}>{item[column!.fieldName || 'name']}</MetadataCell>;

export const onRenderDAIIconCell: typeof onRender = <EntityModel,>(model: EntityModel) => (
  <IconCell key={`icon-cell-${(model as any).name}`} size={listIconSize} src={'/driverlessDarkIcon.png'} />
);

export const onRenderH2OIconCell: typeof onRender = <EntityModel,>(model: EntityModel) => (
  <IconCell
    key={`icon-cell-${(model as any).name}`}
    size={listIconSize}
    styles={{ backgroundColor: 'var(--h2o-gray900)' }}
    src={'/h2o3DarkLogo.png'}
  />
);

const gripperIconStyles = {
  root: {
    cursor: 'grab',
    fontSize: '2rem',
    backgroundColor: 'transparent',
    marginTop: '10px',
  },
  icon: {
    fontSize: '1.4rem',
    color: defaultPalette.gray400,
  },
  rootHovered: {
    backgroundColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: 'transparent',
  },
};

export const onRenderGripperCell: typeof onRender = () => (
  <IconButton iconName="GripperDotsVertical" title="Drag and drop rows to re-order" styles={gripperIconStyles} />
);
export const onRenderBooleanCell: typeof onRender = <EntityModel,>(
  model: EntityModel,
  _i?: number,
  column?: IColumn
) => <MetadataCell title={column?.name}>{model[column?.fieldName || noField] ? 'Yes' : 'No'}</MetadataCell>;

export const onRenderStringArrayCell: typeof onRender = <EntityModel,>(
  model: EntityModel,
  _i?: number,
  column?: IColumn
) => {
  const arrayField = model[column?.fieldName || noField] || [];
  if (!Array.isArray(arrayField) || !arrayField.length) return <></>;
  return <BadgeListCell key={`badge-${column?.fieldName}`} title={column?.name || ''} items={arrayField} />;
};

export const onRenderBigIntStringCell: typeof onRender = <EntityModel,>(
  model: EntityModel,
  _i?: number,
  column?: IColumn
) => <MetadataCell title={column?.name}>{`${bytesToGibibytes(model[column?.fieldName || noField])}GiB`}</MetadataCell>;

export const onRenderDisplayAndId:
  | ((item?: any, index?: number | undefined, column?: IColumn | undefined) => any)
  | undefined = <EntityModel,>(item: EntityModel, _i?: number | undefined, column?: IColumn) => (
  <MetadataCell title={item[column!.fieldName || 'displayName']}>{getIdFromName(item['name'])}</MetadataCell>
);

export const checkForPrefix = (name?: string, prefix?: string) =>
  !name || !prefix ? name : name.startsWith(prefix) ? name : `${prefix}${name}`;

export const mockItemGetter = <EntityModel, EntityRequest, EntityResponse>(
  list: EntityModel[],
  req: EntityRequest,
  responseKey: keyof EntityResponse
) => {
  const filteredList = list.filter((item) => {
    return getIdFromName(item['name']) === req['params']['0'];
  });
  return filteredList && filteredList.length
    ? {
        [responseKey]: filteredList[0] || undefined,
      }
    : undefined;
};
