import { Stack } from '@fluentui/react';
import { ConfirmDialog, TextField } from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';

import { AIEngine } from '../../../../aiem/engine/types';

export type AIEMMigrateCreatorProps = {
  hidden?: boolean;
  engine?: AIEngine;
  onConfirm?: (ownerId: string) => unknown;
  onDismiss?: () => void;
};

export const AIEMMigrateCreator = ({ engine, hidden = true, onConfirm, onDismiss }: AIEMMigrateCreatorProps) => {
  const [creatorId, setCreatorId] = useState<string>(engine?.creator || ''),
    handleConfirm = useCallback(() => {
      if (onConfirm && creatorId) {
        onConfirm(creatorId);
      }
    }, [onConfirm, creatorId]);

  return (
    <ConfirmDialog
      confirmationButtonText="Change"
      hidden={hidden}
      title="Change Engine Creator"
      content={
        <Stack>
          <p>
            {`Changing the creator of this engine will change the of all associated resources
          data.`}
          </p>
          <TextField
            required
            ariaLabel="User ID"
            autoFocus
            onChange={(_, newValue) => {
              newValue && setCreatorId(newValue);
            }}
            placeholder="User ID"
            defaultValue={engine?.creator}
          />
        </Stack>
      }
      onConfirm={handleConfirm}
      onDismiss={onDismiss}
    />
  );
};
