import {
  DirectionalHint,
  ITextStyles,
  ITooltipHostStyles,
  ITooltipProps,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { FontSizes, type IH2OSemanticColors, IconButton, IconName, useTheme } from '@h2oai/ui-kit';

export enum OutOfVersionSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Text = 'text',
}

export type LabelInfoTooltipProps = {
  id?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  tooltip?: string;
  iconName?: IconName;
  severity?: OutOfVersionSeverity;
};

const getIconColor = (
  severity: OutOfVersionSeverity,
  semanticColors?: Partial<IH2OSemanticColors> | { [key: string]: string }
) => {
  const severityColorMap = {
    [OutOfVersionSeverity.Warning]: semanticColors?.messageBarIconWarning,
    [OutOfVersionSeverity.Info]: semanticColors?.messageBarIconInfo,
    [OutOfVersionSeverity.Error]: semanticColors?.messageBarIconError,
  };

  return severityColorMap[severity] || semanticColors?.messageBarTitleText;
};

export const LabelIconTooltip = ({
  label = '',
  tooltip = '',
  iconName = IconName.Info,
  required = false,
  severity = OutOfVersionSeverity.Text,
}: LabelInfoTooltipProps) => {
  const { semanticColors } = useTheme(),
    tooltipHostStyles: ITooltipHostStyles = {
      root: {
        display: 'inline',
        ...(required
          ? {
              '&.ms-TooltipHost': {
                marginLeft: '-10px',
                paddingTop: '5px',
              },
            }
          : {}),
        '.ms-Icon': {
          color: getIconColor(severity, semanticColors),
        },
      },
    },
    tooltipTextStyles: ITextStyles = { root: { color: semanticColors?.tooltipText, fontSize: FontSizes.xsmall } },
    tooltipProps: ITooltipProps = {
      directionalHint: DirectionalHint.rightCenter,
      styles: {
        content: { backgroundColor: semanticColors?.tooltipBackground, padding: '0 10px' },
      },
      onRenderContent: () => (
        <Stack>
          <Text styles={tooltipTextStyles}>{tooltip}</Text>
        </Stack>
      ),
    };
  return (
    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
      {label}
      {tooltip && (
        <TooltipHost
          tooltipProps={tooltipProps}
          styles={tooltipHostStyles}
          calloutProps={{
            styles: {
              beakCurtain: { backgroundColor: semanticColors?.tooltipBackground },
              beak: { backgroundColor: semanticColors?.tooltipBackground },
            },
          }}
        >
          <IconButton styles={{ root: { backgroundColor: semanticColors?.contentBackground } }} iconName={iconName} />
        </TooltipHost>
      )}
    </Stack>
  );
};
