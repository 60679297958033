import { defaultWorkspaceName } from '../defaults';
import { Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { EngineState } from './types';

const transitionalStates = [
  Engine_State.CONNECTING,
  Engine_State.STARTING,
  Engine_State.DELETING,
  Engine_State.PAUSING,
  Engine_State.TERMINATING,
];

export const isTransitionalState = (state: EngineState | undefined): boolean =>
  transitionalStates.includes(state as any);

export const getFullPathFromEngineName = (
  engineName: string,
  engineType: Engine_Type = Engine_Type.DRIVERLESS_AI,
  workspaceName = defaultWorkspaceName
) => {
  const engineTypeUrl = `${engineType === Engine_Type.DRIVERLESS_AI ? 'dai' : 'h2o'}Engines`;
  return `${workspaceName}/${engineTypeUrl}/${engineName}`;
};
