import { Fragment, ReactNode } from 'react';

import { EntitiesMap } from '../../../aiem/entity/services';
import { Entity, EntityField, EntityFieldType, EntityType } from '../../../aiem/entity/types';
import { BooleanEntityModelField, EntityFieldInputProps, FormRow } from './BasicEntityModelComponents';
import { ConstraintFieldsTable } from './ConstraintSetModelField';
import { DurationModelField } from './DurationModelField';
import { KeyValuePairEntityModelField } from './KeyValuePairModelField';
import { YamlEntityModelField } from './YamlEntityModelField';

type EntityModelFieldListProps<EntityModel> = {
  entityType: EntityType;
  model: EntityModel;
  onChange: (fieldName: keyof EntityModel, value: any) => void;
  onRenderFieldWrapper?: (field: EntityField<EntityModel>, children: React.ReactNode) => React.ReactNode;
};

const constraintFields = [EntityFieldType.ConstraintDuration, EntityFieldType.ConstraintNumeric];

export const EntityModelFieldList = <EntityModel,>({
  entityType,
  model,
  onChange,
}: EntityModelFieldListProps<EntityModel>) => {
  const entity = EntitiesMap.get(entityType) as Entity<EntityModel>;
  const { fields } = entity;
  const getModelFieldList = () => {
    const result = fields
      .filter(({ type }) => !constraintFields.includes(type))
      .map((field) => {
        const { type, name } = field;
        const props: EntityFieldInputProps<EntityModel> = {
          entityType,
          field,
          model,
          onChange,
        };
        let modelField: ReactNode | undefined;
        switch (type) {
          case EntityFieldType.Boolean:
            modelField = <BooleanEntityModelField {...props} largeLabel />;
            break;
          case EntityFieldType.KeyValuePair:
            modelField = <KeyValuePairEntityModelField {...props} large />;
            break;
          case EntityFieldType.ConstraintNumeric:
          case EntityFieldType.ConstraintDuration:
            modelField =
              'This field should not be appearing here, the Constraint fields are now being rendered in a table ';
            break;
          case EntityFieldType.Duration:
            modelField = <DurationModelField {...props} />;
            break;
          case EntityFieldType.Yaml:
            modelField = <YamlEntityModelField {...props} />;
            break;
          case EntityFieldType.Hidden:
            modelField = undefined;
            break;
          default:
            modelField = (
              <FormRow>{`${String(name)} is either not implemented or needs support extension here`}</FormRow>
            );
        }
        return <Fragment key={`${String(name)}-form-row`}>{modelField}</Fragment>;
      });

    return result;
  };

  return (
    <>
      <ConstraintFieldsTable
        entityType={entityType}
        fields={fields.filter(({ type }) => constraintFields.includes(type))}
        model={model}
        onChange={onChange}
      />
      {getModelFieldList()}
    </>
  );
};
