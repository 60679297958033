// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/runnable.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

/**
 * A Runnable is a reusable set of instructions in a workflow.
 * It includes details about what code should be executed and where it should run (using kernel_image and kernel_template).
 *
 * @generated from message ai.h2o.orchestrator.v1.Runnable
 */
export type Runnable = {
/**
 * Resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * Required. Name of executor pool which should be used to run this runnable.
 *
 * @generated from field: string executor_pool = 3;
 */
executorPool: string;
/**
 * The code (notebook or interpreter language script (python/R etc.)) to be executed by the Runnable.
 * TODO: add possibility to add location (S3, git) instead of inline code.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable.Code code = 5;
 */
code: Runnable_Code;
/**
 * Output only. Name of an entity that created the Runnable.
 *
 * @generated from field: string creator = 6;
 */
creator?: string;
/**
 * Output only. Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 7;
 */
creatorDisplayName?: string;
/**
 * Output only. Time when the Runnable was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 8;
 */
createTime?: string;
}
;
/**
 * The code (notebook or interpreter language script (python/R etc.)
 *
 * @generated from message ai.h2o.orchestrator.v1.Runnable.Code
 */
export type Runnable_Code = {
/**
 * type of code (notebook or script)
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable.Code.Type type = 1;
 */
type: Runnable_Code_Type;
/**
 * language of source code
 *
 * @generated from field: ai.h2o.orchestrator.v1.Runnable.Code.Language programming_language = 2;
 */
programmingLanguage: Runnable_Code_Language;
/**
 * source code
 *
 * @generated from field: string source = 3;
 */
source: string;
}
;
/**
 * type of code
 *
 * @generated from enum ai.h2o.orchestrator.v1.Runnable.Code.Type
 */
export enum Runnable_Code_Type {
/**
 * type is unspecified or unknown
 *
 * @generated from enum value: TYPE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "TYPE_UNSPECIFIED",

/**
 * type is script
 *
 * @generated from enum value: TYPE_SCRIPT = 1;
 */
SCRIPT = "TYPE_SCRIPT",

/**
 * type is notebook
 *
 * @generated from enum value: TYPE_NOTEBOOK = 2;
 */
NOTEBOOK = "TYPE_NOTEBOOK",
}
/**
 * source code language
 *
 * @generated from enum ai.h2o.orchestrator.v1.Runnable.Code.Language
 */
export enum Runnable_Code_Language {
/**
 * language is unspecified or unknown
 *
 * @generated from enum value: LANGUAGE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "LANGUAGE_UNSPECIFIED",

/**
 * python
 *
 * @generated from enum value: LANGUAGE_PYTHON = 1;
 */
PYTHON = "LANGUAGE_PYTHON",

/**
 * R
 *
 * @generated from enum value: LANGUAGE_R = 2;
 */
R = "LANGUAGE_R",

/**
 * python with spark support
 *
 * @generated from enum value: LANGUAGE_SPARK_PYTHON = 3;
 */
SPARK_PYTHON = "LANGUAGE_SPARK_PYTHON",

/**
 * R with spark support
 *
 * @generated from enum value: LANGUAGE_SPARK_R = 4;
 */
SPARK_R = "LANGUAGE_SPARK_R",
}
