import { useEffect } from 'react';

import { OrchestratorService } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useOrchestratorService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const orchestratorApiUrl = cloudPlatformDiscovery?.orchestratorApiUrl || '';
  useEffect(() => {
    OrchestratorService.setBasePath(orchestratorApiUrl);
  }, [orchestratorApiUrl]);

  return OrchestratorService;
};
