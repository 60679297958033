import { IRawStyle, IStyle, Stack } from '@fluentui/react';
import { useClassNames, useHaicPageTitle } from '@h2oai/ui-kit';
import { createRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { listServices } from '../../discovery-service/api';
import { serviceNames } from '../../discovery-service/constants';
import { ListServicesUtilResponse } from '../../discovery-service/models';
import { EnvService } from '../../services/api';
import { stackStylesNoLeftNav } from '../../themes/themes';
import { useCloudPlatformDiscovery, useEnv, useError } from '../../utils/hooks';
import { handleErrMsg } from '../../utils/utils';
import ErrorPage from '../ErrorPage';
import apiSection from './apiSectionContent';
import cliSection from './cliSectionContent';
import { AccessData, CliAndApiAccessContentType, ContentSection, TableOfContents } from './Helpers';

interface SectionProps extends ContentSection {
  accessData: AccessData | null;
}

interface SectionStyles {
  root: IStyle;
  sectionTitle: IStyle;
  subsectionContainer: IStyle;
  subsectionLabel: IStyle;
  subsectionContent: IStyle;
}

interface SectionClassNames {
  root: string;
  sectionTitle: string;
  subsectionContainer: string;
  subsectionLabel: string;
  subsectionContent: string;
  subSectionCodeContent: string;
}

const sectionStyles = {
  root: {
    marginTop: 36,
    marginRight: 40,
  },
  sectionTitle: {
    margin: 0,
    padding: '20px 0',
  },
  subsectionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subsectionLabel: {
    margin: '14px 0',
    flexBasis: 300,
    flexShrink: 0,
    maxWidth: 300,
  },
  subsectionContent: {
    flexGrow: 1,
  },
  subSectionCodeContent: {
    overflow: 'auto' as IRawStyle['overflow'],
  },
};

const Section = forwardRef((props: SectionProps, ref: React.Ref<HTMLDivElement>) => {
  const classnames = useClassNames<SectionStyles, SectionClassNames>('cliAndApiAccessPageSection', sectionStyles);
  const subsections = props.accessData ? props.subsections(props.accessData) : [];
  return (
    <section id={props.id} ref={ref} className={classnames.root}>
      <h2 className={classnames.sectionTitle}>{props.title}</h2>
      {subsections.map((subsection) => (
        <div className={classnames.subsectionContainer} key={subsection.label}>
          <h3 className={classnames.subsectionLabel}>{subsection.label}</h3>
          <div
            className={`${classnames.subsectionContent} ${
              subsection.type === CliAndApiAccessContentType.CODE ? classnames.subSectionCodeContent : ''
            } `}
          >
            {subsection.renderContent()}
          </div>
        </div>
      ))}
    </section>
  );
});

interface PageStyles {
  container: IStyle;
  main: IStyle;
}

interface PageClassNames {
  container: string;
  main: string;
}

const pageStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 80,
    marginBottom: 120,
  },
  main: {
    marginLeft: 100,
    maxWidth: '75vw',
  },
};

function deriveServiceProperty(
  services: ListServicesUtilResponse,
  serviceName: string,
  propertyName: string
): string | undefined {
  if (!services) return undefined;
  const service = services.find((s) => s.name === serviceName);
  return service?.[propertyName];
}

function CliAndApiAccessPage() {
  const observerRef = useRef<IntersectionObserver | null>(null),
    [accessData, setAccessData] = useState<AccessData | null>(null),
    [sectionIntersections, setSectionIntersections] = useState({}),
    [err, setErr] = useError(),
    discoveryInfo = useCloudPlatformDiscovery(),
    cliInfoRef = createRef<HTMLDivElement>(),
    apiInfoRef = createRef<HTMLDivElement>(),
    maxSectionId = useMemo(() => {
      const sectionIds = Object.keys(sectionIntersections);
      return sectionIds.length > 0
        ? sectionIds.reduce((highestKey, key) => {
            if (sectionIntersections[key] > sectionIntersections[highestKey]) return key;
            return highestKey;
          })
        : undefined;
    }, [sectionIntersections]),
    loadCliData = useCallback(async () => {
      try {
        const cliData = await EnvService.getApiAccessConfig({});
        const services = await listServices({ pageSize: 1000 }); // ensure we get ALL services
        setAccessData({
          ...cliData,
          getTokenUrl: `${cliData.serverAddress}/auth/get-token`,
          getPlatformTokenUrl: `${cliData.serverAddress}/auth/get-platform-token`,
          mlopsPythonClient: deriveServiceProperty(services, serviceNames.mlops, 'pythonClient'),
          steamPythonClient: deriveServiceProperty(services, serviceNames.steam, 'pythonClient'),
          aiemPythonClient: deriveServiceProperty(services, serviceNames.aiem, 'pythonClient'),
          steamUri: deriveServiceProperty(services, serviceNames.steam, 'uri'),
          mlopsUri:
            deriveServiceProperty(services, serviceNames.mlops, 'uri') ||
            deriveServiceProperty(services, serviceNames.mlopsLegacy, 'uri'),
          aiemUri: deriveServiceProperty(services, serviceNames.aiem, 'uri'),
          h2oCloudEnvironment: discoveryInfo?.h2oCloudEnvironment,
        });
      } catch ({ message }) {
        setErr({
          message: `An error occurred while loading CLI config: ${handleErrMsg(message as string)}`,
          fatal: true,
        });
      }
    }, [setErr]),
    classnames = useClassNames<PageStyles, PageClassNames>('cliAndApiAccessPage', pageStyles),
    env = useEnv();

  useEffect(() => {
    if (
      !observerRef.current &&
      apiInfoRef &&
      cliInfoRef &&
      apiInfoRef.current instanceof Element &&
      cliInfoRef.current instanceof Element
    ) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const id = entry.target.id;
            setSectionIntersections((prev) => ({ ...prev, [id]: entry.intersectionRatio }));
          });
        },
        {
          threshold: [0, 0.5, 1],
        }
      );
      observer.observe(apiInfoRef.current);
      observer.observe(cliInfoRef.current);
      observerRef.current = observer;
    }
  }, [cliInfoRef, apiInfoRef, setSectionIntersections]);

  useEffect(
    () => () => {
      // cleanup intersection observer in separate useEffect that only runs on unmount
      if (observerRef.current) observerRef.current.disconnect();
    },
    []
  );

  useEffect(() => {
    loadCliData();
  }, [loadCliData]);

  useHaicPageTitle('CLI & API', env?.cloudInstanceName);
  return err?.fatal ? (
    <ErrorPage {...err} />
  ) : (
    <Stack styles={stackStylesNoLeftNav}>
      <div className={classnames.container}>
        <TableOfContents activeSectionKey={maxSectionId} sections={[apiSection, cliSection]} />
        <main className={classnames.main}>
          <Section {...apiSection} accessData={accessData} ref={apiInfoRef} />
          <Section {...cliSection} accessData={accessData} ref={cliInfoRef} />
        </main>
      </div>
    </Stack>
  );
}

export default CliAndApiAccessPage;
