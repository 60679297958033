import { useBoolean } from '@fluentui/react-hooks';
import { useMediaQuery } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo } from 'react';

import { AppInstance } from '../../ai.h2o.cloud.appstore';
import { FilterRefinement, useRefineData } from '../../utils/hooks';
import { instanceStatusMap, instanceVisibilityMap, matchesSearchString } from '../../utils/utils';
import { ListPageFilter, ListPageSearchAndFilter } from '../ListPages/ListPageSearchAndFilter';
import { InstanceFilterPanel } from './InstanceFilterPanel';

interface InstanceSearchAndFilterProps {
  instances: AppInstance[];
  onRefine: (data: AppInstance[]) => void;
}

export function InstanceSearchAndFilter(props: InstanceSearchAndFilterProps) {
  const { instances, onRefine } = props,
    { isDesktop } = useMediaQuery(),
    [filterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] = useBoolean(false),
    filterSearch = (searchKey: string, data: AppInstance[]) => {
      if (searchKey === '') return data;
      return data.filter((instance) =>
        matchesSearchString(
          [
            instance?.appDetails?.name,
            instance?.appDetails?.title,
            instance?.appDetails?.id,
            instance?.appDetails?.description,
            instance?.appDetails?.longDescription,
            instance?.appDetails?.version,
            instance?.id,
          ],
          searchKey
        )
      );
    },
    {
      data: refinedInstances,
      searchKey,
      setSearchKey,
      filterMap,
      setFilter,
      resetFilter,
      resetAllFilters,
    } = useRefineData<AppInstance>({
      data: instances,
      onSearch: useCallback(filterSearch, []),
      onFilter: useCallback<FilterRefinement<AppInstance>>((filterSet, data) => {
        const filterFunctions: Array<(item: AppInstance) => boolean> = [];
        for (const key in filterSet) {
          if (filterSet.hasOwnProperty(key)) {
            const label = filterSet[key];
            if (label) {
              filterFunctions.push((item: AppInstance) => label === item[key]);
            }
          }
        }
        return data.filter((instance) => filterFunctions.every((filterFunction) => filterFunction(instance)));
      }, []),
    }),
    activeFilters = useMemo(() => {
      if (!filterMap) return [];
      const filters: ListPageFilter[] = [];
      for (const key in filterMap) {
        if (filterMap.hasOwnProperty(key)) {
          const rawLabel = filterMap[key];
          const label = instanceStatusMap[rawLabel] || instanceVisibilityMap[rawLabel];
          if (label) {
            filters.push({ key, label });
          }
        }
      }
      return filters;
    }, [filterMap]),
    onChangeSearchInput = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]),
    onRemoveFilter = useCallback(
      (option) => {
        resetFilter(option.key);
      },
      [filterMap]
    ),
    onChangeStatesDropdown = useCallback(
      (_, option) => {
        setFilter('status', option.key || '');
      },
      [filterMap]
    ),
    onChangeVisibilityDropdown = useCallback(
      (_, option) => {
        setFilter('visibility', option.key || '');
      },
      [filterMap]
    );

  useEffect(() => {
    if (onRefine) {
      onRefine(refinedInstances);
    }
  }, [refinedInstances, onRefine]);
  return (
    <>
      <InstanceFilterPanel
        isOpen={filterPanelOpen}
        statesFilterKey={filterMap?.status || ''}
        visibilityFilterKey={filterMap?.visibility || ''}
        onDismiss={dismissFilterPanel}
        onChangeStates={onChangeStatesDropdown}
        onChangeVisibility={onChangeVisibilityDropdown}
      />
      <ListPageSearchAndFilter
        searchPlaceholder={isDesktop ? 'Search by app name, title, ID, version, description' : 'Search Engine'}
        activeFilters={activeFilters}
        searchText={searchKey}
        onChangeSearchText={onChangeSearchInput}
        showFilterButton={true}
        onOpenFilterPanel={openFilterPanel}
        onRemoveFilter={onRemoveFilter}
        onResetFilters={resetAllFilters}
      />
    </>
  );
}
