import { IStyle, MessageBarType } from '@fluentui/react';
import { Button, Dropdown, Loader, TextField, buttonStylesPrimary, useClassNames, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ExecutorPool } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_pb';
import { ListExecutorPoolsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import {
  Runnable,
  Runnable_Code,
  Runnable_Code_Language,
  Runnable_Code_Type,
} from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { GetRunnableResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import Editor from './Editor';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import { formatError } from './Workflows';
import { useWorkspaces } from './WorkspaceProvider';

export type DropdownOption = { key: string | number; text: string };

export type RunnableNavParams = { runnable_id: string; workspace_id: string };

export const LANGUAGE_DISPLAY_NAMES = {
  LANGUAGE_PYTHON: 'Python',
  LANGUAGE_R: 'R',
  LANGUAGE_SPARK_PYTHON: 'Spark Python',
  LANGUAGE_SPARK_R: 'Spark R',
};

export const CODE_TYPE_DISPLAY_NAMES = {
  TYPE_SCRIPT: 'Script',
  TYPE_NOTEBOOK: 'Notebook',
};

interface IRunnableDetailStyles {
  footerButton: IStyle;
  dialogInputs: IStyle;
  dialogInput: IStyle;
  form: IStyle;
  loader: IStyle;
}

const runnableDetailStyles: Partial<IRunnableDetailStyles> = {
    footerButton: {
      marginTop: 8,
      marginRight: 4,
    },
    dialogInputs: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 16,
    },
    dialogInput: {
      width: '30%',
      maxWidth: 360,
      marginRight: 12,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 20,
      margin: '0px 40px',
    },
    loader: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  languageOptions = [
    { key: 'LANGUAGE_PYTHON', text: LANGUAGE_DISPLAY_NAMES.LANGUAGE_PYTHON },
    { key: 'LANGUAGE_R', text: LANGUAGE_DISPLAY_NAMES.LANGUAGE_R },
  ],
  codeTypeOptions = [
    { key: 'TYPE_SCRIPT', text: CODE_TYPE_DISPLAY_NAMES.TYPE_SCRIPT },
    { key: 'TYPE_NOTEBOOK', text: CODE_TYPE_DISPLAY_NAMES.TYPE_NOTEBOOK },
  ],
  toEditorLanguage = (language: Runnable_Code_Language) => {
    switch (language) {
      case Runnable_Code_Language.PYTHON:
        return 'python';
      case Runnable_Code_Language.R:
        return 'r';
      default:
        return 'python';
    }
  };

const RunnableDetail = () => {
  const { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    classNames = useClassNames<IRunnableDetailStyles, ClassNamesFromIStyles<IRunnableDetailStyles>>(
      'runnableDetail',
      runnableDetailStyles
    ),
    history = useHistory(),
    params = useParams<RunnableNavParams>(),
    orchestratorService = useOrchestratorService(),
    [runnable, setRunnable] = React.useState<Runnable>(),
    isNew = !runnable,
    [language, setLanguage] = React.useState<Runnable_Code_Language>(
      runnable?.code?.programmingLanguage || Runnable_Code_Language.PYTHON
    ),
    [type, setType] = React.useState<Runnable_Code_Type>(runnable?.code?.type || Runnable_Code_Type.SCRIPT),
    [source, setSource] = React.useState(runnable?.code?.source || ''),
    [runnableDisplayName, setRunnableDisplayName] = React.useState(runnable?.displayName || ''),
    [runnablePool, setRunnablePool] = React.useState(runnable?.executorPool || ''),
    [executorPools, setExecutorPools] = React.useState<ExecutorPool[]>(),
    [showRunnableValidation, setShowRunnableValidation] = React.useState(false),
    [poolOptions, setPoolOptions] = React.useState<DropdownOption[]>([]),
    [isActionDisabled, setIsActionDisabled] = React.useState(false),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRunnable: false,
      fetchExecutorPool: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchRunnable && !loadStateRef.current.fetchExecutorPool) setLoading(false);
    },
    getRunnable = React.useCallback(async () => {
      if (!ACTIVE_WORKSPACE_NAME) return;
      loadStateRef.current.fetchRunnable = true;
      setLoading(true);
      try {
        const data: GetRunnableResponse = await orchestratorService.getRunnable({
          name: `workspaces/${params.workspace_id}/runnables/${params.runnable_id}`,
        });
        setRunnable(data?.runnable);
      } catch (err) {
        const message = `Failed to fetch runnable: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchRunnable = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService, addToast, params.runnable_id, params.workspace_id]),
    createRunnable = React.useCallback(
      async (displayName: string, executorPool: string, code: Runnable_Code) => {
        setIsActionDisabled(true);
        try {
          await orchestratorService.createRunnable({
            parent: ACTIVE_WORKSPACE_NAME || '',
            runnable: { displayName, executorPool, code },
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Runnable created successfully.',
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to create runnable: ${formatError(err)}`;
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          console.error(message);
        } finally {
          setIsActionDisabled(false);
        }
      },
      [ACTIVE_WORKSPACE_NAME, orchestratorService, addToast, history]
    ),
    updateRunnable = React.useCallback(
      async (name: string, displayName: string, executorPool: string, code: Runnable_Code) => {
        setIsActionDisabled(true);
        try {
          await orchestratorService.editRunnable({
            runnable: { name, displayName, executorPool, code },
            updateMask: 'displayName,executorPool,code',
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Runnable updated successfully.',
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to update runnable: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setIsActionDisabled(false);
        }
      },
      [orchestratorService, addToast, history]
    ),
    fetchExecutionPools = React.useCallback(async () => {
      loadStateRef.current.fetchExecutorPool = true;
      setLoading(true);
      try {
        const data: ListExecutorPoolsResponse = await orchestratorService.getExecutorPools({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setExecutorPools(data.executorPools);
      } catch (err) {
        const message = `Failed to fetch execution pools: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchExecutorPool = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    onActionClick = React.useCallback(() => {
      if (!runnableDisplayName || !runnablePool || !source) {
        setShowRunnableValidation(true);
        return;
      }
      const code: Runnable_Code = { type: type, programmingLanguage: language, source };
      if (isNew) void createRunnable(runnableDisplayName, runnablePool, code);
      // TODO: Handle if runnable.name is not set.
      else void updateRunnable(runnable.name || '', runnableDisplayName, runnablePool, code);
    }, [runnable, runnableDisplayName, runnablePool, source, language, type, isNew, createRunnable, updateRunnable]),
    onLanguageChange = (_ev: React.FormEvent<HTMLDivElement>, option?: DropdownOption) => {
      if (!option) return;
      setLanguage(option.key as Runnable_Code_Language);
    },
    onTypeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: DropdownOption) => {
      if (!option) return;
      setType(option.key as Runnable_Code_Type);
    },
    onCodeChange = React.useCallback((code: string) => setSource(code), []);

  // TODO: Cleanup running requests on unmount.
  React.useEffect(() => {
    if (!ACTIVE_WORKSPACE_NAME) return;
    if (ACTIVE_WORKSPACE_NAME) void fetchExecutionPools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME, fetchExecutionPools]);

  React.useEffect(() => {
    if (params.runnable_id && params.runnable_id !== 'create-new') void getRunnable();
  }, [params.runnable_id, params.workspace_id, getRunnable]);

  React.useEffect(() => {
    // TODO: Handle cases where "name" or "displayName" are not set.
    if (executorPools) {
      setPoolOptions(executorPools.map((p: ExecutorPool) => ({ key: p.name || '', text: p.displayName || '' })));
    }
  }, [executorPools]);

  React.useEffect(() => {
    if (runnable) {
      setSource(runnable?.code?.source);
      setLanguage(runnable?.code?.programmingLanguage || Runnable_Code_Language.PYTHON);
      setRunnablePool(runnable?.executorPool || '');
      setRunnableDisplayName(runnable?.displayName || '');
      setType(runnable?.code?.type || Runnable_Code_Type.SCRIPT);
    }
  }, [runnable]);

  return (
    <NavigationWrapper>
      <Header
        customPageTitle={`${!permissions.canEditRunnables ? 'View' : isNew ? 'Create' : 'Update'} runnable`}
        isActionButtonDisabled={isActionDisabled}
      />
      {loading ? (
        <div className={classNames.loader}>
          <Loader label="Loading runnable detail..." />
        </div>
      ) : (
        <div className={classNames.form}>
          <div className={classNames.dialogInputs}>
            <TextField
              label="Name"
              className={classNames.dialogInput}
              value={runnableDisplayName}
              onChange={(_ev, newValue) => setRunnableDisplayName(newValue || '')}
              // TODO: Improve error handling.
              errorMessage={showRunnableValidation && !runnableDisplayName ? 'This field cannot be empty.' : ''}
              readOnly={!permissions.canEditRunnables}
            />
            <Dropdown
              className={classNames.dialogInput}
              label="Executor Pool"
              defaultSelectedKey={runnablePool}
              onChange={(_ev, option) => setRunnablePool((option?.key as string) || '')}
              options={poolOptions}
              errorMessage={showRunnableValidation && !runnablePool ? 'This field cannot be empty.' : ''}
              disabled={!permissions.canEditRunnables}
            />
            <Dropdown
              className={classNames.dialogInput}
              label="Language"
              defaultSelectedKey={language || 'LANGUAGE_PYTHON'}
              options={languageOptions}
              onChange={onLanguageChange}
              disabled={!permissions.canEditRunnables}
            />
            <Dropdown
              className={classNames.dialogInput}
              label="Type"
              defaultSelectedKey={type}
              options={codeTypeOptions}
              onChange={onTypeChange}
              disabled={!permissions.canEditRunnables}
            />
          </div>
          <Editor
            language={toEditorLanguage(language)}
            defaultCode={source}
            onCodeChange={onCodeChange}
            error={showRunnableValidation && !source ? 'This field cannot be empty.' : ''}
            readOnly={!permissions.canEditRunnables}
          />
          {permissions.canEditRunnables ? (
            <Button
              text="Save changes"
              onClick={onActionClick}
              styles={buttonStylesPrimary}
              className={classNames.footerButton}
            />
          ) : null}
        </div>
      )}
    </NavigationWrapper>
  );
};

export default RunnableDetail;
