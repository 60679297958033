import { IContextualMenuItem, concatStyleSets } from '@fluentui/react';
import {
  Card,
  CardList as H2OCardList,
  IH2OTheme,
  IItemMetaData,
  Loader,
  LoaderType,
  cardStylesNoGap,
  loaderStylesProgressIndicatorDefault,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';

import { App, AppInstance, AppInstance_Status, TagAssignment } from '../../ai.h2o.cloud.appstore';
import { BaseCategory } from '../../pages/AppStorePage/AppStorePage.models';
import { useApp } from '../../utils/hooks';
import { InstancePauseResumeOpEnum } from '../../utils/utils';
import { InstanceActionButton } from '../InstanceActionButton/InstanceActionButton';

export enum CardType {
  app = 'app',
  instance = 'instance',
}

interface ICard {
  id: string;
  owner: string;
  title: string;
  description: string;
  iconLocation: string;
  categories: string;
  pinned?: boolean;
  liked?: boolean;
  likes?: number;
  status?: string;
  statusColor?: string;
}

export interface ICardListProps {
  data: App[];
  type?: CardType;
  emptyMessage?: string;
  getMenuItems?: (d: any) => IContextualMenuItem[];
  onClick?: (d: any) => void;
  onLike?: (d: any) => void;
  onPin?: (d: any) => void;
  columnCount: number;
  metaDatas?: IItemMetaData[];
  useImageLazyLoader?: boolean;
  hideUserName?: boolean;
  hideCategories?: boolean;
  showStatus?: boolean;
  showLoader?: boolean;
  disableActions?: boolean;
  noGap?: boolean;
  setInstanceSuspension?: (instance: AppInstance, op: InstancePauseResumeOpEnum) => () => void;
}

const getCategories = (tags: TagAssignment[]): string => {
  const tagList = tags.reduce((items: string[], { hidden, isCategory, title }) => {
    if (isCategory && !hidden) {
      items.push(title);
    }
    return items;
  }, []);
  if (!tagList.length) {
    tagList.push(BaseCategory.Other);
  }
  return tagList.join(' | ');
};

const getAppCard = ({ id, owner, title, iconLocation, tags, description, preference }: App): ICard => {
  return {
    id,
    owner,
    title,
    iconLocation: iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png',
    categories: getCategories(tags),
    description,
    pinned: preference?.pinned,
    liked: preference?.liked,
    likes: preference?.likes,
  };
};

const getAppInstanceCard = ({ id, owner, suspendable, status, appDetails }: AppInstance, theme: IH2OTheme): ICard => {
  const { title, iconLocation, tags, description, preference } = appDetails || {};
  const { pinned, liked, likes } = preference || {};
  const statusTextAndColorMap = new Map([
    [0, { status: 'Not Available', statusColor: theme.palette?.gray300 }],
    [1, { status: 'Running', statusColor: theme.palette?.green200 }],
    [2, { status: 'Stopped', statusColor: theme.palette?.red200 }],
  ]);
  const statusTextAndColor =
    suspendable && [AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED].includes(status)
      ? status === AppInstance_Status.DEPLOYED
        ? statusTextAndColorMap.get(1)
        : statusTextAndColorMap.get(2)
      : statusTextAndColorMap.get(0);
  return {
    id,
    owner,
    title: title || ``,
    iconLocation: iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png',
    categories: tags ? getCategories(tags) : ``,
    description: description || ``,
    pinned,
    liked,
    likes,
    status: statusTextAndColor?.status,
    statusColor: statusTextAndColor?.statusColor,
  };
};

const getCardData = (type: CardType, data: unknown, theme: IH2OTheme) => {
  switch (type) {
    case CardType.instance:
      return getAppInstanceCard(data as AppInstance, theme);
    default:
      return getAppCard(data as App);
  }
};

const loaderHeight = 3;

export default function CardList({
  data,
  type = CardType.app,
  emptyMessage = 'No apps found for this category.',
  getMenuItems,
  onPin,
  onLike,
  onClick,
  columnCount,
  metaDatas,
  useImageLazyLoader = true,
  hideUserName,
  hideCategories,
  showStatus,
  showLoader,
  disableActions,
  noGap,
  setInstanceSuspension,
}: ICardListProps) {
  const [loaderId, setLoaderId] = useState<number>();
  const { getAppBadges } = useApp();
  const theme = useTheme();
  const styles = noGap ? cardStylesNoGap : undefined;
  const loaderStyles = {
    root: { position: 'absolute', width: '100%', bottom: '8px' },
    itemProgress: { height: loaderHeight },
    progressBar: {
      height: loaderHeight,
      background: `linear-gradient(to right, ${theme?.palette?.white} 0%, ${theme.semanticColors?.loaderBarIndicator} 50%, ${theme?.palette?.white} 100%)`,
    },
    progressTrack: { height: loaderHeight, background: 'transparent' },
  };
  const itemRenderer = useCallback(
    (item: unknown, _, index: number) => {
      const d = getCardData(type, item, theme);
      const badges = getAppBadges(item as App);
      const menuItems = getMenuItems ? getMenuItems(d) : undefined;
      const clickHandler = onClick
        ? showLoader
          ? () => {
              if (loaderId === undefined) {
                setLoaderId(index);
                onClick(item);
              }
            }
          : () => onClick(item)
        : undefined;
      return (
        <>
          <Card<ICard>
            styles={styles}
            data-test={`${d.title}--app-tile`}
            idField="id"
            categoryField={hideCategories ? undefined : 'categories'}
            titleField="title"
            descriptionField="description"
            userNameField={hideUserName ? undefined : 'owner'}
            pinnedField={onPin ? 'pinned' : undefined}
            likedField={onLike ? 'liked' : undefined}
            likesField={onLike ? 'likes' : undefined}
            pinnedButtonDataTest={`pin-app--${d.title}`}
            likedButtonDataTest={`like-app--${d.title}`}
            menuButtonDataTest={`view-menu-app--${d.title}`}
            statusField={showStatus ? 'status' : undefined}
            statusColorField={showStatus ? 'statusColor' : undefined}
            imagePathField="iconLocation"
            useImageLazyLoader={useImageLazyLoader}
            disableLikedButton={disableActions}
            disablePinnedButton={disableActions}
            data={d}
            badges={badges}
            onClick={clickHandler}
            onClickTitle={clickHandler}
            onLike={() => {
              if (onLike) onLike(item);
            }}
            onPin={() => {
              if (onPin) onPin(item);
            }}
            actions={
              type === CardType.instance ? (
                <InstanceActionButton
                  instance={item as AppInstance}
                  hideTerminateButton={true}
                  hasVisitAppNoTerminatePostponeMenu={true}
                  useVisitInsteadOfPauseButton={true}
                  setInstanceSuspension={setInstanceSuspension}
                />
              ) : undefined
            }
            menuItems={menuItems}
          />
          {showLoader && loaderId === index && (
            <Loader
              type={LoaderType.progressIndicator}
              styles={concatStyleSets(loaderStylesProgressIndicatorDefault, loaderStyles)}
            />
          )}
        </>
      );
    },
    [loaderId]
  );
  return !data?.length ? (
    <span>{emptyMessage}</span>
  ) : (
    <H2OCardList
      styles={styles}
      data={
        data as {
          [key: string]: any;
        }[]
      }
      metaDatas={metaDatas}
      columnCount={columnCount}
      itemRenderer={itemRenderer}
    />
  );
}
