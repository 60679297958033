import { ConstraintNumeric } from './gen/ai/h2o/engine/v1/constraint_pb';
import { DAIEngineServiceUpgradeVersionRequest } from './gen/ai/h2o/engine/v1/dai_engine_service_pb';
import { Engine_Type } from './gen/ai/h2o/engine/v1/engine_pb';
import { ListEnginesRequest } from './gen/ai/h2o/engine/v1/engine_service_pb';
import { toBigIntString } from './gen/runtime';

export const defaultDevelopmentBasePath = 'http://localhost:9000';

export const defaultBasePath =
  process.env.REACT_APP_USE_MOCK_SERVER || process.env.NODE_ENV === 'test' ? defaultDevelopmentBasePath : '';

export const defaultEngineType = Engine_Type.DRIVERLESS_AI;

export const defaultLatest = 'latest';

export const defaultOIDCSuffix = `openid/login`;

export const defaultDAIEngineDisplayName = 'New DAI Engine';

export const defaultEngineId = 'aiem01';

export const defaultWorkspaceID = 'default';

export const defaultWorkspaceName = `workspaces/${defaultWorkspaceID}`;

export const defaultEngineUserId = `ae2f99fc-55b5-49a1-9300-e5c895cd8873`;

export const defaultVersion = '1.10.6';

export const defaultConstraintSet: ConstraintNumeric = {
  min: toBigIntString('0'),
  max: toBigIntString('0'),
  default: toBigIntString('0'),
};

export const defaultListRequest: ListEnginesRequest | object = {
  pageSize: 1000, // if page size is greater than 1000 it will be coerced back to 1000, pagination must be implemented in a later issue because DetailsList pagination is yet to be implemented.
  orderBy: '',
  pageToken: '', // Leave unset to receive the initial page. To list any subsequent pages use the value of 'next_page_token'\nreturned from the Response
  filter: '',
};

export const defaultEnginesListRequest: ListEnginesRequest = {
  parent: defaultWorkspaceName, // `workspaces/default`
  ...defaultListRequest,
};

export const defaultUpgradeVersionRequest: DAIEngineServiceUpgradeVersionRequest = {
  daiEngine: '',
  newVersion: 'latest',
};
