import { IStyle } from '@fluentui/react';
import { Button, useClassNames } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';

interface IContextMenuStyles {
  menu: IStyle;
  button: IStyle;
  hoverButton: IStyle;
}

const contextMenuStyles: Partial<IContextMenuStyles> = {
  menu: {
    position: 'absolute',
    zIndex: 10,
  },
};

export type ContextMenuProps = {
  top: number;
  left: number;
  right: number;
  bottom: number;
  onPaneClick: () => void;
  onCreateClick: () => void;
};

export const ContextMenu = ({ top, left, right, bottom, onPaneClick, onCreateClick }: ContextMenuProps) => {
  const classNames = useClassNames<IContextMenuStyles, ClassNamesFromIStyles<IContextMenuStyles>>(
    'contextMenu',
    contextMenuStyles
  );

  return (
    <div style={{ top, left, right, bottom }} className={classNames.menu} onClick={onPaneClick}>
      <Button onClick={onCreateClick} text="Add node" iconName="Add" />
    </div>
  );
};
