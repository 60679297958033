import * as dagre from '@dagrejs/dagre';
import { IGroup, IStyle, Icon } from '@fluentui/react';
import { useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';
import ReactFlow, { Background, Controls, Edge, Node } from 'reactflow';

import { ClassNamesFromIStyles } from '../../utils/models';
import { WORKFLOW } from './constants';
import { getAutoNodes, getInitialEdges } from './WorkflowDetail';
import { WorkflowFixed, WorkflowStepFixed, nodeTypes } from './WorkflowTabCanvas';
import { StateProps, WorkflowExecutionItem, getStateProps } from './WorkflowTabExecutions';

interface IWorkflowExecutionFlowView {
  workflow: WorkflowFixed;
  executionDetail: WorkflowExecutionItem;
  detailGroups?: IGroup[] | undefined;
}

interface IWorkflowExecutionFlowStyles {
  labelOuterContainer: IStyle;
  labelInnerContainer: IStyle;
  typeBadge: IStyle;
  stateBadge: IStyle;
  stateText: IStyle;
  stepName: IStyle;
}

const workflowExecutionFlowStyles: IWorkflowExecutionFlowStyles = {
  labelOuterContainer: {
    padding: `${WORKFLOW.LABEL_PADDING_HORIZONTAL}px ${WORKFLOW.LABEL_PADDING_VERTICAL}px`,
    width: WORKFLOW.LABEL_WIDTH,
    height: WORKFLOW.LABEL_HEIGHT,
  },
  labelInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typeBadge: {
    borderRadius: 4,
    fontSize: 11,
    padding: '0px 4px',
    width: 'fit-content',
  },
  stateBadge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stateText: { padding: 0, margin: '0px 2px', fontSize: 10 },
  stepName: {
    fontWeight: 700,
    fontSize: 14,
    paddingTop: 8,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
};

const graph = new dagre.graphlib.Graph();

const WorkflowExecutionFlowView = ({ workflow, executionDetail, detailGroups }: IWorkflowExecutionFlowView) => {
  const [nodes, setNodes] = React.useState<Node[]>([]),
    [edges, setEdges] = React.useState<Edge[]>([]),
    theme = useTheme(),
    classNames = useClassNames<IWorkflowExecutionFlowStyles, ClassNamesFromIStyles<IWorkflowExecutionFlowStyles>>(
      'workflowExecutionFlow',
      workflowExecutionFlowStyles
    ),
    getStepExecutionLabel = ({
      displayName,
      runnable,
      workflow,
      stateProps,
    }: WorkflowStepFixed & { altName?: string; stateProps?: StateProps }) => {
      return (
        <div className={classNames.labelOuterContainer}>
          <div className={classNames.labelInnerContainer}>
            <div
              className={classNames.typeBadge}
              style={{
                backgroundColor:
                  runnable !== undefined
                    ? theme.semanticColors?.buttonPrimaryBackground
                    : workflow !== undefined
                    ? theme.palette?.red200
                    : theme.palette?.gray300,
              }}
            >
              {runnable !== undefined ? 'Runnable' : workflow !== undefined ? 'Workflow' : 'Step'}
            </div>
            <div className={classNames.stateBadge}>
              <Icon iconName={stateProps?.icon} style={{ color: stateProps?.color }} />
              <div className={classNames.stateText} style={{ color: stateProps?.color }}>
                {stateProps?.name}
              </div>
            </div>
          </div>
          <div className={classNames.stepName}>{displayName || <br />}</div>
        </div>
      );
    };

  React.useEffect(() => {
    setNodes(() =>
      workflow.steps.map((step) => {
        // TODO: Optimize: avoid find.
        const stepDetail = executionDetail?.stepExecutions?.find(
            (stepExecution) =>
              (step?.runnable && stepExecution.runnableExecution?.runnable?.startsWith(step?.runnable)) ||
              (step?.workflow && stepExecution.workflowExecution?.workflowExecution?.startsWith(step?.workflow))
          ),
          stateProps = getStateProps(stepDetail?.state),
          color = stateProps?.color,
          displayName = step?.displayName || detailGroups?.find((group) => group.key === step?.uniqueId)?.name || '';
        return {
          id: step?.uniqueId || '',
          type: 'custom',
          position: { x: step.xAxis || 0, y: step.yAxis || 0 },
          data: {
            uniqueId: step?.uniqueId || '',
            displayName,
            label: getStepExecutionLabel({ ...step, displayName, stateProps }),
            runnable: step?.runnable,
            workflow: step?.workflow,
            parameters: step?.parameters,
            dependsOn: step?.dependsOn,
            isError: false,
            isActive: false,
            borderColor: color,
            readonly: true,
          },
        };
      })
    );
    setEdges(getInitialEdges(workflow));
  }, [workflow, executionDetail, detailGroups]);

  React.useEffect(() => {
    if (nodes?.length) {
      // Calculate initial positions for nodes if it was created through API (xAxis and yAxis of all nodes are 0).
      const noNodeIsPositioned = nodes.every((node) => node.position.x === 0 && node.position.y === 0);
      if (noNodeIsPositioned) {
        setNodes((nodes) => {
          return getAutoNodes(graph, workflow?.steps || [], nodes);
        });
      }
    }
  }, [nodes]);

  return (
    <ReactFlow
      id="react-flow"
      contentEditable={false}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      nodesConnectable={false}
      onNodeClick={(_ev, node) => {
        window.location.href = `#${node.id}`;
      }}
    >
      <Controls showInteractive={false} />
      <Background />
    </ReactFlow>
  );
};

export default WorkflowExecutionFlowView;
