import { Label, Stack, Toggle } from '@fluentui/react';
import { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';

import { EntityField } from '../../../aiem/entity/types';
import { ConstraintNumeric } from '../../../aiem/gen/ai/h2o/engine/v1/constraint_pb';
import { ConstraintType, constraintTypeMapAttributes } from '../../../aiem/types';
import SpinnerWithTooltip from '../../../components/AIEnginesPage/components/SpinnerWithTooltip/SpinnerWithTooltip';
import { EntityFieldInputProps } from './BasicEntityModelComponents';
import { defaultConstraintHeaderRowStyles, defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

type ConstraintSetSubFieldContainerProps = {
  children?: React.ReactNode;
};

const ConstraintSetSubFieldContainer = ({ children }: ConstraintSetSubFieldContainerProps) => (
  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { minWidth: 150 } }}>
    {children}
  </Stack>
);

interface ConstraintSetState {
  min: number;
  max: number;
  defaultValue: number;
  infinite: boolean;
}

enum ConstraintSetAction {
  MIN = 'min',
  INFINITE = 'infinite',
  MAX = 'max',
  DEFAULT_VALUE = 'defaultValue',
}

type ConstraintSetActions = { type: ConstraintSetAction; value: any };

type ConstraintSetReducerFunction = (state: ConstraintSetState, action: ConstraintSetActions) => ConstraintSetState;

const constraintSetReducer: ConstraintSetReducerFunction = (
  state: ConstraintSetState,
  action: ConstraintSetActions
): ConstraintSetState => {
  const newState = { ...state };
  switch (action.type) {
    case ConstraintSetAction.MIN:
      newState.min = action.value;
      break;
    case ConstraintSetAction.MAX:
      newState.max = action.value;
      break;
    case ConstraintSetAction.DEFAULT_VALUE:
      newState.defaultValue = action.value;
      break;
    case ConstraintSetAction.INFINITE:
      newState.infinite = action.value;
      break;
  }
  return newState;
};

export default function ConstraintSetModelField<EntityModel>({
  field,
  model,
  onChange: onChangeModel,
}: EntityFieldInputProps<EntityModel>) {
  const { constraintType = ConstraintType.CPU, label, name, description } = field,
    attributes = constraintTypeMapAttributes[constraintType] || {};
  const { max: maxMax, min: minMin, suffix, ToView, FromView } = attributes,
    constraint: ConstraintNumeric = (model[name] as ConstraintNumeric) || {
      min: '0',
      max: '0',
      default: '0',
    };

  const convertToView = useCallback(
    (value): number => {
      const newValue = Number(ToView ? ToView(value) : value);
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    },
    [ToView]
  );

  const firstRender = useRef<boolean>(true);
  const originalMax = useRef<string | number | null | undefined>(ToView ? ToView(constraint.max) : constraint.max);
  const [constraintSetState, constraintSetDispatch] = useReducer<ConstraintSetReducerFunction>(constraintSetReducer, {
    min: convertToView(constraint.min),
    max: convertToView(constraint.max),
    defaultValue: convertToView(constraint.default),
    infinite: !Boolean(constraint.max),
  });

  const updateMin = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    // if the default is below the min now, we adjust that too:
    if (value > constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value > constraintSetState.max && !constraintSetState.infinite) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    }
  };
  const updateMax = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    originalMax.current = value;
    // if the max is below the default, adjust the default:
    if (value < constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value < constraintSetState.min) {
      constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    }
  };

  const updateDefaultValue = (value: number) => {
    constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
  };

  const updateInfinite = (value: boolean) => {
    if (!value) {
      const newValue = originalMax.current
        ? Math.max(Number(originalMax.current), constraintSetState.min, constraintSetState.defaultValue)
        : constraintSetState.defaultValue;
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: newValue });
      if (originalMax.current === undefined) {
        constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value: newValue });
      }
    }
    if (value) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: null });
    }
    constraintSetDispatch({ type: ConstraintSetAction.INFINITE, value });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!onChangeModel) {
      return;
    }
    const newConstraintSet = {
      min: FromView ? FromView(constraintSetState.min) : constraintSetState.min,
      default: FromView ? FromView(constraintSetState.defaultValue) : constraintSetState.defaultValue,
    };
    if (!constraintSetState.infinite) {
      newConstraintSet['max'] = FromView ? FromView(constraintSetState.max) : constraintSetState.max;
    }
    onChangeModel(name, newConstraintSet);
  }, [constraintSetState]);

  return (
    <Stack horizontal styles={{ root: defaultEntityFormRowStyles }}>
      <LabelAndDescription label={label} description={description} />
      <ConstraintSetSubFieldContainer>
        <SpinnerWithTooltip
          onChange={(_: any, value: number) => {
            updateMin(value);
          }}
          value={convertToView(constraint.min)}
          min={minMin || 0}
          max={maxMax}
          suffix={suffix}
        />
      </ConstraintSetSubFieldContainer>

      <ConstraintSetSubFieldContainer>
        <Toggle
          checked={constraintSetState.infinite}
          onChange={(_, checked) => {
            const value = Boolean(checked);
            updateInfinite(value);
          }}
        />
      </ConstraintSetSubFieldContainer>

      <ConstraintSetSubFieldContainer>
        {constraintSetState.infinite ? (
          'No limit'
        ) : (
          <SpinnerWithTooltip
            onChange={(_: any, value: number) => {
              updateMax(value);
            }}
            value={convertToView(constraint.max)}
            min={0}
            max={maxMax}
            suffix={suffix}
            disabled={constraintSetState.infinite}
          />
        )}
      </ConstraintSetSubFieldContainer>

      <ConstraintSetSubFieldContainer>
        <SpinnerWithTooltip
          onChange={(_: any, value: number) => {
            updateDefaultValue(value);
          }}
          value={convertToView(constraint.default)}
          min={constraintSetState.min}
          max={constraintSetState.infinite ? undefined : constraintSetState.max}
          suffix={suffix}
        />
      </ConstraintSetSubFieldContainer>
    </Stack>
  );
}

type ConstraintFieldsTableHeaderLabelProps = {
  label: string;
  width?: number;
};

export function ConstraintFieldsTableHeaderLabel({
  label,
  width: minWidth = 150,
}: ConstraintFieldsTableHeaderLabelProps) {
  return (
    <Stack horizontalAlign="center" styles={{ root: { minWidth } }}>
      <Label styles={{ root: { fontWeight: 700 } }}>{label}</Label>
    </Stack>
  );
}

type ConstraintFieldsTableProps<EntityModel> = Omit<EntityFieldInputProps<EntityModel>, 'field'> & {
  fields: EntityField<EntityModel>[];
};

export function ConstraintFieldsTable<EntityModel>({
  entityType,
  fields,
  model,
  onChange,
}: ConstraintFieldsTableProps<EntityModel>) {
  return (
    <Stack>
      <Stack
        horizontal
        styles={{
          root: defaultConstraintHeaderRowStyles,
        }}
      >
        <ConstraintFieldsTableHeaderLabel label="Min" />
        <ConstraintFieldsTableHeaderLabel label="No limit" />
        <ConstraintFieldsTableHeaderLabel label="Max" />
        <ConstraintFieldsTableHeaderLabel label="Default" />
      </Stack>
      {fields.map((field) => (
        <Fragment key={String(field.name)}>
          <ConstraintSetModelField<EntityModel>
            field={field}
            model={model}
            onChange={onChange}
            entityType={entityType}
          />
        </Fragment>
      ))}
    </Stack>
  );
}
