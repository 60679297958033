import { IDialogStyles, MessageBarType, Stack } from '@fluentui/react';
import { ConfirmDialog, IComponent, MessageBar, MessageDialog, partition, useTheme, useToast } from '@h2oai/ui-kit';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { App, AppInstance } from '../../ai.h2o.cloud.appstore';
import { AdminAppService, AppService } from '../../services/api';
import { useError } from '../../utils/hooks';
import { handleErrMsg } from '../../utils/utils';
import { AppListResources } from '../AppListPage/models';
import AppVersionList from './AppVersionList';

const modalTitle = 'Delete Apps';

enum MessageDialogType {
  loadingDialog = 'loading',
  errorDialog = 'error',
  noDeletableApps = 'noDeletableApps',
}

export interface DeleteAppDialogProps<IDialogStyles> extends IComponent<IDialogStyles> {
  deletionApps: App[] | null;
  instances: AppInstance[];
  isAdmin: boolean;
  hidden: boolean;
  setDeletionPendingAppIds: React.Dispatch<React.SetStateAction<string[]>>;
  setResources: React.Dispatch<React.SetStateAction<AppListResources>>;
  onFinishDelete: () => void;
  onDismiss: () => void;
  onDismissed: () => void;
}

function DeleteAppsDialog(props: DeleteAppDialogProps<IDialogStyles>) {
  const {
      deletionApps,
      setDeletionPendingAppIds,
      setResources,
      instances,
      hidden,
      isAdmin,
      onFinishDelete,
      onDismiss,
      onDismissed,
    } = props,
    theme = useTheme(),
    { addToast } = useToast(),
    [err, setErr, onDismissErr] = useError(),
    hiddenRef = useRef<boolean>(),
    [appsWithInstances, appsWithoutInstances] = useMemo(() => {
      const instanceAppIds = instances.map((i) => i.appId);
      return partition<App>(deletionApps || [], (app) => instanceAppIds.includes(app.id));
    }, [deletionApps, instances]),
    onClickDismiss = useCallback(() => {
      onDismissErr();
      onDismiss();
    }, [onDismiss, onDismissErr]),
    onDialogDismissed = useCallback(() => {
      onDismissed();
    }, []),
    onClickConfirm = useCallback(async () => {
      try {
        if (appsWithoutInstances) {
          setDeletionPendingAppIds((prevDeletionPendingAppIds: string[]) => {
            return [...prevDeletionPendingAppIds, ...appsWithoutInstances.map((app) => app.id)];
          });
          const deletionRequests = Promise.allSettled(
            appsWithoutInstances.map(async (app) => {
              try {
                if (isAdmin) {
                  await AdminAppService.deleteApp({ id: app.id });
                } else {
                  await AppService.deleteApp({ id: app.id });
                }
                addToast({
                  message: `Successfully deleted app version with id ${app.id}`,
                  messageBarType: MessageBarType.success,
                });
                setResources((prevResources: AppListResources) => {
                  const nextApps = prevResources.apps?.filter((a) => a.id !== app.id);
                  const nextAppGroups = prevResources.appGroups
                    ?.map((group) => {
                      const nextAppsInGroup = group.data.apps?.filter((a) => a.id !== app.id);
                      return {
                        ...group,
                        data: {
                          ...group.data,
                          apps: nextAppsInGroup,
                        },
                      };
                    })
                    .filter((group) => group.data.apps?.length > 0);
                  return {
                    ...prevResources,
                    apps: nextApps || null,
                    appGroups: nextAppGroups || null,
                  };
                });
              } catch (e) {
                addToast({
                  message: `Failed to delete app version with id ${app.id}`,
                  messageBarType: MessageBarType.error,
                });
              } finally {
                setDeletionPendingAppIds((prevDeletionPendingAppIds: string[]) => {
                  return prevDeletionPendingAppIds.filter((id) => id !== app.id);
                });
              }
            })
          );
          onDismiss();
          await deletionRequests;
          onFinishDelete();
        } else {
          // safeguard, confirmation button should not appear unless some app versions are deletable
          setErr({ message: 'No app versions to delete' });
        }
      } catch ({ message }) {
        setErr({ message: `Something went wrong while attempting to delete apps: ${handleErrMsg(message as string)}` });
      }
    }, [appsWithoutInstances, setErr, onFinishDelete, AppService, hiddenRef, setDeletionPendingAppIds, onDismiss]),
    baseDialogProps = {
      hidden,
      title: modalTitle,
      minWidth: 600,
      onDismiss: onClickDismiss,
      modalProps: {
        onDismissed: onDialogDismissed,
      },
    },
    spacerStyles = { borderTop: `1px solid ${theme.palette?.gray400}`, marginTop: 12, paddingTop: 12 },
    someAppsCanBeDeleted = appsWithoutInstances && appsWithoutInstances.length > 0,
    someAppsCannotBeDeleted = appsWithInstances && appsWithInstances.length > 0,
    messageDialogPropMap = {
      [MessageDialogType.errorDialog]: {
        msg: '',
        content: (
          <MessageBar messageBarType={MessageBarType.error}>
            {err?.message || 'Something went wrong while communicating with the server'}
          </MessageBar>
        ),
      },
      [MessageDialogType.noDeletableApps]: {
        msg: 'All selected app versions have instances. You cannot delete apps with instances',
      },
    };

  useEffect(() => {
    hiddenRef.current = hidden;
  }, [hidden]);

  if (err || !someAppsCanBeDeleted) {
    return (
      <MessageDialog
        {...baseDialogProps}
        {...messageDialogPropMap[err ? MessageDialogType.errorDialog : MessageDialogType.noDeletableApps]}
      />
    );
  }

  return (
    <ConfirmDialog
      {...baseDialogProps}
      content={
        <Stack>
          {someAppsCannotBeDeleted && (
            <>
              <MessageBar messageBarType={1}>
                {`${appsWithInstances.length} app version${
                  appsWithInstances.length > 1 ? 's' : ''
                } cannot be deleted because they have instances`}
              </MessageBar>
              <AppVersionList apps={appsWithInstances || []} />
            </>
          )}
          <span style={someAppsCannotBeDeleted ? spacerStyles : undefined}>
            {`${appsWithoutInstances.length} app version${
              appsWithoutInstances.length > 1 ? 's' : ''
            } will be deleted. Do you want to proceed?`}
          </span>
        </Stack>
      }
      onConfirm={onClickConfirm}
    />
  );
}

export default DeleteAppsDialog;
