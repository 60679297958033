import { BaseDialog, Button, Loader, buttonStylesPrimary, debounce } from '@h2oai/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { useEnv } from '../../utils/hooks';
import { autoLogoutDialogStyles } from './autoLogoutDialog.styles';

const defaultTimeout = 30 * 60;
const defaultWarningTime = 1 * 60 * 1000;
const activityEvents = ['load', 'mousemove', 'keypress', 'click', 'scroll', 'touchmove'];

type AutoLogoutProps = {
  timeout?: number;
  warningTime?: number;
  title?: string;
};

export const AutoLogout = (props: AutoLogoutProps) => {
  const env = useEnv();
  const {
    timeout = (env?.userInactivityTimeoutSeconds ?? defaultTimeout) * 1000,
    warningTime = defaultWarningTime,
    title = 'Session Timeout Warning',
  } = props;
  const auth = useAuth();
  const [endTime, setEndTime] = useState(Date.now() + timeout);
  const [remainingTime, setRemainingTime] = useState(timeout);
  const [showWarning, setShowWarning] = useState(true);
  const countdownChannel = useRef<BroadcastChannel | null>(null);
  const remainingSeconds = Math.floor(remainingTime / 1000);
  const percentComplete = remainingTime / warningTime;

  const resetCountdown = useCallback(() => {
    const newEndTime = Date.now() + timeout;
    setEndTime(newEndTime);
    if (countdownChannel.current) {
      countdownChannel.current.postMessage({ endTime: newEndTime });
    }
  }, [timeout]);
  const debouncedResetCountdown = useCallback(debounce(300, resetCountdown), [resetCountdown]);
  const logoutUser = useCallback(async () => {
    const token = auth.user?.id_token;

    console.warn('You have been logged out due to inactivity.');

    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: token, post_logout_redirect_uri: window.location.origin });
  }, []);

  useEffect(() => {
    countdownChannel.current = new BroadcastChannel('countdownChannel');
    countdownChannel.current.postMessage({ endTime });
    countdownChannel.current.onmessage = (event) => {
      if (event.data.endTime) {
        setEndTime(event.data.endTime);
      }
    };

    const updateCountdown = () => {
      const remaining = endTime - Date.now();
      if (remaining <= 0) {
        clearInterval(intervalId);
        logoutUser();
      } else {
        setRemainingTime(remaining);
        setShowWarning(remaining <= warningTime);
      }
    };
    updateCountdown();

    const intervalId = setInterval(updateCountdown, 100);

    return () => {
      clearInterval(intervalId);
      if (countdownChannel.current) {
        countdownChannel.current.close();
      }
    };
  }, [endTime, warningTime]);

  useEffect(() => {
    activityEvents.forEach((event) => window.addEventListener(event, debouncedResetCountdown));

    return () => {
      activityEvents.forEach((event) => window.removeEventListener(event, debouncedResetCountdown));
    };
  }, [debouncedResetCountdown]);

  return showWarning ? (
    <BaseDialog
      styles={autoLogoutDialogStyles}
      hidden={false}
      title={title}
      footer={<Button onClick={resetCountdown} styles={buttonStylesPrimary} text="Keep me logged in" />}
      content={
        <>
          <p>You will be logged out soon due to inactivity.</p>
          <div style={{ textAlign: 'center' }}>
            <img
              src={'/timeout.svg'}
              alt={
                'Time is up! A scuba diver points to an air supply gauge which reads as empty, as a visual metaphor for the session running out.'
              }
              style={{ height: '25vh' }}
            />
          </div>
          <Loader
            label={`Remaining seconds:`}
            description={remainingSeconds.toString()}
            percentComplete={percentComplete}
          />
        </>
      }
    />
  ) : null;
};
