import {
  IPivotItemProps,
  IPivotStyleProps,
  IPivotStyles,
  IStyle,
  IStyleFunctionOrObject,
  Icon,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import { useClassNames } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';

type TabItem = { key: string; text: string; color?: string; count?: number };

type Props = {
  items: TabItem[];
  onFilterChange: (filter: string) => void;
  children: React.ReactNode;
};

interface IPivotFilterStyles {
  pivotItem: IStyle;
}

const pivotFilterStyles: IPivotFilterStyles = {
    pivotItem: {
      '.h2o-WidgetPanel-root': {
        padding: 0,
      },
    },
  },
  pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> | undefined = {
    root: {
      overflowX: 'auto',
      // Hide scrollbar for Chrome, Safari and Opera.
      '::-webkit-scrollbar': {
        display: 'none',
      },
      // Hide scrollbar for IE, Edge and Firefox.
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
    },
    itemContainer: {
      paddingTop: 8,
    },
  };

const customRenderer = (
  link?: IPivotItemProps,
  defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null => {
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      <Icon
        iconName={link.itemIcon}
        style={{
          color: link?.color,
          marginRight: 8,
        }}
      />
      {defaultRenderer({ ...link, itemIcon: undefined })}
    </span>
  );
};

const PivotFilter = ({ items, onFilterChange, children }: Props) => {
  const classNames = useClassNames<IPivotFilterStyles, ClassNamesFromIStyles<IPivotFilterStyles>>(
    'pivotFilter',
    pivotFilterStyles
  );

  return (
    <div>
      <Pivot onLinkClick={(item) => onFilterChange(item?.props?.itemKey || '')} styles={pivotStyles}>
        {items.map(({ key, text, color, count }) => (
          <PivotItem
            className={classNames.pivotItem}
            key={`pivot-item-${key}`}
            itemKey={key}
            headerText={text}
            itemIcon="ToggleFilled"
            itemCount={count}
            color={color}
            onRenderItemLink={customRenderer}
          >
            {children}
          </PivotItem>
        ))}
      </Pivot>
    </div>
  );
};

export default PivotFilter;
