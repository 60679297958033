import * as dagre from '@dagrejs/dagre';
import { MessageBarType } from '@fluentui/react';
import { Loader, Pivot, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Edge, Node, ReactFlowProvider, useEdgesState, useNodesState } from 'reactflow';

import { Runnable } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { ListRunnablesResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { WORKFLOW } from './constants';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { Permissions, useRoles } from './RoleProvider';
import { formatError } from './Workflows';
import {
  GetWorkflowResponseFixed,
  ListWorkflowsResponseFixed,
  WorkflowFixed,
  WorkflowStepFixed,
  WorkflowTabCanvas,
  getLabel,
} from './WorkflowTabCanvas';
import WorkflowTabExecutions from './WorkflowTabExecutions';
import WorkflowTabTriggers from './WorkflowTabTriggers';
import { useWorkspaces } from './WorkspaceProvider';

export type WorkflowNavParams = { workflow_id: string; workspace_id: string; tab_id?: string; item_name?: string };

const flexStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fillStyles = {
    pivotContainerStyles: {
      root: {
        '.ms-Pivot-wrapper': flexStyles,
        ...flexStyles,
      },
    },
    styles: {
      itemContainer: {
        '> div': flexStyles,
        ...flexStyles,
      },
      root: {
        margin: '0px 60px',
      },
    },
  },
  headstartWorkflowStep = {
    uniqueId: 'step-1',
    displayName: 'Step 1',
    runnable: '',
  },
  headstartNodes = [
    {
      id: 'step-1',
      type: 'custom',
      position: { x: 100, y: 100 },
      data: {
        uniqueId: headstartWorkflowStep.uniqueId,
        displayName: headstartWorkflowStep.displayName,
        label: getLabel({ ...headstartWorkflowStep }),
        runnable: headstartWorkflowStep.runnable,
      },
    },
  ],
  getActionText = (selectedKey: '0' | '1' | '2', permissions: Permissions, params: WorkflowNavParams) => {
    const isEditWorkflowMode = selectedKey === '0' && permissions.canEditWorkflows;
    const isEditTriggerMode = selectedKey === '1' && params.tab_id === 'triggers' && permissions.canRunWorkflows;
    const isTriggerPresent = params.item_name;

    if (isEditWorkflowMode) return 'Save changes';

    if (isEditTriggerMode && !isTriggerPresent && params.workflow_id !== 'create-new') return 'Create new trigger';

    return undefined;
  },
  LoaderView = ({ loaderText }: { loaderText?: string }) => (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader label={loaderText || 'Loading...'} />
    </div>
  ),
  graph = new dagre.graphlib.Graph();

export const getInitialNodes = (workflow: WorkflowFixed | undefined) =>
  workflow?.steps
    ? workflow.steps.map(
        (step) =>
          ({
            id: step?.uniqueId || '',
            type: 'custom',
            position: { x: step.xAxis || 0, y: step.yAxis || 0 },
            data: {
              uniqueId: step?.uniqueId || '',
              displayName: step?.displayName || '',
              label: getLabel(step),
              runnable: step?.runnable,
              workflow: step?.workflow,
              parameters: step?.parameters,
              dependsOn: step?.dependsOn,
              isError: false,
              isActive: false,
            },
          } as Node)
      )
    : headstartNodes;
export const getInitialEdges = (workflow: WorkflowFixed | undefined) =>
  (workflow?.steps || []).reduce((acc, step) => {
    if (step.dependsOn) {
      step.dependsOn.forEach((dep) =>
        acc.push({ id: `e${dep}-${step.uniqueId}`, source: dep, target: step.uniqueId || '', animated: true })
      );
    }
    return acc;
  }, [] as Edge[]);
export const getAutoNodes = (graph: dagre.graphlib.Graph<{}>, workflowSteps: WorkflowStepFixed[], nodes: Node[]) => {
  graph.setGraph({});

  graph.setDefaultEdgeLabel(() => ({}));

  workflowSteps.forEach((step) => {
    graph.setNode(step.uniqueId || '', {
      label: step.uniqueId,
      width: WORKFLOW.LABEL_WIDTH + 2 * WORKFLOW.NODE_PADDING_HORIZONTAL,
      height: WORKFLOW.LABEL_HEIGHT + 2 * WORKFLOW.NODE_PADDING_VERTICAL,
    });
  });

  // Add edges to the graph.
  workflowSteps.forEach((step) => {
    if (step.dependsOn) {
      step.dependsOn.forEach((dep) => {
        graph.setEdge(dep, step.uniqueId || '');
      });
    }
  });

  dagre.layout(graph, { rankdir: 'LR' });

  const autoLayoutNodes = graph.nodes().map((v) => graph.node(v));

  return nodes.map((node) => {
    // TODO: Optimize this.
    const layoutNode = autoLayoutNodes.find((n) => n.label === node.id);
    if (layoutNode) {
      return { ...node, position: { x: layoutNode.x, y: layoutNode.y } };
    }
    return node;
  });
};

const WorkflowDetail = () => {
  const history = useHistory(),
    location = useLocation(),
    // Get the default workflow from the location state if it exists to get faster load than via fetch.
    defaultWorkflow = (location?.state as any)?.state as WorkflowFixed,
    [workflow, setWorkflow] = React.useState<WorkflowFixed | undefined>(defaultWorkflow),
    [selectedKey, setSelectedKey] = React.useState<'0' | '1' | '2'>('0'),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    params = useParams<WorkflowNavParams>(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    nodeState = useNodesState(getInitialNodes(defaultWorkflow)),
    edgeState = useEdgesState(getInitialEdges(defaultWorkflow)),
    [nodes, setNodes] = nodeState,
    [edges, setEdges] = edgeState,
    [isSaved, setIsSaved] = React.useState(true),
    [workflowName, setWorkflowName] = React.useState<string>(defaultWorkflow?.displayName || ''),
    [showValidation, setShowValidation] = React.useState(false),
    [concurrencyLimit, setConcurrencyLimit] = React.useState<number>(defaultWorkflow?.concurrencyLimit || 0),
    [timeout, setTimeout] = React.useState<string | null>(defaultWorkflow?.timeout?.slice(0, -1) || null),
    [workflows, setWorkflows] = React.useState<WorkflowFixed[]>(),
    [runnables, setRunnables] = React.useState<Runnable[]>(),
    [isActionDisabled, setIsActionDisabled] = React.useState(false),
    [showLoader, setShowLoader] = React.useState<boolean>(false),
    timeoutRef = React.useRef<number>(),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRunnables: false,
      fetchWorkflow: false,
      fetchWorkflows: false,
    }),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchRunnables &&
        !loadStateRef.current.fetchWorkflow &&
        !loadStateRef.current.fetchWorkflows
      ) {
        setLoading(false);
      }
    },
    getWorkflowBody = React.useCallback(() => {
      const nameUniformEdges = edges.map((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source),
          targetNode = nodes.find((node) => node.id === edge.target),
          source = sourceNode ? `step-${nodes.indexOf(sourceNode)}` : edge.source,
          target = targetNode ? `step-${nodes.indexOf(targetNode)}` : edge.target;
        return { ...edge, source, target };
      });
      return {
        parent: ACTIVE_WORKSPACE_NAME || '',
        workflow: {
          name: workflow?.name,
          displayName: workflowName,
          steps: nodes.map((node, idx) => {
            const dependsOn = nameUniformEdges
              .filter((edge) => edge.target === `step-${idx}`)
              .map((edge) => edge.source);
            return {
              uniqueId: `step-${idx}`,
              displayName: node.data.displayName,
              dependsOn,
              runnable: node.data.runnable,
              workflow: node.data.workflow,
              parameters: node.data.parameters,
              xAxis: node.position.x,
              yAxis: node.position.y,
            };
          }),
          concurrencyLimit,
          timeout: timeout ? `${timeout}s` : null,
        },
      };
    }, [nodes, edges, workflowName, workflow?.name, ACTIVE_WORKSPACE_NAME, timeout, concurrencyLimit]),
    getWorkflow = React.useCallback(async () => {
      loadStateRef.current.fetchWorkflow = true;
      setLoading(true);
      try {
        const data: GetWorkflowResponseFixed = await orchestratorService.getWorkflow({
          name: `workspaces/${params.workspace_id}/workflows/${params.workflow_id}`,
        });
        setWorkflow(data?.workflow);
        setNodes(getInitialNodes(data?.workflow));
        setEdges(getInitialEdges(data?.workflow));
        setWorkflowName(data?.workflow?.displayName || '');
        setConcurrencyLimit(data?.workflow?.concurrencyLimit || 0);
        setTimeout(data?.workflow?.timeout?.slice(0, -1) || null);
      } catch (err) {
        const message = `Failed to fetch workflow: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflow(undefined);
      } finally {
        loadStateRef.current.fetchWorkflow = false;
        evaluateLoading();
      }
    }, [orchestratorService, params.workspace_id, params.workflow_id]),
    createWorkflow = React.useCallback(async () => {
      setIsActionDisabled(true);
      try {
        await orchestratorService.createWorkflow({ ...getWorkflowBody() });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Workflow created successfully.',
        });
        history.goBack();
      } catch (err) {
        const message = `Failed to create workflow: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setIsActionDisabled(false);
      }
    }, [getWorkflowBody, history, orchestratorService]),
    updateWorkflow = React.useCallback(async () => {
      setIsActionDisabled(true);
      try {
        await orchestratorService.editWorkflow({
          workflow: { ...getWorkflowBody().workflow },
          updateMask: 'displayName,steps',
        });
        setIsSaved(true);
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Workflow updated successfully.',
        });
      } catch (err) {
        const message = `Failed to update workflow: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setIsActionDisabled(false);
      }
    }, [getWorkflowBody, orchestratorService]),
    onActionClick = () => {
      if (selectedKey === '0') {
        // For each step checks if the runnable/workflow is both specified and exists within the workspace.
        const allWorkflowStepsValid = nodes.every(
          (node) => searchItemsNameMappings[node.data.runnable || node.data.workflow]
        );
        if (!workflowName || !allWorkflowStepsValid) {
          setShowValidation(true);
          return;
        }
        if (!workflow) void createWorkflow();
        else void updateWorkflow();
      } else if (selectedKey === '1') {
        history.push(
          `/orchestrator/workspaces/${params.workspace_id}/workflows/${params.workflow_id}/triggers/create-new`
        );
      }
    },
    [searchItemsNameMappings, setSearchItemsNameMappings] = React.useState<{ [key: string]: string }>({}),
    executionsTabProps = React.useMemo(
      () => ({
        searchItemsNameMappings,
        workflow,
      }),
      [searchItemsNameMappings, workflow]
    ),
    triggersTabProps = React.useMemo(
      () => ({
        workflow,
      }),
      [workflow]
    ),
    fetchRunnables = React.useCallback(async () => {
      loadStateRef.current.fetchRunnables = true;
      setLoading(true);
      try {
        const data: ListRunnablesResponse = await orchestratorService.getRunnables({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setRunnables(data?.runnables);
      } catch (err) {
        const message = `Failed to fetch runnables: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setRunnables(undefined);
      } finally {
        loadStateRef.current.fetchRunnables = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    fetchWorkflows = React.useCallback(async () => {
      loadStateRef.current.fetchWorkflows = true;
      setLoading(true);
      try {
        const data: ListWorkflowsResponseFixed = await orchestratorService.getWorkflows({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setWorkflows(data?.workflows);
      } catch (err) {
        const message = `Failed to fetch workflows: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflows(undefined);
      } finally {
        loadStateRef.current.fetchWorkflows = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService, params.workspace_id, params.workflow_id, workflowName]),
    canvasTabProps = {
      nodeState,
      edgeState,
      workflowName,
      onWorkflowNameChange: setWorkflowName,
      defaultWorkflow: workflow,
      showValidation,
      timeout,
      concurrencyLimit,
      onConcurrencyLimitChange: setConcurrencyLimit,
      onTimeoutChange: setTimeout,
      workflows,
      runnables,
      searchItemsNameMappings,
      isSaved,
      setIsSaved,
      loading,
    };

  React.useEffect(() => {
    if (!params.tab_id && selectedKey !== '0') setSelectedKey('0');
    if (params.tab_id === 'triggers' && selectedKey !== '1') setSelectedKey('1');
    if (params.tab_id === 'executions' && selectedKey !== '2') setSelectedKey('2');
  }, [params.tab_id]);

  React.useEffect(() => {
    if (!defaultWorkflow && params.workflow_id && params.workflow_id !== 'create-new') void getWorkflow();
  }, [params.workspace_id, params.workflow_id, getWorkflow, defaultWorkflow]);

  React.useEffect(() => {
    const mappings = [...(runnables || []), ...(workflows || [])].reduce((acc, { name, displayName }) => {
      if (name && displayName) acc[name] = displayName;
      return acc;
    }, {} as { [key: string]: string });
    setSearchItemsNameMappings(mappings);
  }, [runnables, workflows]);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchWorkflows();
      void fetchRunnables();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    // Calculate initial positions for nodes if it was created through API (xAxis and yAxis of all nodes are 0).
    const noNodeIsPositioned = nodes.every((node) => node.position.x === 0 && node.position.y === 0);
    if (noNodeIsPositioned) {
      setNodes((nodes) => {
        return getAutoNodes(graph, workflow?.steps || [], nodes);
      });
    }
  }, []);

  React.useEffect(() => {
    if (loading) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading]);

  return (
    <NavigationWrapper>
      <Header
        customPageTitle="Workflow detail"
        action={getActionText(selectedKey, permissions, params)}
        onActionClick={onActionClick}
        isActionButtonDisabled={isActionDisabled}
      />
      <Pivot
        {...fillStyles}
        onLinkClick={(item) => {
          if (!item) return;
          const tabId = item.props.itemKey === '1' ? 'triggers' : item.props.itemKey === '2' ? 'executions' : '';
          // TODO: Ask about unsaved changes.
          history.push(`/orchestrator/workspaces/${params.workspace_id}/workflows/${params.workflow_id}/${tabId}`);
        }}
        selectedKey={selectedKey}
        items={[
          {
            content:
              showLoader && loading ? (
                <LoaderView loaderText="Loading workflow detail..." />
              ) : (
                <ReactFlowProvider>
                  <WorkflowTabCanvas {...canvasTabProps} />
                </ReactFlowProvider>
              ),
            headerText: 'Details',
          },
          {
            content:
              showLoader && loading ? (
                <LoaderView loaderText="Loading workflow triggers..." />
              ) : (
                <WorkflowTabTriggers {...triggersTabProps} />
              ),
            headerText: 'Triggers',
          },
          {
            content:
              showLoader && loading ? (
                <LoaderView loaderText="Loading workflow executions..." />
              ) : (
                <WorkflowTabExecutions {...executionsTabProps} />
              ),
            headerText: 'Executions',
          },
        ]}
        placeholder={undefined}
      />
    </NavigationWrapper>
  );
};

export default WorkflowDetail;
