// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/adjusted_dai_profile_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { AdjustedDAIProfile } from "./adjusted_dai_profile_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for getting a single AdjustedDAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.GetAdjustedDAIProfileRequest
 */
export type GetAdjustedDAIProfileRequest = {
/**
 * AdjustedDAIProfile resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetAdjustedDAIProfileResponse
 */
export type GetAdjustedDAIProfileResponse = {
/**
 * AdjustedDAIProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.AdjustedDAIProfile adjusted_dai_profile = 1;
 */
adjustedDaiProfile?: AdjustedDAIProfile;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListAdjustedDAIProfilesRequest
 */
export type ListAdjustedDAIProfilesRequest = {
/**
 * Workspace resource name.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of AdjustedDAIProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 AdjustedDAIProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListAdjustedDAIProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListAdjustedDAIProfilesResponse
 */
export type ListAdjustedDAIProfilesResponse = {
/**
 * Collection of AdjustedDAIProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.AdjustedDAIProfile adjusted_dai_profiles = 1;
 */
adjustedDaiProfiles?: AdjustedDAIProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of AdjustedDAIProfiles matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Returns a specific DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.AdjustedDAIProfileService.GetAdjustedDAIProfile
 */
export const AdjustedDAIProfileService_GetAdjustedDAIProfile = new RPC<GetAdjustedDAIProfileRequest, GetAdjustedDAIProfileResponse>("GET", "/v1/{name=workspaces/*/adjustedDAIProfiles/*}");
/**
 * Returns a collection of adjustedDAIProfiles within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.AdjustedDAIProfileService.ListAdjustedDAIProfiles
 */
export const AdjustedDAIProfileService_ListAdjustedDAIProfiles = new RPC<ListAdjustedDAIProfilesRequest, ListAdjustedDAIProfilesResponse>("GET", "/v1/{parent=workspaces/*}/adjustedDAIProfiles");
