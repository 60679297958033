import { Image, ImageFit } from '@fluentui/react';
import { type IH2OTheme } from '@h2oai/ui-kit';

import { type AppGroup } from '../../../utils/models';
import { appVisibilityIconMap, appVisibilityMap } from '../../../utils/utils';
import { BadgeCellContents } from '../../ListPages/BadgeCell';
import { IconCellContents } from '../../ListPages/IconCell';
import CellWrapper from '../CellWrapper';
import TagCell from '../TagCell';

export const isGroupExpanded = (group: AppGroup, expandedGroupKeys: string[]) => {
  return expandedGroupKeys.includes(group.key);
};

export const getTagCell = (
  group: AppGroup,
  expandedGroupKeys: string[],
  currentWidth: any,
  theme: IH2OTheme,
  isAdmin: boolean,
  hideLabel?: boolean
) => {
  return (
    <CellWrapper
      label={hideLabel ? '' : 'Tags'}
      expanded={isGroupExpanded(group, expandedGroupKeys)}
      group={group}
      GroupCell={<TagCell tags={group.data.tags} currentWidth={currentWidth} theme={theme} isAdmin={isAdmin} />}
      onRenderAppCell={(app) => <TagCell tags={app.tags} currentWidth={currentWidth} theme={theme} isAdmin={isAdmin} />}
    />
  );
};

export const getVisibilityCell = (group: AppGroup, expandedGroupKeys: string[], hideLabel?: boolean) => {
  return (
    <CellWrapper
      label={hideLabel ? '' : 'Visibility'}
      expanded={isGroupExpanded(group, expandedGroupKeys)}
      group={group}
      GroupCell={<div />}
      onRenderAppCell={(app) => {
        return (
          <BadgeCellContents
            badgeLabel={appVisibilityMap?.[app.visibility]}
            badgeIconName={appVisibilityIconMap?.[app.visibility]}
            dataTest="visibility-badge"
          />
        );
      }}
    />
  );
};

export const getIconCell = (group: AppGroup, expandedGroupKeys: string[]) => {
  const iconLocation = group.data.latestApp.iconLocation;

  return (
    <CellWrapper
      expanded={isGroupExpanded(group, expandedGroupKeys)}
      group={group}
      GroupCell={<IconCellContents src={iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png'} />}
      onRenderAppCell={(app) => (
        <Image
          key={app.id}
          imageFit={ImageFit.contain}
          src={app.iconLocation ? `/v1/asset/${app.iconLocation}` : '/logo512.png'}
          styles={{ root: { height: 32, width: 32 } }}
        />
      )}
    />
  );
};
