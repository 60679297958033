import { useCallback } from 'react';

import { FilterCondition, FilterLogicalOperatorParenthesisType, useEngine } from '../aiem/engine/hooks';
import AIEnginesPage from '../components/AIEnginesPage/AIEnginesPage';
import { useUser } from '../utils/hooks';

export default function MyAIEnginesPage() {
  const { searchEngines } = useEngine(),
    { id: userId } = useUser();
  const fetchEngines = useCallback(
    async (filter?: FilterCondition[]) => {
      return await searchEngines([
        {
          openType: FilterLogicalOperatorParenthesisType.open,
          name: 'creator',
          value: `users/${userId}`,
          closeType: FilterLogicalOperatorParenthesisType.closeAnd,
        },
        ...(filter || []),
      ]);
    },
    [searchEngines, userId]
  );
  return <AIEnginesPage title="My AI Engines" fetchEngines={fetchEngines} />;
}
