import { Stack } from '@fluentui/react';
import { FontWeights, ILoaderProps, Item, itemStylesCategory, useTheme } from '@h2oai/ui-kit';

import Cell from './Cell';

interface BadgeListCellContentsProps {
  title?: string;
  items: string[];
  badgeBackgroundColor?: string;
  badgeTextColor?: string;
  badgeIconName?: string;
  badgeLoading?: boolean;
  badgeLoaderProps?: ILoaderProps;
  dataTest?: string;
  children?: React.ReactNode;
}

export function BadgeCellContents(props: BadgeListCellContentsProps) {
  const theme = useTheme();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {props.title && <span style={{ fontWeight: FontWeights.bold }}>{props.title}</span>}
      {props.items &&
        props.items.length &&
        props.items.map((item) => (
          <Item
            key={item}
            styles={[
              itemStylesCategory,
              {
                root: {
                  background: props.badgeBackgroundColor || theme.semanticColors?.categoryBackground,
                  color: props.badgeTextColor || theme.semanticColors?.categoryText,
                },
              },
            ]}
            labelField="label"
            iconNameField={props.badgeIconName ? 'icon' : undefined}
            iconStyleField="iconStyles"
            data-test={props.dataTest}
            data={{
              label: item,
              icon: props?.badgeIconName,
              iconStyles: { color: props.badgeTextColor || theme.semanticColors?.categoryText },
            }}
            loading={props.badgeLoading}
            loaderProps={props.badgeLoaderProps}
          />
        ))}
      {props.children}
    </Stack>
  );
}

export function BadgeListCell(props: BadgeListCellContentsProps) {
  return (
    <Cell>
      <BadgeCellContents {...props} />
    </Cell>
  );
}
