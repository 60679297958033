import { Loader, loaderStylesSpinnerXLarge } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { AppPreconditionStatus, App_Visibility, ListAppsRequest } from '../../ai.h2o.cloud.appstore';
import { useApp } from '../../utils/hooks';
import { RoutePaths } from '../Routes';

type AppAliasProps = {
  appName: string;
};

function AppAlias({ appName }: AppAliasProps) {
  const [appId, setAppId] = useState<string>(''),
    [error, setError] = useState<boolean>(false),
    { getApps } = useApp(),
    loadApps = useCallback(async () => {
      try {
        const apps = await getApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: appName,
          latestVersions: true,
          withPreference: false,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [App_Visibility.ALL_USERS, App_Visibility.PUBLIC],
        } as ListAppsRequest);
        if (!apps || !apps.length || !apps[0].id) {
          setError(true);
          return;
        }
        setAppId(apps[0].id);
      } catch (error: unknown) {
        if (error instanceof Error) setError(true);
      }
    }, []);

  useEffect(() => {
    loadApps();
  }, []);

  return error ? (
    <Redirect to={RoutePaths.ERROR_404} />
  ) : appId ? (
    <Redirect to={`/apps/${appId}`} />
  ) : (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  );
}

export default AppAlias;
