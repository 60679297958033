import { IRawStyle, mergeStyleSets, mergeStyles } from '@fluentui/react';
import {
  FontSizes,
  IH2OTheme,
  KeyValuePairEditor,
  KeyValuePairSubmitOutcome,
  Link,
  Pivot,
  useThemeStyles,
} from '@h2oai/ui-kit';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

/* eslint-disable @typescript-eslint/ban-ts-comment, import/no-webpack-loader-syntax */
// @ts-ignore
import TomlParser from 'workerize-loader-5!../../../../workers/tomlParser';
/* eslint-enable */

import { useDebouncedCallback, usePromiseCallback } from '../../../../utils/hooks';
import { CodeArea, ICodeAreaStyles } from '../../../CodeArea/CodeArea';

type Config = Record<string, string>;

const tomlParser = TomlParser();

type TOMLParserError = { message: { message: string } };

const getParseError = (error: Error | TOMLParserError) =>
  typeof error.message === `object` ? error.message.message : error.message;

const mapValuesToString = (obj: Record<string, unknown>): Record<string, string> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)]));

const hasNestedData = (obj: Record<string, unknown>): boolean =>
  Object.values(obj).some((value) => value && typeof value === `object`);

const stylesCodeArea: Partial<ICodeAreaStyles> = {
  root: { flexGrow: 1, width: 'fit-content', minWidth: '100%' },
  codeEditor: { resize: `none` },
};
const stylesErrorMessage = (theme: IH2OTheme) => ({
  color: theme.semanticColors?.inputErrorMessageText,
  fontSize: FontSizes.xxsmall,
  marginBlockStart: `-1.25rem`,
  minBlockSize: `1.5rem`,
});

type CodeEditorProps = {
  config: Config;
  onConfigChange: (newConfig: Config) => void;
  readOnly?: boolean;
};

const ID_CODE_EDITOR_ERROR_MESSAGE = `code-editor-error-message`;
const ID_CODE_EDITOR_HINT = `code-editor-hint`;

function CodeEditor(props: CodeEditorProps) {
  const [rawToml, setRawToml] = useState(`loading...`);
  const [configToRawToml, configToRawTomlLoading] = usePromiseCallback(
    (config: Config) => tomlParser?.stringify(config),
    [],
    {
      onError: (error) => {
        console.error(
          `The advanced config for the engine failed to parse config:`,
          props.config,
          `Received error:`,
          error
        );
      },
      onSuccess: (toml) => {
        setRawToml(toml);
      },
    }
  );
  useEffect(() => {
    configToRawToml(props.config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [parseTomlErrorMessage, setParseTomlErrorMessage] = useState(``);
  const [parseToml] = usePromiseCallback(
    (input: string) =>
      tomlParser.parse(input).then((data: Record<string, unknown>) => {
        if (hasNestedData(data)) {
          throw new Error(`Nested data (TOML tables) are not supported.`);
        }
        return mapValuesToString(data);
      }),
    [],
    {
      onError: (error) => {
        setParseTomlErrorMessage(getParseError(error));
      },
      onSuccess: () => {
        setParseTomlErrorMessage(``);
      },
      onSettled: ({ data }) => {
        if (data) {
          props.onConfigChange(data);
        }
      },
    }
  );
  const parseAndValidateToml = useDebouncedCallback(parseToml, 300);
  const parseAndValidateTomlRef = useRef(parseAndValidateToml);
  const mergedStyles = mergeStyles(useThemeStyles(stylesErrorMessage));
  parseAndValidateTomlRef.current = parseAndValidateToml;
  useLayoutEffect(
    () => () => {
      parseAndValidateTomlRef.current?.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {!props.readOnly && (
        <div id={ID_CODE_EDITOR_HINT}>
          You can add a configuration here using{' '}
          <a href="https://toml.io/en/" target="_blank" rel="noreferrer">
            TOML syntax
          </a>
          . Note that all comments will be lost when you leave the editor.
        </div>
      )}
      <CodeArea
        aria-describedby={`${ID_CODE_EDITOR_ERROR_MESSAGE} ${ID_CODE_EDITOR_HINT}`}
        aria-invalid={Boolean(parseTomlErrorMessage)}
        aria-label="Engine advanced configuration raw content"
        aria-busy={configToRawTomlLoading}
        disabled={configToRawTomlLoading}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          const newValue = event.target.value;
          setRawToml(newValue);
          parseAndValidateToml(newValue);
        }}
        styles={stylesCodeArea}
        value={rawToml}
        readOnly={props.readOnly}
      />
      <div aria-live="polite" className={mergedStyles} id={ID_CODE_EDITOR_ERROR_MESSAGE}>
        {parseTomlErrorMessage}
      </div>
    </>
  );
}

// this style is used to limit max-height of the PairsList component and make
// only its "contentWrapper" a scroll parent. The height must be limited all the
// way up from the "contentWrapper" and some containers, e.g. Viewport, which is
// part of the DetailsList, cannot be styled through the DetailsList, so the
// assignment of this ruleset to all appropriate places is slight complicated.
const stylesScrollParent: IRawStyle = {
  display: `flex`,
  flexDirection: `column`,
  overflow: `hidden`,
};

const advancedConfigurationStyles = {
  root: [
    {
      blockSize: `100%`,
      display: `flex`,
      flexFlow: `column nowrap`,
      gap: `1.5rem`,
    },
    { '& > .ms-Viewport': stylesScrollParent },
  ],
  topContent: {
    display: `flex`,
    alignItems: `end`,
    justifyContent: `space-between`,
  },
};
const advancedConfigurationClassNames = mergeStyleSets(advancedConfigurationStyles);

export type AdvancedConfigurationProps = {
  config: Record<string, string>;
  onConfigChange: (newConfig: Record<string, string>) => void;
  submitKeyValuePair?: () => void;
  readOnly?: boolean;
};

export interface SubmitKeyValueImperativeHandle extends HTMLDivElement {
  submitKeyValue: () => KeyValuePairSubmitOutcome;
}

export const AdvancedConfiguration = forwardRef<SubmitKeyValueImperativeHandle, AdvancedConfigurationProps>(
  ({ config, onConfigChange, readOnly }: AdvancedConfigurationProps, ref) => {
    const elementRef = useRef<SubmitKeyValueImperativeHandle>(null);
    const keyValueEditorRef = useRef<any>(null);

    useImperativeHandle(
      ref,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      () => {
        return {
          submitKeyValue(): KeyValuePairSubmitOutcome {
            if (keyValueEditorRef.current) {
              return keyValueEditorRef.current.submitKeyValue();
            } else {
              return KeyValuePairSubmitOutcome.UNSUCCESSFUL;
            }
          },
          ...elementRef.current,
        };
      },
      []
    );
    return (
      <div className={advancedConfigurationClassNames.root} ref={elementRef}>
        <Link
          href="https://docs.h2o.ai/driverless-ai/latest-stable/docs/userguide/config_toml.html#using-the-config-toml-file"
          target="_blank"
        >
          DAI Config Documentation
        </Link>
        <Pivot
          styles={{ root: { marginBottom: 16 } }}
          pivotContainerStyles={{ root: { marginBottom: 12 } }}
          items={[
            {
              key: 'table',
              iconName: 'Table',
              headerText: 'Table View',
              content: (
                <KeyValuePairEditor
                  ref={keyValueEditorRef}
                  config={config}
                  onUpdateConfig={onConfigChange}
                  readOnly={readOnly}
                />
              ),
            },
            {
              key: 'code',
              iconName: 'Code',
              headerText: 'Code View',
              content: tomlParser.stringify ? (
                <CodeEditor config={config} onConfigChange={onConfigChange} readOnly={readOnly} />
              ) : (
                <p>The Code View is not available due to an error.</p>
              ),
            },
          ]}
        />
      </div>
    );
  }
);
