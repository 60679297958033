import { FontSizes, FontWeights, type IH2OTheme, Sizes, mediaDesktop, mediaNoDesktop } from '@h2oai/ui-kit';

import { type IStyles } from '../../utils/styles';

type IProtectedStylesKey = 'root' | 'main' | 'contents' | 'contentsBody';

export type IProtectedStyles = IStyles<IProtectedStylesKey>;

export const protectedStylesDefault = (theme: IH2OTheme): Partial<IProtectedStyles> => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '.h2o-AppBar-root': { zIndex: 2 },

    '.h2o-LeftPanel-root': {
      position: 'absolute',
      left: 0,
      zIndex: 1,
      ...mediaDesktop({ top: Sizes.headerHeight }),
      ...mediaNoDesktop({ top: 60 }),
    },

    'main.app-store-left-panel': { ...mediaNoDesktop({ paddingLeft: 0 }) },

    'main.app-store-left-panel .h2o-LeftPanel-root': {
      ...mediaNoDesktop({
        position: 'static',
        width: 'auto',
        // Custom positioning with the AppStore Search
        margin: '121px -18px -128px',
        padding: 0,
      }),
      h3: { ...mediaNoDesktop({ display: 'none' }) },
      nav: {
        ...mediaNoDesktop({
          overflowY: 'auto',
          padding: '0 18px',
          maxWidth: '100vw',
        }),
      },
      '.ms-Nav-navItems': { ...mediaNoDesktop({ display: 'flex' }) },
      '.ms-Nav-compositeLink': {
        ...mediaNoDesktop({
          height: 'auto',
          borderBottom: `2px solid ${theme.palette?.gray300}`,
        }),
      },
      '.ms-Nav-compositeLink.is-selected': {
        ...mediaNoDesktop({ borderBottom: `2px solid ${theme.palette?.primary500}` }),
        '.ms-Nav-link': {
          ...mediaNoDesktop({
            backgroundColor: 'transparent',
            fontWeight: FontWeights.semiBold,
          }),
        },
      },
      '.ms-Nav-compositeLink:hover .ms-Nav-link': { ...mediaNoDesktop({ backgroundColor: 'transparent' }) },
      '.ms-Nav-link': {
        ...mediaNoDesktop({
          fontSize: FontSizes.small,
          lineHeight: FontSizes.large,
          fontWeight: FontWeights.semiBold,
          height: 26,
          padding: '0 11px',
          backgroundColor: 'transparent',
          position: 'relative',
        }),
      },
    },
    '.h2o-NotificationBar-root': { ...mediaNoDesktop({ marginBottom: 0 }) },
    'main.orchestrator-left-panel .h2o-LeftPanel-root': {
      ...mediaNoDesktop({
        width: 75,
      }),
    },
    'main.orchestrator-left-panel .h2o-Protected-contents': {
      ...mediaNoDesktop({
        paddingLeft: 75,
        '#orchestrator-left-panel-expand-button': {
          display: 'none',
        },
      }),
    },
    'main.orchestrator-left-panel-force-closed .h2o-LeftPanel-root': {
      width: 75,
    },
    'main.orchestrator-left-panel-force-closed .h2o-Protected-contents': {
      paddingLeft: 75,
      '#orchestrator-left-panel-expand-button': {
        left: '56px !important',
        i: {
          transform: 'rotate(180deg) !important',
          transition: 'transform 0.3s linear 0s !important',
        },
        ...mediaNoDesktop({
          display: 'none',
        }),
      },
    },
    'main.orchestrator-left-panel #orchestrator-workspace-widget': {
      ...mediaNoDesktop({
        width: 51,
        '.ms-Button': {
          border: 'none',
          padding: '11px 15px',
          width: 18,
        },
        '.ms-Button-menuIcon': { display: 'none' },
        '.ms-Icon': { margin: 0 },
        '.ms-Button-label': { display: 'none', padding: 0 },
        '#orchestrator-workspace-widget-title': {
          display: 'none',
        },
      }),
    },
    'main.orchestrator-left-panel-force-closed #orchestrator-workspace-widget': {
      width: 51,
      '.ms-Button': {
        border: 'none',
        padding: '11px 15px',
        width: 18,
      },
      '.ms-Button-menuIcon': { display: 'none' },
      '.ms-Icon': { margin: 0 },
      '.ms-Button-label': { display: 'none', padding: 0 },
      '#orchestrator-workspace-widget-title': {
        display: 'none',
      },
    },
  },
  main: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    ...mediaNoDesktop({ paddingTop: 60 }),
    '&.has-left-panel': {
      '.h2o-Protected-contents': {
        ...mediaDesktop({ paddingLeft: Sizes.navWidth + Sizes.pagesSidePadding }),
      },
    },
  },
  contents: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    ...mediaNoDesktop({ paddingTop: 24 }),
  },
  contentsBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '>.ms-Stack': { ...mediaNoDesktop({ padding: 18 }) },
  },
});
