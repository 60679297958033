import { IStyle, mergeStyles } from '@fluentui/react';
import { Button, IH2OTheme, buttonStylesPrimary, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';

type NoItemViewProps = {
  title: string;
  description: string;
  actionTitle?: string;
  onActionClick?: () => void;
  actionIcon?: string;
  backgroundImage?: string;
  isBackgroundPattern?: boolean;
  shadowed?: boolean;
};

interface INoItemViewStyles {
  wrapper: IStyle;
  backgroundContainer: IStyle;
  contentContainer: IStyle;
  banner: IStyle;
  bannerShadow: IStyle;
  title: IStyle;
  description: IStyle;
  button: IStyle;
}

const noItemViewStyles = (
  theme: IH2OTheme,
  backgroundImage?: string,
  pattern?: boolean
): Partial<INoItemViewStyles> => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    minHeight: 300,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    minHeight: 300,
    backgroundImage,
    backgroundRepeat: pattern ? 'repeat' : 'no-repeat',
    backgroundPosition: pattern ? undefined : 'center',
    backgroundSize: pattern ? undefined : '1100px auto',
    boxShadow: `0 0 80px 80px ${theme.semanticColors?.bodyBackground} inset`,
    borderRadius: 8,
    opacity: 0.24,
  },
  contentContainer: {
    position: 'absolute',
    flexGrow: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    padding: 30,
    borderRadius: 8,
    textAlign: 'center',
    background: theme.semanticColors?.contentBackground,
  },
  bannerShadow: {
    border: `1px solid ${theme.semanticColors?.inputBorder}`,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
  },
  title: { marginBottom: 20, marginTop: 0 },
  description: { color: theme.semanticColors?.textSecondary },
  button: { marginTop: 12 },
});

export const NoItemView = ({
  title,
  description,
  actionTitle,
  onActionClick,
  actionIcon,
  backgroundImage,
  isBackgroundPattern,
  shadowed = true,
}: NoItemViewProps) => {
  const theme = useTheme();
  const classNames = useClassNames<INoItemViewStyles, ClassNamesFromIStyles<INoItemViewStyles>>(
    'noItemView',
    noItemViewStyles(theme, backgroundImage, isBackgroundPattern)
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.backgroundContainer} />
      <div className={classNames.contentContainer}>
        <div className={mergeStyles(classNames.banner, shadowed ? classNames.bannerShadow : {})}>
          <h2 className={classNames.title}>{title}</h2>
          <p className={classNames.description}>{description}</p>
          {actionTitle && onActionClick ? (
            <Button
              onClick={onActionClick}
              iconName={actionIcon}
              text={actionTitle}
              className={classNames.button}
              styles={buttonStylesPrimary}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
