import { Granularity } from '../../../telemetry/gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits_pb';

interface GranularityOption {
  key: Granularity;
  label: string;
  intervalTooLong: (startTime: Date, endTime: Date) => boolean;
  intervalTooShort: (startTime: Date, endTime: Date) => boolean;
  formatDate: (date: Date) => string;
}

const maxVisibleDataPoints = 100;
const minimumVisibleDataPoints = 2;

function timeDeltaLessThanDays(startTime: Date, endTime: Date, days: number): boolean {
  return endTime.getTime() - startTime.getTime() < 1000 * 60 * 60 * 24 * days;
}

function getIntervalsInTimeDelta(startTime: Date, endTime: Date, intervalSize: number): number {
  return Math.ceil((endTime.getTime() - startTime.getTime()) / intervalSize);
}

export function getValidGranularityOptions(startTime: Date, endTime: Date): GranularityOption[] {
  return granularityOptions.filter(
    (option) => !(option.intervalTooLong(startTime, endTime) || option.intervalTooShort(startTime, endTime))
  );
}

export const granularityOptions: GranularityOption[] = [
  {
    key: Granularity.HOUR,
    label: 'Hourly',
    intervalTooLong: (startTime, endTime) =>
      getIntervalsInTimeDelta(startTime, endTime, 1000 * 60 * 60) > maxVisibleDataPoints,
    intervalTooShort: () => false,
    formatDate: (date: Date) =>
      date.toLocaleDateString(undefined, { hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'numeric' }),
  },
  {
    key: Granularity.DAY,
    label: 'Daily',
    intervalTooLong: (startTime, endTime) =>
      getIntervalsInTimeDelta(startTime, endTime, 1000 * 60 * 60 * 24) > maxVisibleDataPoints,
    intervalTooShort: (startTime, endTime) => timeDeltaLessThanDays(startTime, endTime, minimumVisibleDataPoints),
    formatDate: (date: Date) => date.toLocaleDateString(),
  },
  {
    key: Granularity.WEEK,
    label: 'Weekly',
    intervalTooLong: (startTime, endTime) =>
      getIntervalsInTimeDelta(startTime, endTime, 1000 * 60 * 60 * 24 * 7) > maxVisibleDataPoints,
    intervalTooShort: (startTime, endTime) => timeDeltaLessThanDays(startTime, endTime, 7 * minimumVisibleDataPoints),
    formatDate: (date: Date) => date.toLocaleDateString(),
  },
  {
    key: Granularity.MONTH,
    label: 'Monthly',
    intervalTooLong: (startTime, endTime) =>
      getIntervalsInTimeDelta(startTime, endTime, 1000 * 60 * 60 * 24 * 30) > maxVisibleDataPoints,
    intervalTooShort: (startTime, endTime) => timeDeltaLessThanDays(startTime, endTime, 30 * minimumVisibleDataPoints),
    formatDate: (date: Date) => date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' }),
  },
  {
    key: Granularity.YEAR,
    label: 'Yearly',
    intervalTooLong: () => false,
    intervalTooShort: (startTime, endTime) => timeDeltaLessThanDays(startTime, endTime, 365 * minimumVisibleDataPoints),
    formatDate: (date: Date) => date.toLocaleDateString(undefined, { year: 'numeric' }),
  },
];
