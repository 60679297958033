import { Loader, loaderStylesSpinnerXLarge } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { FederatedApp } from '../../../ai.h2o.cloud.appstore';
import { Err } from '../../../components/Err/Err';
import { AdminFederatedAppService } from '../../../services/api';
import { useError } from '../../../utils/hooks';
import { handleErrMsg } from '../../../utils/utils';
import { FederatedAppsList } from './FederatedAppsList/FederatedAppsList';

function AdminMarketplaceAppListPage() {
  const [apps, setApps] = useState<FederatedApp[] | undefined>();
  const [err, setErr, onDismissError] = useError();
  const setFatalError = useCallback(
    (error: any) => {
      setErr({ message: `An error occurred while loading apps: ${handleErrMsg(error?.message)}` });
    },
    [setErr]
  );
  const loadApps = useCallback(async () => {
    const applications = await AdminFederatedAppService.listFederatedApps({});
    setApps(applications.apps);
  }, []);
  useEffect(() => {
    loadApps().catch(setFatalError);
  }, []);
  const pageTitle = 'Admin Marketplace Apps';
  return (
    <>
      {err ? (
        <Err
          err={err.message!}
          onDismissErr={() => {
            loadApps().catch(setFatalError);
            onDismissError();
          }}
        />
      ) : apps ? (
        <FederatedAppsList pageTitle={pageTitle} apps={apps} refetchApps={loadApps} />
      ) : (
        <Loader styles={loaderStylesSpinnerXLarge} label="Loading Apps..." />
      )}
    </>
  );
}

export default AdminMarketplaceAppListPage;
