import { IDropdownOption, ISelectableOption } from '@fluentui/react';
import { Dropdown } from '@h2oai/ui-kit';
import { useCallback } from 'react';

import { useEngine, useEngineQueryParams } from '../../../../aiem/engine/hooks';

type FilterListActionsProps = {
  onChangeStates: (states: string[]) => void;
};

const EngineStateFilter = ({ onChangeStates }: FilterListActionsProps) => {
  const { EngineStateMap } = useEngine();
  const {
    params: { states: selectedStates },
    setStates,
  } = useEngineQueryParams();

  const statesOptions: IDropdownOption[] = Array.from(EngineStateMap, ([_, { id: key, title: text }]) => ({
    key,
    text,
    selected: Boolean(selectedStates?.includes(key)),
  }));

  const onToggleState = useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<ISelectableOption> | undefined) => {
      const { key } = option || {};
      let newStates;
      if (selectedStates) {
        newStates = selectedStates?.includes(key as string)
          ? selectedStates.filter((s) => s !== key)
          : [...selectedStates!, key as string];
      } else {
        newStates = [key as string];
      }
      setStates(newStates);
      onChangeStates(newStates);
    },
    [selectedStates]
  );

  return (
    <Dropdown
      label="Engine State"
      placeholder="All"
      onChange={onToggleState}
      options={statesOptions}
      selectedKeys={selectedStates}
      multiSelect
    />
  );
};

export default EngineStateFilter;
