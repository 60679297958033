import { IColumn, IStyle, mergeStyles } from '@fluentui/react';
import {
  Button,
  DetailsList,
  IDetailsListProps,
  IH2OTheme,
  IconButton,
  Link,
  ListRow,
  Loader,
  Search,
  WidgetPanel,
  buttonStylesPrimary,
  buttonStylesStealth,
  detailsListStylesCard,
  linkStylesBlack,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';

type Props = {
  title?: string;
  columns: IColumn[];
  items?: unknown[];
  loading: boolean;
  NoItemsContent?: JSX.Element;
  ErrorContent?: JSX.Element;
  delayLoader?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  isLoadingSearch?: boolean;
  searchProps?: {
    onSearchChange: (searchText: string) => void;
    placeholder?: string;
  };
  actionProps?: {
    actionTitle: string;
    onActionClick: () => void;
    actionIcon?: string;
  };
  lastRefreshed?: string;
  onRefresh?: () => void;
};

interface IWidgetListStyles {
  detailsListContainer: IStyle;
  noRunnablesContainer: IStyle;
  searchBar: IStyle;
  loadMore: IStyle;
  loadMoreLoader: IStyle;
  loadSearch: IStyle;
  noItemsContainer: IStyle;
  widgetContainer: IStyle;
  lastRefreshedWidget: IStyle;
  actionWidget: IStyle;
  refreshButton: IStyle;
  searchWidget: IStyle;
  noHeader: IStyle;
}

const widgetListStyles = (theme: IH2OTheme): Partial<IWidgetListStyles> => ({
    detailsListContainer: {
      height: '100%',
      overflow: 'auto',
    },
    noRunnablesContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
      padding: '40px 20px',
    },
    searchBar: {
      flexGrow: 1,
      marginBottom: 20,
    },
    loadMore: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
      borderWidth: 1,
      borderTopStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
      borderRadius: 4,
      // TODO: Use theme colors.
      backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)',
      '&:hover': {
        borderColor: theme.semanticColors?.buttonPrimaryBackground,
        cursor: 'pointer',
        button: {
          textDecoration: 'none',
        },
        'button:active': {
          textDecoration: 'none',
        },
      },
      span: {
        color: theme.semanticColors?.textSecondary,
      },
      '&:hover span': {
        color: theme.semanticColors?.textPrimary,
      },
    },
    loadMoreLoader: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
    },
    loadSearch: {
      height: '60%',
      alignItems: 'center',
    },
    noItemsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
      backgroundImage: theme.semanticColors?.bodyBackground,
      borderRadius: 4,
      height: '60%',
    },
    lastRefreshedWidget: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      color: theme.semanticColors?.textSecondary,
      fontSize: 14,
      marginBottom: 6,
    },
    actionWidget: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    refreshButton: {
      marginLeft: 10,
    },
    widgetContainer: {
      display: 'flex',
    },
    searchWidget: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 380,
    },
    noHeader: {
      '& .h2o-WidgetPanel-header': {
        display: 'none',
      },
    },
  }),
  widgetStyles = {
    controls: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    header: {
      padding: 0,
      paddingBottom: 16,
    },
    root: {
      background: 'transparent',
      borderRadius: 'none',
      padding: '20px 40px',
      // Adds extra margin to prevent "Need help?" button from overlapping.
      margin: '0px 20px',
      maxWidth: '100%',
    },
    title: {
      fontSize: 16,
      lineHeight: '20px',
    },
  };

const WidgetList = ({
  title,
  columns,
  items,
  loading,
  NoItemsContent,
  ErrorContent,
  onLoadMore,
  isLoadingMore,
  isLoadingSearch,
  searchProps,
  actionProps,
  delayLoader = true,
  lastRefreshed,
  onRefresh,
}: Props) => {
  const theme = useTheme(),
    classNames = useClassNames<IWidgetListStyles, ClassNamesFromIStyles<IWidgetListStyles>>(
      'widgetList',
      widgetListStyles(theme)
    ),
    rootRef = React.useRef<HTMLDivElement>(null),
    hasSomeItem = !loading && items && items.length > 0,
    timeoutRef = React.useRef<number>(),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [showLoader, setShowLoader] = React.useState<boolean>(!delayLoader),
    [initialItemsCount, setInitialItemsCount] = React.useState<number>(0),
    // TODO: Use useMemo to avoid creating the object on every render.
    listProps: Partial<IDetailsListProps> = {
      styles: detailsListStylesCard,
      focusZoneProps: { style: { width: '100%' } },
      isHeaderVisible: false,
      cellStyleProps: {
        cellExtraRightPadding: 0,
        cellLeftPadding: 0,
        cellRightPadding: 0,
      },
      onRenderDetailsFooter: () => {
        return onLoadMore ? (
          isLoadingMore && showLoader ? (
            <Loader className={classNames.loadMoreLoader} />
          ) : (
            <div className={classNames.loadMore} onClick={onLoadMore}>
              <Link styles={linkStylesBlack}>Load more...</Link>
            </div>
          )
        ) : null;
      },
      onRenderRow: (props?: { item: { [key: string]: any }; columns?: IColumn[] }) => {
        if (!props) return null;
        const { item, columns = [] } = props;
        return (
          <ListRow
            data={item}
            columns={columns}
            styles={{
              row: {
                '&:hover': {
                  background: theme.semanticColors?.contentBackground,
                  '.h2o-ListCell-text': {
                    color: theme.semanticColors?.textSecondary,
                  },
                },
              },
            }}
          />
        );
      },
    };

  React.useEffect(() => {
    if (delayLoader) {
      if (loading || isLoadingMore || isLoadingSearch) {
        timeoutRef.current = window.setTimeout(() => setShowLoader(true), 600);
      } else {
        setShowLoader(false);
        window.clearTimeout(timeoutRef.current);
      }
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading, isLoadingMore, isLoadingSearch]);

  React.useEffect(() => {
    // Scrolls to the bottom of the list when new items are fetched using "Load more...".
    if ((items || []).length > 0) {
      if (!initialItemsCount) {
        setInitialItemsCount((items || []).length);
      } else {
        const detailsList = document.querySelector('.h2o-Protected-main');
        if (detailsList && initialItemsCount < (items || []).length) {
          detailsList.scrollTop = rootRef.current?.scrollHeight ?? 0;
        }
      }
    }
  }, [isLoadingMore, rootRef.current, initialItemsCount, items]);

  return (
    <>
      {hasSomeItem || isSearchStr ? (
        <WidgetPanel title={title} styles={widgetStyles} className={title ? undefined : classNames.noHeader}>
          <div className={classNames.detailsListContainer} ref={rootRef}>
            {searchProps || lastRefreshed ? (
              <div
                className={classNames.widgetContainer}
                style={{
                  justifyContent:
                    searchProps && lastRefreshed ? 'space-between' : lastRefreshed ? 'flex-end' : 'flex-start',
                }}
              >
                {searchProps ? (
                  <div className={classNames.searchWidget}>
                    <Search
                      onSearchTextChange={(text) => {
                        setIsSearchStr(!!text);
                        searchProps?.onSearchChange(text);
                      }}
                      placeholder={searchProps?.placeholder || `Search`}
                      className={classNames.searchBar}
                    />
                  </div>
                ) : null}
                {lastRefreshed ? (
                  <div className={classNames.lastRefreshedWidget}>
                    <>
                      Last refreshed: {lastRefreshed?.toLocaleString()}
                      {onRefresh ? (
                        <IconButton
                          iconName="Refresh"
                          title="Refresh manually"
                          styles={buttonStylesStealth}
                          className={classNames.refreshButton}
                          onClick={onRefresh}
                        />
                      ) : null}
                    </>
                  </div>
                ) : null}
                {actionProps ? (
                  <div className={classNames.actionWidget}>
                    <Button
                      text={actionProps.actionTitle}
                      onClick={actionProps.onActionClick}
                      iconProps={actionProps?.actionIcon ? { iconName: actionProps.actionIcon } : undefined}
                      styles={buttonStylesPrimary}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {isLoadingSearch && showLoader ? (
              <div className={mergeStyles(classNames.loadMore, classNames.loadSearch)}>
                <Loader label="Searching..." />
              </div>
            ) : isSearchStr && !hasSomeItem ? (
              <div className={classNames.noItemsContainer}>No items found.</div>
            ) : (
              <DetailsList {...listProps} columns={columns} items={items || []} />
            )}
          </div>
        </WidgetPanel>
      ) : null}
      {!isSearchStr ? (
        <div className={classNames.noRunnablesContainer}>
          {loading ? (
            showLoader ? (
              <Loader label="Loading..." />
            ) : null
          ) : items !== undefined ? (
            items.length === 0 && (NoItemsContent || <div className={classNames.noItemsContainer}>No items found.</div>)
          ) : (
            ErrorContent || <div>Failed to load items.</div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default WidgetList;
