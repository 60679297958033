import type { ITextStyles } from '@fluentui/react';
import { mediaMobile } from '@h2oai/ui-kit';

import { stackStylesPage } from '../../themes/themes';

export const appDetailPageStyles = (): Partial<ITextStyles> => ({
  root: {
    ...(stackStylesPage.root as object),

    // Details content tabs
    '.app-detail-content div[role="tablist"]': {
      ...mediaMobile({ overflowX: 'auto' }),
      '.ms-Button.is-selected': { ...mediaMobile({ backgroundColor: 'transparent' }) },
    },

    // Images Carousel
    '.h2o-Carousel-root': {
      '.h2o-Carousel-container': { ...mediaMobile({ padding: 0 }) },
      '.h2o-Carousel-leftButtonContainer': { ...mediaMobile({ left: -40 }) },
      '.h2o-Carousel-rightButtonContainer': { ...mediaMobile({ right: -40 }) },
      '.h2o-Pagination-item': { ...mediaMobile({ width: 8, height: 8 }) },
    },
  },
});
