import { concatStyleSets } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

export interface EntityModelItemActionButtonProps<EntityModel> {
  model: EntityModel;
  onEdit?: (model: EntityModel) => void;
  onDelete?: (model: EntityModel) => void;
  onSetLatest?: (model: EntityModel) => void;
}

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 60 } });

export function EntityModelItemActionButton<EntityModel>({
  model,
  onEdit,
  onDelete,
  onSetLatest,
}: EntityModelItemActionButtonProps<EntityModel>) {
  const { palette, semanticColors } = useTheme(),
    buttonProps: IButtonProps = {
      split: true,
      menuIconName: IconName.ChevronDown,
    };

  const aliasesFieldName = 'aliases',
    aliasesField = model[aliasesFieldName] as string[] | undefined;
  const canBeLatest = aliasesField && !aliasesField.includes('latest');

  return (
    <>
      <Button
        {...buttonProps}
        menuItems={[
          ...(canBeLatest
            ? [
                {
                  key: 'setLatest',
                  text: 'Set Latest',
                  onClick: () => onSetLatest!(model),
                  style: { color: semanticColors?.textPrimary, maxHeight: 32 },
                },
              ]
            : []),
          {
            key: 'delete',
            text: 'Delete',
            onClick: () => onDelete!(model),
            style: { color: palette?.red500, maxHeight: 32 },
          },
        ]}
        styles={[buttonStylesSplit, buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => onEdit!(model)}
        text="Edit"
      />
    </>
  );
}
