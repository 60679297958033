import { IStyle } from '@fluentui/react';
import { ICronStyles, IH2OTheme, cronStyles } from '@h2oai/ui-kit';

import { WORKFLOW_PARAMS_SUPPORTED } from './constants';

export interface IWorkflowTabTriggersStyles extends ICronStyles {
  triggerDetailContainer: IStyle;
  saveButton: IStyle;
  cronError: IStyle;
}

export const workflowTabTriggersStyles = (theme: IH2OTheme): Partial<IWorkflowTabTriggersStyles> => ({
  ...cronStyles(theme),
  triggerDetailContainer: { margin: '0px 40px', padding: 20, display: 'flex', flexGrow: 1, flexDirection: 'column' },
  saveButton: { marginTop: WORKFLOW_PARAMS_SUPPORTED ? 20 : 0 },
  cronError: { marginTop: 16, height: 16 },
});
