import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';

import { fetchWrapRPC, getAccessToken } from '../../services/api';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { handleErrMsg } from '../../utils/utils';
import { defaultBasePath } from '../defaults';
import { EntitiesMap } from './services';
import { Entity, EntityActionType, EntityType } from './types';
import { checkForPrefix } from './utils';

export type MessageText = {
  group?: string;
  subject?: string;
  action?: string;
  object?: string;
};

const completeMessageText = (messageText: MessageText | undefined, entityType: EntityType) => {
  const sourceEntity = EntitiesMap.get(entityType);
  if (!sourceEntity) return messageText;
  return {
    ...messageText,
    group: sourceEntity.displayName,
  };
};

const toText = ({ group, action, subject, object }: MessageText) =>
  `AI Engine Settings ${(group || '').concat(String(group).includes(':') ? ' ' : ': ')} ${subject ? subject : ''} ${
    action || 'action occurred '
  } ${object || 'successfully'}`.replaceAll('::', ':');

export function useEntity() {
  const { addToast } = useToast(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    basePath = Object(cloudPlatformDiscovery)?.aiEngineManagerApiUrl || defaultBasePath,
    requestConfig = { basePath, bearerToken: getAccessToken },
    defaultErrorMessage = 'AI Engine Manager Settings Error';

  const act = async <EntityModel, EntityRequest>(
    entity: Entity<EntityModel>,
    type: EntityActionType,
    request: EntityRequest,
    messageText?: MessageText,
    suppressToast = false,
    abortController?: AbortController
  ) => {
    const { actions } = entity;
    const action = actions[type];
    const rpc = action?.rpc;
    const responseKey = action?.responseKey;
    const successfulMessageText = completeMessageText(messageText, entity.type);

    if (rpc) {
      try {
        let response;
        if (abortController) {
          response = await fetchWrapRPC(rpc, requestConfig)(request, { signal: abortController.signal });
        } else {
          response = await fetchWrapRPC(rpc, requestConfig)(request);
        }
        if (!suppressToast) {
          addToast({
            message: toText(successfulMessageText || {}),
            messageBarType: MessageBarType.success,
          });
        }
        const result = response[responseKey || 'data'] as EntityModel[];
        return result;
      } catch (error: any) {
        !suppressToast &&
          addToast({
            message: `${defaultErrorMessage}: ${handleErrMsg(error.message)}`,
            messageBarType: MessageBarType.error,
          });
        throw error;
      }
    }
    return undefined;
  };

  const create = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      messageText?: MessageText,
      abortController?: AbortController
    ) => await act(e, EntityActionType.Create, req, messageText, false, abortController),
    erase = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      messageText?: MessageText,
      abortController?: AbortController
    ) => await act(e, EntityActionType.Delete, req, messageText, false, abortController),
    get = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      suppressToast = false,
      abortController?: AbortController
    ) => {
      const { requestNameKeyPrefix } = e;
      const name = checkForPrefix((req as unknown as { name: string }).name, requestNameKeyPrefix);
      return await act(e, EntityActionType.Get, { ...req, name }, undefined, suppressToast, abortController);
    },
    list = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      abortController?: AbortController
    ) => await act(e, EntityActionType.List, req, undefined, true, abortController),
    update = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      messageText?: MessageText,
      abortController?: AbortController
    ) =>
      await act(
        e,
        EntityActionType.Update,
        { ...req, updateMask: (req as any).updateMask || '*' },
        messageText,
        false,
        abortController
      ),
    reorder = async <EntityModel, EntityRequest>(
      e: Entity<EntityModel>,
      req: EntityRequest,
      messageText?: MessageText,
      abortController?: AbortController
    ) => await act(e, EntityActionType.Reorder, req, messageText, false, abortController),
    assign = async <EntityModel>(
      e: Entity<EntityModel>,
      model: EntityModel,
      messageText?: MessageText,
      suppressToast = true,
      abortController?: AbortController
    ) => {
      const assignAction = e.actions.Assign;
      if (Boolean(assignAction)) {
        const { requestNameKey, requestPayloadKey, payloadKey } = assignAction || {
          requestNameKey: 'key',
          requestPayloadKey: 'payload',
        };
        let payloadValue: any;
        if (payloadKey) {
          payloadValue = model[payloadKey];
        }
        if (payloadValue) {
          const req = {
            [requestNameKey!]: checkForPrefix(model['name'], e.requestNameKeyPrefix),
            [requestPayloadKey!]: payloadValue,
          };
          return await act(
            e,
            EntityActionType.Assign,
            req,
            {
              ...messageText,
              action: messageText?.action || `${String(requestPayloadKey)} assigned `,
            },
            suppressToast,
            abortController
          );
        }
      }
      return undefined;
    },
    setLatest = async <EntityModel>(
      e: Entity<EntityModel>,
      model: EntityModel,
      suppressToast = false,
      abortController?: AbortController
    ) => {
      const field = 'aliases',
        withLatest = ['latest'];
      const aliases = model[field] && Array.isArray(model[field]) ? [...model[field], ...withLatest] : withLatest;
      const newModel = { ...model, aliases };
      return await assign(
        e,
        newModel,
        { subject: model['version'] || model['displayName'] || model['name'], action: 'has been set as latest ' },
        suppressToast,
        abortController
      );
    },
    checkId = async <EntityModel>(entityType: EntityType, name: string, abortController?: AbortController) => {
      const entity = EntitiesMap.get(entityType) as Entity<EntityModel>;
      let available = false;
      try {
        const model = await get(entity, { name }, true, abortController);
        available = !Boolean(model);
      } catch (error: any) {
        if (error.message.includes('not found') || error.code === 5) {
          available = true;
        }
      }
      return available;
    };

  return { assign, checkId, create, erase, get, list, update, reorder, setLatest };
}
