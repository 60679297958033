// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/executor_pool_spec_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { ExecutorPoolSpec } from "./executor_pool_spec_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for GetExecutorPoolSpec
 *
 * @generated from message ai.h2o.orchestrator.v1.GetExecutorPoolSpecRequest
 */
export type GetExecutorPoolSpecRequest = {
/**
 * Required. ExecutorPoolSpec resource name.
 * Format: executorPoolSpecs/{spec}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetExecutorPoolSpec
 *
 * @generated from message ai.h2o.orchestrator.v1.GetExecutorPoolSpecResponse
 */
export type GetExecutorPoolSpecResponse = {
/**
 * The requested spec.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPoolSpec executor_pool_spec = 1;
 */
executorPoolSpec?: ExecutorPoolSpec;
}
;
/**
 * Request message ListExecutorPoolSpecs
 * (-- api-linter: core::0158::request-page-size-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 * (-- api-linter: core::0158::request-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.orchestrator.v1.ListExecutorPoolSpecsRequest
 */
export type ListExecutorPoolSpecsRequest = {
}
;
/**
 * Response message for ListExecutorPoolSpecs
 * (-- api-linter: core::0158::response-next-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.orchestrator.v1.ListExecutorPoolSpecsResponse
 */
export type ListExecutorPoolSpecsResponse = {
/**
 * Collection of specs.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.ExecutorPoolSpec executor_pool_specs = 1;
 */
executorPoolSpecs?: ExecutorPoolSpec[];
}
;
/**
 * Returns a specific ExecutorPoolSpec.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolSpecService.GetExecutorPoolSpec
 */
export const ExecutorPoolSpecService_GetExecutorPoolSpec = new RPC<GetExecutorPoolSpecRequest, GetExecutorPoolSpecResponse>("GET", "/v1/{name=executorPoolSpecs/*}");
/**
 * Returns a collection of ExecutorPoolSpecs.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolSpecService.ListExecutorPoolSpecs
 */
export const ExecutorPoolSpecService_ListExecutorPoolSpecs = new RPC<ListExecutorPoolSpecsRequest, ListExecutorPoolSpecsResponse>("GET", "/v1/executorPoolSpecs");
