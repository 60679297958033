import { MessageBar, MessageBarType } from '@fluentui/react';
import { EmptyMessage, Loader, loaderStylesSpinnerXLarge } from '@h2oai/ui-kit';

import ListPageShell, { BaseListPageShellProps } from './ListPageShell';

export interface ListPageErrorProps extends BaseListPageShellProps {
  error?: string;
}

export function ListPageError(props: ListPageErrorProps) {
  return (
    <ListPageShell {...props}>
      <MessageBar styles={{ root: { marginTop: 16 } }} messageBarType={MessageBarType.error}>
        {props.error || 'An error occurred'}
      </MessageBar>
    </ListPageShell>
  );
}

export function ListPageLoading(props: BaseListPageShellProps) {
  return (
    <ListPageShell {...props}>
      <Loader styles={loaderStylesSpinnerXLarge} label={props.copy.loadingMessage || 'Loading...'} />
    </ListPageShell>
  );
}

export function ListPageDownloading(props: BaseListPageShellProps) {
  return (
    <ListPageShell {...props}>
      <Loader styles={loaderStylesSpinnerXLarge} label={props.copy.downloadingMessage || 'Downloading...'} />
    </ListPageShell>
  );
}

export function ListPageNoData(props: BaseListPageShellProps) {
  return (
    <ListPageShell {...props}>
      <EmptyMessage
        title={props.copy?.noDataTitle}
        message={props.copy.noDataMessage}
        styles={{ root: { paddingTop: 64 } }}
      />
    </ListPageShell>
  );
}
