import { MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  BookTimeDialog,
  Dropdown,
  type ILoaderProps,
  MessageBar,
  MessageDialog,
  compareVersionString,
  groupBy,
  uniqBy,
  useMediaQuery,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import {
  App,
  AppInstance,
  AppInstance_Visibility,
  App_Visibility,
  ImportAppRequest,
  LaunchProfiles,
  RunAppRequest,
  TagAssignment,
} from '../../ai.h2o.cloud.appstore';
import { RunWithProfilePanel } from '../../pages/AppDetailsPage/components/RunWithProfilePanel/RunWithProfilePanel';
import { RoutePaths } from '../../pages/Routes';
import { AdminAppService, AppService, DownloadService, ServiceResponse, UploadService } from '../../services/api';
import { useCategory, useEnv, useError, useRefineData, useUser } from '../../utils/hooks';
import { AppGroup } from '../../utils/models';
import { getToastErrorMessage, handleErrMsg, matchesSearchString, specifiedVisibilityOptions } from '../../utils/utils';
import { AppConfigPanel } from '../AppConfigPanel/AppConfigPanel';
import { AppList } from '../AppList/AppList';
import CreateUpdateDialog, {
  DropdownFieldType,
  FieldType,
  FileFieldType,
} from '../CreateUpdateDialog/CreateUpdateDialog';
import DeleteAppsDialog from '../DeleteAppsDialog/DeleteAppsDialog';
import FilterPanel, { FilterPanelInput } from '../FilterPanel/FilterPanel';
import ListPage from '../ListPages/ListPage';
import { appListPageStyles } from './appListPage.styles';
import { type AppListResources } from './models';

enum PseudoCategories {
  All = 'all',
  Uncategorized = 'uncategorized',
}

export const uploadAppDialogFields: Array<FileFieldType<ImportAppRequest> | DropdownFieldType<ImportAppRequest>> = [
  {
    type: FieldType.FILE,
    prop: 'uploadId',
    label: 'App bundle',
    required: true,
    fileExtensions: ['.zip', '.wave'],
  },
  {
    type: FieldType.DROPDOWN,
    prop: 'visibility',
    label: 'Visibility',
    required: true,
    options: specifiedVisibilityOptions,
  },
];

interface CategoryMemo {
  categories: TagAssignment[];
  uncategorizedCount: number;
}

const initialResourcesState = {
  loading: true,
  errorMsg: null,
  apps: null,
  instances: null,
  myInstanceCount: null,
  appGroups: null,
  categories: null,
  categoryOptions: null,
  uncategorizedAppCount: null,
};

export interface AppListPageProps {
  title: string;
  fetchApps: () => Promise<App[]>;
  fetchInstances: () => Promise<AppInstance[]>;
  isAdmin: boolean;
  fetchProfiles?: () => Promise<LaunchProfiles[] | undefined>;
}

function derivePageDescription(resources: AppListResources) {
  if (resources.loading) return 'Loading apps...';
  if (resources.apps && resources.appGroups) {
    const apps = resources.apps,
      appGroups = resources.appGroups;
    if (apps.length === 0) return 'You have no apps';
    const appSubstring = appGroups.length > 1 ? `${appGroups.length} apps` : `${appGroups.length} app`;
    const versionSubstring = apps.length > 1 ? `${apps.length} versions` : `${apps.length} versions`;
    return `You have ${appSubstring} and ${versionSubstring}`;
  }
  return 'A problem occurred';
}

const toastGroupId = 'AppListPage';

function AppListPage(props: AppListPageProps) {
  const { title, fetchApps, fetchInstances, fetchProfiles, isAdmin } = props,
    theme = useTheme(),
    { isDesktop } = useMediaQuery(),
    auth = useAuth(),
    env = useEnv(),
    [importProgress, setImportProgress] = useState<ILoaderProps | undefined>(),
    [selectedAppIds, setSelectedAppIds] = useState<string[]>([]),
    [resources, setResources] = useState<AppListResources>(initialResourcesState),
    apps = resources.apps || [],
    instances = resources.instances || [],
    [importAppReq, setImportAppReq] = useState<ImportAppRequest | undefined>(),
    [importErr, setImportErr, onDismissImportErr] = useError(),
    [deletionApps, setDeletionApps] = useState<App[] | null>(null),
    [deletionPendingAppIds, setDeletionPendingAppIds] = useState<string[]>([]),
    [editPendingAppIds, setEditPendingAppIds] = useState<string[]>([]),
    [deleteDialogHidden, setDeleteDialogHidden] = useState(true),
    [downloadInProgress, setDownloadInProgress] = useState(false),
    [downloadErr, setDownloadErr] = useState(false),
    [cancelUpload, setCancelUpload] = useState<() => void>(() => {}),
    { addToast } = useToast(),
    { hasFullAccess, visitorModeEnabled } = useUser(),
    [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false),
    [filterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] = useBoolean(false),
    [selectedConfigApp, setSelectedConfigApp] = useState<App | undefined>(),
    [launchProfiles, setLaunchProfiles] = useState<LaunchProfiles[] | undefined>(undefined),
    [appAdvancedRunPanel, setAppAdvancedRunPanel] = useState<App | undefined>(undefined),
    loadResources = useCallback(
      async (backgroundProcess?: Boolean) => {
        if (!backgroundProcess) setResources(initialResourcesState);
        try {
          const applications = await fetchApps();
          const instances = await fetchInstances();
          const profiles = fetchProfiles ? await fetchProfiles() : undefined;
          setLaunchProfiles(profiles);

          const appIds = applications.map((app) => app.id);
          const myInstances = instances.filter((instance) => appIds.includes(instance.appId));

          const sortedApps = applications?.sort((a: App, b: App) => {
            const nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA === nameB) {
              return compareVersionString(b.version, a.version);
            } else {
              return nameA < nameB ? -1 : 1;
            }
          });

          // remove hidden tags and add index
          const modifiedApps = sortedApps.map((a, index) => ({ ...a, index }));
          const appsByName = groupBy(modifiedApps || [], 'name');

          const applicationGroups: AppGroup[] = [];
          appsByName.forEach((appsWithName, appName) => {
            const tags = appsWithName.reduce((memo: TagAssignment[], app) => {
              return [...memo, ...app.tags];
            }, []);
            applicationGroups.push({
              key: appName,
              name: appName,
              startIndex: Math.min(...appsWithName.map((a) => a.index)),
              count: appsWithName.length,
              data: {
                apps: appsWithName,
                latestApp: appsWithName[0],
                tags: uniqBy(tags, 'id'),
              },
            });
          });

          const { categories, uncategorizedCount } = modifiedApps.reduce(
            (memo: CategoryMemo, app: App) => {
              const appCategories = app.tags.filter((tag) => tag.isCategory);
              return {
                categories: [...memo.categories, ...appCategories],
                uncategorizedCount: appCategories.length === 0 ? memo.uncategorizedCount + 1 : memo.uncategorizedCount,
              };
            },
            { categories: [], uncategorizedCount: 0 }
          );

          const uniqCategories = uniqBy(categories, 'id') || [];

          setResources({
            loading: false,
            errorMsg: null,
            apps: modifiedApps,
            instances,
            myInstanceCount: myInstances.length,
            appGroups: applicationGroups,
            categoryOptions: [
              {
                key: PseudoCategories.All,
                text: 'All Apps',
              },
              {
                key: PseudoCategories.Uncategorized,
                text: 'Uncategorized Apps',
              },
              ...uniqCategories.map((c) => ({ key: c.id, text: c.title })),
            ],
            uncategorizedAppCount: uncategorizedCount,
          });
        } catch (error: unknown) {
          if (error instanceof Error) {
            setResources({
              ...initialResourcesState,
              loading: false,
              errorMsg: error?.message || 'An unknown error occurred',
            });
          }
        }
      },
      [setResources, fetchApps, fetchInstances]
    ),
    showImportDialog = useCallback(
      () =>
        setImportAppReq({
          uploadId: '',
          visibility: App_Visibility.PRIVATE,
          customImage: '',
          force: false,
          profile: '',
          storagePath: '',
        }),
      []
    ),
    hideImportDialog = useCallback(() => {
      setImportAppReq(undefined);
      // Allow the modal to fadeout before we hide the error, to avoid UI flickering
      setTimeout(onDismissImportErr, 500);
    }, [onDismissImportErr]),
    onFileUploadProgress = ({ loaded, total }: ProgressEvent<EventTarget>) =>
      setImportProgress({ label: 'Uploading app bundle', percentComplete: loaded / total }),
    importApp = useCallback(
      async ({ uploadId, visibility = App_Visibility.PRIVATE }: ImportAppRequest) => {
        try {
          const uploadCall = UploadService.upload(uploadId as any, auth.user?.access_token, onFileUploadProgress);
          // NOTE: Arrow func wrapper needed because `uploadCall.cancel` is a function, so would execute as a state-setter otherwise
          setCancelUpload(() => uploadCall.cancel);
          const { filename } = await uploadCall.promise;
          setImportProgress({ label: 'Importing App' });
          const importCall = AppService.interruptibleImport({
            uploadId: filename,
            visibility,
            customImage: '',
            force: false,
            profile: '',
            storagePath: '',
          });
          // NOTE: Arrow func wrapper needed because `importCall.cancel` is a function, so would execute as a state-setter otherwise
          setCancelUpload(() => importCall.cancel);
          // Wait for import to finish before cleanup and reloading resources
          await importCall.promise;
          hideImportDialog();
          await loadResources();
        } catch (error: any) {
          setImportAppReq({
            uploadId: '',
            visibility,
            customImage: '',
            force: false,
            profile: '',
            storagePath: '',
          });
          if (error !== ServiceResponse.abort) {
            setImportErr({ message: `Could not import app: ${handleErrMsg(error?.message)}` });
          }
        } finally {
          setImportProgress(undefined);
        }
      },
      [loadResources, setImportErr, hideImportDialog]
    ),
    onFinishDelete = useCallback(async () => {
      try {
        await loadResources(true);
      } catch (error: any) {
        if (error instanceof Error) {
          setResources({ ...initialResourcesState, errorMsg: error?.message || 'An unknown error occurred' });
        }
      } finally {
        setSelectedAppIds([]);
      }
    }, [loadResources, setSelectedAppIds, setResources]),
    onDeleteApp = useCallback(
      (app: App) => () => {
        setDeleteDialogHidden(false);
        setDeletionApps([app]);
      },
      [setDeletionApps, setDeleteDialogHidden]
    ),
    onDeleteSelectedApps = useCallback(() => {
      const selectedApps = apps?.filter((app) => selectedAppIds.includes(app.id));
      if (selectedApps) {
        setDeleteDialogHidden(false);
        setDeletionApps(selectedApps);
      }
    }, [selectedAppIds, setDeletionApps]),
    dismissDeleteDialog = useCallback(() => setDeleteDialogHidden(true), []),
    onDeleteDialogDismissed = useCallback(() => setDeletionApps(null), []),
    runApp = useCallback(
      (app: App, visibility: AppInstance_Visibility = AppInstance_Visibility.VISIBILITY_UNSPECIFIED, profile = '') =>
        async () => {
          addToast({
            messageBarType: MessageBarType.success,
            message: `Running app ${app.title || ''}`,
            groupId: toastGroupId,
          });
          try {
            const runAppRequest: RunAppRequest = {
              id: app.id,
              visibility,
              profile,
            };
            const { instance } = isAdmin
              ? await AdminAppService.runApp(runAppRequest)
              : await AppService.runApp(runAppRequest);
            if (instance) {
              await loadResources(true);
            }
          } catch (message) {
            addToast(getToastErrorMessage(`Could not run app: ${handleErrMsg(message as string)}`, toastGroupId));
          }
        },
      [isAdmin, loadResources, addToast]
    ),
    downloadApp = useCallback(
      (app: App) => async () => {
        try {
          setDownloadInProgress(true);
          const downloadProps: [string, string, string | undefined] = [
            app.id,
            `${app.name.replace(/\s/g, '')}.wave`,
            auth.user?.access_token,
          ];

          const resp = isAdmin
            ? await DownloadService.downloadAdminApp(...downloadProps)
            : await DownloadService.downloadApp(...downloadProps);
          if (!resp.ok) {
            throw new Error('Download failed');
          }
        } catch (error: any) {
          if (error !== ServiceResponse.abort) {
            setDownloadErr(true);
          }
        } finally {
          setDownloadInProgress(false);
        }
      },
      []
    ),
    openConfigPanel = useCallback(
      (app: App) => async () => {
        openPanel();
        setSelectedConfigApp(app);
      },
      [openPanel, setSelectedConfigApp]
    ),
    importAppPermission = hasFullAccess && !visitorModeEnabled,
    showImportApp = !isAdmin && (importAppPermission || env?.menu?.hasBookTime),
    dialogUploadApp = importAppPermission ? (
      <CreateUpdateDialog<ImportAppRequest>
        dataTest="upload-app"
        err={importErr?.message}
        fields={uploadAppDialogFields}
        initialModel={importAppReq}
        isHidden={!importAppReq}
        loading={importProgress}
        onCancel={cancelUpload}
        onDismissErr={onDismissImportErr}
        onSubmit={importApp}
        submitLabel="Import App"
        title="Import App"
        toggleHideDialog={hideImportDialog}
      />
    ) : (
      <BookTimeDialog
        url={env?.menu?.bookTimeLink}
        onDismiss={() => setImportAppReq(undefined)}
        hidden={!importAppReq}
      />
    ),
    { getCategory, setCategory, resetCategory } = useCategory(),
    {
      data: refinedAppGroups,
      searchKey,
      setSearchKey,
      filterMap,
      setFilter,
      resetFilter,
    } = useRefineData({
      data: resources.appGroups,
      onSearch: useCallback((searchKey, data: AppGroup[]) => {
        if (searchKey === '') return data;
        return data.filter((appGroup) => {
          const matchingApps = appGroup.data.apps.filter((app) =>
            matchesSearchString([app.name, app.title, app.id, app.description, app.version], searchKey)
          );
          return matchingApps.length > 0;
        });
      }, []),
      onFilter: useCallback(
        (filterSet, data: AppGroup[]) =>
          data.filter((appGroup) => {
            const filterKey = getCategory(filterSet);
            if (filterKey === '' || filterKey === PseudoCategories.All) return true;
            if (filterKey === PseudoCategories.Uncategorized) return !appGroup.data.tags.some((tag) => tag.isCategory);
            const matchingApps = appGroup.data.apps.filter((app) => app.tags.map((t) => t.id).includes(filterKey));
            return matchingApps.length > 0;
          }),
        []
      ),
    }),
    activeFilters = useMemo(() => {
      if (!filterMap) return [];
      const activeCategory = resources?.categoryOptions?.find((option) => option.key === getCategory(filterMap));
      return activeCategory ? [{ key: activeCategory.key, label: activeCategory.text }] : [];
    }, [filterMap, resources?.categoryOptions]),
    onFinishEdit = useCallback(() => {
      loadResources(true);
    }, [loadResources]),
    onChangeSearchKey = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]),
    onChangeCategoryDropdown = useCallback((_, option) => setCategory(setFilter, option?.key || ''), [setCategory]),
    visibleApps = useMemo(
      () => refinedAppGroups.reduce((memo: App[], appGroup: AppGroup): App[] => [...memo, ...appGroup.data.apps], []),
      [refinedAppGroups]
    );

  useEffect(() => {
    loadResources();
  }, [loadResources]);

  return (
    <>
      <FilterPanel isOpen={filterPanelOpen} onDismiss={dismissFilterPanel}>
        <FilterPanelInput>
          <Dropdown
            label="Category"
            selectedKey={getCategory(filterMap)}
            placeholder="All Apps"
            onChange={onChangeCategoryDropdown}
            options={resources?.categoryOptions || []}
          />
        </FilterPanelInput>
      </FilterPanel>
      {dialogUploadApp}
      <MessageDialog
        hidden={!downloadErr}
        title="Download App"
        content={<MessageBar messageBarType={MessageBarType.error}>{`The download process failed.`}</MessageBar>}
        onDismiss={() => setDownloadErr(false)}
      />
      <DeleteAppsDialog
        isAdmin={isAdmin}
        deletionApps={deletionApps}
        instances={instances}
        hidden={deleteDialogHidden}
        setDeletionPendingAppIds={setDeletionPendingAppIds}
        setResources={setResources}
        onFinishDelete={onFinishDelete}
        onDismiss={dismissDeleteDialog}
        onDismissed={onDeleteDialogDismissed}
      />
      <AppConfigPanel
        isOpen={isOpen}
        isAdmin={isAdmin}
        app={selectedConfigApp}
        onDismissPanel={dismissPanel}
        setEditPendingAppIds={setEditPendingAppIds}
        onSaved={onFinishEdit}
      />
      <ListPage
        stackStyles={appListPageStyles(theme)}
        actions={
          isDesktop
            ? [
                {
                  dangerous: true,
                  'data-test': 'Delete',
                  disabled: selectedAppIds.length === 0,
                  key: 'delete',
                  onClick: onDeleteSelectedApps,
                  text: 'Delete',
                },
              ]
            : []
        }
        activeFilters={activeFilters}
        copy={{
          title,
          subtitle: derivePageDescription(resources),
          noDataTitle: "You don't have any apps",
          noDataMessage: 'Click on Upload App to add your first app',
          loadingMessage: 'Loading apps...',
          downloadingMessage: 'The app is being downloaded...',
          noFilterResultsMessage: 'No apps match these filters',
          searchPlaceholder: isDesktop ? 'Search by app name, title, ID, version, description' : 'Search Engine',
        }}
        downloading={downloadInProgress}
        error={resources.errorMsg || undefined}
        loading={resources.loading || undefined}
        onChangeSearchText={onChangeSearchKey}
        onOpenFilterPanel={openFilterPanel}
        onRemoveFilter={() => {
          resetCategory(resetFilter);
        }}
        onResetFilters={() => {
          resetCategory(resetFilter);
        }}
        primaryButtonProps={
          showImportApp
            ? {
                text: 'Upload App',
                onClick: showImportDialog,
              }
            : isAdmin
            ? {
                text: 'Manage Admin Secrets',
                href: RoutePaths.ADMIN_SECRETS,
              }
            : undefined
        }
        searchText={searchKey}
        showFilterButton
        showNoDataPage={apps && apps.length === 0}
        showNoResults={visibleApps?.length === 0}
        statsCards={[
          {
            title: 'Total App Instances',
            stat: typeof resources.myInstanceCount === 'number' ? resources.myInstanceCount : '',
          },
          {
            title: 'Uncategorized Apps',
            stat: typeof resources.uncategorizedAppCount === 'number' ? resources.uncategorizedAppCount : '',
          },
        ]}
      >
        <AppList
          apps={visibleApps}
          appGroups={refinedAppGroups}
          deleteApp={onDeleteApp}
          downloadApp={downloadApp}
          editApp={openConfigPanel}
          runApp={runApp}
          onRunWithProfiles={
            launchProfiles
              ? (app: App) => {
                  setAppAdvancedRunPanel(app);
                }
              : undefined
          }
          selectedAppIds={selectedAppIds}
          setSelectedAppIds={setSelectedAppIds}
          deletionPendingAppIds={deletionPendingAppIds}
          editPendingAppIds={editPendingAppIds}
          isAdmin={isAdmin}
        />
      </ListPage>

      {appAdvancedRunPanel && launchProfiles && (
        <RunWithProfilePanel
          app={appAdvancedRunPanel}
          profiles={launchProfiles}
          onDismiss={() => setAppAdvancedRunPanel(undefined)}
          onRun={async ({ app, visibility, profile }) => {
            setAppAdvancedRunPanel(undefined);
            await runApp(app, visibility, profile)();
          }}
        />
      )}
    </>
  );
}

export default AppListPage;
