// TODO: Export this component into @h2o-ui-kit.
import {
  DirectionalHint,
  FocusZone,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  IStyle,
  Label,
} from '@fluentui/react';
import { Button, IH2OTheme, SearchBox, useClassNames, useTheme } from '@h2oai/ui-kit';
import React, { CSSProperties } from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';

export interface ISearchableDropdownStyles {
  calloutSearchboxWrapper: IStyle;
  errorMessage: IStyle;
}

export const searchableDropdownStyles = (theme: IH2OTheme): Partial<ISearchableDropdownStyles> => ({
  calloutSearchboxWrapper: {
    top: 0,
    zIndex: 1,
    position: 'sticky',
    padding: '0px 16px 16px 16px',
    backgroundColor: theme.semanticColors?.contentBackground,
  },
  errorMessage: {
    color: theme.semanticColors?.inputErrorMessageText,
    fontSize: 10,
    fontWeight: 600,
    padding: '4px 0px',
  },
});

type Props = {
  options: { key: string; text: string }[];
  placeHolder: string;
  searchBoxPlaceHolder?: string;
  value: string;
  noMatchText?: string;
  onChange: (item: { key: string; text?: string }) => void;
  width: number;
  maxHeight: number;
  label?: string;
  errorMessage?: string;
  required?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
};

const SearchableDropdown = ({
  options,
  placeHolder,
  searchBoxPlaceHolder,
  value,
  noMatchText,
  onChange,
  width,
  maxHeight,
  label,
  errorMessage,
  required,
  style,
  disabled,
}: Props) => {
  const [filteredOptions, setFilteredOptions] = React.useState(options),
    theme = useTheme(),
    classNames = useClassNames<ISearchableDropdownStyles, ClassNamesFromIStyles<ISearchableDropdownStyles>>(
      'searchableDropdown',
      searchableDropdownStyles(theme)
    ),
    onSearch = React.useCallback(
      (_event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const newOptions = options.filter(({ text }) => text.toLowerCase().includes(newValue));
        if (newOptions.length === 0) {
          setFilteredOptions([
            {
              text: 'No Match',
              key: 'NOMATCH',
            },
          ]);
        } else {
          setFilteredOptions(newOptions);
        }
      },
      [options]
    ),
    onRenderMenuList: IRenderFunction<IContextualMenuListProps> | undefined = React.useCallback(
      (itemProps, defaultRender) => {
        return (
          <FocusZone>
            <div className={classNames.calloutSearchboxWrapper}>
              <SearchBox
                autoComplete="off"
                onChange={onSearch}
                placeholder={searchBoxPlaceHolder || 'What do you want to search?'}
              />
            </div>
            {itemProps?.totalItemCount &&
              itemProps.totalItemCount > 0 &&
              itemProps?.items[0].key !== 'NOMATCH' &&
              defaultRender?.(itemProps)}
            {itemProps?.totalItemCount === 1 && itemProps?.items[0].key === 'NOMATCH' && (
              <p>{noMatchText ? noMatchText : 'No results found.'}</p>
            )}
          </FocusZone>
        );
      },
      [noMatchText, onSearch, searchBoxPlaceHolder]
    ),
    menuProps: IContextualMenuProps = React.useMemo(
      () => ({
        items: filteredOptions,
        onItemClick: (_event, item) => {
          if (!item || !item?.text) return;
          setFilteredOptions(options);
          onChange(item);
        },
        onRenderMenuList: onRenderMenuList,
        styles: {
          container: { width, maxHeight },
        },
        directionalHint: DirectionalHint.bottomAutoEdge,
        directionalHintFixed: true,
      }),
      [filteredOptions, maxHeight, onRenderMenuList, onChange, options, width, maxHeight]
    ),
    activeStyles = {
      backgroundColor: theme.semanticColors?.contentBackground,
      borderColor: theme.semanticColors?.inputActiveBorder,
    },
    inactiveStyles = {
      backgroundColor: theme.semanticColors?.contentBackground,
      borderColor: theme.semanticColors?.inputBorder,
    };

  return (
    <div style={style}>
      <Label required={required}>{label}</Label>
      <Button
        disabled={disabled}
        text={value === undefined ? placeHolder : value}
        menuProps={menuProps}
        menuIconProps={{ iconName: 'ChevronDown' }}
        onAfterMenuDismiss={() => {
          setFilteredOptions(options);
        }}
        styles={{
          root: { ...inactiveStyles, width: width, color: theme.semanticColors?.textPrimary },
          rootFocused: inactiveStyles,
          rootExpanded: activeStyles,
          rootHovered: activeStyles,
          rootPressed: activeStyles,
          label: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.semanticColors?.textPrimary,
          },
        }}
      />
      {errorMessage && <div className={classNames.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default SearchableDropdown;
