// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/default_dai_setup_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { DefaultDAISetup } from "./default_dai_setup_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for getting the DefaultDAISetup.
 *
 * @generated from message ai.h2o.engine.v1.GetDefaultDAISetupRequest
 */
export type GetDefaultDAISetupRequest = {
/**
 * DefaultDAISetup resource name.
 * Format: defaultDAISetup
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetDefaultDAISetup.
 *
 * @generated from message ai.h2o.engine.v1.GetDefaultDAISetupResponse
 */
export type GetDefaultDAISetupResponse = {
/**
 * DAISetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultDAISetup default_dai_setup = 1;
 */
defaultDaiSetup?: DefaultDAISetup;
}
;
/**
 * Request message for updating the DefaultDAISetup.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDefaultDAISetupRequest
 */
export type UpdateDefaultDAISetupRequest = {
/**
 * DefaultDAISetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultDAISetup default_dai_setup = 1;
 */
defaultDaiSetup: DefaultDAISetup;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {cpu_constraint, gpu_constraint, memory_bytes_constraint, storage_bytes_constraint,
 * max_idle_duration_constraint, max_running_duration_constraint, max_non_interaction_duration, max_unused_duration,
 * configuration_override, yaml_pod_template_spec, yaml_gpu_tolerations, triton_enabled}
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response for UpdateDefaultDAISetup.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDefaultDAISetupResponse
 */
export type UpdateDefaultDAISetupResponse = {
/**
 * DefaultDAISetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultDAISetup default_dai_setup = 1;
 */
defaultDaiSetup?: DefaultDAISetup;
}
;
/**
 * Returns the DefaultDAISetup.
 *
 * @generated from rpc ai.h2o.engine.v1.DefaultDAISetupService.GetDefaultDAISetup
 */
export const DefaultDAISetupService_GetDefaultDAISetup = new RPC<GetDefaultDAISetupRequest, GetDefaultDAISetupResponse>("GET", "/v1/{name=defaultDAISetup}");
/**
 * Updates the DefaultDAISetup.
 *
 * @generated from rpc ai.h2o.engine.v1.DefaultDAISetupService.UpdateDefaultDAISetup
 */
export const DefaultDAISetupService_UpdateDefaultDAISetup = new RPC<UpdateDefaultDAISetupRequest, UpdateDefaultDAISetupResponse>("PATCH", "/v1/{defaultDaiSetup.name=defaultDAISetup}", "defaultDaiSetup");
