export const defaultPort = 9001;
export const defaultBasePath = `http://localhost:${defaultPort}`;

export const serviceNames = {
  appstore: 'services/appstore',
  aiem: 'services/engine-manager',
  mlops: 'services/mlops-api',
  mlopsLegacy: 'services/mlops',
  steam: 'services/steam',
  logging: 'services/logging',
  telemetry: 'services/telemetry-server',
  orchestrator: 'services/orchestrator',
  authz: 'services/authz-gateway',
};

export const clientNames = {
  appstore: 'clients/appstore',
  platform: 'clients/platform',
};
