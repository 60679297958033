import { PanelType, Stack, Text } from '@fluentui/react';
import { Button, FontSizes, Item, Panel, TextWithCopy, defaultPalette, itemStylesTag } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { EntityType } from '../../../aiem/entity/types';
import { ImagePullPolicy } from '../../../aiem/gen/ai/h2o/engine/v1/base_types_pb';
import { DAIProfile } from '../../../aiem/gen/ai/h2o/engine/v1/dai_profile_pb';
import { InternalDAIVersion } from '../../../aiem/gen/ai/h2o/engine/v1/internal_dai_version_pb';
import { InternalH2OVersion } from '../../../aiem/gen/ai/h2o/engine/v1/internal_h2o_version_pb';
import { bytesToGibibytes, getIdFromName } from '../../../aiem/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';

const ImagePullPolicyMap = new Map<ImagePullPolicy, string>([
  [ImagePullPolicy.ALWAYS, 'Always'],
  [ImagePullPolicy.IF_NOT_PRESENT, 'If Not Present'],
  [ImagePullPolicy.NEVER, 'Never'],
  [ImagePullPolicy.UNSPECIFIED, 'Unspecified'],
]);

const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: defaultPalette?.gray500, width: '40%' } } };
const viewValue = {
  styles: {
    root: {
      fontSize: FontSizes.xsmall,
      color: defaultPalette?.gray900,
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden' as unknown as 'hidden', // not sure why this is needed, but it is
    },
  },
};

const renderDAIProfile = (profile?: DAIProfile): JSX.Element => {
  if (!profile) {
    return <></>;
  }
  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Display name</Text>
        <Text {...viewValue}>{profile.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>ID</Text>
        <Text {...viewValue}>{getIdFromName(profile.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>CPUs</Text>
        <Text {...viewValue}>{profile.cpu}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>GPUs</Text>
        <Text {...viewValue}>{profile.gpu}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Memory (GiB)</Text>
        <Text {...viewValue}>{bytesToGibibytes(profile.memoryBytes)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Storage (GiB)</Text>
        <Text {...viewValue}>{bytesToGibibytes(profile.storageBytes)}</Text>
      </Stack>
    </>
  );
};

const renderAnnotations = (annotations: { [key: string]: string }): JSX.Element[] | string => {
  const keyValues = Object.keys(annotations).map((a) => {
    return (
      <div key={`key_${a}`} style={{ display: 'flex', lineHeight: '1.5rem' }}>
        <div style={{ padding: '3px', width: '50%' }}>{a}:</div>
        <div style={{ padding: '3px', width: '50%' }}>{annotations[a]}</div>
      </div>
    );
  });
  if (keyValues.length) {
    return keyValues;
  }
  return '-';
};

const renderVersion = (version?: InternalDAIVersion): JSX.Element => {
  if (!version) {
    return <></>;
  }
  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Version</Text>
        <Text {...viewValue}>{version.version || '-'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>ID</Text>
        <Text {...viewValue}>{getIdFromName(version.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Aliases</Text>
        {version.aliases?.length
          ? version.aliases.map((d: string) => (
              <Item
                key={`key_${d}`}
                styles={itemStylesTag}
                idField="id"
                labelField="title"
                styleField="style"
                data={{
                  id: d,
                  title: d,
                  style: {
                    backgroundColor: defaultPalette.gray200,
                    color: defaultPalette.gray800,
                  },
                }}
              />
            ))
          : '-'}
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Deprecated</Text>
        <Text {...viewValue}>{version.deprecated ? 'Yes' : 'No'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image</Text>
        <Text {...viewValue} title={version.image}>
          <TextWithCopy text={version.image} />
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull policy</Text>
        <Text {...viewValue}>
          {version.imagePullPolicy ? ImagePullPolicyMap.get(version.imagePullPolicy) || '-' : '-'}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull secrets</Text>
        <Text {...viewValue}>{version.imagePullSecrets?.join(', ') || '-'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Annotations</Text>
        <div style={{ width: '60%' }}>{version.annotations ? renderAnnotations(version.annotations) : '-'}</div>
      </Stack>
    </>
  );
};

export interface IAIEMSettingsViewPanelProps<EntityModel> {
  item?: EntityModel;
  onDismiss: () => any;
  panelTitle: string;
  type: EntityType;
}

export function AIEMSettingsViewPanel<EntityModel>(props: IAIEMSettingsViewPanelProps<EntityModel>) {
  const { item, type, onDismiss, panelTitle } = props;

  const content = useMemo(() => {
    return type === EntityType.DAIProfile
      ? renderDAIProfile(item as unknown as DAIProfile)
      : renderVersion(item as unknown as InternalDAIVersion | InternalH2OVersion);
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
}
