import { IStyle } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export interface IExecutorPoolDetailStyles {
  footerButton: IStyle;
  dialogInput: IStyle;
  form: IStyle;
  expandedContentItem: IStyle;
  tooltip: IStyle;
  infoLabel: IStyle;
  messageBar: IStyle;
  formEditor: IStyle;
  loader: IStyle;
}
export const executorPoolDetailStyles = (theme: IH2OTheme): Partial<IExecutorPoolDetailStyles> => ({
  footerButton: {
    marginTop: 8,
    marginRight: 4,
  },
  dialogInput: {
    width: '30%',
    minWidth: 360,
    maxWidth: 460,
    marginRight: 12,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
  },
  expandedContentItem: {
    flexWrap: 'nowrap',
    display: 'flex',
  },
  tooltip: {
    padding: 4,
  },
  infoLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  messageBar: {
    marginTop: 8,
    maxWidth: 360,
  },
  formEditor: {
    width: '30%',
    minWidth: 360,
    maxWidth: 460,
    outlineWidth: 1,
    outlineStyle: 'solid',
    outlineColor: theme.semanticColors?.inputBorder,
    borderRadius: 4,
    padding: 10,
    '.ms-Label[id*="title"]': {
      // TODO: Hide the main title.
      // TODO: .ms-Grid-row - Place key-value pairs side by side.
      fontWeight: 600,
      fontSize: '14px',
    },
    'span:has(.ms-Button--commandBar)': {
      float: 'left !important',
    },
    'label[for*="_newKey-"]': {
      // TODO: Add Key styles.
    },
    'label[for$="_newKey"]': {
      // TODO: Add Value styles.
    },
    'input[name*="_newKey"]': {
      // Add input styles.
    },
    'button:has(i[data-icon-name="Delete"])': {
      marginTop: 26,
      i: {
        color: theme.semanticColors?.buttonPrimaryText,
      },
      'i:hover': {
        color: theme.semanticColors?.buttonHoverText,
      },
    },
    '.ms-Button--commandBar': {
      color: theme.semanticColors?.buttonPrimaryText,
      backgroundColor: theme.semanticColors?.buttonInlineBackground,
      borderRadius: '4px',
      ':hover': {
        color: theme.semanticColors?.buttonHoverText,
      },
    },
    '.ms-Button--commandBar i': {
      color: theme.semanticColors?.buttonPrimaryText,
    },
    '.ms-Button--commandBar:hover i': {
      color: theme.semanticColors?.buttonHoverText,
    },
    '.ms-TextField-field[readonly]': {
      color: theme.semanticColors?.textDisabled,
    },
    '.field-object': {
      display: 'flex',
      flexDirection: 'column',
    },
    marginTop: 10,
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
