import { IStyle } from '@fluentui/react';

export const defaultEntityLabelColumnWidth = 500;
export const defaultEntityFormRowRule = 'solid 1px #e1e1e1';
export const defaultConstraintHeaderRowStyles: IStyle = {
  paddingLeft: defaultEntityLabelColumnWidth,
  paddingBottom: 10,
  fontWeight: 700,
};
export const defaultEntityFormRowStyles = { padding: '10px 0', borderTop: defaultEntityFormRowRule };
