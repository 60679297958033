import { AIEngine, EngineConstraintSet } from '../../../../../aiem/engine/types';
import { AdjustedDAIProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/adjusted_dai_profile_pb';
import { MetadataTable, MetadataTableBody } from '../../MetadataTable/MetadataTable';
import EngineProfileTableRows from './EngineProfileTableRows';

interface DisplayPresetEngineProfileProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  profile?: AdjustedDAIProfile;
}

export default function DisplayPresetEngineProfile({
  engine,
  constraintSet,
  profile,
}: DisplayPresetEngineProfileProps) {
  return (
    <MetadataTable>
      <MetadataTableBody>
        <EngineProfileTableRows loading={!constraintSet} engine={engine} profile={profile} />
      </MetadataTableBody>
    </MetadataTable>
  );
}
