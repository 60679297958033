import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog, Dropdown, FontSizes, useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  App,
  AppPreconditionStatus,
  App_Visibility,
  CreateSecretRequest,
  Secret,
  Secret_Visibility,
  UpdateSecretRequest,
} from '../ai.h2o.cloud.appstore';
import FilterPanel, { FilterPanelInput } from '../components/FilterPanel/FilterPanel';
import ListPage from '../components/ListPages/ListPage';
import {
  ManagedSecretsList,
  getSecretUniqueId,
  visibilityOptions,
} from '../components/ManagedSecretsList/ManagedSecretsList';
import {
  ISecretConfigPanelProps,
  SecretConfigPanel,
  SecretConfigPanelEvent,
  convertAdminSecretDataToREST,
} from '../components/SecretConfigPanel/SecretConfigPanel';
import { AdminSecretsService } from '../services/api';
import { useApp, useCategory, useRefineData } from '../utils/hooks';
import { getToastErrorMessage } from '../utils/utils';
import { RoutePaths } from './Routes';

const appNameSanitize = (appName?: string) => {
  if (!appName) {
    return '';
  }

  return appName.startsWith('app:') ? appName : `app:${appName}`;
};

// NB: for some reason, the generated typescript for the data property of the Secret type is incorrect. This is the correct type for the 'data' property.
export type SecretData = Record<string, string>;
export type CorrectSecret = Omit<Secret, 'data'> & { data: SecretData };

const visibilityFilter = (filterVisibility: Secret_Visibility | string, itemVisibility: Secret_Visibility | string) =>
  filterVisibility === Secret_Visibility.VISIBILITY_UNSPECIFIED || filterVisibility === itemVisibility;

const filter = (
  items: CorrectSecret[] = [],
  filterText = '',
  visibility: Secret_Visibility | string = Secret_Visibility.VISIBILITY_UNSPECIFIED
): CorrectSecret[] => {
  filterText = filterText.trim().toLowerCase();
  return items.filter((d) => {
    return filterText
      ? d.name.toLowerCase().includes(filterText) && visibilityFilter(visibility, d.visibility)
      : visibilityFilter(visibility, d.visibility);
  });
};

function AdminManageSecretsPage() {
  const [secrets, setSecrets] = useState<CorrectSecret[]>([]),
    [appList, setAppList] = useState<App[]>([]),
    refFilterText = useRef<string>(),
    [filterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] = useBoolean(false),
    [loading, { setTrue: setLoading, setFalse: setFinishedLoading }] = useBoolean(false),
    [loadingSecretIds, setLoadingSecretIds] = useState<[string, boolean]>(),
    [hideDeleteConfirmDialog, { toggle: toggleHideDeleteConfirmDialog }] = useBoolean(true),
    [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false),
    [secretConfigPanelProps, setSecretConfigPanelProps] = useState<ISecretConfigPanelProps | undefined>(),
    { addToast } = useToast(),
    toastError = (message: string) => {
      addToast(getToastErrorMessage(message, 'AdminManageSecretsPage'));
    },
    refSelectedSecret = useRef<CorrectSecret>(),
    { getAdminApps } = useApp(),
    loadApps = useCallback(async () => {
      try {
        const app = await getAdminApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: '',
          latestVersions: false,
          withPreference: false,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [],
        });
        setAppList(app);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading app`);
        }
      }
    }, [appList, setAppList]),
    loadSecrets = useCallback(async () => {
      try {
        const { secrets: data } = await AdminSecretsService.listSecrets({
          visibility: Secret_Visibility.VISIBILITY_UNSPECIFIED,
          parent: '',
        });
        setSecrets(data as unknown as CorrectSecret[]);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading secrets`);
        }
      }
    }, [addToast]),
    deleteSecret = useCallback(
      async (secret: CorrectSecret) => {
        setLoadingSecretIds([getSecretUniqueId(secret), true]);
        try {
          await AdminSecretsService.deleteSecret(secret);
          await loadSecrets();
        } catch (error) {
          if (error instanceof Error) {
            toastError(`An error has occurred while deleting a secret(${secret.name})`);
          }
        }
        setLoadingSecretIds([getSecretUniqueId(secret), false]);
      },
      [addToast, loadSecrets]
    ),
    openConfigPanel = useCallback(
      async (secret?: any) => {
        refSelectedSecret.current = secret;
        openPanel();
        const panelProps: ISecretConfigPanelProps = {
          secret,
          appList,
          onClose: dismissPanel,
          onConfirm: confirmPanel,
        };
        setSecretConfigPanelProps(panelProps);
      },
      [setSecretConfigPanelProps, openPanel, dismissPanel]
    ),
    confirmPanel = useCallback<SecretConfigPanelEvent>(
      async (name, visibility, data, parent) => {
        dismissPanel();
        const uniqueId = name !== '' && visibility ? refSelectedSecret.current?.name + visibility : undefined;
        if (uniqueId) setLoadingSecretIds([uniqueId, true]);
        try {
          const basicRequest = {
            data: convertAdminSecretDataToREST(data),
            visibility,
            parent: appNameSanitize(parent),
          };
          if (name) {
            await AdminSecretsService.createSecret({
              name,
              allowMissingParent: visibility !== Secret_Visibility.APP,
              ...basicRequest,
            } as CreateSecretRequest); // NB: the generated types are incorrect, so type coercion is required here
          } else {
            if (refSelectedSecret.current?.name) {
              await AdminSecretsService.updateSecret({
                name: refSelectedSecret.current.name,
                replace: true,
                ...basicRequest,
              } as UpdateSecretRequest); // NB: the generated types are incorrect, so type coercion is required here
            }
          }
          await loadSecrets();
        } catch (error) {
          if (name) {
            if (error instanceof Error) {
              toastError(`An error has occurred while creating a secret(${name})`);
            }
          } else {
            if (error instanceof Error && refSelectedSecret.current?.name) {
              toastError(`An error has occurred while updating a secret(${refSelectedSecret.current.name})`);
            }
          }
        }
        if (uniqueId) setLoadingSecretIds([uniqueId, false]);
      },
      [addToast, loadingSecretIds]
    ),
    deleteSecretConfirmDialog = useCallback(async (secret: CorrectSecret) => {
      refSelectedSecret.current = secret;
      toggleHideDeleteConfirmDialog();
    }, []),
    { getCategory, setCategory, resetCategory } = useCategory(),
    {
      data: refinedItems,
      searchKey,
      setSearchKey,
      filterMap,
      setFilter,
      resetFilter,
    } = useRefineData<CorrectSecret>({
      data: secrets,
      onSearch: useCallback((searchNewKey, data: CorrectSecret[]) => {
        refFilterText.current = searchNewKey;
        if (searchNewKey === '') return data;
        return filter(data, searchNewKey);
      }, []),
      onFilter: useCallback((filterNewKey, data: CorrectSecret[]) => {
        if (getCategory(filterNewKey) === '') return data;
        return filter(data, refFilterText.current, getCategory(filterNewKey));
      }, []),
    }),
    onChange = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]),
    onDropdownChange = useCallback((_, option) => setCategory(setFilter, option?.key || ''), [setCategory]),
    clearFilters = useCallback(() => resetCategory(resetFilter), [resetCategory]),
    activeFilters = useMemo(() => {
      const matchingOption = visibilityOptions.find((option) => option.key === getCategory(filterMap));
      if (!matchingOption) return [];
      return [
        {
          key: matchingOption.key,
          label: matchingOption.text,
        },
      ];
    }, [filterMap]);
  useEffect(() => {
    const load = async () => {
      setLoading();
      await loadApps();
      await loadSecrets();
      setFinishedLoading();
    };
    load();
  }, []);

  return (
    <>
      <FilterPanel isOpen={filterPanelOpen} onDismiss={dismissFilterPanel}>
        <FilterPanelInput>
          <Dropdown
            label="Visibility"
            placeholder="All Apps"
            options={visibilityOptions}
            onChange={onDropdownChange}
            selectedKey={getCategory(filterMap)}
          />
        </FilterPanelInput>
      </FilterPanel>
      <ListPage
        activeFilters={activeFilters}
        copy={{
          title: 'Admin App Secrets',
          subtitle: '',
          noDataMessage: "You don't have any app secrets",
          noFilterResultsMessage: 'No app secrets match these filters',
          loadingMessage: 'Loading App Secrets...',
          searchPlaceholder: 'Search by secret name',
        }}
        loading={loading}
        onChangeSearchText={onChange}
        onOpenFilterPanel={openFilterPanel}
        onRemoveFilter={clearFilters}
        onResetFilters={clearFilters}
        parentPage={RoutePaths.ADMIN_APPS}
        primaryButtonProps={{
          text: 'Create secret',
          onClick: async () => {
            await openConfigPanel();
          },
        }}
        searchText={searchKey}
        showFilterButton
        showNoDataPage={secrets?.length === 0}
        showNoResults={refinedItems.length === 0}
      >
        <ManagedSecretsList
          secrets={refinedItems}
          loading={loadingSecretIds}
          editSecret={openConfigPanel}
          deleteSecret={deleteSecretConfirmDialog}
        />
        <ConfirmDialog
          hidden={hideDeleteConfirmDialog}
          onDismiss={toggleHideDeleteConfirmDialog}
          title="Delete Secret"
          content={
            <div style={{ fontSize: FontSizes.small }}>
              Do you really want to delete the secret ({refSelectedSecret.current?.name})?
            </div>
          }
          onConfirm={() => {
            if (refSelectedSecret.current) {
              deleteSecret(refSelectedSecret.current);
            }
            toggleHideDeleteConfirmDialog();
          }}
          confirmationButtonText="Delete"
        />
      </ListPage>
      {isOpen && appList && (
        <SecretConfigPanel
          key={secretConfigPanelProps?.secret?.name}
          secret={refSelectedSecret.current}
          isOpen={isOpen}
          onConfirm={confirmPanel}
          onClose={dismissPanel}
          appList={appList}
        />
      )}
    </>
  );
}

export default AdminManageSecretsPage;
