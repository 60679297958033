import {
  EngineQueryParamsType,
  FilterCondition,
  FilterLogicalOperatorParenthesisType,
  FilterValueEnclosingType,
  filterSummarizeSelection,
} from '../../aiem/engine/hooks';
import { EngineVersion } from '../../aiem/engine/types';
import { Engine_State, Engine_Type } from '../../aiem/gen/ai/h2o/engine/v1/engine_pb';

export type VersionsSummary = {
  anySelected: boolean;
  totalVersions?: number;
  totalSelected?: number;
  versions: string[];
};

export type TypeVersionSummary = {
  anySelected: boolean;
  totalVersions?: number;
  totalSelected?: number;
  types: { [P in keyof Engine_Type as string]: VersionsSummary } | undefined;
};

export type VersionSelection = {
  [key: string]: boolean;
};

export type StateSelection = {
  [P in Engine_State as string]: boolean;
};

export type TypeVersions = {
  [P in keyof Engine_Type as string]: EngineVersion[];
};

export type TypeVersionSelection = {
  [P in keyof Engine_Type as string]: VersionSelection;
};

export const getStateConditions = (states: string[]): FilterCondition[] => {
  const newStates = states.map((state, index) => {
    return {
      openType: index ? FilterLogicalOperatorParenthesisType.or : FilterLogicalOperatorParenthesisType.open,
      name: 'state',
      value: state as Engine_State,
      closeType: index === states.length - 1 ? FilterLogicalOperatorParenthesisType.close : undefined,
    };
  });
  return newStates;
};

export const getSearchConditions = (value?: string): FilterCondition[] => {
  const enclosingType = FilterValueEnclosingType.Contains,
    conditions = value
      ? [
          {
            openType: FilterLogicalOperatorParenthesisType.open,
            name: 'engine_id',
            enclosingType,
            value,
          },
          {
            openType: FilterLogicalOperatorParenthesisType.or,
            name: 'display_name',
            enclosingType,
            value,
            closeType: FilterLogicalOperatorParenthesisType.closeAnd,
          },
        ]
      : [];
  return conditions as FilterCondition[];
};

export const getSummaryFromVersions = (typeVersions: TypeVersions) => {
  const result = {
    anySelected: false,
    totalVersions: Object.keys(typeVersions)
      .map((key) => typeVersions[key].length)
      .reduce((acc, curr) => acc + curr, 0),
  };
  return result;
};

export const getVersionsConditions = (selection: TypeVersionSelection): FilterCondition[] => {
  const { summary } = filterSummarizeSelection(selection);

  if (!summary.anySelected) return [];
  const totalTypes = Object.keys(summary.types!).length;

  const conditions: FilterCondition[] = Object.entries(summary.types!).reduce((acc, [key, { versions }], typeIndex) => {
    const totalSelected = versions.length,
      lastType = typeIndex + 1 === totalTypes;
    acc.push({
      openType:
        typeIndex || totalTypes < 2
          ? FilterLogicalOperatorParenthesisType.open
          : FilterLogicalOperatorParenthesisType.doubleOpen,
      name: 'type',
      value: key,
      closeType: FilterLogicalOperatorParenthesisType.andOpen,
    });

    let versionIndex = 0,
      closeType: FilterLogicalOperatorParenthesisType | undefined = undefined;

    for (const value of versions) {
      const lastVersion = versionIndex + 1 === totalSelected;

      switch (true) {
        case lastType && lastVersion && totalTypes > 1:
          closeType = FilterLogicalOperatorParenthesisType.tripleCloseAnd;
          break;
        case lastType && lastVersion:
          closeType = FilterLogicalOperatorParenthesisType.doubleCloseAnd;
          break;
        case lastVersion && !lastType:
          closeType = FilterLogicalOperatorParenthesisType.doubleCloseOr;
          break;
        default:
          closeType = FilterLogicalOperatorParenthesisType.or;
      }

      acc.push({
        name: 'version',
        value,
        enclosingType: FilterValueEnclosingType.Quote,
        closeType,
      });
      versionIndex++;
    }

    return acc;
  }, [] as FilterCondition[]);

  return conditions;
};

export const conditionsFromParams = ({ searchTerm, versions, states }: EngineQueryParamsType): FilterCondition[] => [
  ...getSearchConditions(searchTerm),
  ...getVersionsConditions(versions),
  ...getStateConditions(states),
];
