import { AIEngine } from '../../../../../aiem/engine/types';
import { AdjustedDAIProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/adjusted_dai_profile_pb';
import { Engine_Type } from '../../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { bytesToGibibytes } from '../../../../../aiem/utils';
import { LabelIconTooltip, OutOfVersionSeverity } from '../../LabelIconTooltip/LabelIconTooltip';
import { MetadataLabelCell, MetadataRow, MetadataValueCell } from '../../MetadataTable/MetadataTable';

interface DisplayPresetEngineProfileProps {
  loading?: boolean;
  engine: AIEngine;
  profile?: AdjustedDAIProfile;
}

export default function EngineProfileTableRows({ loading, engine, profile }: DisplayPresetEngineProfileProps) {
  const { engineType, cpu, gpu, nodeCount } = engine;
  return (
    <>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'CPUs per Node' : 'CPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'cpu-info'}
            data-test="cpu-info"
            label={cpu}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedCpuReason || ''}
          />
        </MetadataValueCell>
      </MetadataRow>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'GPUs per Node' : 'GPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'gpu-info'}
            data-test="gpu-info"
            label={gpu}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedGpuReason}
          />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === Engine_Type.H2O && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Number of Nodes</MetadataLabelCell>
          <MetadataValueCell loading={loading}>{nodeCount}</MetadataValueCell>
        </MetadataRow>
      )}
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'Memory per Node' : 'Memory'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'memory-info'}
            data-test="memory-info"
            label={`${bytesToGibibytes(engine.memoryBytes)} GiB`}
            severity={OutOfVersionSeverity.Warning}
            tooltip={profile?.adjustedMemoryBytesReason || undefined}
          />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === Engine_Type.DRIVERLESS_AI && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Storage</MetadataLabelCell>
          <MetadataValueCell loading={loading}>
            <LabelIconTooltip
              id={'storage-info'}
              data-test="storage-info"
              label={`${bytesToGibibytes(engine.storageBytes)} GiB`}
              severity={OutOfVersionSeverity.Warning}
              tooltip={profile?.adjustedStorageBytesReason || undefined}
            />
          </MetadataValueCell>
        </MetadataRow>
      )}
    </>
  );
}
