import { IStackStyles, Stack } from '@fluentui/react';
import {
  Button,
  IconName,
  PageHeader,
  buttonStylesLink,
  buttonStylesLinkBlack,
  buttonStylesPrimary,
} from '@h2oai/ui-kit';
import { ReactNode } from 'react';

import { stackStylesNoLeftNav } from '../../themes/themes';
import { ListPageProps } from './ListPage';

export interface BaseListPageShellProps {
  copy: ListPageProps['copy'];
  parentPage: ListPageProps['parentPage'];
  primaryButtonProps: ListPageProps['primaryButtonProps'];
  stackStyles?: Partial<IStackStyles>;
  showPageTitle?: boolean;
}

export interface ListPageShellProps extends BaseListPageShellProps {
  children: ReactNode;
}

/* These UI elements are shared across list components in every state,
 * including loading, error, downloading, and no data. */
function ListPageShell(props: ListPageShellProps) {
  const { children, copy, parentPage, primaryButtonProps, showPageTitle, stackStyles = stackStylesNoLeftNav } = props;
  return (
    <Stack styles={stackStyles}>
      {parentPage && (
        <Button
          styles={[buttonStylesLink, buttonStylesLinkBlack]}
          text="Back"
          iconName={IconName.Back}
          href={parentPage}
        />
      )}
      {(copy.title || copy.subtitle || primaryButtonProps) && (
        <Stack horizontal horizontalAlign="space-between">
          {(showPageTitle ? copy.title || copy.subtitle : '') && (
            <Stack.Item disableShrink>
              <PageHeader pageTitle={showPageTitle ? copy.title : ''} description={copy.subtitle} />
            </Stack.Item>
          )}
          {primaryButtonProps && (
            <Stack horizontalAlign="end" styles={{ root: { width: '100%' } }}>
              <Button styles={buttonStylesPrimary} {...primaryButtonProps} />
            </Stack>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
}

export default ListPageShell;
