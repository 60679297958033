import { IColumn, Stack, Text } from '@fluentui/react';
import {
  Accordion,
  Loader,
  TextWithCopy,
  loaderStylesSpinnerXLarge,
  sortList,
  useMediaQuery,
  useTheme,
} from '@h2oai/ui-kit';
import { type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import type { Alias, AppInstance, UpdateAppInstanceRequest } from '../../ai.h2o.cloud.appstore';
import { useInstance, useUser } from '../../utils/hooks';
import CreateUpdateDialog, {
  dialogContentStylesInstanceVisibility,
  dialogStylesInstanceVisibility,
} from '../CreateUpdateDialog/CreateUpdateDialog';
import EmptyStateMessage from '../EmptyStateMessage/EmptyStateMessage';
import { willAutoSuspend } from '../InstanceActionButton/InstanceActionButton';
import List from '../ListPages/List';
import { MetadataCell } from '../ListPages/MetadataCell';
import { copyTextCellStyles } from '../ListPages/TitleCell';
import {
  getAccordionRowHeader,
  getRowActions,
  getRowDetails,
  getRowIcon,
  getRowStatus,
  getRowTitle,
  getRowVisibility,
} from './components/parts';
import { columnsByTypeMap, mobileColumns, visibilityDialogFields } from './constants';
import { contentStyles, instancesAccordion, labelStyles } from './InstanceList.styles';
import type { InstanceListElement, InstanceListProps } from './InstanceList.types';

const getInstanceIdAliasMap = (aliases: Alias[] = []): Map<string, string> => {
  const instanceIdAliasMap = new Map<string, string>();
  aliases.forEach(({ instanceId, alias, primary }: Alias) => {
    if (primary) {
      instanceIdAliasMap.set(instanceId, alias);
    }
  });
  return instanceIdAliasMap;
};

export function InstanceList(props: InstanceListProps) {
  const {
    aliases,
    instances,
    terminateInstance,
    type,
    loadingInstance,
    loadingMsg,
    terminatingInstance,
    hideInstanceLogButton,
    updateVisibility,
    setInstanceSuspension,
  } = props;
  const { isDesktop } = useMediaQuery();
  const columns = columnsByTypeMap.get(type) || [];
  const availableColumns = useMemo(() => {
    if (isDesktop) {
      return columns;
    } else {
      return columns.filter((column) => mobileColumns.indexOf(column.key) > -1);
    }
  }, [isDesktop]);
  const instanceIdAliasMap = getInstanceIdAliasMap(aliases);
  const theme = useTheme(),
    { name: currentUserName } = useUser(),
    [sortedColumns, setSortedColumns] = useState<IColumn[]>(availableColumns),
    [updateInstanceReq, setUpdateInstanceReq] = useState<UpdateAppInstanceRequest | null>(null),
    [instanceList, setInstanceList] = useState<InstanceListElement[]>(instances || []),
    { goToInstance, goToInstanceLog } = useInstance(),
    toggleVisibilityDialog = useCallback(
      (instance: AppInstance | null) => () => {
        setUpdateInstanceReq(
          instance
            ? {
                id: instance.id,
                visibility: instance.visibility,
              }
            : null
        );
      },
      []
    ),
    onRenderItemColumn = (instance: InstanceListElement, _index?: number, col?: IColumn) => {
      if (!col) return <span />;
      const autoSuspend = willAutoSuspend(instance);

      switch (col.key) {
        case 'icon':
          return getRowIcon(instance.appDetails);
        case 'title-id-state':
          return getRowTitle(instance, type, instanceIdAliasMap, autoSuspend, instance.suspendAfter);
        case 'owner':
          return <MetadataCell title="Owner" metadata={[instance.owner]} />;
        case 'details':
          return getRowDetails(instance);
        case 'status':
          return getRowStatus(theme, instance);
        case 'visibility':
          return getRowVisibility(instance);
        case 'actions':
          return getRowActions({
            currentUserName,
            instance,
            updateVisibility,
            toggleVisibilityDialog,
            hideInstanceLogButton,
            goToInstanceLog,
            type,
            setInstanceSuspension,
            terminateInstance,
            goToInstance,
            status: instance.status,
          });
      }
      return (
        <Stack horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <Text styles={labelStyles(theme)}>{col.name}</Text>
          <Text styles={contentStyles(theme)}>{instance[col.fieldName as keyof string]}</Text>
        </Stack>
      );
    },
    onColumnClick = (_ev?: MouseEvent<HTMLElement>, column?: IColumn) => {
      if (!column || !column.fieldName) return;
      const key = column.fieldName,
        isSortedDescending = column.isSorted ? !column.isSortedDescending : !!column.isSortedDescending;

      const _sortedInstances = instanceList?.length ? instanceList : instances || [];
      setInstanceList(sortList(_sortedInstances, key as keyof AppInstance, isSortedDescending));
      setSortedColumns(
        availableColumns.map((col) => {
          col.isSorted = col.key === column.key;
          if (col.isSorted) {
            col.isSortedDescending = isSortedDescending;
          }
          return col;
        })
      );
    };

  useEffect(() => {
    setInstanceList([...(instances || [])]);
  }, [instances]);

  useEffect(() => {
    const newInstanceList = instanceList.map((instance) => {
      if (instance.id === loadingInstance?.[0]) {
        return { ...instance, loading: loadingInstance?.[1] };
      }
      if (instance.id === terminatingInstance?.[0]) {
        return { ...instance, deleting: terminatingInstance?.[1] };
      }
      return instance;
    });
    setInstanceList([...newInstanceList]);
  }, [loadingInstance, terminatingInstance, setInstanceList]);

  if (loadingMsg) return <Loader styles={loaderStylesSpinnerXLarge} label={loadingMsg || 'Loading App Instances...'} />;
  if (!instances?.length) return <EmptyStateMessage message="No instances found" />;

  const updateVisibilityDialog = updateVisibility && (
    <CreateUpdateDialog<UpdateAppInstanceRequest>
      title="Instance visibility"
      fields={visibilityDialogFields}
      onSubmit={(req) => {
        toggleVisibilityDialog(null)();
        updateVisibility(req)();
      }}
      isHidden={!updateInstanceReq}
      initialModel={updateInstanceReq || undefined}
      toggleHideDialog={toggleVisibilityDialog(null)}
      dataTest="update-instance-visibility-open-modal"
      styles={dialogStylesInstanceVisibility}
      dialogContentStyles={dialogContentStylesInstanceVisibility}
    />
  );

  return !isDesktop ? (
    <Stack styles={instancesAccordion(theme)}>
      {instances.map((instance) => (
        <div key={instance.id} className="instance-accordion-row">
          <Accordion headerRenderer={() => getAccordionRowHeader(instance, theme)} isClose>
            <div className="accordion-header-subtitle">
              <TextWithCopy text={instance.id} styles={copyTextCellStyles} />
            </div>
            {getRowDetails(instance, true)}
            {getRowActions({
              currentUserName,
              instance,
              updateVisibility,
              toggleVisibilityDialog,
              hideInstanceLogButton,
              goToInstanceLog,
              type,
              setInstanceSuspension,
              terminateInstance,
              goToInstance,
              status: instance.status,
            })}

            {updateVisibilityDialog}
          </Accordion>
        </div>
      ))}
    </Stack>
  ) : (
    <>
      <List
        noHeader
        dataTest="instance-list"
        items={instanceList}
        columns={sortedColumns}
        onRenderItemColumn={onRenderItemColumn}
        onColumnHeaderClick={onColumnClick}
      />
      {updateVisibilityDialog}
    </>
  );
}
