import { type IStyles, mediaDesktop, mediaNoDesktop } from '@h2oai/ui-kit';

type ListPageSearchAndFilterKey = 'root';
export type ListPageSearchAndFilter = IStyles<ListPageSearchAndFilterKey>;

export const listPageSearchAndFilterStyles = (): ListPageSearchAndFilter => ({
  root: {
    ...mediaNoDesktop({
      width: '100%',
      marginBottom: 18,
      flexWrap: 'wrap',
    }),
    '.search-container': {
      ...mediaDesktop({ width: 400 }),
      ...mediaNoDesktop({
        flexGrow: 1,
        height: 54,
      }),
    },
    '.search-search-separator': {},
    'div.list-page-search-active-filters': {
      ...mediaNoDesktop({
        width: '100%',
        height: 54,
        marginLeft: 0,
        padding: '12px 0',
      }),
      '.ms-Stack-inner': {
        ...mediaNoDesktop({ width: '100%' }),
        '.h2o-ButtonContainer-root': { ...mediaNoDesktop({ margin: '0 0 0 auto' }) },
      },
    },
    '.ms-Stack > div, .ms-SearchBox, .ms-Button': { ...mediaNoDesktop({ height: '100%' }) },
  },
});
