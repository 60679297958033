import { mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  AppBar,
  AppStoreMenuName,
  BookTimeDialog,
  Button,
  ConfirmDialog,
  FontSizes,
  FontWeights,
  IAppBarStyles,
  IconButton,
  IconName,
  MenuItem,
  Sizes,
  appBarStylesThick,
  buttonStylesStealth,
  buttonStylesTextWithIconRight,
  getMenuItems,
  getPersonaProps,
  getUserMenuItems,
  iconButtonStylesAppBar,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router-dom';

import { AppInstance_Status, AppInstance_Visibility } from '../../ai.h2o.cloud.appstore';
import { useCloudPlatformDiscovery, useEnv, useInstance, useUser } from '../../utils/hooks';
import { HaicAboutModal } from '../HaicAboutModal/HaicAboutModal';
import { navigationStyle } from './navigation.styles';

const getMenuItemKey = (pathname: string, menuItems?: MenuItem[]): string | undefined =>
  menuItems?.reduce(
    (key: string | undefined, item: MenuItem) => (pathname.startsWith(item.to) ? item.key : key),
    undefined
  );

export function Navigation() {
  const auth = useAuth();
  const logout = useCallback(async () => {
    // close modal
    setInstancePromptHidden(true);
    const token = auth.user?.id_token;
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: token });
  }, []);
  const env = useEnv(),
    user = useUser(),
    publicMode = env?.publicModeEnabled && !user.hasFullAccess,
    theme = useTheme(),
    history = useHistory(),
    { pathname } = useLocation(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    [instancePromptHidden, setInstancePromptHidden] = useState(true),
    [hiddenAbout, { setFalse: showAbout, setTrue: hideAbout }] = useBoolean(true),
    [instanceCount, setInstanceCount] = useState<null | number>(null),
    [menuItems, setMenuItems] = useState<MenuItem[]>(),
    isNavCompact = menuItems && menuItems.length > 7,
    { getInstancesList } = useInstance(),
    goToMyInstances = useCallback(() => {
      setInstancePromptHidden(true);
      history.push('/instances');
    }, [history, setInstancePromptHidden]),
    onLogoutClick = useCallback(async () => {
      let count;
      try {
        const instances = await getInstancesList({
          appId: '',
          includeAppDetails: false,
          allUsers: false,
          visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
        });
        count = instances.filter((instance) =>
          [
            AppInstance_Status.DEPLOYED,
            AppInstance_Status.PENDING,
            AppInstance_Status.STATUS_UNSPECIFIED,
            AppInstance_Status.STATUS_UNKNOWN,
          ].includes(instance.status)
        ).length;
      } catch (err) {
        console.error(err);
        count = null;
      }
      setInstanceCount(count);
      count !== 0 ? setInstancePromptHidden(false) : await logout();
    }, [instanceCount, logout, getInstancesList]),
    [bookTimeHidden, setBookTimeHidden] = useState<boolean>(true),
    headerClick = () => history.push('/'),
    signInButtonStyles = mergeStyleSets(buttonStylesStealth, buttonStylesTextWithIconRight, {
      root: {
        border: 'none',
        fontSize: FontSizes.large,
        color: theme.semanticColors?.textPrimary,
      },
      rootHovered: {
        color: theme.semanticColors?.textPrimary,
      },
      label: {
        fontWeight: FontWeights.fw400,
      },
      icon: {
        color: theme.semanticColors?.textPrimary,
        fontWeight: FontWeights.fw800,
        fontSize: '0.7em',
      },
    });
  useEffect(() => {
    if (env?.menu) {
      // TODO: Use proper way of adding Orchestrator to the UI.
      const menuItems = getMenuItems(env.menu, { hasMenu: false });
      const orchestratorMenuItem = {
        key: 'Orchestrator',
        dataTest: 'orchestrator--main-menu-item',
        name: 'Orchestrator',
        to: `/orchestrator`,
        isExternal: false,
      };
      setMenuItems(cloudPlatformDiscovery?.orchestratorApiUrl ? [...menuItems, orchestratorMenuItem] : menuItems);
    }
  }, [env?.menu]);

  return (
    <>
      {!publicMode && (
        <AppBar
          hasMenuIcon={true}
          styles={navigationStyle(theme, isNavCompact)}
          logoImageProps={{ src: '/logo.svg', alt: '' }}
          personaProps={getPersonaProps(env?.menu || {})}
          userMenuItems={getUserMenuItems(env?.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout)}
          onLogoClick={headerClick}
          selectedMenuItemKey={getMenuItemKey(pathname, menuItems)}
          menuItems={menuItems}
          onMenuItemClick={(_e, item) => {
            if (item.key === AppStoreMenuName.collaborate) {
              setBookTimeHidden(false);
              _e.preventDefault();
              _e.stopPropagation();
            }
          }}
        >
          {env?.beamerEnabled && (
            <IconButton iconName="News" title="What's new" styles={iconButtonStylesAppBar} id={'beamerButton'} />
          )}
        </AppBar>
      )}
      {publicMode && (
        <AppBar
          styles={mergeStyleSets(appBarStylesThick, {
            root: { paddingRight: Sizes.height },
            title: { color: theme.semanticColors?.textPrimary, fontWeight: FontWeights.fw500 },
          } as IAppBarStyles)}
          logoImageProps={{
            src: '/logo.svg',
            alt: '',
          }}
          onLogoClick={headerClick}
          onTitleClick={headerClick}
          title={env?.appStoreName || 'App Store'}
          personaProps={auth.user?.id_token ? getPersonaProps(env?.menu || {}) : undefined}
          userMenuItems={
            auth.user?.id_token
              ? getUserMenuItems(env?.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout)
              : undefined
          }
        >
          {!auth.user?.id_token && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={'Sign in'}
                onClick={() => auth.signinRedirect()}
                styles={signInButtonStyles}
                iconName={IconName.ChevronRight}
              />
            </div>
          )}
        </AppBar>
      )}
      <ConfirmDialog
        hidden={instancePromptHidden}
        title="Terminate instances before logout?"
        content={
          <>
            You have <b>{instanceCount ?? 'Unknown'}</b> running app instance{instanceCount === 1 ? '' : 's'} that are
            consuming cluster resources, do you wish to review and terminate them before logging out?
          </>
        }
        confirmationButtonText="No, logout"
        dismissalButtonText="Yes, go to My Instances"
        onDismiss={goToMyInstances}
        onConfirm={logout}
      />
      <HaicAboutModal hidden={hiddenAbout} onDismiss={hideAbout} />
      <BookTimeDialog url={env?.menu?.bookTimeLink} onDismiss={() => setBookTimeHidden(true)} hidden={bookTimeHidden} />
    </>
  );
}
