import { IDropdownOption } from '@fluentui/react';
import { Dropdown } from '@h2oai/ui-kit';

import { instanceStatusMap, instanceVisibilityMap } from '../../utils/utils';
import FilterPanel, { FilterPanelInput } from '../FilterPanel/FilterPanel';

interface InstanceFilterPanelProps {
  statesFilterKey: string;
  visibilityFilterKey: string;
  isOpen: boolean;
  onChangeStates: (event: any, option?: any) => void;
  onChangeVisibility: (event: any, option?: any) => void;
  onDismiss: () => void;
}

export function InstanceFilterPanel(pros: InstanceFilterPanelProps) {
  const { statesFilterKey, visibilityFilterKey, isOpen, onChangeStates, onChangeVisibility, onDismiss } = pros,
    getVisibilityDropdownOptions = () => {
      const options: IDropdownOption<{ key: string; text: string }>[] = [];
      for (const key in instanceVisibilityMap) {
        if (instanceVisibilityMap.hasOwnProperty(key)) {
          const text = instanceVisibilityMap[key];
          options.push({ key, text });
        }
      }
      return options;
    },
    getStatusDropdownOptions = () => {
      const options: IDropdownOption<{ key: string; text: string }>[] = [];
      for (const key in instanceStatusMap) {
        if (instanceStatusMap.hasOwnProperty(key)) {
          const text = instanceStatusMap[key];
          options.push({ key, text });
        }
      }
      return options;
    };
  return (
    <FilterPanel isOpen={isOpen} onDismiss={onDismiss}>
      <FilterPanelInput>
        <Dropdown
          label="States"
          selectedKey={statesFilterKey}
          placeholder="All Apps"
          onChange={onChangeStates}
          options={getStatusDropdownOptions()}
        />
        <Dropdown
          label="Visibility"
          selectedKey={visibilityFilterKey}
          placeholder="All Apps"
          onChange={onChangeVisibility}
          options={getVisibilityDropdownOptions()}
        />
      </FilterPanelInput>
    </FilterPanel>
  );
}
