import { useEffect } from 'react';

import { TelemetryService } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export function useTelemetryService() {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const telemetryApiUrl = cloudPlatformDiscovery?.telemetryApiUrl || '';
  useEffect(() => {
    TelemetryService.setBasePath(telemetryApiUrl);
  }, [telemetryApiUrl]);

  return TelemetryService;
}
