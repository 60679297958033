import { PanelType, Separator, Stack } from '@fluentui/react';
import { Panel } from '@h2oai/ui-kit';

export interface FilterPanelElementProps {
  showSeparator?: boolean;
  children: React.ReactNode;
}

export function FilterPanelInput(props: FilterPanelElementProps) {
  return (
    <Stack style={{ marginTop: 8 }}>
      {props.showSeparator && <Separator />}
      {props.children}
    </Stack>
  );
}

export interface FilterPanelProps {
  children: React.ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
}

function FilterPanel(props: FilterPanelProps) {
  const { children, isOpen, onDismiss } = props;

  return (
    <Panel
      isLightDismiss
      customWidth="450px"
      headerText="Filter By"
      isFooterAtBottom
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.custom}
    >
      {children}
    </Panel>
  );
}

export default FilterPanel;
