import { IStyle } from '@fluentui/react';
import { Button, IH2OTheme, buttonStylesPrimary, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';

// TODO: put this component into ui-kit

export interface IPanelFooterStyles {
  root: IStyle;
  container: IStyle;
}

export const EditablePanelFooterStyles = (theme: IH2OTheme): IPanelFooterStyles => {
  return {
    root: {
      position: 'absolute',
      bottom: '0',
      padding: '1.5rem',
      left: '0',
      width: '100%',
      backgroundColor: theme.semanticColors?.contentBackground,
      borderTop: `solid 1px ${theme.semanticColors?.inputBorder}`,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
};

export interface IEditablePanelFooterProps {
  onCancel: () => any;
  onSave: () => any;
  closeButtonText: string;
  saveButtonText: string;
  saveButtonDisabled: boolean;
}

export function EditablePanelFooter({
  onCancel,
  onSave,
  closeButtonText = 'Close',
  saveButtonText = 'Save',
  saveButtonDisabled = false,
}: IEditablePanelFooterProps) {
  const theme = useTheme();
  const footerClassnames = useClassNames<IPanelFooterStyles, ClassNamesFromIStyles<IPanelFooterStyles>>(
    'PanelFooter',
    EditablePanelFooterStyles(theme)
  );
  return (
    <div className={footerClassnames.root}>
      <div className={footerClassnames.container}>
        <Button text={closeButtonText} onClick={onCancel} />
        <Button disabled={saveButtonDisabled} styles={buttonStylesPrimary} text={saveButtonText} onClick={onSave} />
      </div>
    </div>
  );
}
