import { IBreadcrumbItem, IPanelStyles, PanelType, Stack } from '@fluentui/react';
import { Breadcrumb, FontSizes, IconButton, Panel, buttonStylesIcon } from '@h2oai/ui-kit';
import { useState } from 'react';

import { AIEMOpType, AIEngine, ValidEngineType } from '../../../../aiem/engine/types';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { extractId } from '../../../../aiem/utils';
import { EmptyDAIEngine, EmptyH2OEngine } from '../../constants';
import { getRandomEngineName } from '../../utils';
import { EngineLowStorageBadge, EngineResizingBadge } from '../Badges';
import { EngineConfiguration } from '../EngineConfiguration/EngineConfiguration';
import { EngineSelection } from '../EngineSelection/EngineSelection';

// NOTES on conversions:
// The API accepts strings only for engine parameters.
// The API provides only strings for engine parameter values, constraints, and defaults.
// The UI controls sometimes use numbers (Slider), and sometimes use strings (Spinner)
// The UI controls need to show human-usable units (hours, not seconds; gibibytes, not bytes)
// We probably want to convert the units for these controls (both type and magnitude)
// immediately before inputting the data into the control, and immediately when outputting - not several layers up
// So - until the data gets to the control, it should remain in the same format as the API,
// and when the data comes out of the control, it should be immediately converted into the API data format (string, and seconds/bytes)

export type AIEMPanelProps = {
  onDismiss: () => void;
  op: AIEMOpType;
  engine?: AIEngine;
  onSave: () => void;
};

const styleResetHeading = {
  fontSize: `100%`,
  fontWeight: `inherit`,
  margin: 0,
};

enum PanelStep {
  Selection = 'selection',
  EngineConfiguration = 'engineConfiguration',
}

// TODO: put these styles in a more logical place:
export const stylesPanel: Partial<IPanelStyles> = {
  header: {
    paddingLeft: '0px',
  },
  closeButton: {
    marginRight: '0',
  },
  content: {
    padding: 0,
    overflowX: 'hidden',
  },
  scrollableContent: {
    '& > * + *': {
      marginTop: `2rem`,
    },
    '& > *': {
      paddingInline: `1.5rem !important`,
    },
  },
  footerInner: {
    display: `flex`,
    flexFlow: `row-reverse`, // the button order is reversed, the "Confirm" has lower tab-index
    gap: `0.5rem`,
    justifyContent: `space-between`,
    paddingBottom: 0,
  },
  main: {
    paddingBlock: `1.5rem`,
  },
  commands: {
    padding: 0,
    '& > * + *': {
      marginTop: `0.5rem`,
    },
    '& h2': [styleResetHeading, { fontSize: FontSizes.large }],
  },
};

type PanelEditHeaderProps = {
  breadcrumbItems: IBreadcrumbItem[];
  onBackClick?: () => void;
  panelHeading: string;
};

export function AIEMPanel({ op, onDismiss, onSave, engine }: AIEMPanelProps) {
  const [paramEngine, setEngine] = useState<AIEngine | undefined>(engine);
  const [currentStep, setCurrentStep] = useState<PanelStep>(
      op === AIEMOpType.create && !engine?.engineType ? PanelStep.Selection : PanelStep.EngineConfiguration
    ),
    showSelection = () => {
      setCurrentStep(PanelStep.Selection);
    },
    showEngineConfiguration = () => {
      setCurrentStep(PanelStep.EngineConfiguration);
    };

  const rootBreadcrumbItem = { key: `root`, text: `Create AI Engine` },
    engineSelectionBreadcrumbItem = {
      key: `select`,
      text: `Select Engine`,
      onClick: showSelection,
    },
    { breadcrumbItems, panelHeading }: PanelEditHeaderProps = (function () {
      if (currentStep === PanelStep.Selection) {
        return {
          breadcrumbItems: [rootBreadcrumbItem, { ...engineSelectionBreadcrumbItem, onClick: undefined }],
          panelHeading: `Engine Selection`,
        };
      } else {
        return {
          breadcrumbItems: [
            rootBreadcrumbItem,
            ...(op === AIEMOpType.create ? [engineSelectionBreadcrumbItem] : []),
            {
              key: `edit`,
              text: `Engine Configuration`,
              isCurrentItem: true,
            },
          ],
          // onBackClick: op === AIEMOpType.create ? showSelection : onDismiss,
          panelHeading: `Engine Configuration`,
        };
      }
    })();

  const onEngineSelection = (engineType: Engine_Type | undefined) => {
    const displayName = engineType ? getRandomEngineName(engineType) : '';
    setEngine({
      ...(engineType === Engine_Type.H2O ? EmptyH2OEngine : EmptyDAIEngine),
      engineType,
      displayName,
      id: extractId(displayName),
    });
    showEngineConfiguration();
  };

  const onCancelEdit = () => {
    if (op === AIEMOpType.create) {
      setCurrentStep(PanelStep.Selection);
    } else {
      onDismiss();
    }
  };

  return (
    <Panel
      isLightDismiss
      data-test="aiem-panel"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      customWidth="500px"
      isOpen={true}
      onDismiss={onDismiss}
      onRenderNavigation={() => {
        return (
          <>
            <Stack horizontal horizontalAlign="space-between">
              <h2>{panelHeading}</h2>
              <IconButton iconName="ChromeClose" onClick={onDismiss} styles={[buttonStylesIcon]} />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <EngineLowStorageBadge engine={engine} />
              <EngineResizingBadge engine={engine} />
            </Stack>
            {op === AIEMOpType.create && <Breadcrumb items={breadcrumbItems} styles={{ item: { paddingInline: 0 } }} />}
          </>
        );
      }}
      styles={stylesPanel}
      type={PanelType.custom}
    >
      {currentStep === PanelStep.Selection ? (
        <EngineSelection
          onEngineSelection={onEngineSelection}
          engineType={(engine?.engineType as ValidEngineType) || ''}
        />
      ) : paramEngine ? (
        <EngineConfiguration op={op} engine={paramEngine} onSave={onSave} onCancel={onCancelEdit} />
      ) : null}
    </Panel>
  );
}
