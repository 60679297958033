import { ITextStyles } from '@fluentui/react';
import { type IH2OTheme, Sizes, mediaDesktop, mediaMobile, mediaNoDesktop } from '@h2oai/ui-kit';

export const subpageStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    borderRadius: Sizes.borderRadius,
    alignItems: 'stretch',
    backgroundColor: theme.palette?.white,
    ...mediaDesktop({ padding: `1.5rem` }),
    ...mediaNoDesktop({ padding: '8px 16px' }),
    ...mediaMobile({ flexWrap: 'wrap' }),
  },
});
