import { DefaultH2OSetup } from '../gen/ai/h2o/engine/v1/default_h2o_setup_pb';
import {
  DefaultH2OSetupService_GetDefaultH2OSetup,
  DefaultH2OSetupService_UpdateDefaultH2OSetup,
  GetDefaultH2OSetupRequest,
  GetDefaultH2OSetupResponse,
  UpdateDefaultH2OSetupRequest,
  UpdateDefaultH2OSetupResponse,
} from '../gen/ai/h2o/engine/v1/default_h2o_setup_service_pb';
import { ConstraintType } from '../types';
import { getDefaultH2OSetupResponseMock } from './mocks/responses.mock';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';

const entityType = EntityType.DefaultH2OSetup,
  required = true;

export const actions = {
  [EntityActionType.Get]: {
    name: 'GetDefaultH2OSetup',
    rpc: DefaultH2OSetupService_GetDefaultH2OSetup,
    type: EntityActionType.Get,
    mockPath: '/v1/defaultH2OSetup',
    responseKey: 'defaultH2oSetup',
    mockResponse: getDefaultH2OSetupResponseMock,
    entityType,
  } as EntityAction<DefaultH2OSetup, GetDefaultH2OSetupRequest, GetDefaultH2OSetupResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDefaultH2OSetup',
    rpc: DefaultH2OSetupService_UpdateDefaultH2OSetup,
    mockPath: '/v1/defaultH2OSetup',
    type: EntityActionType.Update,
    responseKey: 'defaultH2oSetup',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'defaultH2oSetup',
    entityType,
    mockResponse: {},
  } as EntityAction<DefaultH2OSetup, UpdateDefaultH2OSetupRequest, UpdateDefaultH2OSetupResponse>,
};

export const fields: EntityField<DefaultH2OSetup>[] = [
  {
    name: 'name',
    type: EntityFieldType.Hidden,
    label: 'Name',
    description: 'Resource name.',
    required,
  },
  {
    name: 'nodeCountConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Node Count',
    description: `Constraint for each H2OEngine's node count in the workspace.`,
    required,
    constraintType: ConstraintType.NODECOUNT,
  },
  {
    name: 'cpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'CPU Constraint',
    description: `Constraint for each H2OEngine's cpu in the workspace.`,
    required,
    constraintType: ConstraintType.CPU,
  },
  {
    name: 'gpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'GPU Constraint',
    description: `Constraint for each H2OEngine's gpu in the workspace.`,
    required,
    constraintType: ConstraintType.GPU,
  },
  {
    name: 'memoryBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Memory Constraint (GiB)',
    description: `Constraint for each H2OEngine's memory_bytes in the workspace.`,
    required,
    constraintType: ConstraintType.MEMORYBYTES,
  },
  {
    name: 'maxIdleDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Idle Duration Constraint',
    description: `Constraint for each H2OEngine's max_idle_duration in the workspace.`,
    required,
    constraintType: ConstraintType.MAXIDLEDURATION,
  },
  {
    name: 'maxRunningDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Running Duration Constraint',
    description: `Constraint for each H2OEngine's max_running_duration in the workspace.`,
    required,
    constraintType: ConstraintType.MAXRUNNINGDURATION,
  },
  {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'Pod Template Specification (YAML)',
    description: `
      YAML representation of custom PodTemplateSpec serialized into bytes.
      Definition of PodTemplateSpec: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#podtemplatespec-v1-core

      When specified, then it is applied for each H2OEngine in the workspace.
      PodTemplateSpec describes what will be applied on top of a regular H2O pod before it is created.
      This template is merged into H2O default pod using StrategicMergePatch method (it overrides the
      default pod).
      More info about StrategicMergePatch: https://kubernetes.io/docs/tasks/manage-kubernetes-objects/update-api-object-kubectl-patch/

      Example YAML value (custom annotation applied for each H2O pod):
      metadata:
       annotations:
         custom-key: custom-value
    `,
    required,
  },
  {
    name: 'yamlGpuTolerations',
    type: EntityFieldType.Yaml,
    label: 'YAML GPU Tolerations',
    description: `
      YAML representation of custom GPU Tolerations serialized into bytes.
      Definition of one Toleration: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#toleration-v1-core

      When specified, then it is applied for each H2OEngine in the workspace.
      GPUTolerations sets H2O's pod.spec.tolerations in case H2OEngine has GPU > 0. This will override
      any tolerations defined in yaml_pod_template_spec.PodSpec.Tolerations field.

      Example YAML value (two GPU tolerations applied for each H2O pod):
       - key: "dedicated"
         operator: "Equal"
         value: "gpu"
         effect: "NoSchedule"
       - key: "key2"
         operator: "Equal"
         value: "value2"
         effect: "NoSchedule"
       `,
    required,
  },
];

export const defaultH2OSetupEntity: Entity<DefaultH2OSetup> = {
  type: EntityType.DefaultH2OSetup,
  name: 'defaultH2OSetup',
  createButtonLabel: 'Save Changes',
  displayName: 'Default H2O Setup',
  description: 'Customize your default H2O Setup configurations here.',
  displayNameKey: 'name',
  actions,
  columns: [],
  fields,
  requestNameKeyPrefix: '',
  emptyModel: {
    name: 'new-default-dai-setup',
    nodeCountConstraint: {},
    cpuConstraint: {},
    gpuConstraint: {},
    memoryBytesConstraint: {},
    maxIdleDurationConstraint: {},
    maxRunningDurationConstraint: {},
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
  },
  canBeReordered: true,
};
