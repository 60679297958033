import { ChoiceGroup, Text, mergeStyleSets } from '@fluentui/react';
import {
  Checkbox,
  DetailsList,
  IH2OTheme,
  IPanelProps,
  KeyValuePairEditor,
  KeyValuePairSubmitOutcome,
  KeyValuePairValidationFn,
  KeyValueValidation,
  MessageBar,
  TextField,
  checkboxStylesCircle,
  debounce,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { App, Secret_Visibility } from '../../ai.h2o.cloud.appstore';
import { CorrectSecret, SecretData } from '../../pages/AdminManageSecretsPage';
import { ManagedListSeparator, ManagedListWrapper } from '../ManagedList/ManagedList';
import { ManagedListConfigPanel } from '../ManagedListConfigPanel/ManagedListConfigPanel';

export type AdminSecretsDataUI = { [key: string]: string };

export type visibilityItem = { key: Secret_Visibility; text: string };

export const visibilityList: visibilityItem[] = [
  { key: Secret_Visibility.ALL_USERS, text: 'All apps' },
  { key: Secret_Visibility.APP, text: 'Specific app' },
];

const convertAdminSecretDataToUI = (data?: SecretData) => {
  // convert base64 encoded values to human readable text
  if (!data) {
    return {};
  }
  const newData = { ...data };
  Object.keys(newData).forEach((k) => {
    try {
      newData[k] = atob(data[k]);
    } catch (error: any) {
      // if there is an error, just return the value
      console.warn(
        `The value '${newData[k]}' was expected to be base64 encoded, but it was not, so it cannot be decoded. Returning the value as it is.`
      );
    }
  });
  return newData;
};

export const convertAdminSecretDataToREST = (data?: AdminSecretsDataUI) => {
  // encode secrets to base64
  if (!data) {
    return [];
  }
  const newData = { ...data };
  Object.keys(newData).forEach((k) => (newData[k] = btoa(data[k])));
  return newData;
};

type AdminSecretVisibility = Secret_Visibility.ALL_USERS | Secret_Visibility.APP;

export const appNameUISanitize = (appName?: string) => {
  if (!appName) {
    return '';
  }

  return appName.replace('app:', '');
};

export type SecretConfigPanelEvent = (
  name: string | undefined,
  visibility: AdminSecretVisibility,
  data: SecretData,
  appId?: string
) => void;

const isObjectEmpty = (objectName: {}) => Object.keys(objectName).length === 0;

export interface ISecretConfigPanelProps extends IPanelProps {
  appList: App[];
  secret?: CorrectSecret;
  onClose: () => void;
  onConfirm: SecretConfigPanelEvent;
}

// https://docs.h2o.ai/h2o-ai-cloud/developerguide/cli#creating-and-updating-private-secrets
const regExpConstructor = new RegExp('(^[a-z0-9-]*$)', 'gm');

const roundCheckChoice = (theme: IH2OTheme) =>
  mergeStyleSets({
    root: {
      '.ms-Checkbox-checkmark': { background: theme.palette?.black, borderRadius: '50%', color: theme.palette?.black },
      ':hover .ms-Checkbox-checkmark': {
        background: theme.palette?.black,
        color: theme.palette?.black,
        opacity: 0.6,
      },
      ':hover.is-checked .ms-Checkbox-checkmark': {
        opacity: 1,
      },
    },
  });

export function SecretConfigPanel(props: ISecretConfigPanelProps) {
  const { appList, secret, onConfirm, onClose, ..._hProps } = props,
    theme = useTheme(),
    data = convertAdminSecretDataToUI(secret?.data),
    [selectedAppItem, setSelectedAppItem] = useState<App | undefined>(),
    [config, setConfig] = useState<{ [key: string]: string }>(data),
    [newSecretName, setNewSecretName] = useState<string>(''),
    [newSecretNameError, setNewSecretNameError] = useState<string | undefined>(),
    [appItems, setAppItems] = useState<App[]>(appList),
    [selectedVisibility, setSelectedVisibility] = useState<AdminSecretVisibility>(
      (secret?.visibility as AdminSecretVisibility) || Secret_Visibility.ALL_USERS
    ),
    checkedForm = useCallback(
      () =>
        (!newSecretName && !secret?.name) ||
        isObjectEmpty(config) ||
        (selectedVisibility === Secret_Visibility.APP && !selectedAppItem),
      [newSecretName, config, selectedVisibility, selectedAppItem]
    ),
    filterApp = useCallback(
      (_e, text: string) =>
        setAppItems(appList.filter((app) => app.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))),
      []
    ),
    onSearchInstanceTextChange = useMemo(() => debounce(300, filterApp), [filterApp]),
    validation: KeyValuePairValidationFn = (keyValue: { key?: string; value?: string }): KeyValueValidation => {
      const message: KeyValueValidation = {};
      if (keyValue.key && keyValue.key.length > 253) {
        message.keyValidation = 'Secret key may not be greater than 253 characters.';
        return message;
      }
      if (keyValue.key && !keyValue.key.match(/^[-._a-zA-Z0-9]+$/)) {
        message.keyValidation = 'Secret key must contain only alphanumeric and the following characters: . _ -';
        return message;
      }
      return message;
    };

  useEffect(() => {
    if (secret) {
      const selectedApp = appList.find((x) => appNameUISanitize(secret?.parent) === x.name);
      setSelectedAppItem(selectedApp);
    }
  }, []);
  const keyValuePairEditorRef = useRef<any>();
  const waitingForConfigStateUpdate = useRef<boolean>(false);
  const onClickSave = useCallback(() => {
    if (keyValuePairEditorRef.current) {
      waitingForConfigStateUpdate.current = true;
      const saveOutcome = keyValuePairEditorRef.current.submitKeyValue();
      switch (saveOutcome) {
        case KeyValuePairSubmitOutcome.UNSUCCESSFUL:
          waitingForConfigStateUpdate.current = false;
          onConfirm(newSecretName, selectedVisibility, config, selectedAppItem?.name);
          break;
        case KeyValuePairSubmitOutcome.SUCCESSFUL:
          waitingForConfigStateUpdate.current = true;
          break;
        case KeyValuePairSubmitOutcome.INVALID:
          waitingForConfigStateUpdate.current = false;
          break;
        default:
          onConfirm(newSecretName, selectedVisibility, config, selectedAppItem?.name);
      }
    } else {
      onConfirm(newSecretName, selectedVisibility, config, selectedAppItem?.name);
    }
  }, [
    onConfirm,
    keyValuePairEditorRef.current,
    waitingForConfigStateUpdate.current,
    newSecretName,
    selectedVisibility,
    config,
    selectedAppItem?.name,
  ]);
  // if the state has been updated, and we have been waiting for an update,
  useEffect(() => {
    if (waitingForConfigStateUpdate.current) {
      waitingForConfigStateUpdate.current = false;
      onConfirm(newSecretName, selectedVisibility, config, selectedAppItem?.name);
    }
  }, [newSecretName, selectedVisibility, config, selectedAppItem?.name]);
  return (
    <ManagedListConfigPanel
      {..._hProps}
      buttonTitleEdit="Update"
      isEditable={!!secret}
      isDisabled={checkedForm()}
      titleCreate="Create secret"
      titleEdit="Update secret"
      onDismiss={onClose}
      onDone={onClickSave}
    >
      <ManagedListWrapper>
        <MessageBar messageBarType={0}>
          All secret values are base64 encoded before saving, and decoded prior to presentation.
        </MessageBar>
      </ManagedListWrapper>
      {!secret ? (
        <TextField
          label="Secret name"
          required={true}
          placeholder="Choose secret name"
          onChange={(_e, newValue) => {
            if (newValue || newValue === '') {
              setNewSecretName(newValue);

              if (newValue.length <= 64 && newValue.match(regExpConstructor)) {
                setNewSecretNameError(undefined);
              } else {
                setNewSecretNameError(
                  'Only small alphanumeric characters and - are allowed. Maximum length is 63 characters.'
                );
              }
            }
          }}
          errorMessage={newSecretNameError}
          value={newSecretName}
        />
      ) : (
        <Text>Secret name: {secret.name}</Text>
      )}
      <ManagedListSeparator text="Secret content" />
      <KeyValuePairEditor
        config={config || {}}
        onUpdateConfig={setConfig}
        validation={validation}
        ref={keyValuePairEditorRef}
      />
      {!secret ? (
        <div>
          <ManagedListSeparator text="Additional configuration" />
          <ChoiceGroup
            options={visibilityList}
            defaultSelectedKey={selectedVisibility}
            label="Visibility"
            required={true}
            disabled={!!secret}
            onChange={(_, value) => {
              setSelectedVisibility((value?.key as AdminSecretVisibility) || Secret_Visibility.ALL_USERS);
            }}
          />
          {selectedVisibility === Secret_Visibility.APP && secret && selectedAppItem && (
            <ManagedListWrapper>
              {selectedAppItem.name} - {selectedAppItem.visibility} - {selectedAppItem.owner}
            </ManagedListWrapper>
          )}
          {selectedVisibility === Secret_Visibility.APP && !secret && (
            <ManagedListWrapper>
              <TextField
                placeholder="Filter by App name"
                onChange={onSearchInstanceTextChange}
                iconProps={{ iconName: 'Filter' }}
              />
              <div style={{ height: 310, overflow: 'auto' }} data-is-scrollable="true">
                <DetailsList
                  isHeaderVisible={false}
                  columns={[
                    {
                      key: 'select',
                      name: 'Select',
                      minWidth: 10,
                      maxWidth: 15,
                      onRender: (d: App) => (
                        <div style={{ textAlign: 'center' }}>
                          <Checkbox
                            name="id"
                            checked={d.id === selectedAppItem?.id}
                            styles={[checkboxStylesCircle, roundCheckChoice(theme)]}
                            onChange={() => setSelectedAppItem(d)}
                          />
                        </div>
                      ),
                    },
                    {
                      fieldName: 'id',
                      key: 'title',
                      minWidth: 50,
                      name: '',
                      onRender: (d: App) => (
                        <>
                          {d.name} - {d.visibility} - {d.owner}
                        </>
                      ),
                    },
                  ]}
                  items={appItems}
                />
              </div>
            </ManagedListWrapper>
          )}
        </div>
      ) : null}
    </ManagedListConfigPanel>
  );
}
