import {
  Item,
  TooltipHost,
  itemStylesCategory,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerDefault,
  useTheme,
} from '@h2oai/ui-kit';

import { Engine } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { badgeLoaderStyles } from './BadgeLoaderStyles';

export function EngineResizingBadge(props: { engine?: Engine }) {
  const theme = useTheme();
  const { engine } = props;
  const loaderTheme = badgeLoaderStyles(theme);
  return engine?.storageResizing ? (
    <TooltipHost
      content={
        'The Engine storage is resizing, this may take several hours to complete. You can continue using the Engine.'
      }
    >
      <Item
        styles={[
          itemStylesCategory,
          {
            root: {
              background: theme.semanticColors?.messageBarBackgroundWarning,
              color: theme.semanticColors?.textPrimary,
              padding: 0,
            },
          },
        ]}
        data={{}}
        loading={true}
        loaderProps={{
          label: 'Disk resizing',
          styles: [loaderStylesSpinnerDefault, loaderStylesSpinnerButtonPrimary, loaderTheme],
        }}
      />
    </TooltipHost>
  ) : null;
}
