import { fetchWrapRPC } from '../services/api';
import {
  CalculateMaxUsageRequest,
  CalculateUsageRequest,
  ComponentService_ListComponents,
  GetQuotaRequest,
  ListComponentsRequest,
  QuotaService_GetQuota,
  UsageService_CalculateMaxUsage,
  UsageService_CalculateUsage,
} from './gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits_pb';

export class TelemetryServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async calculateMaxUsage(req: CalculateMaxUsageRequest) {
    return await fetchWrapRPC(UsageService_CalculateMaxUsage, { basePath: this.basePath })({ ...req });
  }

  public async calculateUsage(req: CalculateUsageRequest) {
    return await fetchWrapRPC(UsageService_CalculateUsage, { basePath: this.basePath })({ ...req });
  }

  public async getQuota(req: GetQuotaRequest = {}) {
    return await fetchWrapRPC(QuotaService_GetQuota, { basePath: this.basePath })({ ...req });
  }

  public async listComponents(req: ListComponentsRequest = {}) {
    return await fetchWrapRPC(ComponentService_ListComponents, { basePath: this.basePath })({ ...req });
  }
}
