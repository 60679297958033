import { Stack } from '@fluentui/react';
import { Button, IButtonProps, IconName, buttonStylesIcon } from '@h2oai/ui-kit';

import Cell from './Cell';

interface ActionCellContentsProps {
  primaryButton?: React.ReactNode;
  primaryButtonProps?: IButtonProps;
  secondaryButton?: React.ReactNode;
  secondaryButtonProps?: IButtonProps;
  expandButton?: React.ReactNode;
  expandButtonProps?: IButtonProps;
  expanded?: boolean;
}

export function ActionCellContents(props: ActionCellContentsProps) {
  return (
    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end" verticalAlign="center">
      {props.secondaryButtonProps && <Button {...props.secondaryButtonProps} />}
      {props.secondaryButton && props.secondaryButton}
      {props.primaryButtonProps && <Button {...props.primaryButtonProps} />}
      {props.primaryButton && props.primaryButton}
      {props.expandButtonProps && (
        <Button
          title="Expand"
          iconName={IconName.ChevronRight}
          styles={[
            buttonStylesIcon,
            {
              icon: {
                marginRight: 0,
                transform: props.expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                transformOrigin: '50% 50%',
                transition: 'transform 0.1s linear 0s',
              },
            },
          ]}
          {...props.expandButtonProps}
        />
      )}
      {props.expandButton && props.expandButton}
    </Stack>
  );
}

export function ActionCell(props: ActionCellContentsProps) {
  return (
    <Cell alignRight style={{ marginRight: 16 }} className="action-cell-contents">
      <ActionCellContents {...props} />
    </Cell>
  );
}
