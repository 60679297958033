import { OutOfVersionSeverity } from '../LabelIconTooltip/LabelIconTooltip';

export const versionLabelSeverityMapping: Record<string, OutOfVersionSeverity> = {
  deleted: OutOfVersionSeverity.Error,
  upgradeAvailable: OutOfVersionSeverity.Info,
  deprecated: OutOfVersionSeverity.Warning,
};

export const tooltipMessages: Record<string, string> = {
  deleted: 'This version is no longer available. Upgrade to a newer version.',
  upgradeAvailable: 'A newer version of Driverless AI is available for upgrade.',
  deprecated: 'This Version is deprecated. Upgrade to a newer version.',
};
