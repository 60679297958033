import { FontSizes, FontWeights, type IH2OTheme, appBarStylesResponsive, mediaNoDesktop } from '@h2oai/ui-kit';

import type { NavigationStyles } from './Navigation.types';

export const navigationStyle = (theme: IH2OTheme, compact?: boolean): NavigationStyles => ({
  ...appBarStylesResponsive(theme),
  root: {
    ...(appBarStylesResponsive(theme).root as object),

    '.h2o-AppBar-logo': {
      ...(appBarStylesResponsive(theme).root as object)['.h2o-AppBar-logo'],

      '.ms-Image:after': {
        ...mediaNoDesktop({
          content: `"HAIC"`,
          position: 'absolute',
          left: '100%',
          top: '50%',
          marginLeft: 11,
          fontSize: FontSizes.large,
          lineHeight: FontSizes.xlarge,
          fontWeight: FontWeights.semiBold,
          color: theme.palette?.primary900,
          transition: 'transform .3s .2s, opacity .3s .2s',
          transform: 'translate3d(-100%, -50%, 0)',
          opacity: 0,
        }),
      },
    },

    a: {
      letterSpacing: compact ? '0.4px !important' : undefined,
      fontSize: compact ? '14px !important' : undefined,
    },
  },
});
