import { IColumn } from '@fluentui/react';

import { listIconSize } from '../../utils/utils';
import { ImagePullPolicy } from '../gen/ai/h2o/engine/v1/base_types_pb';
import { InternalDAIVersion } from '../gen/ai/h2o/engine/v1/internal_dai_version_pb';
import {
  AssignInternalDAIVersionAliasesRequest,
  AssignInternalDAIVersionAliasesResponse,
  CreateInternalDAIVersionRequest,
  CreateInternalDAIVersionResponse,
  DeleteInternalDAIVersionRequest,
  DeleteInternalDAIVersionResponse,
  GetInternalDAIVersionRequest,
  GetInternalDAIVersionResponse,
  InternalDAIVersionService_AssignInternalDAIVersionAliases,
  InternalDAIVersionService_CreateInternalDAIVersion,
  InternalDAIVersionService_DeleteInternalDAIVersion,
  InternalDAIVersionService_GetInternalDAIVersion,
  InternalDAIVersionService_ListInternalDAIVersions,
  InternalDAIVersionService_UpdateInternalDAIVersion,
  ListInternalDAIVersionsRequest,
  ListInternalDAIVersionsResponse,
  UpdateInternalDAIVersionRequest,
  UpdateInternalDAIVersionResponse,
} from '../gen/ai/h2o/engine/v1/internal_dai_version_service_pb';
import { listInternalDAIVersionsResponseMock } from './mocks/responses.mock';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import {
  getOptionsFromEnum,
  mockItemGetter,
  onRender,
  onRenderBooleanCell,
  onRenderDAIIconCell,
  onRenderStringArrayCell,
} from './utils';

const entityType = EntityType.InternalDAIVersion,
  required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateInternalDAIVersion',
    rpc: InternalDAIVersionService_CreateInternalDAIVersion,
    type: EntityActionType.Create,
    responseKey: 'internalDaiVersion',
    requestNameKey: 'internalDaiVersionId',
    requestPayloadKey: 'internalDaiVersion',
    entityType,
  } as EntityAction<InternalDAIVersion, CreateInternalDAIVersionRequest, CreateInternalDAIVersionResponse>,
  [EntityActionType.Get]: {
    name: 'GetInternalDAIVersion',
    rpc: InternalDAIVersionService_GetInternalDAIVersion,
    type: EntityActionType.Get,
    responseKey: 'internalDaiVersion',
    mockPath: '/v1/internalDAIVersions/*',
    mockResponse: (req) =>
      mockItemGetter(listInternalDAIVersionsResponseMock.internalDaiVersions!, req, 'internalDaiVersion'),
    entityType,
  } as EntityAction<InternalDAIVersion, GetInternalDAIVersionRequest, GetInternalDAIVersionResponse>,
  [EntityActionType.List]: {
    name: 'ListInternalDAIVersion',
    rpc: InternalDAIVersionService_ListInternalDAIVersions,
    type: EntityActionType.List,
    mockResponse: listInternalDAIVersionsResponseMock,
    responseKey: 'internalDaiVersions',
    entityType,
  } as EntityAction<InternalDAIVersion, ListInternalDAIVersionsRequest, ListInternalDAIVersionsResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateInternalDAIVersion',
    rpc: InternalDAIVersionService_UpdateInternalDAIVersion,
    type: EntityActionType.Update,
    mockPath: '/v1/internalDAIVersions/*',
    mockResponse: listInternalDAIVersionsResponseMock.internalDaiVersions![0],
    entityType,
    responseKey: 'internalDaiVersion',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'internalDaiVersion',
  } as EntityAction<InternalDAIVersion, UpdateInternalDAIVersionRequest, UpdateInternalDAIVersionResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteInternalDAIVersion',
    rpc: InternalDAIVersionService_DeleteInternalDAIVersion,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<InternalDAIVersion, DeleteInternalDAIVersionRequest, DeleteInternalDAIVersionResponse>,
  [EntityActionType.Assign]: {
    name: 'AssignInternalDAIVersionAliases',
    rpc: InternalDAIVersionService_AssignInternalDAIVersionAliases,
    type: EntityActionType.Assign,
    responseKey: 'internalDaiVersions',
    requestNameKey: 'internalDaiVersion',
    requestPayloadKey: 'aliases',
    payloadKey: 'aliases',
    entityType,
  } as EntityAction<
    InternalDAIVersion,
    AssignInternalDAIVersionAliasesRequest,
    AssignInternalDAIVersionAliasesResponse
  >,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: '',
    className: 'entity-model-icon',
    fieldName: 'icon',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    onRender: onRenderDAIIconCell,
  },
  {
    key: 'version',
    name: 'Version',
    fieldName: 'version',
    minWidth: 180,
    maxWidth: 180,
    onRender,
  },
  {
    key: 'aliases',
    name: 'Aliases',
    fieldName: 'aliases',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderStringArrayCell,
  },
  {
    key: 'deprecated',
    name: 'Deprecated',
    fieldName: 'deprecated',
    minWidth: 150,
    maxWidth: 150,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 200,
    maxWidth: 1000,
    onRender,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<InternalDAIVersion>[] = [
  {
    name: 'name',
    type: EntityFieldType.Hidden,
    label: 'Name',
    description: 'DAI Version resource name.',
    required,
  },
  {
    name: 'version',
    type: EntityFieldType.Semver,
    label: 'Version',
    description: 'Version identifier. For example "1.10.1.2".',
    required,
    minimum: '1.10.4',
    relayValueTo: ['name'],
    readOnlyOnEdit: true,
  },
  {
    name: 'aliases',
    type: EntityFieldType.LatestAndAliases,
    label: 'Aliases',
    description: `
      Aliases for the specified Driverless AI version.
      For example {"latest", "prerelease"}.`,
  },
  {
    name: 'deprecated',
    type: EntityFieldType.Boolean,
    label: 'Deprecated',
    description: 'Indicates whether DAIVersion is deprecated.',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    description: 'Name of the Docker image used when using this DAIVersion.',
    required,
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    description: `
      Image pull policy applied when using this DAIVersion.
      When unset, server will choose a default one.`,
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
    description: `
      List of references to k8s secrets that can be used for pulling an image of this H2OVersion
      from a private container image registry or repository.`,
  },
  {
    name: 'gpuResourceName',
    type: EntityFieldType.Hidden,
    label: 'K8s GPU Resource Name',
    description: `
      K8s GPU resource name. For example: 'nvidia.com/gpu' or 'amd.com/gpu'.
      When unset, server will choose a default value.`,
  },
  {
    name: 'dataDirectoryStorageClass',
    type: EntityFieldType.Hidden,
    label: 'Data Directory Storage Class',
    description: `
      Name of the storage class used by Driverless AI when using this DAIVersion.
      When unset, the default storage class of the k8s cluster will be used.`,
  },
  {
    name: 'annotations',
    type: EntityFieldType.KeyValuePair,
    label: 'Annotations',
    description: `
      Additional arbitrary metadata associated with the DAIVersion.

      Annotations are key/value pairs.
      The key must:
      - be 63 characters or less
      - begin and end with an alphanumeric character ([a-z0-9A-Z])
      - with dashes (-), underscores (_), dots (.), and alphanumerics between
      - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$`,
  },
];

export const daiVersionEntity: Entity<InternalDAIVersion> = {
  type: entityType,
  name: 'InternalDAIVersion',
  createButtonLabel: 'Add Version',
  displayName: 'DAI Version',
  displayNameKey: 'version',
  requestNameKeyPrefix: 'internalDAIVersions/',
  actions,
  columns,
  fields,
  emptyModel: {
    name: '',
    version: '',
    aliases: [],
    deprecated: false,
    image: '',
    imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
    imagePullSecrets: [],
    gpuResourceName: '',
    dataDirectoryStorageClass: '',
    annotations: {},
  },
  canBeReordered: false,
  filterFields: ['version', 'deprecated'],
};
