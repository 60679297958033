// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/cloud/telemetry_read_api/v1/aiunits.proto (package ai.h2o.cloud.telemetry_read_api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BigIntString } from "../../../../../runtime";
import { RPC } from "../../../../../runtime";
import type { Interval } from "../../../../../google/type/interval_pb";

/**
 * @generated from enum ai.h2o.cloud.telemetry_read_api.v1.Granularity
 */
export enum Granularity {
/**
 * @generated from enum value: GRANULARITY_UNSPECIFIED = 0;
 */
GRANULARITY_UNSPECIFIED = "GRANULARITY_UNSPECIFIED",

/**
 * @generated from enum value: YEAR = 1;
 */
YEAR = "YEAR",

/**
 * @generated from enum value: MONTH = 2;
 */
MONTH = "MONTH",

/**
 * @generated from enum value: WEEK = 3;
 */
WEEK = "WEEK",

/**
 * @generated from enum value: DAY = 4;
 */
DAY = "DAY",

/**
 * @generated from enum value: HOUR = 5;
 */
HOUR = "HOUR",
}
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.ListComponentsRequest
 */
export type ListComponentsRequest = {
/**
 * The maximum number of components to return. The service may return fewer than
 * this value.
 * If unspecified, at most 50 component will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * A page token, received from a previous `ListComponents` call.
 * Provide this to retrieve the subsequent page.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.ListComponentsResponse
 */
export type ListComponentsResponse = {
/**
 * The components set.
 *
 * @generated from field: repeated ai.h2o.cloud.telemetry_read_api.v1.Component components = 1;
 */
components?: Component[];
/**
 * A token, which can be sent as `page_token` to retrieve the next page.
 * If this field is omitted, there are no subsequent pages.
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.Component
 */
export type Component = {
/**
 * Format: components/{component}
 * The name of a component.
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * Format: components/{component}:calculateUsage
 *
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.CalculateUsageRequest
 */
export type CalculateUsageRequest = {
/**
 * The start of interval of a time for which the usage is calculated.
 *
 * @generated from field: google.protobuf.Timestamp start_time = 1;
 */
startTime: string;
/**
 * The end of interval of a time for which the usage is calculated.
 *
 * @generated from field: google.protobuf.Timestamp end_time = 2;
 */
endTime: string;
/**
 * The granularity of the usage calculation, limited to difference between From and To field values of the request's interval.
 * Examples:
 * Request of the usage between 1.01.2022 and 1.01.2023 would return:
 * - 1 year in case when granularity is set to YEAR
 * - 12 months in case when granularity is set to MONTH
 * - 52 weeks in case when granularity is set to WEEK
 * Though request of the usage between 6.00 and 10.00 of a given day with the granularity set to DAY,
 * will return 4 hours (downgrading the granularity to HOUR), as there's not enough data to calculate a whole day.
 *
 * @generated from field: ai.h2o.cloud.telemetry_read_api.v1.Granularity granularity = 3;
 */
granularity: Granularity;
/**
 * A component of which the usage is calculated. Example: components/global:calculateUsage
 *
 * @generated from field: string component = 4;
 */
component: string;
/**
 * The maximum number of usage data points to return. The service may return fewer than
 * this value.
 * If unspecified, at most 50 usage data points will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 5;
 */
pageSize?: number;
/**
 * A page token, received from a previous `CalculateUsage` call.
 * Provide this to retrieve the subsequent page.
 *
 * @generated from field: string page_token = 6;
 */
pageToken?: string;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.CalculateUsageResponse
 */
export type CalculateUsageResponse = {
/**
 * The usage time series.
 *
 * @generated from field: repeated ai.h2o.cloud.telemetry_read_api.v1.Usage usage = 1;
 */
usage?: Usage[];
/**
 * A token, which can be sent as `page_token` to retrieve the next page.
 * If this field is omitted, there are no subsequent pages.
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.Usage
 */
export type Usage = {
/**
 * The milli AI Units amount.
 *
 * @generated from field: int64 milli_ai_units = 1;
 */
milliAiUnits?: BigIntString;
/**
 * The interval where AI Units were used.
 *
 * @generated from field: google.type.Interval interval = 2;
 */
interval?: Interval;
}
;
/**
 * Format: components/{component}:calculateMaxUsage
 *
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.CalculateMaxUsageRequest
 */
export type CalculateMaxUsageRequest = {
/**
 * The component of which the usage is calculated. Example: components/global:calculateMaxUsage
 *
 * @generated from field: string component = 1;
 */
component: string;
/**
 * The start of the interval of a time for which the max usage is calculated. Optional.
 * If not provided, max usage will be calculated only with respect to `to` field value.
 *
 * @generated from field: google.protobuf.Timestamp start_time = 2;
 */
startTime?: string | null;
/**
 * The end of the interval of a time for which the max usage is calculated. Optional.
 * If not provided, max usage will be calculated only with respect to `from` field value.
 *
 * @generated from field: google.protobuf.Timestamp end_time = 3;
 */
endTime?: string | null;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.MaxUsage
 */
export type MaxUsage = {
/**
 * The milli AI Units amount.
 *
 * @generated from field: int64 milli_ai_units = 1;
 */
milliAiUnits?: BigIntString;
/**
 * The timestamp where max usage occurred.
 *
 * @generated from field: google.protobuf.Timestamp timestamp = 2;
 */
timestamp?: string | null;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.CalculateMaxUsageResponse
 */
export type CalculateMaxUsageResponse = {
/**
 * The max usage including relevant timestamp.
 *
 * @generated from field: ai.h2o.cloud.telemetry_read_api.v1.MaxUsage max_usage = 1;
 */
maxUsage?: MaxUsage;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.GetQuotaRequest
 */
export type GetQuotaRequest = {
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.GetQuotaResponse
 */
export type GetQuotaResponse = {
/**
 * Current quota.
 *
 * @generated from field: ai.h2o.cloud.telemetry_read_api.v1.Quota quota = 1;
 */
quota?: Quota;
}
;
/**
 * @generated from message ai.h2o.cloud.telemetry_read_api.v1.Quota
 */
export type Quota = {
/**
 * Milli AI Units.
 *
 * @generated from field: int64 milli_ai_units = 1;
 */
milliAiUnits?: BigIntString;
}
;
/**
 * @generated from rpc ai.h2o.cloud.telemetry_read_api.v1.ComponentService.ListComponents
 */
export const ComponentService_ListComponents = new RPC<ListComponentsRequest, ListComponentsResponse>("GET", "/v1/components");
/**
 * @generated from rpc ai.h2o.cloud.telemetry_read_api.v1.UsageService.CalculateUsage
 */
export const UsageService_CalculateUsage = new RPC<CalculateUsageRequest, CalculateUsageResponse>("GET", "/v1/{component=components/*}:calculateUsage");
/**
 * @generated from rpc ai.h2o.cloud.telemetry_read_api.v1.UsageService.CalculateMaxUsage
 */
export const UsageService_CalculateMaxUsage = new RPC<CalculateMaxUsageRequest, CalculateMaxUsageResponse>("GET", "/v1/{component=components/*}:calculateMaxUsage");
/**
 * @generated from rpc ai.h2o.cloud.telemetry_read_api.v1.QuotaService.GetQuota
 */
export const QuotaService_GetQuota = new RPC<GetQuotaRequest, GetQuotaResponse>("GET", "/v1/quota");
