import { PanelType } from '@fluentui/react';
import { Panel } from '@h2oai/ui-kit';

import { Entity, HasName } from '../../../aiem/entity/types';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { AddEditModelForm } from './AddEditModelForm';

export interface IAIEMSettingsEditPanelProps<EntityModel> {
  panelTitle: string;
  onDismiss: () => any;
  onSave: () => any;
  isCreate: boolean;
  entity: Entity<EntityModel>;
  model?: EntityModel;
}

export function AIEMSettingsEditPanel<EntityModel extends HasName>(props: IAIEMSettingsEditPanelProps<EntityModel>) {
  const { panelTitle, onDismiss, isCreate, onSave, entity, model } = props;
  if (!model) {
    return <></>;
  }
  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <AddEditModelForm<EntityModel>
        entity={entity}
        model={model}
        isCreate={isCreate}
        onSave={onSave}
        onDismiss={onDismiss}
      />
    </Panel>
  );
}
