import { IDropdownOption, Stack, Text } from '@fluentui/react';
import {
  Dropdown,
  IconName,
  ImageLazyLoader,
  Loader,
  Sizes,
  dropdownStylesIcon,
  loaderStylesSpinnerXLarge,
} from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { App } from '../../../../ai.h2o.cloud.appstore';
import CardList from '../../../../components/CardList/CardList';
import { useEnv } from '../../../../utils/hooks';
import { ALL_APPS, ALL_CATEGORY, MOST_POPULAR } from '../../../../utils/models';
import { redirectActionAppCard } from '../../AppStorePage';
import { AppCategory, SeeAllProps, SortBy, onSort } from '../../AppStorePage.models';
import AppStoreSections from '../AppStoreSections/AppStoreSections';

type AppStoreContentProps = {
  categoryAppsMap: Map<string, AppCategory>;
  appsCategories: AppCategory[];
  filterBy: string;
  filteredApps: App[];
  listTitle: string;
  loadingMsg: string;
  onDismissError: () => void;
  onLikeApp: (app: App) => void;
  onPinApp: (app: App) => void;
  onSeeAll: (props: SeeAllProps) => void;
  onSortChange: (_e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
  showSections: boolean;
  sortBy: SortBy;
  sortOptions: IDropdownOption[];
};

export default function AppStoreContent({
  categoryAppsMap,
  appsCategories,
  filteredApps,
  listTitle,
  loadingMsg,
  onLikeApp,
  onPinApp,
  onSeeAll,
  onSortChange,
  showSections,
  sortBy,
  sortOptions,
}: AppStoreContentProps) {
  const auth = useAuth();
  const env = useEnv();
  const isPublicModeEnabled = !!env?.publicModeEnabled;
  const isAuthUser = !!auth.user?.id_token;
  const [isContentReady, setIsContentReady] = useState(false);
  const [columnCount, setColumnCount] = useState(1);
  const refContainer = useRef<HTMLElement>(null);
  const updateColumnCount = useCallback(() => {
    const el = refContainer.current;
    if (el && el.clientWidth) {
      const count = Math.ceil(el.clientWidth / Sizes.cardWidth);
      if (count !== columnCount) {
        setColumnCount(count);
      }
    }
  }, [columnCount]);
  useEffect(() => {
    updateColumnCount();
    window.removeEventListener('resize', updateColumnCount);
    window.addEventListener('resize', updateColumnCount);
    return () => {
      window.removeEventListener('resize', updateColumnCount);
    };
  }, [updateColumnCount]);
  const sectionsContent = (
    <AppStoreSections
      categoryAppsMap={categoryAppsMap}
      columnCount={columnCount}
      data={filteredApps}
      onPin={onPinApp}
      onLike={onLikeApp}
      appsCategories={appsCategories}
      onSeeAll={onSeeAll}
    />
  );
  const allCategoryTitle = listTitle === MOST_POPULAR || listTitle === ALL_CATEGORY ? ALL_APPS : listTitle;
  const emptyClass = !filteredApps?.length ? ' empty' : '';
  const allCategoryContent = (
    <section className="app-section--detailed-listing">
      <Stack as="header" horizontal horizontalAlign="space-between" verticalAlign="end">
        <Text as="h1" variant="xLarge">
          {!!filteredApps.length ? `${allCategoryTitle} (${filteredApps.length})` : allCategoryTitle}
        </Text>
        <Dropdown
          styles={[
            dropdownStylesIcon,
            {
              root: {
                width: 209,
                marginBottom: 16,
              },
            },
          ]}
          iconName={IconName.Sort}
          placeholder="Sort By"
          options={sortOptions}
          selectedKey={sortBy}
          onChange={onSortChange}
        />
      </Stack>
      <CardList
        data={filteredApps.sort(onSort[sortBy])}
        hideCategories
        hideUserName
        disableActions={!isAuthUser}
        showLoader={isPublicModeEnabled}
        getMenuItems={
          isPublicModeEnabled
            ? (app) => [
                {
                  key: 'view-details',
                  text: 'View details',
                  'data-test': 'view-details-button',
                  href: `/apps/${app.id}`,
                },
              ]
            : undefined
        }
        onClick={(app) =>
          redirectActionAppCard(app, isPublicModeEnabled).then((path) => {
            window.location.href = path;
          })
        }
        onPin={onPinApp}
        onLike={onLikeApp}
        columnCount={columnCount}
      />
    </section>
  );
  useEffect(() => {
    if (!loadingMsg) setIsContentReady(true);
  }, [loadingMsg, filteredApps, columnCount]);
  return (
    <>
      {loadingMsg && <Loader styles={loaderStylesSpinnerXLarge} label={loadingMsg} />}
      <article ref={refContainer} className={`card-grid${emptyClass}`} style={{ minHeight: 280 }}>
        {showSections ? sectionsContent : allCategoryContent}
      </article>
      <ImageLazyLoader
        containerSelector=".card-grid"
        isBackgroundImage={true}
        isReady={isContentReady}
        errorImagePath="/logo512.png"
        onLoaded={() => setIsContentReady(false)}
      />
    </>
  );
}
