import { Dropdown, IDropdownOption, ITextFieldProps, Label, Stack, TextField, Toggle } from '@fluentui/react';
import { BasicList, Item, TextListEditor, basicListStylesInput, itemStylesDropdown } from '@h2oai/ui-kit';
import { useState } from 'react';

import { EntityField, EntityType } from '../../../aiem/entity/types';
import { bytesToGibibytes, gibibytesToBigIntStringBytes } from '../../../aiem/utils';
import { FluentNumberField } from '../../../components/AIEnginesPage/components/FluentNumberField/FluentNumberField';
import { LabelIconTooltip } from '../../../components/AIEnginesPage/components/LabelIconTooltip/LabelIconTooltip';
import { useFormAttributes } from '../../../utils/utils';
import { defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export interface EntityFieldInputProps<EntityModel> {
  field: EntityField<EntityModel>;
  model: EntityModel;
  onChange?: (name: keyof EntityModel, value: any) => void;
  onChangeMultiple?: (partialModel: Partial<EntityModel>) => void;
  entityType: EntityType;
  isCreate?: boolean;
  validate?: (valid: boolean) => void;
  disabled?: boolean;
  largeLabel?: boolean;
}

interface NumberEntityFieldInputProps<EntityModel> extends EntityFieldInputProps<EntityModel> {
  convertToGibibytes?: boolean;
}

type FormRowProps = { singleRow?: boolean; children: React.ReactNode };

export const FormRow = ({ singleRow = false, children }: FormRowProps) => {
  const { inputContainerProps, inputRowProps, singleRowInputRowProps } = useFormAttributes();
  return (
    <Stack {...(singleRow ? singleRowInputRowProps : inputRowProps)} style={defaultEntityFormRowStyles}>
      <Stack {...inputContainerProps}>{children}</Stack>
    </Stack>
  );
};

export function TextEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, description, required } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[name] as string);
  return (
    <FormRow>
      <TextField
        required={required}
        disabled={disabled}
        autoFocus
        label={label}
        data-test={`admin-setting-${String(name)}-input`}
        value={fieldValue}
        onChange={(_ev, value) => {
          setFieldValue(value || '');
          onChange && onChange(name, value);
        }}
        onRenderLabel={(
          labelProps: ITextFieldProps | undefined,
          defaultRender: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined
        ) => (
          <LabelIconTooltip
            id={labelProps?.id}
            data-test={`admin-settings-${String(name)}-info`}
            label={defaultRender!(labelProps) as any}
            tooltip={description}
          />
        )}
      />
    </FormRow>
  );
}

export function NumberEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  convertToGibibytes = false,
  disabled = false,
}: NumberEntityFieldInputProps<EntityModel>) {
  const { name, label, description, minimum } = field;
  const fieldValue: string = (model as any)[name] as string;
  return (
    <FormRow>
      <FluentNumberField
        value={convertToGibibytes ? bytesToGibibytes(fieldValue) : fieldValue}
        min={Number(minimum)}
        label={label}
        tooltip={description}
        disabled={disabled}
        onChange={(_ev, value) => {
          onChange && onChange!(name, convertToGibibytes ? gibibytesToBigIntStringBytes(Number(value)) : Number(value));
        }}
      />
    </FormRow>
  );
}

export function BooleanEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
  largeLabel = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label } = field;
  const checked: boolean = Boolean((model as any)[name]) as boolean;
  return (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} />}
      <Toggle
        checked={checked}
        onChange={(_ev, newValue) => (onChange ? onChange(name, newValue) : undefined)}
        inlineLabel
        label={largeLabel ? undefined : label}
        disabled={disabled}
      />
    </Stack>
  );
}

export function SelectEnumEntityModelField<EntityModel>({
  field,
  model,
  onChange,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, options } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[name] as string);
  return (
    <FormRow>
      <Label>{label}</Label>
      {options ? (
        <Dropdown
          options={options}
          selectedKey={fieldValue}
          onChange={(_ev, v) => {
            const value = v?.key as string;
            setFieldValue(value);
            onChange && onChange(name, value);
          }}
        />
      ) : (
        <>This field has an implementation error</>
      )}
    </FormRow>
  );
}

export function StringArrayEntityModelField<EntityModel>({
  field,
  model,
  onChange,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label } = field;
  const items: string[] = (model as any)[name] as string[];
  return (
    <FormRow>
      <Label>{label}</Label>
      <TextListEditor items={items} onChange={(value: string[]) => (onChange ? onChange(name, value) : undefined)} />
    </FormRow>
  );
}

export function ReadOnlyStringArrayEntityModelField<EntityModel>({ field, model }: EntityFieldInputProps<EntityModel>) {
  const { name, label } = field;
  const items: string[] = (model as any)[name] as string[];
  return (
    <FormRow>
      <Label>{label}</Label>
      <BasicList
        title={label}
        styles={basicListStylesInput}
        idField="key"
        labelField="text"
        data={[{ key: '__input__', text: 'input' }, ...items?.map((d) => ({ key: d, text: d }))]}
        data-test="text-list-editor"
        horizontal
        itemRenderer={(d: IDropdownOption) => (
          <Item
            key={`${d.key}-string-array-item`}
            styles={itemStylesDropdown}
            data={d}
            idField="key"
            labelField="text"
          />
        )}
      />
    </FormRow>
  );
}

export function LatestAndAliasesEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label } = field;
  const originalItems = (model as any)[name] as string[];
  const [items, setItems] = useState<string[]>(originalItems);
  const [latest, setLatest] = useState<boolean>(originalItems.includes('latest'));
  return (
    <>
      <FormRow singleRow>
        <Toggle
          checked={latest}
          onChange={(_ev, checked) => {
            const newItems = [
              ...(checked ? ['latest'] : []),
              ...(checked ? items : items.filter((item) => item !== 'latest')),
            ];
            setItems(newItems);
            setLatest(Boolean(checked));
            onChange && onChange(name, newItems);
          }}
          inlineLabel
          label={'Latest'}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <Label>{label}</Label>
        <TextListEditor
          items={items}
          onChange={(value: string[]) => {
            setItems(value);
            setLatest(value.includes('latest'));
            onChange && onChange(name, value);
          }}
        />
      </FormRow>
    </>
  );
}
