// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/internal_h2o_version_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { InternalH2OVersion } from "./internal_h2o_version_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new InternalH2OVersion.
 * (-- api-linter: core::0133::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalH2OVersion has no parent (it's a top-level resource) --)
 *
 * @generated from message ai.h2o.engine.v1.CreateInternalH2OVersionRequest
 */
export type CreateInternalH2OVersionRequest = {
/**
 * The InternalH2OVersion resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.InternalH2OVersion internal_h2o_version = 2;
 */
internalH2oVersion: InternalH2OVersion;
/**
 * Specify the InternalH2OVersion resource ID, which will become a part of the InternalH2OVersion
 * resource name.
 *
 * It must:
 * - be in semver format (but allowing more segments than only the standard three segments Major.Minor.Patch)
 * - contain max 63 characters
 *
 * Examples: "3.42.0.3", "3.42.0.3-alpha", "3.42.0.3.1", "3.42.0.3.1-alpha"
 *
 * @generated from field: string internal_h2o_version_id = 3;
 */
internalH2oVersionId: string;
}
;
/**
 * Response message for InternalH2OVersionService.CreateInternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.CreateInternalH2OVersionResponse
 */
export type CreateInternalH2OVersionResponse = {
/**
 * InternalH2OVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalH2OVersion internal_h2o_version = 1;
 */
internalH2oVersion?: InternalH2OVersion;
}
;
/**
 * Request message for getting a single InternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.GetInternalH2OVersionRequest
 */
export type GetInternalH2OVersionRequest = {
/**
 * InternalH2OVersion resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for InternalH2OVersionService.GetInternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.GetInternalH2OVersionResponse
 */
export type GetInternalH2OVersionResponse = {
/**
 * InternalH2OVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalH2OVersion internal_h2o_version = 1;
 */
internalH2oVersion?: InternalH2OVersion;
}
;
/**
 * Request message for listing InternalH2OVersions.
 * (-- api-linter: core::0133::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalH2OVersion has no parent (it's a top-level resource) --)
 * (-- api-linter: core::0132::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalH2OVersion has no parent (it's a top-level resource) --)
 *
 * @generated from message ai.h2o.engine.v1.ListInternalH2OVersionsRequest
 */
export type ListInternalH2OVersionsRequest = {
/**
 * Maximum number of InternalH2OVersions to return in a response.
 * If unspecified (or set to 0), at most 50 InternalH2OVersions will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListInternalH2OVersionsResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for InternalH2OVersionService.ListInternalH2OVersions.
 *
 * @generated from message ai.h2o.engine.v1.ListInternalH2OVersionsResponse
 */
export type ListInternalH2OVersionsResponse = {
/**
 * Collection of InternalH2OVersions.
 *
 * @generated from field: repeated ai.h2o.engine.v1.InternalH2OVersion internal_h2o_versions = 1;
 */
internalH2oVersions?: InternalH2OVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of InternalH2OVersions matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for updating an existing InternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.UpdateInternalH2OVersionRequest
 */
export type UpdateInternalH2OVersionRequest = {
/**
 * InternalH2OVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalH2OVersion internal_h2o_version = 1;
 */
internalH2oVersion: InternalH2OVersion;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {deprecated, image, image_pull_policy, image_pull_secrets,
 * gpu_resource_name, annotations}.
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response for InternalH2OVersionService.UpdateInternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.UpdateInternalH2OVersionResponse
 */
export type UpdateInternalH2OVersionResponse = {
/**
 * InternalH2OVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalH2OVersion internal_h2o_version = 1;
 */
internalH2oVersion?: InternalH2OVersion;
}
;
/**
 * Request message for deleting an existing InternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.DeleteInternalH2OVersionRequest
 */
export type DeleteInternalH2OVersionRequest = {
/**
 * InternalH2OVersion resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for InternalH2OVersionService.DeleteInternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.DeleteInternalH2OVersionResponse
 */
export type DeleteInternalH2OVersionResponse = {
}
;
/**
 * Request message for assigning aliases to InternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.AssignInternalH2OVersionAliasesRequest
 */
export type AssignInternalH2OVersionAliasesRequest = {
/**
 * InternalH2OVersion resource name to which assign the new aliases.
 *
 * @generated from field: string internal_h2o_version = 1;
 */
internalH2oVersion: string;
/**
 * New aliases of the InternalH2OVersion.
 *
 * @generated from field: repeated string aliases = 2;
 */
aliases: string[];
}
;
/**
 * Response message for assigning aliases to InternalH2OVersion.
 *
 * @generated from message ai.h2o.engine.v1.AssignInternalH2OVersionAliasesResponse
 */
export type AssignInternalH2OVersionAliasesResponse = {
/**
 * All InternalH2OVersions with updated aliases.
 *
 * @generated from field: repeated ai.h2o.engine.v1.InternalH2OVersion internal_h2o_versions = 1;
 */
internalH2oVersions?: InternalH2OVersion[];
}
;
/**
 * Creates a new InternalH2OVersion.
 * (-- api-linter: core::0133::http-uri-parent=disabled
 *     aip.dev/not-precedent: InternalH2OVersion has no parent (it's a top-level resource) --)
 * (-- api-linter: core::0133::method-signature=disabled
 *     aip.dev/not-precedent: InternalH2OVersion has no parent (it's a top-level resource) --)
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.CreateInternalH2OVersion
 */
export const InternalH2OVersionService_CreateInternalH2OVersion = new RPC<CreateInternalH2OVersionRequest, CreateInternalH2OVersionResponse>("POST", "/v1/internalH2OVersions", "internalH2oVersion");
/**
 * Returns a specific InternalH2OVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.GetInternalH2OVersion
 */
export const InternalH2OVersionService_GetInternalH2OVersion = new RPC<GetInternalH2OVersionRequest, GetInternalH2OVersionResponse>("GET", "/v1/{name=internalH2OVersions/*}");
/**
 * Returns a collection of InternalH2OVersions.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.ListInternalH2OVersions
 */
export const InternalH2OVersionService_ListInternalH2OVersions = new RPC<ListInternalH2OVersionsRequest, ListInternalH2OVersionsResponse>("GET", "/v1/internalH2OVersions");
/**
 * Updates a InternalH2OVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.UpdateInternalH2OVersion
 */
export const InternalH2OVersionService_UpdateInternalH2OVersion = new RPC<UpdateInternalH2OVersionRequest, UpdateInternalH2OVersionResponse>("PATCH", "/v1/{internalH2oVersion.name=internalH2OVersions/*}", "internalH2oVersion");
/**
 * Deletes a InternalH2OVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.DeleteInternalH2OVersion
 */
export const InternalH2OVersionService_DeleteInternalH2OVersion = new RPC<DeleteInternalH2OVersionRequest, DeleteInternalH2OVersionResponse>("DELETE", "/v1/{name=internalH2OVersions/*}");
/**
 * Same behaviour as in AssignInternalDAIVersionAliases.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Google linter incorrectly requires URI suffix "assignInternalH2oVersionAliases" --)
 *
 * @generated from rpc ai.h2o.engine.v1.InternalH2OVersionService.AssignInternalH2OVersionAliases
 */
export const InternalH2OVersionService_AssignInternalH2OVersionAliases = new RPC<AssignInternalH2OVersionAliasesRequest, AssignInternalH2OVersionAliasesResponse>("POST", "/v1/internalH2OVersions:assignInternalH2OVersionAliases");
