import { Stack } from '@fluentui/react';
import { Button, Item, SearchBox, buttonStylesStealth, itemStylesTag, useTheme } from '@h2oai/ui-kit';

import { listPageSearchAndFilterStyles } from './listPageSearchAndFilter.styles';

export interface ListPageFilter {
  key: string | number;
  label: string;
  data?: any;
}

interface ListPageSearchAndFilterProps {
  activeFilters?: ListPageFilter[];
  searchPlaceholder: string | undefined;
  searchText?: string;
  onChangeSearchText?: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
  showFilterButton?: boolean;
  showSearchInput?: boolean;
  onOpenFilterPanel?: () => void;
  onRemoveFilter?: (filter: ListPageFilter) => void;
  onResetFilters?: () => void;
}

export function ListPageSearchAndFilter(props: ListPageSearchAndFilterProps) {
  const {
      activeFilters,
      searchPlaceholder,
      searchText,
      onChangeSearchText,
      showFilterButton,
      onOpenFilterPanel,
      onRemoveFilter,
      onResetFilters,
      showSearchInput = true,
    } = props,
    showActiveFilters = activeFilters && activeFilters.length > 0,
    theme = useTheme();
  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }} styles={listPageSearchAndFilterStyles()}>
      {showSearchInput && (
        <div className="search-container">
          <SearchBox
            placeholder={searchPlaceholder}
            value={searchText}
            onChange={onChangeSearchText}
            styles={{ root: { height: 32 } }}
          />
        </div>
      )}
      {showFilterButton && (
        <>
          <span className="search-separator">|</span>
          <Button text="Filter" iconName="Filter" onClick={onOpenFilterPanel} />
          {showActiveFilters && (
            <Stack
              horizontal
              wrap
              verticalAlign="center"
              tokens={{ childrenGap: 4 }}
              className="list-page-search-active-filters"
            >
              {activeFilters.map((filter) => (
                <Item
                  key={filter.key}
                  idField="key"
                  labelField="label"
                  styles={[
                    itemStylesTag,
                    {
                      root: {
                        backgroundColor: theme.palette?.gray300,
                      },
                    },
                  ]}
                  data={filter}
                  hasClose
                  onClose={onRemoveFilter}
                />
              ))}
              {onResetFilters && (
                <Button styles={buttonStylesStealth} onClick={onResetFilters}>
                  Reset filters
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
