import { IProgressIndicatorStyles, Modal, ProgressIndicator, Stack, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Button,
  Dropdown,
  FontSizes,
  FontWeights,
  IH2OTheme,
  IconName,
  Log,
  LogLine,
  buttonStylesIcon,
  buttonStylesLink,
  buttonStylesLinkBlack,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppInstance } from '../../ai.h2o.cloud.appstore';
import { WebSocketService, instanceLogDownload } from '../../services/api';
import { buttonStylesGray, buttonStylesRow, stackStylesLog } from '../../themes/themes';
import { useEnv } from '../../utils/hooks';
import { CANCEL_WEBSOCKET_DOWNLOAD } from '../../utils/utils';
import { InstanceLogPageHeader } from './InstanceLogPageHeader';

export enum InstanceLogType {
  APP = 'App',
  ENGINE = 'Engine',
}

export interface InstanceLogPageProps {
  instanceType: InstanceLogType;
  instanceName: string;
  instanceId: string;
  websocketUrl: URL | undefined;
  enablePreviousLog?: boolean;
  instance?: AppInstance; // legacy for handling GA events only. TODO: remove for 23.04 release
}

enum LOG_TYPE {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

type CurrentOrPreviousOption = {
  key: LOG_TYPE;
  text: string;
};

const currentOrPreviousOptions: CurrentOrPreviousOption[] = [
  { key: LOG_TYPE.CURRENT, text: 'Current process' },
  { key: LOG_TYPE.PREVIOUS, text: 'Previous process' },
];

const defaultTail = 1024;

const initialLine = `Starting from the most recent ${defaultTail} lines. To obtain entire log, click the Download button.\n\n`;

function InstanceLogPage({
  instanceType,
  instanceName,
  instanceId,
  enablePreviousLog,
  websocketUrl,
  instance,
}: InstanceLogPageProps) {
  // state
  const theme = useTheme(),
    [paused, setPaused] = useState<boolean>(false),
    [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false),
    [lastMessage, setLastMessage] = useState<LogLine>(),
    [currentOrPreviousLog, setCurrentOrPreviousLog] = useState<LOG_TYPE>(LOG_TYPE.CURRENT),
    [downloadErr, setDownloadErr] = useState<string>(''),
    // refs
    messageHistory = useRef<LogLine[]>([{ data: initialLine }]),
    displayMessageHistory = useRef<LogLine[]>([]),
    logWebSocket = useRef<WebSocket>(),
    downloadWebSocket = useRef<WebSocket>(),
    // utilities
    history = useHistory(),
    // callbacks
    onPlay = useCallback(() => {
      return setPaused(false);
    }, [setPaused]),
    onPause = useCallback(() => {
      return setPaused(true);
    }, [setPaused]),
    returnToInstanceList = useCallback(
      () => () => {
        history.goBack();
      },
      [history]
    ),
    onPrepareDownload = useCallback(() => {
      if (!websocketUrl) return;
      setDownloadErr('');
      showModal();
      // consider removing if no longer used:
      downloadWebSocket.current = instanceLogDownload(
        { url: websocketUrl },
        instanceId,
        currentOrPreviousLog === LOG_TYPE.PREVIOUS,
        () => {
          hideModal();
        },
        (_err) => {
          setDownloadErr('An error occurred while attempting to download the log file.');
        },
        instanceType === InstanceLogType.APP ? -1 : undefined
      );
    }, [
      currentOrPreviousLog,
      websocketUrl,
      instanceId,
      instance,
      setDownloadErr,
      downloadWebSocket,
      hideModal,
      showModal,
    ]),
    cancelLogDownload = useCallback(() => {
      downloadWebSocket.current?.close(1000, CANCEL_WEBSOCKET_DOWNLOAD);
      downloadWebSocket.current = undefined;
      hideModal();
    }, [downloadWebSocket, hideModal]),
    onToggleCurrentPrevious = useCallback(
      (option: LOG_TYPE) => {
        // this prevents race conditions where it is possible for the prior websocket
        // to add a log line to the new log
        if (logWebSocket.current) {
          logWebSocket.current!.onmessage = () => {};
        }
        setCurrentOrPreviousLog(option);
        if (paused) {
          setPaused(false);
        }
      },
      [setCurrentOrPreviousLog, paused, setPaused]
    ),
    env = useEnv();

  useHaicPageTitle('App Instance Logs', env?.cloudInstanceName);
  // memos
  messageHistory.current = useMemo(() => messageHistory.current?.concat(lastMessage || { data: '' }), [lastMessage]);
  displayMessageHistory.current = useMemo(() => {
    return paused && lastMessage ? displayMessageHistory.current : messageHistory.current;
  }, [paused, lastMessage]);

  // effects
  useEffect(() => {
    if (!websocketUrl) {
      return;
    }
    messageHistory.current = [];
    setLastMessage({
      data: initialLine,
    });
    displayMessageHistory.current = [];
    const ws = WebSocketService.getSocket({
      url: websocketUrl,
      queryParameters: {
        follow: true,
        tailLines: defaultTail,
        previous: currentOrPreviousLog === LOG_TYPE.PREVIOUS,
      },
    });
    logWebSocket.current = ws;
    ws.onmessage = (messageEvent) => {
      setLastMessage(messageEvent);
    };
    ws.onerror = () => {
      const errorAddendum =
        currentOrPreviousLog === LOG_TYPE.PREVIOUS ? ' There may not be a previous log available.' : '';
      setLastMessage({ data: `ERROR\n\nAn error occurred while attempting to retrieve the log.${errorAddendum}` });
    };
    return () => {
      ws.close();
    };
  }, [currentOrPreviousLog, websocketUrl]);
  const modalClassNames = contentStyles(theme);
  return (
    <>
      <Stack styles={stackStylesLog(theme)}>
        <InstanceLogPageHeader>
          <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'flex-start' }}>
            <div>
              <Button
                styles={[buttonStylesLink, buttonStylesLinkBlack]}
                data-test="back"
                title={`Return to ${instanceType} list`}
                iconName={IconName.Back}
                onClick={returnToInstanceList()}
                text="Back"
              />
              <h1>{instanceName}</h1>
              <div className="instance-id">ID: {instanceId}</div>
            </div>
            {enablePreviousLog && (
              <div style={{ alignSelf: 'flex-end', marginLeft: '30px' }}>
                <Dropdown
                  selectedKey={currentOrPreviousLog}
                  options={currentOrPreviousOptions}
                  onChange={(_e, option) => {
                    onToggleCurrentPrevious(option?.key as unknown as LOG_TYPE);
                  }}
                  preventDismissOnEventTypes={['scroll']}
                />
              </div>
            )}
          </div>
          <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'flex-start' }}>
            <div>
              <Button
                iconName={IconName.DownloadCircle}
                onClick={onPrepareDownload}
                text="Download"
                styles={[buttonStylesGray, buttonStylesRow]}
              />
              {paused ? (
                <Button
                  styles={[buttonStylesGray, buttonStylesRow]}
                  data-test="log-resume"
                  title="Resume"
                  iconName={IconName.MSNVideos}
                  onClick={onPlay}
                  text="Resume"
                />
              ) : (
                <Button
                  styles={[buttonStylesGray, buttonStylesRow]}
                  data-test="log-pause"
                  title="Pause"
                  iconName={IconName.CirclePause}
                  onClick={onPause}
                  text="Pause"
                />
              )}
            </div>
          </div>
        </InstanceLogPageHeader>
        <div className="log-container">
          <Log log={displayMessageHistory.current} />
        </div>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={modalClassNames.container}
      >
        <div className={modalClassNames.header}>
          <span>Download started</span>
          <Button ariaLabel="Close download modal" onClick={hideModal} iconName={'Cancel'} styles={buttonStylesIcon} />
        </div>
        <div className={modalClassNames.body}>
          {downloadErr ? (
            <div>
              <p>{`ERROR: ${downloadErr}`}</p>
              <div className={'download-buttons'}>
                <Button ariaLabel="Close" text={'Close'} onClick={hideModal} />
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div style={{ fontSize: '0.9rem' }}>{instanceName}</div>
                <div
                  style={{
                    fontSize: '0.75rem',
                    color: theme.palette?.gray600,
                    marginTop: '5px',
                  }}
                >
                  ID: {instanceId}
                </div>
              </div>
              <ProgressIndicator styles={progressStyles(theme)} />
              <div className={'download-buttons'}>
                <Button ariaLabel="Cancel download" text={'Cancel download'} onClick={cancelLogDownload} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

const contentStyles = (theme: IH2OTheme) =>
  mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      minHeight: '100px',
      width: '35%',
      right: '20px',
      bottom: '20px',
      position: 'fixed',
      borderRadius: '8px',
    },
    header: [
      {
        fontSize: FontSizes.large,
        flex: '1 1 auto',
        color: theme.palette?.gray750,
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: FontWeights.semiBold,
        padding: '15px 15px 0 15px',
      },
    ],
    body: {
      padding: '15px',
      '.download-buttons': {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
        button: {
          marginLeft: '10px',
        },
      },
    },
  });

const progressStyles = (theme: IH2OTheme): IProgressIndicatorStyles =>
  mergeStyleSets({
    itemDescription: {},
    itemName: {},
    itemProgress: {
      borderRadius: '8px',
      height: '8px',
      padding: '0',
      marginTop: '7px',
    },
    progressBar: {
      height: '8px',
      borderRadius: '8px',
      backgroundImage: 'none',
      background: theme.palette?.green500,
    },
    progressTrack: {
      height: '8px',
      borderRadius: '8px',
    },
    root: {},
  });

export default InstanceLogPage;
