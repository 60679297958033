import { CodeBlock, Link } from '@h2oai/ui-kit';

import { AccessData, CliAndApiAccessContentType, ContentSection, EponymousLink, LabeledValue } from './Helpers';

const apiSection: ContentSection = {
  id: 'api-info',
  title: 'Use the Python APIs',
  sidebarLabel: 'Use the Python APIs',
  subsections: (data: AccessData) => [
    {
      label: 'What are the Python APIs',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => <p>H2O APIs allow you to interact with the H2O AI Cloud via Python.</p>,
    },
    {
      label: 'User persona',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>
          This is ideal for the <b>data scientist</b> or organizations who would like to have repeatable scripts for
          their data science pipelines.
        </p>
      ),
    },
    {
      label: 'Learn more',
      type: CliAndApiAccessContentType.TEXT,
      renderContent: () => (
        <p>
          <EponymousLink href="https://github.com/h2oai/haic-tutorials" />
        </p>
      ),
    },
    {
      label: 'Install the APIs',
      type: CliAndApiAccessContentType.CODE,
      renderContent: () => {
        const pythonClients = [
          { label: 'Authentication', value: 'h2o-authn~=1.0' },
          { label: 'AI Engine Manager', value: data?.aiemPythonClient },
          { label: 'Steam', value: data?.steamPythonClient },
          { label: 'ML Ops', value: data?.mlopsPythonClient },
        ].filter((c) => c?.value);
        return (
          <article style={{ marginBottom: 16 }}>
            <p>
              Install the H2O libraries in your Python project or notebook to interact with the H2O AI Cloud. You can
              copy the required pip commands, copy the contents of your project&apos;s requirements.txt file, or
              download the requirements.txt file.
            </p>
            <CodeBlock showCopyButton>{pythonClients.map((c) => `pip install ${c.value}`).join('\n')}</CodeBlock>
            <CodeBlock showCopyButton downloadFilename="requirements.tx">
              {pythonClients.map((c) => `${c.value}`).join('\n')}
            </CodeBlock>
          </article>
        );
      },
    },
    {
      label: 'Connect to the platform',
      type: CliAndApiAccessContentType.CODE,
      renderContent: () => (
        <article>
          <LabeledValue label="API Token (Visit this link to generate token)">
            <EponymousLink href={data.getPlatformTokenUrl} />
          </LabeledValue>
          <p>Use the following to connect to this environment</p>
          <CodeBlock showCopyButton>
            {`import h2o_authn 
import getpass 

print(f"Visit ${data.getPlatformTokenUrl} to get your platform token") 
token_provider = h2o_authn.TokenProvider(
  refresh_token=getpass.getpass('Enter your platform token: '),
  client_id="${data.platformClientId}", 
  token_endpoint_url="${data.platformTokenUrl}" 
)`}
          </CodeBlock>
          {data?.steamUri && (
            <>
              <p>
                Connect to{' '}
                <Link href="https://docs.h2o.ai/enterprise-steam/latest-stable/docs/user-docs/index.html">Steam</Link>{' '}
                for making models with Driverless AI and H2O-3
              </p>
              <CodeBlock showCopyButton>
                {`import h2osteam 
h2osteam.login( 
  url="${data.steamUri}", 
  access_token=token_provider() 
)`}
              </CodeBlock>
            </>
          )}
          {data?.h2oCloudEnvironment && (
            <>
              <p>
                Connect to{' '}
                <Link href="https://h2oai.github.io/ai-engine-manager/docs/introduction/">AI Engine Manager</Link> for
                making models with Driverless AI and H2O-3
              </p>
              <CodeBlock showCopyButton>
                {`import h2o_engine_manager 
aiem = h2o_engine_manager.login(
  environment="${data.h2oCloudEnvironment}", 
  token_provider=token_provider
)`}
              </CodeBlock>
            </>
          )}
          {data?.mlopsUri && (
            <>
              <p>
                Connect to <Link href="https://docs.h2o.ai/mlops/">MLOps</Link> for operating models
              </p>
              <CodeBlock showCopyButton>
                {`import h2o_mlops 
mlops = h2o_mlops.Client( 
  gateway_url="${data.mlopsUri}", 
  token_provider=token_provider 
)`}
              </CodeBlock>
            </>
          )}
        </article>
      ),
    },
  ],
};

export default apiSection;
