import { DefaultDAISetup } from '../gen/ai/h2o/engine/v1/default_dai_setup_pb';
import {
  DefaultDAISetupService_GetDefaultDAISetup,
  DefaultDAISetupService_UpdateDefaultDAISetup,
  GetDefaultDAISetupRequest,
  GetDefaultDAISetupResponse,
  UpdateDefaultDAISetupRequest,
  UpdateDefaultDAISetupResponse,
} from '../gen/ai/h2o/engine/v1/default_dai_setup_service_pb';
import { ConstraintType } from '../types';
import { getDefaultDAISetupResponseMock } from './mocks/responses.mock';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';

const entityType = EntityType.DefaultDAISetup,
  required = true;

export const actions = {
  [EntityActionType.Get]: {
    name: 'GetDefaultDAISetup',
    rpc: DefaultDAISetupService_GetDefaultDAISetup,
    type: EntityActionType.Get,
    mockPath: '/v1/defaultDAISetup',
    responseKey: 'defaultDaiSetup',
    mockResponse: getDefaultDAISetupResponseMock,
    entityType,
  } as EntityAction<DefaultDAISetup, GetDefaultDAISetupRequest, GetDefaultDAISetupResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDefaultDAISetup',
    rpc: DefaultDAISetupService_UpdateDefaultDAISetup,
    mockPath: '/v1/defaultDAISetup',
    type: EntityActionType.Update,
    responseKey: 'defaultDaiSetup',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'defaultDaiSetup',
    entityType,
    mockResponse: getDefaultDAISetupResponseMock,
  } as EntityAction<DefaultDAISetup, UpdateDefaultDAISetupRequest, UpdateDefaultDAISetupResponse>,
};

export const fields: EntityField<DefaultDAISetup>[] = [
  {
    name: 'name',
    type: EntityFieldType.Hidden,
    label: 'Name',
    description: 'Resource name.',
    required,
  },
  {
    name: 'cpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'CPU Constraint',
    description: `Constraint for each DAIEngine's cpu in the workspace.`,
    required,
    constraintType: ConstraintType.CPU,
  },
  {
    name: 'gpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'GPU Constraint',
    description: `Constraint for each DAIEngine's gpu in the workspace.`,
    required,
    constraintType: ConstraintType.GPU,
  },
  {
    name: 'memoryBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Memory Constraint (GiB)',
    description: `Constraint for each DAIEngine's memory_bytes in the workspace.`,
    required,
    constraintType: ConstraintType.MEMORYBYTES,
  },
  {
    name: 'storageBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Storage Constraint (GiB)',
    description: `Constraint for each DAIEngine's storage_bytes in the workspace.`,
    required,
    constraintType: ConstraintType.STORAGEBYTES,
  },
  {
    name: 'maxIdleDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Idle Duration Constraint',
    description: `Constraint for each DAIEngine's max_idle_duration in the workspace.`,
    required,
    constraintType: ConstraintType.MAXIDLEDURATION,
  },
  {
    name: 'maxRunningDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Running Duration Constraint',
    description: `Constraint for each DAIEngine's max_running_duration in the workspace.`,
    required,
    constraintType: ConstraintType.MAXRUNNINGDURATION,
  },
  {
    name: 'maxNonInteractionDuration',
    type: EntityFieldType.Duration,
    label: 'Max Non Interaction Duration Constraint',
    description: `Max non-interaction duration applied on all DAIEngines in the workspace.`,
  },
  {
    name: 'maxUnusedDuration',
    type: EntityFieldType.Duration,
    label: 'Max Unused Duration Constraint',
    description: `Max unused duration applied on all DAIEngines in the workspace.`,
  },
  {
    name: 'tritonEnabled',
    type: EntityFieldType.Boolean,
    label: 'Triton Enabled',
    description: 'True when DAI built-in Triton inference server is enabled, false when it is disabled.',
  },
  {
    name: 'configurationOverride',
    type: EntityFieldType.KeyValuePair,
    label: 'Configuration Override',
    description: `
      When specified, then it is applied on top of each DAIEngine's configuration_override in the workspace.

      It replaces config values for keys that are set and inserts new values for keys
      that are not set already. Other configuration key-value pairs are preserved.

      For example, if DAIEngine's config is set by user as {"foo": "bar", "foo2": "baz"} and
      DAISetup.configuration_override is {"foo2": "boom", "foo3": "booz"} then the resulting DAIEngine
      configuration will be {"foo": "bar", "foo2": "boom", "foo3": "booz"}.    
    `,
    required,
  },
  {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'Pod Template Specification (YAML)',
    description: `
      YAML representation of custom PodTemplateSpec serialized into bytes.
      Definition of PodTemplateSpec: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#podtemplatespec-v1-core

      When specified, then it is applied for each DAIEngine in the workspace.
      PodTemplateSpec describes what will be applied on top of a regular DriverlessAI pod before it is created.
      This template is merged into DriverlessAI default pod using StrategicMergePatch method (it overrides the
      default pod).
      More info about StrategicMergePatch: https://kubernetes.io/docs/tasks/manage-kubernetes-objects/update-api-object-kubectl-patch/

      Example YAML value (custom annotation applied for each DAI pod):
      metadata:
       annotations:
         custom-key: custom-value
    `,
    required,
  },
  {
    name: 'yamlGpuTolerations',
    type: EntityFieldType.Yaml,
    label: 'YAML GPU Tolerations',
    description: `
      YAML representation of custom GPU Tolerations serialized into bytes.
      Definition of one Toleration: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#toleration-v1-core

      When specified, then it is applied for each DAIEngine in the workspace.
      GPUTolerations sets DriverlessAI's pod.spec.tolerations in case DAIEngine has GPU > 0. This will override
      any tolerations defined in yaml_pod_template_spec.PodSpec.Tolerations field.

      Example YAML value (two GPU tolerations applied for each DAI pod):
       - key: "dedicated"
         operator: "Equal"
         value: "gpu"
         effect: "NoSchedule"
       - key: "key2"
         operator: "Equal"
         value: "value2"
         effect: "NoSchedule"
       `,
    required,
  },
];

export const defaultDAISetupEntity: Entity<DefaultDAISetup> = {
  type: EntityType.DefaultDAISetup,
  name: 'defaultDAISetup',
  description: 'Customize your default DAI Setup configurations here.',
  createButtonLabel: 'Save Changes',
  displayName: 'Default DAI Setup',
  displayNameKey: 'name',
  actions,
  columns: [],
  fields,
  requestNameKeyPrefix: '',
  emptyModel: {
    name: 'new-default-dai-setup',
    cpuConstraint: {},
    gpuConstraint: {},
    memoryBytesConstraint: {},
    storageBytesConstraint: {},
    maxIdleDurationConstraint: {},
    maxRunningDurationConstraint: {},
    maxNonInteractionDuration: '',
    maxUnusedDuration: '',
    configurationOverride: {},
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
    tritonEnabled: false,
  },
  canBeReordered: true,
};
