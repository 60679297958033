import { MessageBarType, SpinnerSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Loader, useToast } from '@h2oai/ui-kit';
import { Fragment, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';

import { MessageText, useEntity } from '../../../aiem/entity/hooks';
import {
  Entity,
  EntityActionType,
  EntityField,
  EntityFieldType,
  EntityType,
  HasName,
} from '../../../aiem/entity/types';
import { EditablePanelFooter } from '../../../components/EditablePanelFooter/EditablePanelFooter';
import { handleErrMsg, isNotEmpty } from '../../../utils/utils';
import {
  BooleanEntityModelField,
  EntityFieldInputProps,
  FormRow,
  LatestAndAliasesEntityModelField,
  NumberEntityModelField,
  ReadOnlyStringArrayEntityModelField,
  SelectEnumEntityModelField,
  StringArrayEntityModelField,
  TextEntityModelField,
} from './BasicEntityModelComponents';
import ConstraintSetModelField from './ConstraintSetModelField';
import { DurationModelField } from './DurationModelField';
import { EntityDisplayAndId } from './EntityDisplayAndId';
import { KeyValuePairEntityModelField } from './KeyValuePairModelField';
import { SemverEntityModelField } from './SemverEntityModelField';
import { YamlEntityModelField } from './YamlEntityModelField';

export interface IEntityModelFormProps<EntityModel> {
  entity: Entity<EntityModel>;
  onSave?: () => any;
  model?: EntityModel;
  onDismiss?: () => any;
  isCreate?: boolean;
}

export interface ValidationState {
  requiredFields: boolean;
  validId: boolean;
  isNew: boolean;
}

export enum ValidationAction {
  REQUIRED_FIELDS = 'requiredFields',
  VALID_ID = 'validId',
  IS_NEW = 'isNew',
}

export type ValidationActions = { type: ValidationAction; value: boolean };

export type ValidationReducerFunction = (state: ValidationState, action: ValidationActions) => ValidationState;

export const validationReducer: ValidationReducerFunction = (
  state: ValidationState,
  action: ValidationActions
): ValidationState => {
  const newState = { ...state };
  newState[action.type] = action.value;
  return newState;
};

export function AddEditModelForm<EntityModel extends HasName>(props: IEntityModelFormProps<EntityModel>) {
  const { entity, model: originalModel, onSave, onDismiss, isCreate = false } = props;
  const [validationState, validationDispatch] = useReducer<ValidationReducerFunction>(validationReducer, {
    requiredFields: !isCreate,
    validId: !isCreate,
    isNew: isCreate,
  });
  const entityDataConnector = useEntity();
  const { addToast } = useToast();
  const { fields, type: entityType } = entity;
  const requiredFields = fields.filter((field) => field.required).map((field) => field.name as keyof EntityModel);
  const [model, setModel] = useState<EntityModel | undefined>(originalModel ? { ...originalModel } : undefined);
  const [loading, { setFalse: showContent }] = useBoolean(true);
  const [valid, setValid] = useState<boolean>();
  const modelName = model?.name;

  // TODO: re-enable masking when API bugs are sorted out:
  // const getMaskedModel = (newModel: EntityModel, oldModel: EntityModel): Partial<EntityModel> => {
  //   const mask: Partial<EntityModel> = {};
  //   for (const key in newModel) {
  //     if (newModel[key] !== oldModel[key]) {
  //       mask[key] = newModel[key];
  //     }
  //   }
  //   return mask;
  // };

  const onChange = (fieldName: keyof EntityModel, value: any) => {
    if (!model) return;
    const partial: Partial<EntityModel> = {};
    partial[fieldName] = value;
    const isRequiredFieldFilled = requiredFields.includes(fieldName) ? isNotEmpty(value) : true;
    const newModel = { ...model, ...partial };
    validationDispatch({
      type: ValidationAction.REQUIRED_FIELDS,
      value: requiredFields.every((field) => newModel[field]) && isRequiredFieldFilled,
    });
    validationDispatch({
      type: ValidationAction.IS_NEW,
      value: isCreate || JSON.stringify(originalModel) !== JSON.stringify(newModel),
    });
    setModel({ ...model, ...partial });
  };

  const displayAndIdFilter = ({ type }: EntityField<EntityModel>) =>
    [EntityFieldType.DisplayOnDisplayAndId, EntityFieldType.IdOnDisplayAndId].includes(type);
  const displayAndIdFields: { display: EntityField<EntityModel>; id: EntityField<EntityModel> } = {} as {
    display: EntityField<EntityModel>;
    id: EntityField<EntityModel>;
  };
  fields.filter(displayAndIdFilter).forEach((field) => {
    if (field.type === EntityFieldType.DisplayOnDisplayAndId) displayAndIdFields.display = field;
    if (field.type === EntityFieldType.IdOnDisplayAndId) displayAndIdFields.id = field;
  });

  const isFieldDisabled = (field: EntityField<EntityModel>) => {
    if (!model) return;
    // Specific case rules
    // For versions, a version cannot be both "latest" AND "deprecated".
    // Therefore, if one is true, the other field must be disabled.
    if (entity.type === EntityType.InternalDAIVersion || entity.type === EntityType.InternalH2OVersion) {
      if (field.name === 'aliases') {
        if (model['deprecated']) return true;
      }
      if (field.name === 'deprecated') {
        if (model['aliases'].includes('latest')) return true;
      }
    }
    return false;
  };

  const nonIdOrDisplayFields = model
    ? fields
        .filter(({ type }) => !displayAndIdFilter({ type } as EntityField<EntityModel>))
        .map((field) => {
          const { type, name } = field;
          const props: EntityFieldInputProps<EntityModel> = {
            field,
            model,
            onChange,
            entityType,
            isCreate,
            disabled: isFieldDisabled(field),
          };
          let modelField: ReactNode | undefined;
          switch (type) {
            case EntityFieldType.SelectEnum:
              modelField = <SelectEnumEntityModelField {...props} />;
              break;
            case EntityFieldType.Boolean:
              modelField = <BooleanEntityModelField {...props} />;
              break;
            case EntityFieldType.Text:
              modelField = <TextEntityModelField {...props} />;
              break;
            case EntityFieldType.Number:
              modelField = <NumberEntityModelField {...props} />;
              break;
            case EntityFieldType.Bytes:
              modelField = <NumberEntityModelField {...props} convertToGibibytes={true} />;
              break;
            case EntityFieldType.ReadOnlyStringArray:
              modelField = <ReadOnlyStringArrayEntityModelField {...props} />;
              break;
            case EntityFieldType.StringArray:
              modelField = <StringArrayEntityModelField {...props} />;
              break;
            case EntityFieldType.KeyValuePair:
              modelField = <KeyValuePairEntityModelField {...props} />;
              break;
            case EntityFieldType.Semver:
              modelField = (
                <SemverEntityModelField
                  {...props}
                  onChange={undefined}
                  onChangeMultiple={(partialModel: Partial<EntityModel>) => {
                    setModel((oldModel) => (oldModel ? { ...oldModel, ...partialModel } : undefined));
                  }}
                  validate={(validId) => validationDispatch({ type: ValidationAction.VALID_ID, value: validId })}
                />
              );
              break;
            case EntityFieldType.LatestAndAliases:
              modelField = <LatestAndAliasesEntityModelField {...props} />;
              break;
            case EntityFieldType.ConstraintNumeric:
            case EntityFieldType.ConstraintDuration:
              modelField = <ConstraintSetModelField<EntityModel> {...props} />;
              break;
            case EntityFieldType.Duration:
              modelField = <DurationModelField {...props} />;
              break;
            case EntityFieldType.Yaml:
              modelField = <YamlEntityModelField {...props} />;
              break;
            case EntityFieldType.Hidden:
              modelField = undefined;
              break;
            default:
              modelField = <FormRow>{`${String(name)} is yet to be implemented`}</FormRow>;
          }
          return <Fragment key={`${String(name)}-form-row`}>{modelField}</Fragment>;
        })
    : [];

  const onCreateOrUpdate = useCallback(async () => {
    if (!model) return;
    const action = isCreate ? entity.actions.Create : entity.actions.Update;
    if (!action) {
      return;
    }
    const { requestNameKey, requestPayloadKey } = action || {
      requestNameKey: 'bad-action-key',
      requestPayloadKey: 'bad-action-payload',
    };

    const nameObj = { name: model.name };

    try {
      const request = {
        [requestPayloadKey!]: model,
        [requestNameKey!]: model.name,
      };
      const messageText: MessageText = {
        subject: model[entity.displayNameKey] as unknown as string, // TODO: check if this coercion is safe
        action: isCreate ? 'created ' : 'updated',
      };
      if (isCreate) {
        await entityDataConnector.create(entity, request, messageText);
      } else {
        // TODO: re-enable masking when API bugs are sorted out:
        // const newModel = getMaskedModel(model, originalModel);
        // const updateMask = Object.keys(newModel).join(',');
        // we have to add the name always in order to satisfy the connector generated by protoc-gen-grpc-gateway-es,
        // even though the name field gets stripped out before the request is made:
        // newModel[requestNameKey!] = originalModel[requestNameKey!];
        const newReq = { ...request, updateMask: '*' };
        await entityDataConnector.update(entity, newReq, messageText);
      }
      await entityDataConnector.assign(entity, { ...model, ...nameObj }, messageText);
      onSave && onSave();
    } catch (error: any) {
      addToast({
        message: `An error occurred while attempting to ${isCreate ? 'create' : 'update'} the ${
          entity.displayName
        }: ${handleErrMsg(error.message)}`,
        messageBarType: MessageBarType.error,
      });
      onDismiss && onDismiss();
    }
  }, [model]);

  useEffect(() => {
    const fetchModel = async () => {
      const freshModel = await entityDataConnector.get(entity, { name: modelName || entity.name }, true);
      setModel((freshModel || originalModel) as EntityModel);
      showContent();
    };
    if (!isCreate) fetchModel();
    else showContent();
  }, []);

  useEffect(() => {
    setValid(validationState.requiredFields && validationState.validId && validationState.isNew);
  }, [validationState]);

  return (
    <Stack horizontal>
      {loading || !model ? (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingLeft: 200, paddingTop: 200 } }}>
          <Loader size={SpinnerSize.large} />
        </Stack>
      ) : (
        <>
          <Stack style={{ marginBottom: 10 }}>
            {displayAndIdFields.display && displayAndIdFields.id && (
              <EntityDisplayAndId
                model={model}
                onDisplayNameChange={(value: string) => onChange(displayAndIdFields.display.name, value)}
                onIdChange={(value: string) => onChange(displayAndIdFields.id.name, value)}
                validate={(isValid: boolean) => {
                  validationDispatch({ type: ValidationAction.VALID_ID, value: isValid });
                }}
                entityType={entityType}
                actionType={EntityActionType.Create}
                editableId={isCreate}
              />
            )}
            {nonIdOrDisplayFields}
          </Stack>
          <EditablePanelFooter
            onCancel={onDismiss!}
            onSave={onCreateOrUpdate}
            closeButtonText={'Cancel'}
            saveButtonText={isCreate ? `Add ${entity.displayName}` : `Save`}
            saveButtonDisabled={!valid}
          />
        </>
      )}
    </Stack>
  );
}
