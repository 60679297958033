import { BaseButton, FontSizes, FontWeights, ITextFieldProps, SpinnerSize, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Loader, LoaderType, TextField, IconButton as UIKitIconButton } from '@h2oai/ui-kit';
import { MouseEventHandler, useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { useEntity } from '../../../aiem/entity/hooks';
import { defaultId } from '../../../aiem/entity/mocks/constants';
import { EntitiesMap } from '../../../aiem/entity/services';
import { EntityActionType, EntityType } from '../../../aiem/entity/types';
import { concatId, getIdFromName, getVariant, tryName } from '../../../aiem/utils';
import { DisplayAndIdRuleField } from '../../../components/AIEnginesPage/components/DisplayAndIdRuleField/DisplayAndIdRuleField';
import { LabelIconTooltip } from '../../../components/AIEnginesPage/components/LabelIconTooltip/LabelIconTooltip';
import { validateId } from '../../../components/AIEnginesPage/utils';
import { useDebouncedCallback, usePromiseCallback } from '../../../utils/hooks';
import { useFormAttributes } from '../../../utils/utils';

const validateFirstLetterId = (id: string | undefined | null): boolean => {
  const item = id?.charAt(0);
  return Boolean(item) && /^[a-z]?$/.test(item!) && item === item?.toLowerCase();
};

const validateLastLetterId = (id: string | undefined | null): boolean => {
  const item = id?.slice(-1);
  return Boolean(item) && /^[a-z0-9]?$/.test(item!) && item === item?.toLowerCase();
};

const validateContainLetterId = (id: string | undefined | null): boolean => Boolean(id) && /^[a-z0-9-]+$/.test(id!);

const validateLengthId = (id: string | undefined | null): boolean =>
  Boolean(id) && Boolean(id && id?.length > 0) && Boolean(id && id?.length < 61);

enum IdModeType {
  editableView = 'editableView',
  edit = 'edit',
  view = 'view',
}

type IconButtonProps = {
  iconName: string;
  onClick:
    | MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement>
    | undefined;
  title?: string;
};

function IconButton(props: IconButtonProps) {
  return (
    <Stack styles={{ root: { marginTop: 8 } }}>
      <UIKitIconButton {...props} />
    </Stack>
  );
}

export type EntityDisplayAndIdProps<EntityModel> = {
  editableId?: boolean;
  model: EntityModel;
  onDisplayNameChange: (value: string) => void;
  onIdChange: (value: string) => void;
  idKey?: keyof EntityModel;
  displayKey?: keyof EntityModel;
  entityType: EntityType;
  actionType: EntityActionType;
  validate?: (valid: boolean) => void;
};

interface ValidationState {
  availableId: boolean;
  validId: boolean;
  validFirstLetterId: boolean;
  validLastLetterId: boolean;
  validContainerLetterId: boolean;
  validLengthId: boolean;
}

enum ValidationAction {
  AVAILABLE_ID = 'availableId',
  VALID_ID = 'validId',
  VALID_FIRST_LETTER_ID = 'validFirstLetterId',
  VALID_LAST_LETTER_ID = 'validLastLetterId',
  VALID_CONTAINER_LETTER_ID = 'validContainerLetterId',
  VALID_LENGTH_ID = 'validLengthId',
}

type ValidationActions = { type: ValidationAction; value: boolean };

type ValidationReducerFunction = (state: ValidationState, action: ValidationActions) => ValidationState;

const validationReducer: ValidationReducerFunction = (
  state: ValidationState,
  action: ValidationActions
): ValidationState => {
  const newState = { ...state };
  newState[action.type] = action.value;
  return newState;
};

export function EntityDisplayAndId<EntityModel>({
  editableId,
  model,
  onDisplayNameChange,
  onIdChange,
  idKey = 'name' as keyof EntityModel,
  displayKey = 'displayName' as keyof EntityModel,
  entityType,
  actionType,
  validate,
}: EntityDisplayAndIdProps<EntityModel>) {
  const { formRowHeight, inputContainerProps, inputRowProps } = useFormAttributes(),
    { checkId } = useEntity();

  const [validationState, validationDispatch] = useReducer<ValidationReducerFunction>(validationReducer, {
      availableId: !editableId,
      validId: !editableId,
      validFirstLetterId: !editableId,
      validLastLetterId: !editableId,
      validContainerLetterId: !editableId,
      validLengthId: !editableId,
    }),
    idTextStyles = { root: { fontSize: FontSizes.small, paddingTop: 10 } },
    [idMode, setIdMode] = useState<IdModeType>(editableId ? IdModeType.editableView : IdModeType.view),
    [currentDisplayName, setCurrentDisplayName] = useState<string | undefined>(String(model[displayKey])),
    [currentId, setCurrentId] = useState<string | undefined>(getIdFromName(String(model[idKey] || model[displayKey]))),
    [hasIdBeenEdited, setHasIdBeenEdited] = useState<boolean>(false),
    [editingId, setEditingId] = useState<string | undefined>(``),
    [firstDisplayFocus, { setFalse: onDisplayBlur }] = useBoolean(true),
    [checkingId, { setTrue: showChecking, setFalse: hideChecking }] = useBoolean(false),
    [loading, { setFalse: showContent }] = useBoolean(true),
    successMessage = { successMessage: `Entity ID available to use` },
    unavailableErrorMessage = { errorMessage: `Entity ID already in use` },
    invalidIdErrorMessage = {
      errorMessage: `Please ensure the following rules are followed`,
    },
    validateAllRules = (value: string | undefined) => {
      validationDispatch({ type: ValidationAction.VALID_FIRST_LETTER_ID, value: validateFirstLetterId(value) });
      validationDispatch({ type: ValidationAction.VALID_LAST_LETTER_ID, value: validateLastLetterId(value) });
      validationDispatch({ type: ValidationAction.VALID_CONTAINER_LETTER_ID, value: validateContainLetterId(value) });
      validationDispatch({ type: ValidationAction.VALID_LENGTH_ID, value: validateLengthId(value) });
    },
    editId = () => {
      setIdMode(IdModeType.edit);
      setEditingId(currentId);
      validationDispatch({ type: ValidationAction.VALID_ID, value: validateId(currentId) });
      validateAllRules(currentId);
    },
    awaitingDisplayCheckResponse = useRef<boolean>(false),
    awaitingIdCheckResponse = useRef<boolean>(false),
    displayCheckController = useRef<AbortController>(),
    idCheckController = useRef<AbortController>();
  const idChecker = (id: string, controller?: AbortController) => checkId<EntityModel>(entityType, id, controller),
    testDisplay = useCallback(
      async (displayName: string) => {
        const idPair = tryName(displayName as string),
          { id } = idPair;
        let { variant } = idPair,
          candidateId = id || tryName(defaultId).id;
        let available = false;
        while (!available) {
          if (awaitingDisplayCheckResponse.current && displayCheckController.current) {
            displayCheckController.current?.abort();
          }
          displayCheckController.current = new AbortController();
          awaitingDisplayCheckResponse.current = true;
          available = await idChecker(candidateId, displayCheckController.current);
          awaitingDisplayCheckResponse.current = false;
          if (available) {
            setCurrentId(candidateId);
            onIdChange(candidateId);
            validationDispatch({ type: ValidationAction.AVAILABLE_ID, value: true });
            validate && validate(true);
            validationDispatch({ type: ValidationAction.VALID_ID, value: validateId(candidateId) });
            validateAllRules(candidateId);
          } else {
            variant = getVariant(variant);
          }
          candidateId = concatId(id, variant);
        }
      },
      [onIdChange]
    ),
    testId = useCallback(
      async (value: string) => {
        showChecking();
        if (awaitingIdCheckResponse.current && idCheckController.current) {
          idCheckController.current?.abort();
        }
        idCheckController.current = new AbortController();
        awaitingIdCheckResponse.current = true;
        const available = await idChecker(value, idCheckController.current);
        validate && validate(available);
        awaitingIdCheckResponse.current = false;
        validationDispatch({ type: ValidationAction.AVAILABLE_ID, value: available });
      },
      [showChecking]
    );

  const [checkIdErrorMessage, setCheckIdErrorMessage] = useState(``);
  const [useTest] = usePromiseCallback(
    (value: string, idCheck = false) => (idCheck ? testId(value) : testDisplay(value)),
    [testDisplay, testId],
    {
      onError: (workerError) => {
        setCheckIdErrorMessage(workerError.message.message);
      },
      onSuccess: () => {
        setCheckIdErrorMessage(``);
      },
      onSettled: hideChecking,
    }
  );
  const debounceDisplayCallback = useDebouncedCallback(useTest, 500);
  const debounceIdCallback = useDebouncedCallback(useTest, 600);

  const handleDisplayChange = (event: any) => {
      const displayName = event?.target?.value;
      setCurrentDisplayName(displayName);
      onDisplayNameChange(displayName);
      if (!hasIdBeenEdited && idMode === IdModeType.editableView) {
        debounceDisplayCallback(displayName);
      }
    },
    handleIdChange = (event: any) => {
      const value = event?.target?.value;
      setEditingId(value);
      setHasIdBeenEdited(value !== currentId);
      const valid = validateId(value);
      validationDispatch({ type: ValidationAction.VALID_ID, value: valid });
      validateAllRules(value);
      validate && validate(valid);
      if (valid) {
        debounceIdCallback(value, true);
      }
      onIdChange(value);
    };

  const onRenderDescription = () => {
    return (
      <Stack styles={{ root: { height: 10, width: 20, paddingTop: 4 } }}>
        <Loader type={LoaderType.progressIndicator} />
      </Stack>
    );
  };

  useEffect(() => {
    // fetch a new Profile Name with a Random Number. It must form a valid and available id.
    const generateDisplayAndId = async () => {
      const display = String(model[displayKey]);
      await testDisplay(display);
      showContent();
    };
    if (editableId) generateDisplayAndId();
    else showContent();
    return () => {
      if (awaitingIdCheckResponse && idCheckController.current) {
        idCheckController.current?.abort();
      }
      if (awaitingDisplayCheckResponse && displayCheckController.current) {
        displayCheckController.current?.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (validate) {
      let valid = true;
      Object.keys(validationState).forEach((key) => {
        if (!validationState[key]) {
          valid = false;
        }
      });
      validate(valid);
    }
  }, [validationState]);

  if (!EntitiesMap.has(entityType)) return null;
  const entity = EntitiesMap.get(entityType);
  if (!entity?.actions[actionType]) return null;

  return (
    <Stack horizontal>
      <Stack style={{ marginBottom: idMode === IdModeType.edit ? 10 : 0 }}>
        {loading ? (
          <Stack styles={{ root: { paddingLeft: 90, paddingTop: 20, height: 90 } }}>
            <Loader size={SpinnerSize.large} />
          </Stack>
        ) : (
          <>
            <Stack {...inputRowProps}>
              <Stack {...inputContainerProps}>
                <TextField
                  required
                  autoFocus
                  styles={{ root: { width: 450 } }}
                  label="Display Name"
                  data-test="display-name-input"
                  value={currentDisplayName}
                  onChange={handleDisplayChange}
                  onFocus={(event) => {
                    if (firstDisplayFocus) event.target.select();
                  }}
                  onBlur={onDisplayBlur}
                  onRenderLabel={(
                    labelProps: ITextFieldProps | undefined,
                    defaultRender: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined
                  ) => (
                    <LabelIconTooltip
                      id={labelProps?.id}
                      data-test="display-name-info"
                      label={defaultRender!(labelProps) as any}
                      required
                      tooltip="The display name is free to have all kinds
              of special and alphanumeric characters
              and has no characters limit,
              although simplicity will work in your favor"
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              {...inputRowProps}
              styles={{
                root: {
                  ...inputRowProps.styles.root,
                  height: idMode === IdModeType.edit ? formRowHeight : 20,
                },
              }}
            >
              {idMode !== IdModeType.edit && (
                <>
                  <Stack
                    styles={{
                      root: {
                        height: 20,
                        width: inputContainerProps.styles.root.width,
                        marginTop: '-24px',
                        marginLeft: 2,
                      },
                    }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { fontSize: '10px' } }}>
                      {currentId && (
                        <Text styles={{ root: { ...idTextStyles.root, fontWeight: FontWeights.semibold } }}>
                          {`ID: ${currentId}`}
                        </Text>
                      )}
                      {idMode === IdModeType.editableView && (
                        <IconButton
                          title={'Edit'}
                          key="editButton"
                          data-test="edit-button"
                          onClick={editId}
                          iconName="Edit"
                        />
                      )}
                    </Stack>
                  </Stack>
                </>
              )}
              {idMode === IdModeType.edit && (
                <>
                  <Stack {...inputContainerProps}>
                    <Stack {...inputRowProps}>
                      <Stack styles={{ root: { minWidth: 450, height: 80 } }}>
                        <TextField
                          required
                          data-test="id-input"
                          id="idTextField"
                          value={editingId}
                          onChange={handleIdChange}
                          label={`Entity ID`}
                          onFocus={(event) => event.target.select()}
                          {...(checkingId
                            ? { onRenderDescription }
                            : validationState.validId
                            ? validationState.availableId
                              ? successMessage
                              : unavailableErrorMessage
                            : invalidIdErrorMessage)}
                          styles={{ root: { minWidth: 230 } }}
                          onRenderLabel={(labelProps, defaultRender) => (
                            <LabelIconTooltip
                              id={labelProps?.id}
                              key="idInfo"
                              data-test="id-info"
                              label={defaultRender!(labelProps) as any}
                              required
                              tooltip="Starts with a letter.
                        Allowed characters: a-z, 0-9 and dashes cannot be used as last characters.
                        Uppercases will be converted to lowercases.
                        It cannot be changed after the Entity Creation."
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack styles={{ root: { minWidth: 450, height: 80, paddingTop: 5 } }}>
                      <DisplayAndIdRuleField
                        isValid={validationState.validFirstLetterId}
                        text={'Must start with lower case letter'}
                      />
                      <DisplayAndIdRuleField
                        isValid={validationState.validLastLetterId}
                        text={'Must end with lower case letter or digit'}
                      />
                      <DisplayAndIdRuleField
                        isValid={validationState.validContainerLetterId}
                        text={'Can contain only lowercase letters, digits or dashes (-)'}
                      />
                      <DisplayAndIdRuleField
                        isValid={validationState.validLengthId}
                        text={'Min length 1 char Max length 63 chars'}
                      />
                    </Stack>
                  </Stack>
                  {checkIdErrorMessage && <>{checkIdErrorMessage}</>}
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
