// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/executor_pool_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { ExecutorPool } from "./executor_pool_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for CreateExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateExecutorPoolRequest
 */
export type CreateExecutorPoolRequest = {
/**
 * Required. The resource name of the workspace to create ExecutorPool in.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The ID to use for the executor pool, which will become the final component of the executor pool resource name.
 *
 * @generated from field: string executor_pool_id = 2;
 */
executorPoolId?: string;
/**
 * Required. The ExecutorPool resource to create.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPool executor_pool = 3;
 */
executorPool: ExecutorPool;
}
;
/**
 * Response message for CreateExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateExecutorPoolResponse
 */
export type CreateExecutorPoolResponse = {
/**
 * The created ExecutorPool.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPool executor_pool = 1;
 */
executorPool?: ExecutorPool;
}
;
/**
 * Request message for UpdateExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateExecutorPoolRequest
 */
export type UpdateExecutorPoolRequest = {
/**
 * Required. ExecutorPool resource to update
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPool executor_pool = 1;
 */
executorPool: ExecutorPool;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 * Allowed field paths are: {display_name, max_queue_size_per_executor, max_executors}.
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateExecutorPoolResponse
 */
export type UpdateExecutorPoolResponse = {
/**
 * Updated ExecutorPool resource.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPool executor_pool = 1;
 */
executorPool?: ExecutorPool;
}
;
/**
 * Request message for GetExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.GetExecutorPoolRequest
 */
export type GetExecutorPoolRequest = {
/**
 * Required. ExecutorPool resource name.
 * Format: workspaces/{workspace}/executorPools/{executorPool}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.GetExecutorPoolResponse
 */
export type GetExecutorPoolResponse = {
/**
 * The requested executorPool.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ExecutorPool executor_pool = 1;
 */
executorPool?: ExecutorPool;
}
;
/**
 * Request message ListExecutorPools
 *
 * @generated from message ai.h2o.orchestrator.v1.ListExecutorPoolsRequest
 */
export type ListExecutorPoolsRequest = {
/**
 * Required. The resource name of the workspace to list executorPools from.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return runnable executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * display_name
 *   * create_time
 *
 * Examples:
 * display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z
 * create_time >= 2023-12-30T23:59:43Z AND create_time <= 2024-03-20T08:00:00Z
 * NOT(display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z)
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workflow executions ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListExecutorPools
 *
 * @generated from message ai.h2o.orchestrator.v1.ListExecutorPoolsResponse
 */
export type ListExecutorPoolsResponse = {
/**
 * Collection of executorPools.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.ExecutorPool executor_pools = 1;
 */
executorPools?: ExecutorPool[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for DeleteExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteExecutorPoolRequest
 */
export type DeleteExecutorPoolRequest = {
/**
 * Required. ExecutorPool resource name.
 * Format: workspaces/{workspace}/executorPools/{executorPool}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteExecutorPool
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteExecutorPoolResponse
 */
export type DeleteExecutorPoolResponse = {
}
;
/**
 * Creates a new ExecutorPool within a specified workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolService.CreateExecutorPool
 */
export const ExecutorPoolService_CreateExecutorPool = new RPC<CreateExecutorPoolRequest, CreateExecutorPoolResponse>("POST", "/v1/{parent=workspaces/*}/executorPools", "executorPool");
/**
 * Updates a ExecutorPool.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolService.UpdateExecutorPool
 */
export const ExecutorPoolService_UpdateExecutorPool = new RPC<UpdateExecutorPoolRequest, UpdateExecutorPoolResponse>("PATCH", "/v1/{executorPool.name=workspaces/*/executorPools/*}", "executorPool");
/**
 * Deletes a ExecutorPool. Deletion is possible only if there are no attached Runnables to it.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolService.DeleteExecutorPool
 */
export const ExecutorPoolService_DeleteExecutorPool = new RPC<DeleteExecutorPoolRequest, DeleteExecutorPoolResponse>("DELETE", "/v1/{name=workspaces/*/executorPools/*}");
/**
 * Returns a specific ExecutorPool.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolService.GetExecutorPool
 */
export const ExecutorPoolService_GetExecutorPool = new RPC<GetExecutorPoolRequest, GetExecutorPoolResponse>("GET", "/v1/{name=workspaces/*/executorPools/*}");
/**
 * Returns a collection of ExecutorPools within a workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.ExecutorPoolService.ListExecutorPools
 */
export const ExecutorPoolService_ListExecutorPools = new RPC<ListExecutorPoolsRequest, ListExecutorPoolsResponse>("GET", "/v1/{parent=workspaces/*}/executorPools");
