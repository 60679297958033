import { IColumn } from '@fluentui/react';

import { listIconSize } from '../../utils/utils';
import { DAIProfile } from '../gen/ai/h2o/engine/v1/dai_profile_pb';
import {
  CreateDAIProfileRequest,
  CreateDAIProfileResponse,
  DAIProfileService_CreateDAIProfile,
  DAIProfileService_DeleteDAIProfile,
  DAIProfileService_GetDAIProfile,
  DAIProfileService_ListDAIProfiles,
  DAIProfileService_ReorderDAIProfile,
  DAIProfileService_UpdateDAIProfile,
  DeleteDAIProfileRequest,
  DeleteDAIProfileResponse,
  GetDAIProfileRequest,
  GetDAIProfileResponse,
  ListDAIProfilesRequest,
  ListDAIProfilesResponse,
  ReorderDAIProfileRequest,
  ReorderDAIProfileResponse,
  UpdateDAIProfileRequest,
  UpdateDAIProfileResponse,
} from '../gen/ai/h2o/engine/v1/dai_profile_service_pb';
import { toBigIntString } from '../gen/runtime';
import { gibibytesToBytes } from '../utils';
import { listDAIProfilesResponseMock } from './mocks/responses.mock';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import { mockItemGetter, onRender, onRenderBigIntStringCell, onRenderDisplayAndId, onRenderGripperCell } from './utils';

const entityType = EntityType.DAIProfile,
  required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateDAIProfile',
    rpc: DAIProfileService_CreateDAIProfile,
    type: EntityActionType.Create,
    responseKey: 'daiProfile',
    requestNameKey: 'daiProfileId',
    requestPayloadKey: 'daiProfile',
    requestNameKeyPrefix: 'daiProfiles/',
    entityType,
  } as EntityAction<DAIProfile, CreateDAIProfileRequest, CreateDAIProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetDAIProfile',
    rpc: DAIProfileService_GetDAIProfile,
    type: EntityActionType.Get,
    mockPath: '/v1/daiProfiles/*',
    responseKey: 'daiProfile',
    mockResponse: (req) => mockItemGetter(listDAIProfilesResponseMock.daiProfiles!, req, 'daiProfile'),
    entityType,
  } as EntityAction<DAIProfile, GetDAIProfileRequest, GetDAIProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListDAIProfile',
    rpc: DAIProfileService_ListDAIProfiles,
    type: EntityActionType.List,
    mockResponse: listDAIProfilesResponseMock,
    responseKey: 'daiProfiles',
    entityType,
  } as EntityAction<DAIProfile, ListDAIProfilesRequest, ListDAIProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDAIProfile',
    rpc: DAIProfileService_UpdateDAIProfile,
    mockPath: '/v1/daiProfiles/*',
    type: EntityActionType.Update,
    responseKey: 'daiProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'daiProfile',
    entityType,
    mockResponse: listDAIProfilesResponseMock.daiProfiles![0],
  } as EntityAction<DAIProfile, UpdateDAIProfileRequest, UpdateDAIProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteDAIProfile',
    rpc: DAIProfileService_DeleteDAIProfile,
    mockPath: '/v1/*',
    mockResponse: {},
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<DAIProfile, DeleteDAIProfileRequest, DeleteDAIProfileResponse>,
  [EntityActionType.Reorder]: {
    name: 'ReorderDAIProfile',
    rpc: DAIProfileService_ReorderDAIProfile,
    mockPath: '/v1/*:reorder',
    mockResponse: listDAIProfilesResponseMock,
    type: EntityActionType.Reorder,
    responseKey: 'daiProfiles',
    entityType,
  } as EntityAction<DAIProfile, ReorderDAIProfileRequest, ReorderDAIProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'grabber',
    name: '',
    className: 'grabber-icon',
    fieldName: 'icon',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    onRender: onRenderGripperCell,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 320,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'cpu',
    name: 'CPU',
    fieldName: 'cpu',
    minWidth: 80,
    maxWidth: 80,
    onRender,
  },
  {
    key: 'gpu',
    name: 'GPU',
    fieldName: 'gpu',
    minWidth: 80,
    maxWidth: 80,
    onRender,
  },
  {
    key: 'memory',
    name: 'Memory',
    fieldName: 'memoryBytes',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderBigIntStringCell,
  },
  {
    key: 'storage',
    name: 'Storage',
    fieldName: 'storageBytes',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderBigIntStringCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<DAIProfile>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'DAIProfile resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of DAIProfile.',
    required,
  },
  {
    name: 'cpu',
    type: EntityFieldType.Number,
    label: 'CPU',
    description: 'The number of CPU units.',
    required,
    minimum: 1,
  },
  {
    name: 'gpu',
    type: EntityFieldType.Number,
    label: 'GPU',
    description: 'The number of GPU units.',
    minimum: 0,
  },
  {
    name: 'memoryBytes',
    type: EntityFieldType.Bytes,
    label: 'Memory',
    description: 'The amount of memory in Gibibytes.',
    required,
    minimum: 1,
  },
  {
    name: 'storageBytes',
    type: EntityFieldType.Bytes,
    label: 'Storage',
    description: 'The amount of storage in Gibibytes.',
    required,
    minimum: 1,
  },
  {
    name: 'order',
    type: EntityFieldType.Hidden,
    label: 'Order',
    description:
      'Custom order of a DAIProfile within the other DAIProfiles. Zero represents that the DAIProfile has no specified order.',
  },
];

export const daiProfileEntity: Entity<DAIProfile> = {
  type: EntityType.DAIProfile,
  name: 'DAIProfile',
  createButtonLabel: 'Add Profile',
  displayName: 'DAI Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'daiProfiles/',
  emptyModel: {
    name: 'new-dai-profile',
    displayName: 'New DAI Profile',
    cpu: 1,
    gpu: 0,
    memoryBytes: toBigIntString(gibibytesToBytes(4)!),
    storageBytes: toBigIntString(gibibytesToBytes(4)!),
    order: 0,
  },
  canBeReordered: true,
};
