// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/workflow_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { Workflow } from "./workflow_pb";
import type { WorkflowExecution } from "./workflow_execution_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for CreateWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateWorkflowRequest
 */
export type CreateWorkflowRequest = {
/**
 * Required. The resource name of the workspace to create Workflow in.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The ID to use for the workflow, which will become the final component of the runnable resource name.
 *
 * @generated from field: string workflow_id = 2;
 */
workflowId?: string;
/**
 * Required. The Workflow resource to create.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Workflow workflow = 3;
 */
workflow: Workflow;
}
;
/**
 * Response message for CreateWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateWorkflowResponse
 */
export type CreateWorkflowResponse = {
/**
 * The created Workflow.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Workflow workflow = 1;
 */
workflow?: Workflow;
}
;
/**
 * Request message for UpdateWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateWorkflowRequest
 */
export type UpdateWorkflowRequest = {
/**
 * Required. Workflow to update.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Workflow workflow = 1;
 */
workflow: Workflow;
/**
 * Required. The list of fields to update.
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateWorkflowResponse
 */
export type UpdateWorkflowResponse = {
/**
 * Updated workflow.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Workflow workflow = 1;
 */
workflow?: Workflow;
}
;
/**
 * Request message for GetWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowRequest
 */
export type GetWorkflowRequest = {
/**
 * Required. Workflow resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowResponse
 */
export type GetWorkflowResponse = {
/**
 * The requested workflow.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Workflow workflow = 1;
 */
workflow?: Workflow;
}
;
/**
 * Request message for ListWorkflows
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowsRequest
 */
export type ListWorkflowsRequest = {
/**
 * Required. The resource name of the workspace to list runnables from.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return runnable executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * display_name
 *   * create_time
 *
 * Examples:
 * display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z
 * create_time >= 2023-12-30T23:59:43Z AND create_time <= 2024-03-20T08:00:00Z
 * NOT(display_name = 'Some display name' OR create_time <= 2023-12-30T23:59:43Z)
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workflow executions ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListWorkflows
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowsResponse
 */
export type ListWorkflowsResponse = {
/**
 * Collection of workflows.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.Workflow workflows = 1;
 */
workflows?: Workflow[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for DeleteWorkflow
 * (-- api-linter: core::0135::force-field=disabled
 *     aip.dev/not-precedent: Executions will be always deleted, there is no point to move that on API level. --)
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowRequest
 */
export type DeleteWorkflowRequest = {
/**
 * Required. Workflow resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowResponse
 */
export type DeleteWorkflowResponse = {
}
;
/**
 * Request message for ExecuteWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.ExecuteWorkflowRequest
 */
export type ExecuteWorkflowRequest = {
/**
 * Required. Workflow resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * Parameters which will be passed to workflow execution. Override parameters defined on workflow.
 *
 * @generated from field: map<string, string> parameters = 2;
 */
parameters?: { [key: string]: string };
}
;
/**
 * Response message for ExecuteWorkflow
 *
 * @generated from message ai.h2o.orchestrator.v1.ExecuteWorkflowResponse
 */
export type ExecuteWorkflowResponse = {
/**
 * Started execution of workflow.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution workflow_execution = 1;
 */
workflowExecution?: WorkflowExecution;
}
;
/**
 * Create a Workflow
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.CreateWorkflow
 */
export const WorkflowService_CreateWorkflow = new RPC<CreateWorkflowRequest, CreateWorkflowResponse>("POST", "/v1/{parent=workspaces/*}/workflows", "workflow");
/**
 * Updates a Workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.UpdateWorkflow
 */
export const WorkflowService_UpdateWorkflow = new RPC<UpdateWorkflowRequest, UpdateWorkflowResponse>("PATCH", "/v1/{workflow.name=workspaces/*/workflows/*}", "workflow");
/**
 * Deletes a Workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.DeleteWorkflow
 */
export const WorkflowService_DeleteWorkflow = new RPC<DeleteWorkflowRequest, DeleteWorkflowResponse>("DELETE", "/v1/{name=workspaces/*/workflows/*}");
/**
 * Returns a specific Workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.GetWorkflow
 */
export const WorkflowService_GetWorkflow = new RPC<GetWorkflowRequest, GetWorkflowResponse>("GET", "/v1/{name=workspaces/*/workflows/*}");
/**
 * Returns a collection of WorkflowExecution within a workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.ListWorkflows
 */
export const WorkflowService_ListWorkflows = new RPC<ListWorkflowsRequest, ListWorkflowsResponse>("GET", "/v1/{parent=workspaces/*}/workflows");
/**
 * Start execution of workflow
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowService.ExecuteWorkflow
 */
export const WorkflowService_ExecuteWorkflow = new RPC<ExecuteWorkflowRequest, ExecuteWorkflowResponse>("POST", "/v1/{name=workspaces/*/workflows/*}:execute");
