import { IStyle, Icon } from '@fluentui/react';
import { Button, IH2OTheme, buttonStylesGhost, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';

type FailedToLoadViewProps = {
  title: string;
  description: string;
  actionTitle: string;
  onActionClick: () => void;
  actionIcon: string;
};

interface IFailedToLoadViewStyles {
  wrapper: IStyle;
  contentContainer: IStyle;
  description: IStyle;
  button: IStyle;
}

const failedToLoadViewStyles = (theme: IH2OTheme): Partial<IFailedToLoadViewStyles> => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    minHeight: 300,
  },
  contentContainer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  description: { color: theme.semanticColors?.textSecondary },
  button: {
    marginTop: 10,
  },
});

export const FailedToLoadView = ({
  title,
  description,
  actionTitle,
  onActionClick,
  actionIcon,
}: FailedToLoadViewProps) => {
  const theme = useTheme();
  const classNames = useClassNames<IFailedToLoadViewStyles, ClassNamesFromIStyles<IFailedToLoadViewStyles>>(
    'failedToLoadView',
    failedToLoadViewStyles(theme)
  );

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.contentContainer}>
        <Icon
          iconName="Error"
          styles={{ root: { fontSize: 100, color: theme.semanticColors?.inputErrorMessageText } }}
        />
        <h2>{title}</h2>
        <p className={classNames.description}>{description}</p>
        <Button
          onClick={onActionClick}
          iconProps={{ iconName: actionIcon }}
          text={actionTitle}
          className={classNames.button}
          styles={buttonStylesGhost}
        />
      </div>
    </div>
  );
};
