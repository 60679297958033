import { PanelType } from '@fluentui/react';
import { Button, IPanelProps, Panel, buttonStylesPrimary } from '@h2oai/ui-kit';
import { useCallback } from 'react';

export interface IManagedListConfigPanelProps extends IPanelProps {
  buttonTitleCreate?: string;
  buttonTitleEdit?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  isEditable?: boolean;
  onDone?: () => void;
  titleCreate?: string;
  titleEdit?: string;
}

export function ManagedListConfigPanel(props: IManagedListConfigPanelProps) {
  const {
      buttonTitleCreate,
      buttonTitleEdit,
      children,
      isDisabled: isDisable,
      isEditable,
      onDone,
      titleCreate,
      titleEdit,
      ..._hProps
    } = props,
    { type = PanelType.custom, customWidth = '800px', isOpen, onDismiss } = _hProps,
    onRenderFooterContent = useCallback(() => {
      return (
        <>
          <Button
            styles={buttonStylesPrimary}
            disabled={isDisable}
            text={isEditable ? buttonTitleEdit || 'Ok' : buttonTitleCreate || 'Create'}
            onClick={onDone}
          />
          <Button
            text={'Back'}
            onClick={() => {
              if (onDismiss) {
                onDismiss();
              }
            }}
          />
        </>
      );
    }, [isOpen, isDisable, onDone]);

  return (
    <Panel
      {..._hProps}
      styles={{
        footerInner: {
          display: `flex`,
          flexFlow: `row-reverse`,
          gap: `0.5rem`,
          justifyContent: `space-between`,
        },
      }}
      isOpen={isOpen}
      type={type}
      customWidth={customWidth}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      headerText={isEditable ? titleEdit || 'Edit' : titleCreate || 'Create'}
      onRenderFooterContent={onRenderFooterContent}
    >
      {children}
    </Panel>
  );
}
