import { Item, TooltipHost, itemStylesCategory, useTheme } from '@h2oai/ui-kit';

import { Engine } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { bigIntStringToNumber, toBigIntString } from '../../../../aiem/gen/runtime';
import { LowStorageWarningThreshold } from '../../constants';

export function EngineLowStorageBadge(props: { engine?: Engine }) {
  const theme = useTheme();
  const { engine } = props;
  if (!engine?.freeDiskSizeBytes || !engine.totalDiskSizeBytes) {
    return null;
  }
  const percentage =
    bigIntStringToNumber(toBigIntString(engine.freeDiskSizeBytes!)) /
    bigIntStringToNumber(toBigIntString(engine.totalDiskSizeBytes));
  return percentage < LowStorageWarningThreshold ? (
    <TooltipHost
      content={
        'The available storage is nearly exhausted and your experiments may fail. Increase the storage or contact your administrator.'
      }
    >
      <Item
        styles={[
          itemStylesCategory,
          {
            root: {
              background: theme.semanticColors?.buttonDanger,
              color: theme.semanticColors?.buttonSecondaryText,
            },
          },
        ]}
        labelField="label"
        data={{
          label: `${Math.round(percentage * 100)}% disk space remaining`,
          icon: 'WarningSolid',
          iconStyles: { color: theme.semanticColors?.buttonSecondaryText },
        }}
        iconNameField={'icon'}
        iconStyleField="iconStyles"
      />
    </TooltipHost>
  ) : null;
}
