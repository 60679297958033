import { MessageBarType } from '@fluentui/react';
import { ConfirmDialog, IconButton, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListExecutorPoolsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import { Runnable } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_pb';
import { ListRunnablesResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/runnable_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ENDPOINTS } from './apiEndpoints';
import { FailedToLoadView } from './FailedToLoadView';
import Header from './Header';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import { useRoles } from './RoleProvider';
import { CODE_TYPE_DISPLAY_NAMES, LANGUAGE_DISPLAY_NAMES } from './RunnableDetail';
import WidgetList from './WidgetList';
import { ContextMenuIconButton, formatError } from './Workflows';
import { RowHeaderTitle } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

type RunnableItem = Runnable & {
  executorPoolDisplayName: string;
  programmingLanguage: string;
  codeType: string;
  createTimeLocal?: string;
  viewOnly?: boolean;
  onClickDelete: () => void;
  onClickEdit: () => void;
};

type KeyNameMappings = { [key: string]: string };

type WidgetIconButtonProps = {
  iconName: string;
  onClick: () => void;
  iconColor?: string;
  backgroundColor?: string;
  title?: string;
  hasBorder?: boolean;
};

export const WidgetIconButton = ({
  iconName,
  onClick,
  iconColor,
  backgroundColor,
  title,
  hasBorder = true,
}: WidgetIconButtonProps) => (
  <IconButton
    title={title || iconName}
    onClick={onClick}
    styles={{
      root: {
        width: 30,
        height: 30,
        marginLeft: 3,
        marginRight: 3,
        backgroundColor: backgroundColor,
        border: hasBorder ? undefined : 'none',
      },
      rootHovered: {
        backgroundColor: backgroundColor,
      },
    }}
    iconProps={{
      iconName: iconName,
      styles: {
        root: {
          fontSize: 18,
          color: iconColor,
        },
      },
    }}
  />
);

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 360,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickEdit }: RunnableItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickEdit }),
        emptyMessage: 'No description',
        iconProps: {
          iconName: 'Rocket',
        },
      },
    },
  },
  {
    key: 'executorPool',
    name: 'Executor pool',
    fieldName: 'executorPoolDisplayName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'creator',
    name: 'Creator',
    fieldName: 'creatorDisplayName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'createTime',
    name: 'Created at',
    fieldName: 'createTimeLocal',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'programmingLanguage',
    name: 'Language',
    fieldName: 'programmingLanguage',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'codeType',
    name: 'Code type',
    fieldName: 'codeType',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 140,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No description',
        onRenderText: ({ onClickDelete, onClickEdit, viewOnly }: RunnableItem) => (
          // TODO: Show View/Edit button on permissions base.
          <ContextMenuIconButton
            items={[
              {
                key: viewOnly ? 'view' : 'edit',
                text: viewOnly ? 'View' : 'Edit',
                onClick: onClickEdit,
                iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onClickDelete,
                style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Runnables = () => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false),
    [dialogAction, setDialogAction] = React.useState<() => unknown>(),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [runnableItems, setRunnableItems] = React.useState<RunnableItem[]>(),
    [poolKeysToNames, setPoolKeysToNames] = React.useState<KeyNameMappings>({}),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchExecutionPools: false,
      fetchRunnables: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchExecutionPools && !loadStateRef.current.fetchRunnables) {
        setLoading(false);
      }
    },
    closeDeleteDialog = () => setIsDeleteDialogOpen(false),
    onEditRunnable = (r: Runnable) => history.push(`/orchestrator/${r.name}`),
    onDeleteRunnable = (id: string) => {
      setDialogAction(() => () => {
        void deleteRunnable(id);
        closeDeleteDialog();
      });
      setIsDeleteDialogOpen(true);
    },
    onCreateButtonClick = () => history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}${ENDPOINTS.RUNNABLES}/create-new`),
    fetchExecutionPools = React.useCallback(async () => {
      loadStateRef.current.fetchExecutionPools = true;
      setLoading(true);
      try {
        const data: ListExecutorPoolsResponse = await orchestratorService.getExecutorPools({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setPoolKeysToNames(
          (data.executorPools || []).reduce((acc, pool) => {
            if (pool.name) acc[pool.name] = pool.displayName || pool.name;
            return acc;
          }, {} as KeyNameMappings)
        );
      } catch (err) {
        const message = `Failed to fetch execution pools: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchExecutionPools = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService]),
    fetchRunnables = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (pageToken) {
          setIsLoadingMore(true);
        } else if (filter || filter === `display_name = ""`) {
          setIsLoadingSearch(true);
        } else {
          loadStateRef.current.fetchRunnables = true;
          setLoading(true);
        }
        try {
          const data: ListRunnablesResponse = await orchestratorService.getRunnables({
            parent: ACTIVE_WORKSPACE_NAME || '',
            pageSize: 20,
            pageToken,
            orderBy: 'create_time desc',
            filter: filter === `display_name = ""` ? undefined : filter,
          });
          const newRunnableItems: RunnableItem[] | undefined = data?.runnables
            ? data.runnables.map((r) => ({
                ...r,
                programmingLanguage: r.code?.programmingLanguage
                  ? LANGUAGE_DISPLAY_NAMES[r.code.programmingLanguage]
                  : '',
                codeType: r.code?.type ? CODE_TYPE_DISPLAY_NAMES[r.code.type] : '',
                createTimeLocal: r.createTime ? new Date(r.createTime).toLocaleString() : undefined,
                executorPoolDisplayName: r.executorPool ? poolKeysToNames[r.executorPool] || r.executorPool : '',
                onClickDelete: () => onDeleteRunnable(r.name || ''),
                onClickEdit: () => onEditRunnable(r),
                viewOnly: !permissions.canEditRunnables,
              }))
            : undefined;
          if (data && !newRunnableItems) console.error('No runnables found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setRunnableItems((items) => (pageToken ? [...(items || []), ...(newRunnableItems || [])] : newRunnableItems));
        } catch (err) {
          const message = `Failed to fetch runnables: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.fetchRunnables = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [ACTIVE_WORKSPACE_NAME, orchestratorService, poolKeysToNames, permissions]
    ),
    deleteRunnable = React.useCallback(
      async (id: string) => {
        try {
          await orchestratorService.deleteRunnable({ name: id });
          void fetchRunnables();
        } catch (err) {
          const message = `Failed to delete runnable: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [orchestratorService, fetchRunnables]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) void fetchExecutionPools();
    // TODO: Cleanup running requests on unmount.
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) void fetchRunnables();
    // TODO: Cleanup running requests on unmount.
  }, [ACTIVE_WORKSPACE_NAME, fetchRunnables]);

  React.useEffect(() => {
    setRunnableItems((items) =>
      items?.[0]?.viewOnly === !permissions.canEditRunnables
        ? items
        : items?.map((item) => ({
            ...item,
            viewOnly: !permissions.canEditRunnables,
          }))
    );
  }, [permissions]);

  return (
    <NavigationWrapper>
      {runnableItems?.length || isSearchStr || loading ? <Header /> : null}
      <ConfirmDialog
        title="Delete Runnable"
        hidden={!isDeleteDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        msg="Are you sure you want to delete this runnable? Once it is deleted it cannot be restored."
        confirmationButtonText="Delete"
        dismissalButtonText="Cancel"
        // TODO: Add danger button style.
      />
      <WidgetList
        columns={columns}
        items={runnableItems}
        loading={loading}
        isLoadingSearch={isLoadingSearch}
        isLoadingMore={isLoadingMore}
        onLoadMore={nextPageToken ? () => void fetchRunnables(nextPageToken) : undefined}
        searchProps={{
          placeholder: 'Search runnables',
          onSearchChange: (value) => {
            setIsSearchStr(!!value);
            void fetchRunnables(undefined, `display_name = '${value}'`);
          },
        }}
        actionProps={
          permissions.canEditRunnables
            ? {
                actionIcon: 'Add',
                actionTitle: 'Create runnable',
                onActionClick: onCreateButtonClick,
              }
            : undefined
        }
        NoItemsContent={NoItemView({
          title: 'Runnables',
          description: `There are no runnables created in this workspace. ${
            permissions.canEditRunnables ? 'Create the first one.' : ''
          }`,
          actionTitle: permissions.canEditRunnables ? 'Create runnable' : undefined,
          onActionClick: onCreateButtonClick,
          actionIcon: 'Add',
          backgroundImage: `url(${require('./assets/rocket.png')})`,
          isBackgroundPattern: true,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load runnables',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchRunnables,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default Runnables;
