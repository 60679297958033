import { ITextStyles, Stack, Text } from '@fluentui/react';
import { getDate, useTheme } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { App } from '../../../../ai.h2o.cloud.appstore';
import { appVisibilityMap } from '../../../../utils/utils';
import * as styles from './additionalInfo.styles';

type AdditionalInfoProps = {
  app: App;
};

const tokens = { childrenGap: 10 };

const generateTime = (date?: Date) => date && `${date.toDateString()} | ${date.toLocaleTimeString()}`;

export function AdditionalInfo({ app }: AdditionalInfoProps) {
  const { owner, visibility } = app;
  const theme = useTheme();
  const createTime = useMemo(() => generateTime(getDate(app.createTime)), [app.createTime]);
  const updateTime = useMemo(() => generateTime(getDate(app.updateTime)), [app.updateTime]);

  const componentStyles: ITextStyles = styles.additionalInfoStyles(theme);
  const containerStyles: ITextStyles = styles.additionalInfoContainerStyles(theme);
  const labelStyles: ITextStyles = styles.additionalInfoLabelStyles(theme);
  const contentStyles: ITextStyles = styles.additionalInfoContentStyles(theme);

  return (
    <Stack className="AdditionalInfo" horizontalAlign="start" tokens={tokens} styles={componentStyles}>
      <Stack horizontalAlign="space-between" className="AdditionalInfoContentWrapper">
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Owner
          </Text>
          <Text variant="small" styles={contentStyles} title={owner}>
            {owner}
          </Text>
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Created At
          </Text>
          {createTime ? (
            <Text variant="small" styles={contentStyles} title={createTime}>
              {createTime}
            </Text>
          ) : (
            <Text variant="small" styles={contentStyles}>
              {app.createTime}
            </Text>
          )}
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Last Updated At
          </Text>
          {updateTime ? (
            <Text variant="small" styles={contentStyles} title={updateTime}>
              {updateTime}
            </Text>
          ) : (
            <Text variant="small" styles={contentStyles} title={app.updateTime}>
              {app.updateTime}
            </Text>
          )}
        </Stack>
        <Stack horizontal styles={containerStyles}>
          <Text variant="small" styles={labelStyles}>
            Visibility
          </Text>
          <Text variant="small" styles={contentStyles}>
            {appVisibilityMap[visibility]}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
