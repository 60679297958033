import { FontSizes } from '@h2oai/ui-kit';
import { ReactNode } from 'react';

export const textCellStyles = { display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' };

export interface CellProps {
  alignRight?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export default function Cell(props: CellProps) {
  const { style = {}, className } = props;
  const cellStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: props.alignRight ? 'flex-end' : 'flex-start',
    fontSize: FontSizes.textPrimary,
    ...style,
  };
  return (
    <div style={cellStyles} className={className}>
      {props.children}
    </div>
  );
}
