export const WORKFLOW = {
  LABEL_WIDTH: 188,
  LABEL_HEIGHT: 46,
  LABEL_PADDING_HORIZONTAL: 4,
  LABEL_PADDING_VERTICAL: 2,
  NODE_PADDING_HORIZONTAL: 15,
  NODE_PADDING_VERTICAL: 10,
};

// Remove once the support is added. This is to prevent commenting out/removing the code needed for the fuåture.
export const WORKFLOW_PARAMS_SUPPORTED = false;
