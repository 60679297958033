// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/default_h2o_setup_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { DefaultH2OSetup } from "./default_h2o_setup_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for getting the DefaultH2OSetup.
 *
 * @generated from message ai.h2o.engine.v1.GetDefaultH2OSetupRequest
 */
export type GetDefaultH2OSetupRequest = {
/**
 * DefaultH2OSetup resource name.
 * Format: defaultH2OSetup
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetDefaultH2OSetup.
 *
 * @generated from message ai.h2o.engine.v1.GetDefaultH2OSetupResponse
 */
export type GetDefaultH2OSetupResponse = {
/**
 * H2OSetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultH2OSetup default_h2o_setup = 1;
 */
defaultH2oSetup?: DefaultH2OSetup;
}
;
/**
 * Request message for updating the DefaultH2OSetup.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDefaultH2OSetupRequest
 */
export type UpdateDefaultH2OSetupRequest = {
/**
 * DefaultH2OSetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultH2OSetup default_h2o_setup = 1;
 */
defaultH2oSetup: DefaultH2OSetup;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {node_count_constraint, cpu_constraint, gpu_constraint, memory_bytes_constraint,
 * max_idle_duration_constraint, max_running_duration_constraint, yaml_pod_template_spec, yaml_gpu_tolerations}
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response for UpdateDefaultH2OSetup.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDefaultH2OSetupResponse
 */
export type UpdateDefaultH2OSetupResponse = {
/**
 * DefaultH2OSetup resource.
 *
 * @generated from field: ai.h2o.engine.v1.DefaultH2OSetup default_h2o_setup = 1;
 */
defaultH2oSetup?: DefaultH2OSetup;
}
;
/**
 * Returns the DefaultH2OSetup.
 *
 * @generated from rpc ai.h2o.engine.v1.DefaultH2OSetupService.GetDefaultH2OSetup
 */
export const DefaultH2OSetupService_GetDefaultH2OSetup = new RPC<GetDefaultH2OSetupRequest, GetDefaultH2OSetupResponse>("GET", "/v1/{name=defaultH2OSetup}");
/**
 * Updates the DefaultH2OSetup.
 *
 * @generated from rpc ai.h2o.engine.v1.DefaultH2OSetupService.UpdateDefaultH2OSetup
 */
export const DefaultH2OSetupService_UpdateDefaultH2OSetup = new RPC<UpdateDefaultH2OSetupRequest, UpdateDefaultH2OSetupResponse>("PATCH", "/v1/{defaultH2oSetup.name=defaultH2OSetup}", "defaultH2oSetup");
