import { DirectionalHint, ITextStyles, ITooltipHostStyles, Stack, Text, TooltipHost } from '@fluentui/react';
import {
  Button,
  DatePicker,
  FontSizes,
  PageHeader,
  buttonStylesGhost,
  buttonStylesSecondary,
  buttonStylesSmall,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Granularity, Usage } from '../../telemetry/gen/ai/h2o/cloud/telemetry_read_api/v1/aiunits_pb';
import { useTelemetryService } from '../../telemetry/hooks';
import { stackStylesNoLeftNav } from '../../themes/themes';
import { useEnv } from '../../utils/hooks';
import { TimeSeries, UsageDatum, barGradient, getDaysAgo } from './components/TimeSeries';
import { getValidGranularityOptions, granularityOptions } from './constants/granularity';

const pageTitle = 'Peak AI Unit Consumption';
const subtitle = 'Understand the maximum number of concurrently used AI Units over time';
const dateInputWidth = 180;

const defaultStartTime = getDaysAgo(30);
const defaultEndTime = getDaysAgo(0);

function PlatformUsage() {
  const theme = useTheme(),
    TelemetryService = useTelemetryService(),
    [startTime, setStartTime] = useState<Date>(defaultStartTime), // one week ago
    [endTime, setEndTime] = useState<Date>(defaultEndTime),
    [granularity, setGranularity] = useState<Granularity>(Granularity.DAY),
    [usageDataLoading, setUsageDataLoading] = useState(true),
    [usageData, setUsageData] = useState<Usage[] | undefined>(undefined),
    [fetchError, setFetchError] = useState<Error | undefined>(undefined),
    [formattedUsageData, setFormattedUsageData] = useState<UsageDatum[] | undefined>(undefined),
    lastDataRequestTime = useRef<number>(),
    tooltipTextStyles: ITextStyles = { root: { color: theme.semanticColors?.tooltipText, fontSize: FontSizes.xsmall } },
    tooltipHostStyles: ITooltipHostStyles = {
      root: {
        display: 'inline',
      },
    },
    onChangeStartDate = useCallback(
      (newStartTime: Date | null | undefined) => {
        if (newStartTime) {
          setStartTime(newStartTime);
          const validGranularityOptions = getValidGranularityOptions(newStartTime, endTime);
          if (!validGranularityOptions.find((option) => option.key === granularity)) {
            setGranularity(validGranularityOptions[0].key);
          }
        }
      },
      [granularity, endTime, setStartTime]
    ),
    onChangeEndDate = useCallback(
      (newEndTime: Date | null | undefined) => {
        if (newEndTime) {
          setEndTime(newEndTime);
          const validGranularityOptions = getValidGranularityOptions(startTime, newEndTime);
          if (!validGranularityOptions.find((option) => option.key === granularity)) {
            setGranularity(validGranularityOptions[0].key);
          }
        }
      },
      [granularity, startTime, setEndTime]
    ),
    onChangeGranularity = useCallback(
      (granularity) => () => {
        setGranularity(granularity);
      },
      [setGranularity]
    ),
    env = useEnv();

  useHaicPageTitle(pageTitle, env?.cloudInstanceName);

  useEffect(() => {
    async function fetchUsageData() {
      setUsageDataLoading(true);
      const dataRequestTime = Date.now();
      lastDataRequestTime.current = dataRequestTime;

      try {
        const response = await TelemetryService.calculateUsage({
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          granularity: granularity,
          component: 'components/global',
        });

        /* If another data request has been dispatched since this one began, don't
           update the chart. Doing so causes the wrong data to be displayed. */
        if (dataRequestTime === lastDataRequestTime.current) {
          setUsageData(response.usage);
        }
      } catch (error) {
        setFetchError(error as Error);
      }
      setUsageDataLoading(false);
    }

    fetchUsageData();
  }, [granularity, startTime, endTime, lastDataRequestTime]);

  useEffect(() => {
    if (!usageData) return;
    const currentGranularity = granularityOptions.find((option) => option.key === granularity);
    setFormattedUsageData(
      usageData.map((datum) => ({
        value: datum?.milliAiUnits ? parseInt(datum.milliAiUnits) * 0.001 : NaN,
        time: currentGranularity?.formatDate(new Date(datum?.interval?.startTime)) || '',
      }))
    );
  }, [granularity, usageData, setFormattedUsageData]);

  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item disableShrink>
          <PageHeader pageTitle={pageTitle} description={subtitle} />
        </Stack.Item>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }} styles={{ root: {} }}>
          <DatePicker
            ariaLabel="Change start date"
            styles={{ root: { width: dateInputWidth } }}
            label="Start Date"
            onSelectDate={onChangeStartDate}
            value={startTime}
            maxDate={getDaysAgo(1)}
          />
          <DatePicker
            ariaLabel="Change end date"
            data-test="platform-usage-change-end-date"
            styles={{ root: { width: dateInputWidth } }}
            label="End Date"
            onSelectDate={onChangeEndDate}
            value={endTime}
            maxDate={getDaysAgo(0)}
          />
        </Stack>
      </Stack>

      <div style={{ height: 380, background: 'white', padding: '16px 24px 72px 24px', marginTop: 16, borderRadius: 4 }}>
        <Stack horizontal horizontalAlign="end">
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            tokens={{ childrenGap: 4 }}
            style={{ marginBottom: 8 }}
          >
            {granularityOptions.map((option) => {
              const intervalTooLong = option.intervalTooLong(startTime, endTime);
              const intervalTooShort = option.intervalTooShort(startTime, endTime);
              const tooltipText = intervalTooLong
                ? `This option is disabled because the selected time range is too long. Choose a shorter time range to view data at the ${option.label.toLowerCase()} frequency.`
                : `This option is disabled because the selected time range is too short. Choose a longer time range to view data at the ${option.label.toLowerCase()} frequency.`;
              const validOption = !(intervalTooLong || intervalTooShort);
              return validOption ? (
                <Button
                  ariaLabel={`Switch frequency to ${option.label}`}
                  data-test={`platform-usage-frequency-${option.label.toLowerCase()}-button`}
                  size={2}
                  key={option.key}
                  onClick={onChangeGranularity(option.key)}
                  styles={
                    option.key === granularity
                      ? [buttonStylesSecondary, buttonStylesSmall]
                      : [buttonStylesGhost, buttonStylesSmall]
                  }
                >
                  {option.label}
                </Button>
              ) : (
                <TooltipHost
                  key={option.key}
                  styles={tooltipHostStyles}
                  calloutProps={{
                    styles: {
                      beakCurtain: { backgroundColor: theme.semanticColors?.tooltipBackground },
                      beak: { backgroundColor: theme.semanticColors?.tooltipBackground },
                    },
                  }}
                  tooltipProps={{
                    directionalHint: DirectionalHint.bottomCenter,
                    styles: {
                      content: { backgroundColor: theme.semanticColors?.tooltipBackground, paddingLeft: 10 },
                    },
                    onRenderContent: () => (
                      <Stack>
                        <Text styles={tooltipTextStyles}>{tooltipText}</Text>
                      </Stack>
                    ),
                  }}
                >
                  <Button
                    ariaLabel={`Switch frequency to ${option.label}`}
                    data-test={`platform-usage-frequency-${option.label.toLowerCase()}-button`}
                    disabled
                    size={2}
                    onClick={onChangeGranularity(option.key)}
                    styles={[buttonStylesGhost, buttonStylesSmall]}
                  >
                    {option.label}
                  </Button>
                </TooltipHost>
              );
            })}
          </Stack>
        </Stack>
        {fetchError ? (
          <Text as="p" variant="large" styles={{ root: { textAlign: 'center', marginTop: 40, display: 'block' } }}>
            An error occurred while attempting to fetch platform usage data.
          </Text>
        ) : (
          <TimeSeries
            loadingMessage={usageDataLoading ? 'Calculating usage...' : undefined}
            getTooltipMessage={(datum) => `${datum.value.toLocaleString()} AI Units`}
            gradient={barGradient(theme)}
            axisProps={{
              data: formattedUsageData || [],
              hasAxis: true,
              hasGrid: true,
              labelField: 'time',
              fields: ['value'],
              min: 0,
              hasTooltip: true,
              hasAnimation: true,
              responsive: true,
            }}
          />
        )}
      </div>
    </Stack>
  );
}

export default PlatformUsage;
