import {
  DetailsHeader,
  DetailsRow,
  IDetailsHeaderStyles,
  IDetailsRowProps,
  IDragDropContext,
  IDragDropEvents,
  SelectionMode,
  Stack,
  concatStyleSets,
} from '@fluentui/react';
import {
  Checkbox,
  DetailsList,
  IDetailsListProps,
  IH2OTheme,
  Loader,
  LoaderType,
  detailsListStyles,
  loaderStylesProgressIndicatorDefault,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

const loaderHeight = 3;

interface StyledDetailsRowProps extends IDetailsRowProps {
  loading?: boolean;
  deleting?: boolean;
}

export type ReorderItem<T> = {
  item: T;
  newOrder: number;
};

function StyledDetailsRow(props: StyledDetailsRowProps) {
  const { loading, deleting, styles = {}, ...rest } = props;
  const theme = useTheme(),
    showLoader = loading || deleting,
    detailsRowStyles = {
      root: {
        marginBottom: 8,
        border: `1px solid ${theme.palette?.gray300}`,
        pointerEvents: showLoader ? 'none' : 'unset',
        userSelect: showLoader ? 'none' : 'unset',
        '&.dragEnter': {
          marginTop: '15px',
        },
        '&.dragEnter:before': {
          position: 'absolute',
          top: '-15px',
          left: '0',
          width: '100%',
          content: "''",
          display: 'block',
          height: '2px',
          backgroundColor: theme.semanticColors?.buttonBorder,
        },
      },
      cell: {
        alignSelf: 'start',
        paddingTop: 16,
        paddingBottom: 16,
        height: '100%',
      },
    },
    barColor = loading ? theme.semanticColors?.loaderBarIndicator : theme.semanticColors?.loaderBarIndicatorError,
    loaderStyles = {
      root: { position: 'absolute', width: '100%', bottom: '8px' },
      itemProgress: { height: loaderHeight },
      progressBar: {
        height: loaderHeight,
        background: `linear-gradient(to right, ${theme?.palette?.white} 0%, ${barColor} 50%, ${theme?.palette?.white} 100%)`,
      },
      progressTrack: { height: loaderHeight, background: 'transparent' },
    };

  return (
    <div style={{ position: 'relative' }}>
      <DetailsRow disabled={loading || deleting} {...rest} styles={concatStyleSets(detailsRowStyles, styles)} />
      {showLoader && (
        <Loader
          type={LoaderType.progressIndicator}
          styles={concatStyleSets(loaderStylesProgressIndicatorDefault, loaderStyles)}
        />
      )}
    </div>
  );
}

// items with truthy `deleting` or `loading` properties will be styled accordingly
export interface ListProps<T> extends IDetailsListProps {
  dataTest: string;
  noHeader?: boolean;
  onClickSelectAll?: (e?: React.FormEvent, checked?: boolean) => void;
  selectAllButtonChecked?: boolean;
  showSelectAllButton?: boolean;
  items: T[];
  onReorderItem?: (updatedItem: ReorderItem<T>) => any;
  canBeReordered?: boolean;
  orderField?: string;
  sortFields?: (keyof T)[];
  headerStyles?: (theme: IH2OTheme) => Partial<IDetailsHeaderStyles>;
}

function List<T>(props: ListProps<T>) {
  const {
    dataTest,
    items,
    noHeader,
    onClickSelectAll,
    selectAllButtonChecked,
    showSelectAllButton = false,
    onReorderItem,
    canBeReordered,
    orderField = 'order',
    headerStyles,
    sortFields,
    ...rest
  } = props;

  const [draggedItem, setDraggedItem] = useState<T | undefined>();
  const [renderItems, setRenderItems] = useState<T[]>([]);

  const insertBeforeItem = (item: T) => {
    const insertIndex = renderItems.indexOf(item);
    const _items = renderItems.filter((itm) => itm !== draggedItem);

    _items.splice(insertIndex, 0, draggedItem!);
    setRenderItems(_items);
  };

  const dragDropEvents: IDragDropEvents = {
    canDrop: (_dropContext?: IDragDropContext, _dragContext?: IDragDropContext) => {
      return !!canBeReordered;
    },
    canDrag: (_item?: any) => {
      return !!canBeReordered;
    },
    onDragEnter: (_item?: any, _event?: DragEvent) => {
      // return string is the css classes that will be added to the entering element.
      return 'dragEnter';
    },
    onDragLeave: (_item?: any, _event?: DragEvent) => {
      return;
    },
    onDrop: (item?: any, _event?: DragEvent) => {
      if (draggedItem) {
        insertBeforeItem(item);
      }
      // NB: this logic assumes that the collection being re-ordered contains a numeric field for specifying the order:
      let newOrder = item[orderField];
      if (draggedItem && draggedItem[orderField] < newOrder) {
        newOrder--;
      }
      if (onReorderItem && newOrder > 0 && draggedItem) {
        onReorderItem({ item: draggedItem, newOrder });
      }
    },
    onDragStart: (item?: any, _itemIndex?: number, _selectedItems?: any[], _event?: MouseEvent) => {
      setDraggedItem(item);
    },
    onDragEnd: (_item?: any, _event?: DragEvent) => {
      setDraggedItem(undefined);
    },
  };
  const onRenderDetailsHeader = useCallback(
    (headerProps) =>
      showSelectAllButton ? (
        <Checkbox
          checked={selectAllButtonChecked}
          onChange={onClickSelectAll}
          styles={{ root: { margin: '24px 16px 16px 25px' } }}
          label="Select all"
        />
      ) : sortFields ? (
        <DetailsHeader {...headerProps} styles={detailsListStyles} />
      ) : null,
    [showSelectAllButton, selectAllButtonChecked, onClickSelectAll]
  );
  const onRenderRow = useCallback(
    (props?: IDetailsRowProps) => {
      return props ? (
        <StyledDetailsRow
          deleting={props?.item?.deleting}
          loading={props?.item?.editing || props?.item?.loading}
          styles={{ cell: { alignSelf: 'center' } }}
          {...props}
        />
      ) : null;
    },
    [items]
  );

  useEffect(() => {
    setRenderItems(items);
  }, [items]);

  return (
    <Stack
      data-test={dataTest}
      styles={{ root: { width: '100%', position: 'relative', paddingTop: noHeader ? 20 : undefined } }}
    >
      <DetailsList
        selectionMode={SelectionMode.none} // Selection is handled outside of DetailsList
        isHeaderVisible={!noHeader}
        items={renderItems || []}
        onRenderRow={onRenderRow}
        onRenderDetailsHeader={onRenderDetailsHeader}
        dragDropEvents={dragDropEvents}
        headerStyles={headerStyles}
        {...rest}
      />
    </Stack>
  );
}

export default List;
