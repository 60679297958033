import { IButtonStyles, IDropdownOption, IDropdownStyles, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Button,
  Dropdown,
  IH2OTheme,
  IconName,
  LoaderType,
  PageHeader,
  buttonStylesGhost,
  buttonStylesTextWithIconRight,
  dropdownStylesDefault,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import './LoggingServicesPage.css';
import { defaultResourceNamePrefix } from '../../logging-service/defaults';
import { FetchLogNamesRequest } from '../../logging-service/gen/ai/h2o/logging/v1/internal_service.pb';
import { useLogs } from '../../logging-service/hooks';
import { IntervalParams, Intervals, getStartTime, useLogQueryParams } from '../../logging-service/utils';
import { stackStylesLog } from '../../themes/themes';
import { useEnv } from '../../utils/hooks';
import { Error404Page } from '../ErrorPage';
import { ButtonSearchBox } from './components/ButtonSearchBox';
import { InfiniteLog } from './components/InfiniteLog';
import { LogNamesPanel } from './components/LogNamesPanel';

const currentOrPreviousOptions: IDropdownOption[] = [
  { key: Intervals.OneHour, text: 'Last 1 hour' },
  { key: Intervals.ThreeHours, text: 'Last 3 hours' },
  { key: Intervals.SixHours, text: 'Last 6 hours' },
  { key: Intervals.TwelveHours, text: 'Last 12 hours' },
  { key: Intervals.OneDay, text: 'Last 1 day' },
  { key: Intervals.TwoDays, text: 'Last 2 days' },
  { key: Intervals.ThreeDays, text: 'Last 3 days' },
  { key: Intervals.SevenDays, text: 'Last 7 days' },
  { key: Intervals.OneMonth, text: 'Last 1 month' },
];

// TODO: normalize control presentation styles in UI Kit (i.e. we should have buttons, dropdowns and other controls that all have the same styling)
const normalButtonStyles = (theme: IH2OTheme): IButtonStyles => ({
  ...buttonStylesGhost(theme),
  root: {
    color: theme.semanticColors?.inputReadOnlyText,
    backgroundColor: theme.semanticColors?.buttonGhostBackground,
    borderColor: theme.semanticColors?.inputBorder,
  },
  label: {
    fontWeight: 'normal',
  },
});

const normalDropDownStyles = (theme: IH2OTheme): Partial<IDropdownStyles> => ({
  ...dropdownStylesDefault(theme),
  dropdown: {
    '.ms-Dropdown-title': {
      borderColor: theme.semanticColors?.inputBorder,
    },
    '&:hover .ms-Dropdown-title': {
      border: `1px solid ${theme.semanticColors?.buttonGhostBorderHovered}`,
    },
    '&:active .ms-Dropdown-title': {
      border: `1px solid ${theme.semanticColors?.buttonGhostBorderActive}`,
    },
  },
});

function LoggingServicesPage() {
  const pageTitle = 'Logging Services',
    { getParams, setResource, setStartTime, setLogNamesExclude } = useLogQueryParams(),
    {
      resource: resourceParam,
      startTime: startTimeParam,
      searchTerm: searchTermParam,
      regexSearch: regexSearchParam,
      logNamesExclude: logNamesExcludeParam,
    } = getParams(),
    resource = !resourceParam || resourceParam === defaultResourceNamePrefix ? '' : resourceParam,
    { downloadLogs, fetchLogNames: fetchLogNamesService } = useLogs(),
    theme = useTheme(),
    env = useEnv();

  const [searchText, setSearchText] = useState<string>(searchTermParam || ''),
    [regex, setRegex] = useState<boolean>(Boolean(regexSearchParam));

  const [selectedIntervalKey, setSelectedIntervalKey] = useState<Intervals>(Intervals.OneHour),
    startTime = startTimeParam || getStartTime(),
    getDates = (interval: Intervals): IntervalParams => {
      const startTime = getStartTime(interval);
      return {
        startTime,
        endTime: new Date().toISOString(),
      };
    },
    onIntervalChange = useCallback((_e, option) => {
      const interval = String(option?.key) as Intervals;
      setSelectedIntervalKey(interval);
      const { startTime } = getDates(interval);
      setStartTime(startTime);
    }, []);

  const [logNamesExclude, setLocalLogNamesExclude] = useState<string[]>(logNamesExcludeParam || []),
    [logNamesAvailable, setLogNamesAvailable] = useState<string[]>([]),
    [shownLogNamesPanel, { setTrue: showLogNamesPanel, setFalse: hideLogNamesPanel }] = useBoolean(false),
    [logNamesButtonText, setLogNamesButtonText] = useState<string>('Log Names'),
    [loadingLogNames, { setTrue: loadingLogNamesRun, setFalse: loadingLogNamesStop }] = useBoolean(false),
    [noneLogNames, { setTrue: noneLogNamesTrue }] = useBoolean(false);

  const fetchLogNames = useCallback(async (req: FetchLogNamesRequest & { namesExclude?: string[] }) => {
    loadingLogNamesRun();

    try {
      const availableLogNames: string[] =
        (await fetchLogNamesService({
          ...req,
          endTime: new Date().toISOString(),
        })) || [];

      const names = req.namesExclude || logNamesExclude;

      const totalCount = availableLogNames.length,
        excludedCount = names.length;
      let counterText = '';

      if (totalCount > 0 && !excludedCount) {
        counterText = 'All';
      } else if (totalCount > 0 && excludedCount > 0 && excludedCount < totalCount) {
        counterText = `${totalCount - excludedCount}/${totalCount}`;
      } else if (totalCount === excludedCount) {
        counterText = 'None';
        noneLogNamesTrue();
      }

      setLogNamesButtonText(`Log Names: ${counterText}`);
      setLogNamesAvailable(availableLogNames);
      return availableLogNames;
    } catch (error) {
      loadingLogNamesStop();
      return [];
    } finally {
      loadingLogNamesStop();
    }
  }, []);

  const onLogNamesChange = useCallback(async (namesExclude: string[]) => {
    setLogNamesExclude(namesExclude);
    setLocalLogNamesExclude(namesExclude);
    await fetchLogNames({ resource, startTime, namesExclude });
    hideLogNamesPanel();
  }, []);

  const [downloading, setDownloading] = useState<boolean>(false),
    onDownload = async () => {
      setDownloading(true);
      const data = await downloadLogs({
        resource,
        startTime,
        endTime: new Date().toISOString(),
        search: searchText,
      });
      if (data) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(data);
        a.download = `h2o-log-data--${startTime}-${new Date().toISOString()}.zip`;
        a.type = 'application/zip';
        a.click();
        URL.revokeObjectURL(a.href); // revoke the data, avoid memory leak, clean up cache
      }
      setDownloading(false);
    };

  const { startTime: intervalStartTime, endTime: intervalEndTime } = useMemo(
    () => getDates(selectedIntervalKey),
    [selectedIntervalKey]
  );

  const onLogNamesClick = useCallback(async () => {
    await fetchLogNames({ resource, startTime, namesExclude: logNamesExclude });
    showLogNamesPanel();
  }, [logNamesExclude, startTime, logNamesExclude]);

  useHaicPageTitle(pageTitle, env?.cloudInstanceName);

  useEffect(() => {
    setResource(resource || '');
  }, [resource]);

  // manage URL Search Params
  // fetch log names to manage Log Names Button Text
  // and initial log names panel load
  useEffect(() => {
    const fetch = async () => {
      await fetchLogNames({ resource, startTime });
    };
    fetch();
  }, [resource, startTime]);

  return resource ? (
    <Stack styles={stackStylesLog(theme)}>
      <Stack styles={{ root: { paddingLeft: 30 } }}>
        <Stack.Item disableShrink>
          <PageHeader pageTitle={pageTitle} description={`Resource: ${resource}`} />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        wrap
        tokens={{ childrenGap: '12px 0' }}
        styles={{
          root: {
            padding: '20px 0 0 30px',
            marginBottom: '20px',
          },
        }}
      >
        <Stack
          horizontal
          styles={{
            root: {
              width: 920,
              paddingRight: `20px`,
              '@media(max-width: 1550px)': {
                width: 550,
              },
              '@media(max-width: 1180px)': {
                width: 400,
              },
              '@media(max-width: 900px)': {
                width: 350,
              },
            },
          }}
          tokens={{ childrenGap: 10 }}
        >
          <ButtonSearchBox
            value={searchText}
            onSearch={(value, _regex) => {
              setRegex(_regex);
              setSearchText(value);
            }}
            regex={regex}
            invalidCharacters={['`']}
          />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          style={{ borderLeft: `solid 1px ${theme.semanticColors?.inputBorder}`, padding: `0 20px` }}
        >
          <Text styles={{ root: { paddingTop: 6 } }}>Filter By:</Text>
          <Stack styles={{ root: { minWidth: 140 } }}>
            <Dropdown
              options={currentOrPreviousOptions}
              styles={normalDropDownStyles}
              selectedKey={selectedIntervalKey}
              onChange={onIntervalChange}
              preventDismissOnEventTypes={['scroll']}
            />
          </Stack>
          <Button
            styles={normalButtonStyles}
            text={logNamesButtonText}
            onClick={onLogNamesClick}
            disabled={noneLogNames || loadingLogNames}
            loading={loadingLogNames}
            loaderProps={{ type: LoaderType.progressIndicator }}
          />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          style={{ borderLeft: `solid 1px ${theme.semanticColors?.inputBorder}`, padding: `0 20px` }}
        >
          <Button
            styles={[normalButtonStyles, buttonStylesTextWithIconRight]}
            text="Download Logs"
            onClick={onDownload}
            disabled={downloading}
            iconName={IconName.Download}
            loading={downloading}
            loaderProps={{ type: LoaderType.progressIndicator }}
          />
        </Stack>
      </Stack>
      <div data-is-scrollable={true} className="log-container">
        <InfiniteLog
          resource={resource}
          search={searchText}
          searchRegex={regex}
          logNameExclude={logNamesExclude?.length ? logNamesExclude : undefined}
          startTime={intervalStartTime}
          endTime={intervalEndTime}
        />
      </div>
      {shownLogNamesPanel && (
        <LogNamesPanel
          logNamesExclude={logNamesExclude}
          logNamesAvailable={logNamesAvailable}
          onApply={onLogNamesChange}
          onDismiss={hideLogNamesPanel}
        />
      )}
    </Stack>
  ) : (
    <Error404Page />
  );
}

export default LoggingServicesPage;
