import { IH2OTheme } from '@h2oai/ui-kit';

export const badgeLoaderStyles = (theme: IH2OTheme) => {
  return {
    root: {
      position: 'relative' as any,
      backgroundColor: theme.semanticColors?.buttonPrimaryBackground,
      padding: '0 4px',
    },
    label: {
      color: theme.semanticColors?.buttonPrimaryText,
      margin: '0 4px',
      fontSize: theme.defaultFontStyle?.fontSize || '12px',
    },
  };
};
