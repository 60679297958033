import { useEffect } from 'react';

import { AuthzService } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useAuthzService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const authzUrl = cloudPlatformDiscovery?.authzUrl || '';
  useEffect(() => {
    AuthzService.setBasePath(authzUrl);
  }, [authzUrl]);

  return AuthzService;
};
