import { useCallback, useEffect, useRef, useState } from 'react';

import { useEngine } from '../../../../../aiem/engine/hooks';
import { calculatedEngineSizes } from '../../../../../aiem/engine/services';
import { AIEngine, EngineConstraintSet } from '../../../../../aiem/engine/types';
import { H2OEngineSize } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { bytesToGibibytes, gibibytesToBytes } from '../../../../../aiem/utils';
import { H2OEngineProfileOptionKeyType, defaultH2ORawEngineSizeRequest } from '../../../constants';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';
import EngineProfileTableRows from './EngineProfileTableRows';

const fetchEngineSize = async (basePath: string, datasetSize: number) => {
  const method = calculatedEngineSizes[H2OEngineProfileOptionKeyType.raw].calculate;
  const size: H2OEngineSize = await method(basePath, '', { datasetSizeBytes: datasetSize.toString() } as any);
  return size;
};

interface ConfigureCustomEngineSizeProps {
  engine: AIEngine;
  constraintSet?: EngineConstraintSet;
  modifyEngine: any;
}

export default function ConfigureCustomEngineSize({
  engine,
  constraintSet,
  modifyEngine,
}: ConfigureCustomEngineSizeProps) {
  const { basePath } = useEngine(),
    mostRecentRequestTime = useRef<number>(0),
    [loading, setLoading] = useState(false),
    [datasetSize, setDatasetSize] = useState(bytesToGibibytes(defaultH2ORawEngineSizeRequest.datasetSizeBytes));

  const calculateEngineSize = useCallback(
    async (datasetSize) => {
      setLoading(true);
      const thisRequestTime = Date.now();
      mostRecentRequestTime.current = thisRequestTime;
      const size = await fetchEngineSize(basePath, datasetSize);
      if (size && mostRecentRequestTime.current === thisRequestTime) {
        modifyEngine({
          nodeCount: size.nodeCount,
          memoryBytes: size.memoryBytes,
          cpu: constraintSet?.cpu?.default || 1,
          gpu: constraintSet?.gpu?.default || 1,
        });
      }
      setLoading(false);
    },
    [setLoading, basePath, fetchEngineSize, constraintSet]
  );

  const onChangeDatasetSize = useCallback(
    (_, value: string) => {
      setDatasetSize(value);
      calculateEngineSize(gibibytesToBytes(value));
    },
    [calculateEngineSize, setDatasetSize]
  );

  useEffect(() => {
    /* Do not add datasetSize to this dependency array. This effect is supposed 
       to occur only once after the constraints are loaded. */
    if (constraintSet) {
      calculateEngineSize(gibibytesToBytes(datasetSize));
    }
  }, [constraintSet]);

  return (
    <MetadataTable>
      <MetadataTableBody>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Dataset Size</MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={onChangeDatasetSize}
              value={Number(datasetSize) || 0}
              min={1}
              max={100000000}
              tooltip="How large is your dataset?"
              suffix="GiB"
            />
          </MetadataValueCell>
        </MetadataRow>
        <EngineProfileTableRows loading={loading || !constraintSet} engine={engine} />
      </MetadataTableBody>
    </MetadataTable>
  );
}
