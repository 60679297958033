import { MessageBarType, getId } from '@fluentui/react';
import { CardBadge, useTheme, useToast } from '@h2oai/ui-kit';
import type { DebounceSettings, DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  App,
  AppAttribute,
  AppInstance,
  ListAppInstancesRequest,
  ListAppsRequest,
  UpdateAppPreferenceRequest,
} from '../ai.h2o.cloud.appstore';
import { RoutePaths } from '../pages/Routes';
import { AdminAppService, AppService } from '../services/api';
import { HTTPResponse } from '../services/HTTPResponse';
import {
  CloudPlatformDiscoveryContext,
  EnvContext,
  LeftPanelContext,
  NotificationContext,
  UserContext,
} from './contexts';
import { AnyFunction, INotification, NotificationActionType } from './models';
import { InstancePauseResumeOp, handleErrMsg, redirect } from './utils';

export function useDialog(): [boolean, () => void] {
  const [isDialogHidden, setIsDialogHidden] = useState(true),
    toggleDialog = useCallback(() => setIsDialogHidden(!isDialogHidden), [isDialogHidden]);
  return [isDialogHidden, toggleDialog];
}

export function useError(): [HTTPResponse | null, Dispatch<SetStateAction<HTTPResponse | null>>, () => void] {
  const [err, setErr] = useState<HTTPResponse | null>(null),
    dismissErr = useCallback(() => setErr(null), []);
  return [err, setErr, dismissErr];
}

export type QueryParamProps = {
  name: string;
  value: string;
};

export function useQueryParams() {
  const history = useHistory(),
    params = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]),
    clearParams = useCallback(() => {
      history.replace({ search: '' });
    }, [history]),
    clearParam = useCallback(
      (name: string) => {
        const currentSearch = new URLSearchParams(window.location.search);
        currentSearch.delete(name);
        history.replace({ search: currentSearch.toString() });
      },
      [params, history]
    ),
    setParams = useCallback(
      (input: QueryParamProps | QueryParamProps[]) => {
        const currentSearch = new URLSearchParams(window.location.search);
        const source = Array.isArray(input) ? input : [input];
        source.forEach(({ name, value }) => {
          if (value) {
            currentSearch.set(name, value);
          } else {
            currentSearch.delete(name);
          }
        });
        history.replace({ search: currentSearch.toString() });
      },
      [params, history]
    );

  return { params, clearParams, clearParam, setParams };
}

export type QueryParamsState = Record<string, string>;

export function useStateQueryParams() {
  const history = useHistory();
  const queryParams = useMemo<QueryParamsState>(
    () => Object.fromEntries(new URLSearchParams(window.location.search).entries()),
    [window.location.search]
  );
  const queryParamsRef = useRef(queryParams);
  queryParamsRef.current = queryParams;
  const setQueryParams = useCallback((update: QueryParamsState | ((old: QueryParamsState) => QueryParamsState)) => {
    const oldParams = queryParamsRef.current;
    const newParams = typeof update === `function` ? update(oldParams) : update;
    if (newParams !== oldParams) {
      const newSearch = new URLSearchParams(newParams).toString();
      history.replace({ search: newSearch.toString() });
    }
  }, []);
  return [queryParams, setQueryParams] as const;
}

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export type Refinement<T> = (key: string, data: T[]) => T[];
export type FilterRefinement<T> = (filters: Record<string, string>, data: T[]) => T[];

interface UseRefinementsArgs<T> {
  data?: T[] | null;
  onSearch?: Refinement<T>;
  defaultSearchKey?: string;
  onSort?: Refinement<T>;
  defaultSortKey?: string;
  onFilter?: FilterRefinement<T>;
  defaultFilterKey?: Record<string, string>;
}

const RefinementNoOp = <T>(_: string, data: T[]): T[] => data;
const FilterRefinementNoOp = <T>(_: Record<string, string>, data: T[]): T[] => data;

export enum ParamKeys {
  Filter = 'category',
  Hint = 'hint',
  Search = 'q',
  Sort = 'sort',
}

function isFilterKey(key: string) {
  return key !== ParamKeys.Search && key !== ParamKeys.Sort && key !== ParamKeys.Hint;
}

function getInitialKey(urlParams: URLSearchParams, paramKey: ParamKeys, defaultKey?: string) {
  const param = urlParams.get(paramKey);
  return param || defaultKey || '';
}

function getInitialFilterKey(urlParams: URLSearchParams, defaultKey?: Record<string, string>): Record<string, string> {
  const filterMap: Record<string, string> = {};
  for (const [key, value] of urlParams) {
    if (isFilterKey(key)) {
      filterMap[key] = value || defaultKey?.[key] || '';
    }
  }
  return filterMap;
}

export function useRefineData<T>(args: UseRefinementsArgs<T>) {
  const { data, onSearch, defaultSearchKey, onSort, defaultSortKey, onFilter, defaultFilterKey } = args;
  const { params, clearParams, clearParam, setParams } = useQueryParams();
  const [searchKey, setSearchKey] = useState<string>(getInitialKey(params, ParamKeys.Search, defaultSearchKey));
  const [sortKey, setSortKey] = useState<string>(getInitialKey(params, ParamKeys.Sort, defaultSortKey));
  const [filterMap, setFilterMap] = useState<Record<string, string>>(getInitialFilterKey(params, defaultFilterKey));
  const refinedData = useMemo(
    debounce(
      () => {
        const searchedData = (onSearch || RefinementNoOp)(searchKey, data || []);
        const filteredData = (onFilter || FilterRefinementNoOp)(filterMap, searchedData);
        return (onSort || RefinementNoOp)(sortKey, filteredData);
      },
      250,
      { leading: true }
    ),
    [data, searchKey, sortKey, filterMap, onSearch, onFilter, onSort]
  );
  const setFilter = (key: string, value: string) => {
    setFilterMap((old) => ({ ...old, [key]: value }));
  };

  useEffect(() => {
    setParams([
      {
        name: ParamKeys.Search,
        value: searchKey,
      },
      {
        name: ParamKeys.Sort,
        value: sortKey,
      },
      ...Object.entries(filterMap).map(([key, value]) => ({ name: key, value })),
    ]);
  }, [searchKey, sortKey, filterMap]);

  const resetSearch = useCallback(() => {
    setSearchKey(defaultSearchKey || '');
    clearParam(ParamKeys.Search);
  }, [defaultSearchKey]);
  const resetSort = useCallback(() => {
    setSortKey(defaultSortKey || '');
    clearParam(ParamKeys.Sort);
  }, [defaultSortKey]);
  const resetFilter = useCallback(
    (key: string) => {
      setFilter(key, defaultFilterKey?.[key] || '');
      clearParam(key);
    },
    [defaultFilterKey]
  );
  const resetAllFilters = useCallback(() => {
    Object.keys(filterMap || {}).forEach((key) => {
      setFilter(key, defaultFilterKey?.[key] || '');
      clearParam(key);
    });
  }, [filterMap, defaultFilterKey]);
  const resetAll = useCallback(() => {
    setSearchKey(defaultSearchKey || '');
    setSortKey(defaultSortKey || '');
    Object.keys(filterMap || {}).forEach((key) => setFilter(key, defaultFilterKey?.[key] || ''));
    clearParams();
  }, [defaultSearchKey, defaultSortKey, defaultFilterKey]);

  return {
    data: refinedData || [],
    searchKey,
    sortKey,
    filterMap,
    setSearchKey,
    setSortKey,
    setFilter,
    resetSearch,
    resetSort,
    resetFilter,
    resetAllFilters,
    resetAll,
  };
}

export function useCategory() {
  const getCategory = useCallback((newFilterMap: Record<string, string>) => newFilterMap?.[ParamKeys.Filter] || '', []),
    setCategory = useCallback((setFilter: (key: string, value: string) => void, value: string) => {
      setFilter(ParamKeys.Filter, value);
    }, []),
    resetCategory = useCallback((resetFilter: (key: string) => void) => resetFilter(ParamKeys.Filter), []);

  return { getCategory, setCategory, resetCategory };
}

export function useNotification() {
  const { messages, dispatch } = useContext(NotificationContext);
  const add = useCallback(
    (message: INotification) => {
      const n = { ...message, id: getId() };
      dispatch({ type: NotificationActionType.Add, message: n });
      return n;
    },
    [dispatch]
  );
  const remove = useCallback(
    (message: INotification) => dispatch({ type: NotificationActionType.Remove, message }),
    [dispatch]
  );
  return { messages, add, remove } as const;
}

export const useUser = () => useContext(UserContext);

export const useLeftPanel = () => useContext(LeftPanelContext);

export const useEnv = () => useContext(EnvContext);

export const useCloudPlatformDiscovery = () => useContext(CloudPlatformDiscoveryContext);

export function useApp() {
  const theme = useTheme();
  const { addToast } = useToast();
  const getApps = useCallback(async (params: ListAppsRequest, pinned = false) => {
    const { apps = [] } = await AppService.listApps(params);
    // default order: ORDER BY created_at DESC
    return pinned ? apps.filter((app) => app.preference?.pinned) : apps;
  }, []);
  const getAdminApps = useCallback(async (params: ListAppsRequest) => {
    const { apps = [] } = await AdminAppService.listApps(params);
    return apps;
  }, []);
  const getAppBadges = useCallback(
    (app: App): CardBadge[] => {
      const badges = app.attributes.map((appAttribute) => {
        switch (appAttribute) {
          case AppAttribute.DEPRECATED:
            return {
              label: 'Deprecated',
              color: theme.palette?.gray800 || '',
            };
          case AppAttribute.MVP:
            return {
              label: 'MVP',
              color: theme.palette?.yellow500 || '',
            };
          case AppAttribute.PREVIEW:
            return {
              label: 'Preview',
              color: theme.palette?.yellow500 || '',
            };
          case AppAttribute.SUPPORTED:
            return {
              label: 'H2O.ai Supported',
              color: theme.palette?.yellow500 || '',
            };
          case AppAttribute.ATTRIBUTE_UNSPECIFIED:
          default:
            return undefined;
        }
      });
      return badges.filter(Boolean) as CardBadge[];
    },
    [theme]
  );
  const updateAppPreference = useCallback(
    async (params: UpdateAppPreferenceRequest, errorMessage: string) => {
      let app: App | null = null;
      try {
        const res = await AppService.updateAppPreference(params);
        app = res.app;
      } catch (error: unknown) {
        if (error instanceof Error) {
          addToast({
            messageBarType: MessageBarType.error,
            message: `${errorMessage}: ${handleErrMsg(error.message)}`,
          });
        }
      }
      return app;
    },
    [addToast]
  );
  const updatePin = useCallback(
    async (app: App) => {
      const { id, preference } = app,
        { liked, pinned } = preference;
      const resApp = await updateAppPreference(
        { id, liked: { value: liked }, pinned: { value: !pinned } },
        `An error has occurred while ${pinned ? 'un' : ''}pinning this app`
      );
      return resApp;
    },
    [updateAppPreference]
  );
  const updateLikes = useCallback(
    async (app: App) => {
      const { id, preference } = app,
        { pinned, liked } = preference;
      const resApp = await updateAppPreference(
        { id, pinned: { value: pinned }, liked: { value: !liked } },
        `An error has occurred while ${liked ? 'un' : ''}liking this app`
      );
      return resApp;
    },
    [updateAppPreference]
  );
  return { getApps, getAdminApps, getAppBadges, updatePin, updateLikes };
}

export function useInstance() {
  const history = useHistory();
  const { addToast } = useToast();
  const getInstancesList = useCallback(async (params: ListAppInstancesRequest) => {
    const { instances } = await AppService.listAppInstances(params);
    return instances;
  }, []);
  const getAdminInstancesList = useCallback(async (params: ListAppInstancesRequest) => {
    const { instances } = await AdminAppService.listAppInstances(params);
    return instances;
  }, []);
  const setInstanceSuspension = useCallback(
    async ({ id }: AppInstance, op: InstancePauseResumeOp, isAdmin = false) => {
      const { suspend, description, openInstance } = op;
      try {
        const service = isAdmin ? AdminAppService : AppService;
        const { instance } = await service.setAppInstanceSuspension({ id, suspend });
        if (openInstance) window.open(instance.location, '_blank');
      } catch (message: any) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `Could not ${description} app instance: ${handleErrMsg(message.message)}`,
        });
      }
    },
    [addToast]
  );
  const setInstancesSuspension = useCallback(
    async (instances: AppInstance[], op: InstancePauseResumeOp, isAdmin = false) => {
      const { suspend, description } = op;
      const service = isAdmin ? AdminAppService : AppService;
      const results = await Promise.all(
        instances
          .map(({ id }: AppInstance) => service.setAppInstanceSuspension({ id, suspend }))
          .map((p) => p.catch((e) => e))
      );
      const messages: string[] = [];
      results.forEach((result, i) => {
        if (result instanceof Error) {
          const app = instances[i].appDetails;
          const instanceId = instances[i].id;
          messages.push(`Instance ID: ${instanceId} (App Name: ${app.name} / App version:${app.version})`);
        }
      });
      if (messages.length > 0) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `Could not ${description} app instance: ${messages.join(', ')}}`,
        });
      }
    },
    [addToast]
  );
  const terminateInstance = useCallback(
    async (instance: AppInstance, isAdmin = false) => {
      const { id } = instance;
      try {
        const service = isAdmin ? AdminAppService : AppService;
        await service.terminateAppInstance({ id });
      } catch (message: any) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `Could not terminate app instance: ${handleErrMsg(message.message)}`,
        });
      }
    },
    [addToast]
  );
  const goToInstance = useCallback(
    (instance: AppInstance) => () => {
      redirect(instance.location);
    },
    []
  );
  const goToInstanceLog = useCallback(
    (instance: AppInstance, isAdmin = false) =>
      () => {
        const path = isAdmin
          ? `${RoutePaths.ADMIN_INSTANCELOG.split(':')[0]}${instance.id}`
          : `${RoutePaths.INSTANCELOG.split(':')[0]}${instance.id}`;
        history.push(path);
      },
    [history]
  );
  const goToAppDetail = useCallback(
    (appId: string) => () => {
      // TODO: when Admin App Details are developed, link to Admin App Detail view
      history.push(RoutePaths.APP.replace(':id', appId));
    },
    [history]
  );
  const getInstance = useCallback(async (instanceId: string) => {
    const appInstance = await AppService.getAppInstance({ id: instanceId, includeAppDetails: false });
    return appInstance.instance;
  }, []);
  const getAdminInstance = useCallback(async (instanceId: string) => {
    const appInstance = await AdminAppService.getAppInstance({ id: instanceId, includeAppDetails: false });
    return appInstance.instance;
  }, []);
  return {
    getInstance,
    getAdminInstance,
    getInstancesList,
    getAdminInstancesList,
    setInstanceSuspension,
    setInstancesSuspension,
    terminateInstance,
    goToInstance,
    goToAppDetail,
    goToInstanceLog,
  };
}

export function useInterval(callback: AnyFunction, delay: number | null) {
  const savedCallbackRef = useRef<AnyFunction>();
  savedCallbackRef.current = callback;

  useEffect((): AnyFunction | void => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

/**
 * Combines React.useCallback with lodash.debounce
 * @param {Function} callback to be memoized and debounced
 * @param {number | null} wait the number of milliseconds to delay invocation.
 * Pass `null` to use RequestAnimationFrame. This config cannot be changed
 * during mount.
 * @param {DebounceSettings} [debounceSettings] lodash debounce settings. This
 * config cannot be changed during mount.
 * @return {Function}
 */
export function useDebouncedCallback<Callback extends (...args: any[]) => any>(
  callback: Callback,
  wait: number | null,
  debounceSettings?: DebounceSettings
): DebouncedFunc<Callback> {
  const callbackRef = useRef<Callback>(callback);
  callbackRef.current = callback;
  const [debouncedCallback] = useState(() => {
    return debounce((...args) => callbackRef.current(...args), wait as any, debounceSettings);
  });
  useEffect(
    () => () => {
      debouncedCallback.cancel();
    },
    []
  );
  return debouncedCallback;
}

export type UsePromiseCallbackSettlement<Callback extends (...args: any[]) => Promise<any>> = {
  error?: any;
  data?: Awaited<ReturnType<Callback>>;
  mountedRef: Readonly<RefObject<boolean>>;
};

/**
 * Utility to integrate promise with React, it manages the `loading` state, and
 * it prevents propagating the promise result when the component unmounts.
 * @param {Function} callback - a function that returns Promise,
 * @param {any[]} dependencies - dependencies of the callback (same as
 * `React.useCallback` or `React.useMemo`).
 * @param {object} [options]
 * @property {Function} options.onError - callback, that will be invoked when
 * the promise rejects. Called only when this hook is mounted.
 * @property {Function} options.onSuccess - callback, that will be invoked when
 * the promise resolves. Called only when this hook is mounted.
 * @property {Function} options.onSettled - callback, that will be invoked as
 * the promise settles. Called always, even when the hook is unmounted. The
 * callback receives an object with either `result` in case of resolved promise
 * or `error` in case of rejected promise. The object also contains a ref object
 * which holds the "mounted" state of the hook - it is `true` when the component
 * is mounted and changes to `false` when the component unmounts.
 * @return {Array<*> & { 0: Function, 1: boolean }} usePromiseCallbackResult
 * @property {Function} usePromiseCallbackResult.0 - passed callback, memoized
 * the same way `React.useCallback` works. The Promise returned by this callback
 * never rejects to prevent unhandled rejection error. The result of the promise
 * is communicated via UsePromiseCallbackSettlement object. The object contains
 * also a `mountedRef` property, that should be checked before propagating the
 * Promise settlement.
 * @property {boolean} usePromiseCallbackResult.1 - the 'loading' state of the
 * returned callback, if `true` the promise is pending.
 */
export function usePromiseCallback<Callback extends (...args: any[]) => Promise<any>>(
  callback: Callback,
  dependencies: readonly any[],
  options: {
    onError?: (error: any) => any;
    onSuccess?: (result: Awaited<ReturnType<Callback>>) => any;
    onSettled?: (settlement: UsePromiseCallbackSettlement<Callback>) => any;
  } = {}
): [(...args: Parameters<Callback>) => Promise<UsePromiseCallbackSettlement<Callback>>, boolean] {
  const mountedRef = useRef(true);
  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );
  const optionsRef = useRef(options);
  optionsRef.current = options;
  const [loading, setLoading] = useState<boolean>(false);
  const memoizedCallback = useCallback((...args) => {
    setLoading(true);
    return callback(...args)
      .finally(() => {
        if (mountedRef.current) {
          setLoading(false);
        }
      })
      .then(
        (data) => {
          if (mountedRef.current) {
            optionsRef.current.onSuccess?.(data);
          }
          const settlement = { mountedRef, data };
          optionsRef.current?.onSettled?.(settlement);
          return settlement;
        },
        (error) => {
          if (mountedRef.current) {
            optionsRef.current.onError?.(error);
          }
          const settlement = { mountedRef, error };
          optionsRef.current?.onSettled?.(settlement);
          return settlement;
        }
      );
  }, dependencies);
  return [memoizedCallback as any, loading];
}
