import { ColumnActionsMode, type IColumn } from '@fluentui/react';

import { UpdateAppInstanceRequest } from '../../ai.h2o.cloud.appstore';
import { InstanceListType } from '../../utils/models';
import { listIconSize, specifiedVisibilityOptions } from '../../utils/utils';
import { DropdownFieldType, FieldType } from '../CreateUpdateDialog/CreateUpdateDialog';

export const columns: { [key: string]: IColumn } = {
  icon: {
    key: 'icon',
    name: '',
    className: 'instance-icon',
    fieldName: 'iconLocation',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  title: {
    key: 'title-id-state',
    name: '',
    fieldName: 'title',
    minWidth: 200,
    maxWidth: 800,
  },
  owner: {
    key: 'owner',
    name: 'Owner',
    fieldName: 'owner',
    minWidth: 160,
    maxWidth: 160,
  },
  details: {
    key: 'details',
    name: 'Details',
    fieldName: 'details',
    minWidth: 240,
    maxWidth: 240,
  },
  status: {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 88,
    maxWidth: 88,
  },
  visibility: {
    key: 'visibility',
    name: 'Visibility',
    fieldName: 'visibility',
    minWidth: 112,
    maxWidth: 112,
  },
  actions: {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 260,
    maxWidth: 260,
    columnActionsMode: ColumnActionsMode.disabled,
  },
};

export const columnsByTypeMap = new Map<InstanceListType, IColumn[]>();
columnsByTypeMap.set(InstanceListType.app, [
  columns.icon,
  { ...columns.title, minWidth: 140, maxWidth: 800 },
  columns.owner,
  { ...columns.details, minWidth: 160, maxWidth: 160 },
  columns.status,
  columns.visibility,
  columns.actions,
]);
columnsByTypeMap.set(InstanceListType.admin, [
  columns.icon,
  columns.title,
  columns.owner,
  columns.details,
  columns.status,
  columns.visibility,
  columns.actions,
]);
columnsByTypeMap.set(InstanceListType.my, [
  columns.icon,
  columns.title,
  columns.details,
  columns.status,
  columns.visibility,
  columns.actions,
]);

export const visibilityDialogFields: DropdownFieldType<UpdateAppInstanceRequest>[] = [
  {
    prop: 'visibility',
    type: FieldType.DROPDOWN,
    label: 'Visible to',
    required: true,
    dataTest: 'instance-visibility-dropdown',
    options: specifiedVisibilityOptions,
  },
];

export const mobileColumns = ['icon', 'title', 'details', 'actions'];
