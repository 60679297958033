import { BigIntString, toBigIntString } from './gen/runtime';
import { bytesToGibibytes, gibibytesToBytes, hoursToSeconds, secondsToHours } from './utils';

export enum TimeUnit {
  Second = 'Second',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export type TimeUnitInfo = {
  key: string;
  text: string;
  singular: string;
  plural: string;
  type: TimeUnit;
};

export const timeUnitInfoMap: { [key in TimeUnit]: TimeUnitInfo } = {
  [TimeUnit.Second]: {
    key: 'seconds',
    text: 'Seconds',
    singular: 'second',
    plural: 'seconds',
    type: TimeUnit.Second,
  },
  [TimeUnit.Minute]: {
    key: 'minutes',
    text: 'Minutes',
    singular: 'minute',
    plural: 'minutes',
    type: TimeUnit.Minute,
  },
  [TimeUnit.Hour]: {
    key: 'seconds',
    text: 'Hours',
    singular: 'hour',
    plural: 'hours',
    type: TimeUnit.Hour,
  },
  [TimeUnit.Day]: {
    key: 'days',
    text: 'Days',
    singular: 'day',
    plural: 'days',
    type: TimeUnit.Day,
  },
  [TimeUnit.Week]: {
    key: 'weeks',
    text: 'Weeks',
    singular: 'week',
    plural: 'weeks',
    type: TimeUnit.Week,
  },
  [TimeUnit.Month]: {
    key: 'months',
    text: 'Months',
    singular: 'month',
    plural: 'months',
    type: TimeUnit.Month,
  },
  [TimeUnit.Year]: {
    key: 'years',
    text: 'Years',
    singular: 'year',
    plural: 'years',
    type: TimeUnit.Year,
  },
};

export enum ConstraintType {
  CPU = 'cpu',
  GPU = 'gpu',
  MAXIDLEDURATION = 'maxIdleDuration',
  MAXRUNNINGDURATION = 'maxRunningDuration',
  MEMORYBYTES = 'memoryBytes',
  NODECOUNT = 'nodeCount',
  STORAGEBYTES = 'storageBytes',
}

export enum ConstraintBasicType {
  NUMERIC,
  TIME,
  BYTES,
}

export const ConstraintTypeMap = new Map<ConstraintType, ConstraintBasicType>([
  [ConstraintType.CPU, ConstraintBasicType.NUMERIC],
  [ConstraintType.GPU, ConstraintBasicType.NUMERIC],
  [ConstraintType.MAXIDLEDURATION, ConstraintBasicType.TIME],
  [ConstraintType.MAXRUNNINGDURATION, ConstraintBasicType.TIME],
  [ConstraintType.MEMORYBYTES, ConstraintBasicType.BYTES],
  [ConstraintType.NODECOUNT, ConstraintBasicType.NUMERIC],
  [ConstraintType.STORAGEBYTES, ConstraintBasicType.BYTES],
]);

export type Parser =
  | ((value: string | number | BigIntString | undefined | null) => string | number | BigIntString | undefined | null)
  | undefined;

export type ConstraintTypeMapAttributes = {
  FromView?: Parser;
  ToView?: Parser;
  suffix?: string | undefined;
  basicType: ConstraintBasicType;
  max?: number;
  min?: number;
};

export const maxSeconds = 259200,
  maxBytes = 1_000_000_000_000;
const suffix = 'GiB';

const parseNumberToString: Parser = (value) => (value === null ? null : value?.toString());
const parseStringToNumber: Parser = (value) => (typeof value !== 'string' ? value : parseInt(value, 10));
const parseToBytesAndBigIntString: Parser = (value) => toBigIntString(Number(gibibytesToBytes(value)));
const parseBytesToGibibytes: Parser = (value) => bytesToGibibytes(value);
const parseSecondsToHours: Parser = (value) => secondsToHours(value);
const parseHoursToSeconds: Parser = (value) => hoursToSeconds(value);

export const constraintTypeMapAttributes: { [key in ConstraintType as string]: ConstraintTypeMapAttributes } = {
  [ConstraintType.CPU]: {
    FromView: parseNumberToString,
    ToView: parseStringToNumber,
    basicType: ConstraintBasicType.NUMERIC,
    min: 1,
  },
  [ConstraintType.GPU]: {
    FromView: parseNumberToString,
    ToView: parseStringToNumber,
    basicType: ConstraintBasicType.NUMERIC,
  },
  [ConstraintType.MAXIDLEDURATION]: {
    FromView: parseHoursToSeconds,
    ToView: parseSecondsToHours,
    basicType: ConstraintBasicType.TIME,
    max: maxSeconds,
  },
  [ConstraintType.MAXRUNNINGDURATION]: {
    FromView: parseHoursToSeconds,
    ToView: parseSecondsToHours,
    basicType: ConstraintBasicType.TIME,
    max: maxSeconds,
  },
  [ConstraintType.MEMORYBYTES]: {
    FromView: parseToBytesAndBigIntString,
    ToView: parseBytesToGibibytes,
    suffix,
    basicType: ConstraintBasicType.BYTES,
    max: maxBytes,
    min: 1,
  },
  [ConstraintType.STORAGEBYTES]: {
    FromView: parseToBytesAndBigIntString,
    ToView: parseBytesToGibibytes,
    suffix,
    basicType: ConstraintBasicType.BYTES,
    max: maxBytes,
    min: 1,
  },
  [ConstraintType.NODECOUNT]: {
    basicType: ConstraintBasicType.NUMERIC,
    min: 1,
  },
};
