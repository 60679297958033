import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { useEngine } from '../../aiem/engine/hooks';
import { AIEngine } from '../../aiem/engine/types';
import { getFullPathFromEngineName } from '../../aiem/engine/utils';
import { Engine_Type } from '../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { handleErrMsg } from '../../utils/utils';
import InstanceLogPage, { InstanceLogType } from '../InstanceLogPage/InstanceLogPage';

export interface EngineLogPageProps {
  engineName: string; // TODO understand the request represented by Object DAIEngineServiceDownloadLogsRequest.daiEngine:string
  engineType: Engine_Type;
}

function LegacyEngineLogPage({ engineName, engineType }: EngineLogPageProps) {
  // state
  const { basePath, opOnEngine } = useEngine(),
    [engine, setEngine] = useState<AIEngine | null>(null),
    [websocketUrl, setWebsocketUrl] = useState<URL>(),
    // utilities
    { addToast } = useToast(),
    getEngine = useCallback(async () => {
      try {
        // TODO: support workspaces other than the default:
        const name = getFullPathFromEngineName(engineName, engineType);
        const e = (await opOnEngine({ name, engineType } as AIEngine)) as AIEngine;
        setEngine(e);
        const path = `/v1/${e?.name}:stream_logs`;
        setWebsocketUrl(new URL(`${basePath}${path}`));
      } catch ({ message }: any) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `An error occurred while loading the engine: ${handleErrMsg(message as string)}`,
        });
      }
    }, [engineName, basePath]);
  useEffect(() => {
    getEngine();
  }, [engineName, getEngine]);
  return (
    <InstanceLogPage
      instanceType={InstanceLogType.ENGINE}
      instanceName={engine?.displayName || ''}
      instanceId={engineName}
      websocketUrl={websocketUrl}
    />
  );
}

export default LegacyEngineLogPage;
