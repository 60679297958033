import { FontSizes, FontWeights, IH2OTheme, IStyles, mediaDesktop } from '@h2oai/ui-kit';

import { DefaultPageStyles } from '../../themes/themes';

type AppListKey = 'root';
export type AppList = IStyles<AppListKey>;

export const appListPageStyles = (theme: IH2OTheme): AppList => ({
  root: {
    ...mediaDesktop({ ...(DefaultPageStyles as object) }),

    '.h2o-Accordion-root': { marginBottom: 8 },
    '.h2o-Accordion-root.is-close': { height: '74px !important' },

    '.h2o-Accordion-header': { height: 64 },

    '.h2o-Accordion-content': {
      background: theme.palette?.white,
      padding: 8,
    },

    '.h2o-Accordion-icon': { right: 6, top: -14 },

    '.instance-accordion-row': { padding: '12px 0' },
    '.instance-accordion-row:last-child': { paddingBottom: 0 },
    '.h2o-Accordion-header .instance-accordion-row': { paddingTop: 0 },
    '.h2o-Accordion-body .instance-accordion-row': {
      borderTop: `1px solid ${theme.palette?.gray300}`,
    },

    '.instance-accordion-row-head': {
      display: `flex`,
      alignItems: 'start',
      fontSize: FontSizes.xsmall,
      lineHeight: FontSizes.medium,
    },
    '.instance-accordion-row-head .ms-Image': {
      width: 32,
      height: 32,
      marginRight: 12,
    },
    '.instance-accordion-row-head .instance-accordion-row-title': {
      overflow: 'hidden',
    },
    '.instance-accordion-row-head strong': {
      fontSize: FontSizes.medium,
      lineHeight: FontSizes.xlarge,
      fontWeight: FontWeights.fw400,
      display: 'block',
      padding: '2px 0 6px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '.instance-accordion-row-info': {
      display: `flex`,
      justifyContent: 'space-between',
      button: {
        height: 'auto',
        minHeight: 0,
        lineHeight: FontSizes.medium,
      },
      '> *': {
        display: `flex`,
        alignItems: 'center',
      },
      span: {
        fontSize: FontSizes.xxsmall,
        lineHeight: FontSizes.small,
        marginRight: 4,
      },
    },
    '.instance-accordion-row-info .cell-wrapper > div': {
      height: 'auto !important',
      minHeight: '0 !important',
    },
    '.instance-accordion-row-actions': {
      position: 'relative',
      paddingTop: 14,
      '.h2o-ButtonContainer-root': { display: 'block' },
      '.h2o-ButtonContainer-root > div': {
        height: 'auto !important',
        width: '100%',
      },
      button: {
        backgroundColor: 'transparent',
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette?.gray900,
        fontSize: FontSizes.medium,
        lineHeight: FontSizes.xlarge,
      },
      'button .ms-Icon': { color: theme.palette?.gray900 },
      'button + button': { width: 56 },
      '.ms-SplitButton-divider': { right: 58 },
      'div.mobile-view-action': {
        // Hard fix for the known issue with the dropdown button on mobile-view in fluentui
        // https://github.com/microsoft/fluentui/issues/14303
        opacity: 0,
        position: 'absolute',
        top: 14,
        bottom: 0,
        left: 0,
        right: 60,
        zIndex: 1,
        button: {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
});
