import { ImagePullPolicy } from '../../gen/ai/h2o/engine/v1/base_types_pb';
import { ListDAIProfilesResponse } from '../../gen/ai/h2o/engine/v1/dai_profile_service_pb';
import { GetDefaultDAISetupResponse } from '../../gen/ai/h2o/engine/v1/default_dai_setup_service_pb';
import { GetDefaultH2OSetupResponse } from '../../gen/ai/h2o/engine/v1/default_h2o_setup_service_pb';
import { ListInternalDAIVersionsResponse } from '../../gen/ai/h2o/engine/v1/internal_dai_version_service_pb';
import { ListInternalH2OVersionsResponse } from '../../gen/ai/h2o/engine/v1/internal_h2o_version_service_pb';
import { toBigIntString } from '../../gen/runtime';

export const listDAIProfilesResponseMock: ListDAIProfilesResponse = {
  daiProfiles: [...Array(20)].map((_item: any, index) => {
    return {
      name: `daiProfiles/dai-profile-${index + 1}`,
      displayName: `Profile ${index + 1}`,
      cpu: index + 10,
      gpu: index + 1,
      memoryBytes: toBigIntString(322122547200),
      storageBytes: toBigIntString(8589934592),
      order: index + 1,
    };
  }),
  nextPageToken: '',
  totalSize: 20,
};

export const listInternalDAIVersionsResponseMock: ListInternalDAIVersionsResponse = {
  internalDaiVersions: [
    {
      name: 'internalDAIVersions/1.10.6.1',
      version: '1.10.6.1',
      aliases: ['latest'],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.6.1-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.ALWAYS,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {
        foo: 'bar',
        biz: 'buzz',
        something: 'else',
        another: 'thing',
      },
    },
    {
      name: 'internalDAIVersions/1.10.6',
      version: '1.10.6',
      aliases: ['broken', 'pre-release'],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.6-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.ALWAYS,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
    {
      name: 'internalDAIVersions/1.10.5',
      version: '1.10.5',
      aliases: [],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.5-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.ALWAYS,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
    {
      name: 'internalDAIVersions/1.10.4.3',
      version: '1.10.4.3',
      aliases: [],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.4.3-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
    {
      name: 'internalDAIVersions/1.10.4.2',
      version: '1.10.4.2',
      aliases: [],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.4.2-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
    {
      name: 'internalDAIVersions/1.10.4.1',
      version: '1.10.4.1',
      aliases: [],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.4.1-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
    {
      name: 'internalDAIVersions/1.10.4',
      version: '1.10.4',
      aliases: [],
      deprecated: false,
      image: '524466471676.dkr.ecr.us-east-1.amazonaws.com/q8s/driverless-ai:1.10.4-cuda11.2.2',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      dataDirectoryStorageClass: '',
      annotations: {},
    },
  ],
  nextPageToken: '',
  totalSize: 6,
};

export const listInternalH2OVersionsResponseMock: ListInternalH2OVersionsResponse = {
  internalH2oVersions: [
    {
      name: 'internalH2OVersions/3.42.0.3',
      version: '3.42.0.3',
      aliases: ['latest'],
      deprecated: false,
      image: 'h2oai/h2o-open-source-k8s:3.42.0.3',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      annotations: {},
    },
    {
      name: 'internalH2OVersions/3.40.0.4',
      version: '3.40.0.4',
      aliases: [],
      deprecated: false,
      image: 'h2oai/h2o-open-source-k8s:3.40.0.4',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      annotations: {},
    },
    {
      name: 'internalH2OVersions/3.38.0.4',
      version: '3.38.0.4',
      aliases: [],
      deprecated: false,
      image: 'h2oai/h2o-open-source-k8s:3.38.0.4',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      annotations: {},
    },
    {
      name: 'internalH2OVersions/3.36.1.5',
      version: '3.36.1.5',
      aliases: [],
      deprecated: false,
      image: 'h2oai/h2o-open-source-k8s:3.36.1.5',
      imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
      imagePullSecrets: [],
      gpuResourceName: 'nvidia.com/gpu',
      annotations: {},
    },
  ],
  nextPageToken: '',
  totalSize: 5,
};

export const getDefaultDAISetupResponseMock: GetDefaultDAISetupResponse = {
  defaultDaiSetup: {
    name: 'defaultDAISetup',
    cpuConstraint: {
      min: toBigIntString(1),
      max: toBigIntString(30),
      default: toBigIntString(2),
    },
    gpuConstraint: {
      min: toBigIntString(0),
      max: toBigIntString(1),
      default: toBigIntString(0),
    },
    memoryBytesConstraint: {
      min: toBigIntString(4294967296),
      max: toBigIntString(268435456000),
      default: toBigIntString(85899345920),
    },
    storageBytesConstraint: {
      min: toBigIntString(4294967296),
      default: toBigIntString(8589934592),
    },
    maxIdleDurationConstraint: {
      min: '0s',
      default: '3600s',
    },
    maxRunningDurationConstraint: {
      min: '0s',
      default: '172800s',
    },
    maxUnusedDuration: '1209600s',
    configurationOverride: {
      enabled_file_systems: 'upload, file, hdfs, s3, recipe_file, recipe_url, h2o_drive, feature_store',
      feature_store_endpoint_url: 'cloud-feature-store-dev-api-core.feature-store-dev:9090',
      h2o_drive_endpoint_url: 'http://drive-dev-drive.drive-dev:8081',
      h2o_mlops_ui_url: 'https://mlops.cloud-dev.h2o.ai',
      hac_link_url: 'https://cloud-dev.h2o.ai',
    },
    yamlPodTemplateSpec: `metadata:
  annotations:
    custom-key: custom-value
  creationTimestamp: null
  spec:
    containers:
      - name: driverless-ai
        env:
          - name: CUSTOM_VAR
            value: "CUSTOM_VAL"
          - name: DRIVERLESS_AI_OVERRIDE_VIRTUAL_CORES
            value: "20"
      - name: custom-container
        image: gcr.io/vorvan/h2oai/mockdai
        ports:
          - containerPort: 21212
            protocol: TCP
        args: [ "--port", "1111" ]
    tolerations:
      - key: "dedicated"
        operator: "Equal"
        value: "steam"
        effect: "NoSchedule"`,
    yamlGpuTolerations: '- effect: NoSchedule\n  key: dedicated\n  operator: Equal\n  value: gpu\n',
    tritonEnabled: true,
  },
};

export const getDefaultH2OSetupResponseMock: GetDefaultH2OSetupResponse = {
  defaultH2oSetup: {
    name: 'defaultH2OSetup',
    nodeCountConstraint: {
      min: toBigIntString(1),
      max: toBigIntString(16),
      default: toBigIntString(1),
    },
    cpuConstraint: {
      min: toBigIntString(1),
      max: toBigIntString(30),
      default: toBigIntString(1),
    },
    gpuConstraint: {
      min: toBigIntString(0),
      max: toBigIntString(1),
      default: toBigIntString(0),
    },
    memoryBytesConstraint: {
      min: toBigIntString(2147483648),
      max: toBigIntString(268435456000),
      default: toBigIntString(4294967296),
    },
    maxIdleDurationConstraint: {
      min: '0s',
      default: '3600s',
    },
    maxRunningDurationConstraint: {
      min: '0s',
      default: '172800s',
    },
    yamlPodTemplateSpec:
      'metadata:\n  creationTimestamp: null\nspec:\n  containers:\n  - name: h2o-3\n    resources: {}\n  tolerations:\n  - effect: NoSchedule\n    key: dedicated\n    operator: Equal\n    value: steam\n',
    yamlGpuTolerations: '- effect: NoSchedule\n  key: dedicated\n  operator: Equal\n  value: gpu\n',
  },
};
