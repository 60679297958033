import { memo } from 'react';

import { AIEMOpType, AIEngine } from '../../../../aiem/engine/types';
import { AIEMPanelView } from '../AIEMPanelView/AIEMPanelView';
import { AIEMPanel } from './AIEMPanel';

export type AIEMPanelWrapperProps = {
  onDismiss: () => void;
  op: AIEMOpType;
  engine: AIEngine;
  onSave: () => void;
};

const AIEMPanelComponent = ({ onDismiss, engine, op, onSave }: AIEMPanelWrapperProps) => {
  switch (op) {
    case AIEMOpType.create:
    case AIEMOpType.edit:
      return <AIEMPanel data-test="aiem-panel" engine={engine} onSave={onSave} onDismiss={onDismiss} op={op} />;
    case AIEMOpType.view:
      return <AIEMPanelView data-test="aiem-panel" engine={engine} onDismiss={onDismiss} />;
    default:
      throw new Error(`The operation "${op}" is not supported by AIEMPanel.`);
  }
};

export const AIEMPanelWrapper = memo(AIEMPanelComponent);
