import type { ITextStyles } from '@fluentui/react';
import { FontSizes, FontWeights, type IH2OTheme, LineHeights, Sizes, mediaDesktop, mediaMobile } from '@h2oai/ui-kit';

export const additionalInfoStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    background: theme.palette?.gray200,
    borderRadius: Sizes.borderRadius,
    flexGrow: 0,
    ...mediaDesktop({ padding: '17px 24px' }),
    ...mediaMobile({
      margin: '-1.5rem',
      padding: 8,
      lineHeight: FontSizes.medium,
    }),
    '.AdditionalInfoContentWrapper': {
      ...mediaMobile({
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
      }),
    },
  },
});

export const additionalInfoContainerStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    borderRadius: Sizes.borderRadius,
    backgroundColor: theme.semanticColors?.bodyBackground,
    ...mediaDesktop({ lineHeight: LineHeights.xxxlarge }),
    ...mediaMobile({ display: 'table-row' }),
  },
});

export const additionalInfoLabelStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    color: theme.semanticColors?.textPrimary,
    fontSize: FontSizes.textPrimary,
    paddingRight: 10,
    ...mediaDesktop({ width: 155 }),
    ...mediaMobile({
      display: 'table-cell',
      paddingBottom: 11,
      fontWeight: FontWeights.semiBold,
    }),
  },
});

export const additionalInfoContentStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    fontSize: FontSizes.textPrimary,
    color: theme.semanticColors?.textPrimary,
    wordBreak: 'break-word',
    ...mediaMobile({
      display: 'table-cell',
      paddingBottom: 11,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      lineHeight: FontSizes.medium,
    }),
  },
});
