import { IStyle } from '@fluentui/react';
import { Loader, useClassNames } from '@h2oai/ui-kit';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { ClassNamesFromIStyles } from '../../utils/models';
import { useWorkspaces } from './WorkspaceProvider';

interface IWorkspaceRedirectStyles {
  loaderContainer: IStyle;
}

const workspaceRedirectStyles: Partial<IWorkspaceRedirectStyles> = {
  loaderContainer: {
    padding: 20,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    minHeight: 450,
    whiteSpace: 'pre-line',
  },
};

const WorkspaceRedirect: React.FC = () => {
  const { ACTIVE_WORKSPACE_NAME, activeWorkspaceInitialized } = useWorkspaces(),
    classNames = useClassNames<IWorkspaceRedirectStyles, ClassNamesFromIStyles<IWorkspaceRedirectStyles>>(
      'workspaceRedirect',
      workspaceRedirectStyles
    ),
    timeoutRef = React.useRef<number>(),
    [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
    if (!activeWorkspaceInitialized) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [activeWorkspaceInitialized]);

  return (
    <>
      {!activeWorkspaceInitialized ? (
        <div className={classNames.loaderContainer}>
          {showLoader ? <Loader label="Loading active workspace info" /> : null}
        </div>
      ) : (
        <Redirect to={`/orchestrator/${ACTIVE_WORKSPACE_NAME || 'workspaces'}`} />
      )}
    </>
  );
};

export default WorkspaceRedirect;
