import { Label, Stack } from '@fluentui/react';

import { defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';

type LabelAndDescriptionProps = {
  label: string;
  description?: string;
  light?: boolean;
};

export const LabelAndDescription = ({ label, description, light = false }: LabelAndDescriptionProps) => (
  <Stack
    styles={
      light ? undefined : { root: { minWidth: defaultEntityLabelColumnWidth, maxWidth: defaultEntityLabelColumnWidth } }
    }
  >
    <Label styles={{ root: { fontWeight: light ? 400 : 700 } }}>{label}</Label>
    {description}
  </Stack>
);
