import { IStyle, MessageBarType, mergeStyles } from '@fluentui/react';
import { IH2OTheme, Loader, mediaDesktop, mediaNoDesktop, useClassNames, useTheme, useToast } from '@h2oai/ui-kit';
import React from 'react';

import { ComputeDashboardStatsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/dashboard_service_pb';
import { WorkflowExecution_State } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_execution_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import ActivePools from './ActivePools';
import Executions from './Executions';
import NavigationWrapper from './NavigationWrapper';
import { NoItemView } from './NoItemView';
import PivotFilter from './PivotFilter';
import { useRoles } from './RoleProvider';
import WorkflowRunChart from './WorkflowRunChart';
import { formatError } from './Workflows';
import { getStateProps } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

interface IDashboardStyles {
  dasboard: IStyle;
  title: IStyle;
  widget: IStyle;
  widgetsContainer: IStyle;
  workflowRunsWidget: IStyle;
  executorPoolsWidget: IStyle;
  chartWrapper: IStyle;
  centerItems: IStyle;
}

const dashboardStyles = (theme: IH2OTheme): IDashboardStyles => {
  return {
    dasboard: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '10px 40px 20px 40px',
      // Adds extra margin to prevent "Need help?" button from overlapping.
      margin: '0px 20px',
    },
    title: {
      marginTop: 0,
    },
    widget: {
      backgroundColor: theme.semanticColors?.contentBackground,
      padding: 20,
      borderWidth: 1,
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
    },
    widgetsContainer: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      ...mediaNoDesktop({
        flexWrap: 'wrap',
      }),
    },
    workflowRunsWidget: {
      ...mediaDesktop({
        marginRight: 20,
        width: 'calc(64% - 20px)',
      }),
      ...mediaNoDesktop({
        width: 'calc(100% - 20px)',
      }),
    },
    executorPoolsWidget: {
      ...mediaDesktop({
        width: '36%',
        flexGrow: 1,
      }),
      ...mediaNoDesktop({
        marginTop: 15,
        width: 'calc(100% - 20px)',
      }),
    },
    chartWrapper: {
      marginBottom: 20,
    },
    centerItems: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  };
};

const defaultPivotItems = [
  { key: '', text: 'All', color: 'black', count: 0 },
  { key: 'running', text: 'Running', color: getStateProps(WorkflowExecution_State.RUNNING).color, count: 0 },
  { key: 'finished', text: 'Finished', color: getStateProps(WorkflowExecution_State.FINISHED).color, count: 0 },
  { key: 'failed', text: 'Failed', color: getStateProps(WorkflowExecution_State.FAILED).color, count: 0 },
  { key: 'cancelled', text: 'Cancelled', color: getStateProps(WorkflowExecution_State.CANCELLED).color, count: 0 },
];

const Dashboard = () => {
  const orchestratorService = useOrchestratorService(),
    theme = useTheme(),
    classNames = useClassNames<IDashboardStyles, ClassNamesFromIStyles<IDashboardStyles>>(
      'dashboard',
      dashboardStyles(theme)
    ),
    { permissions } = useRoles(),
    [loading, setLoading] = React.useState(true),
    [pivotFilter, setPivotFilter] = React.useState<string>(),
    [pivotItems, setPivotItems] = React.useState(defaultPivotItems),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { addToast } = useToast(),
    fetchDashboardStats = React.useCallback(async () => {
      setLoading(true);
      try {
        const data: ComputeDashboardStatsResponse = await orchestratorService.getDashboardStats({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        if (data && !data?.dashboardStats) console.error('No dashboard stats found in the response.');
        setPivotItems((pivotItems) => {
          const newPivotItems = [...pivotItems];
          newPivotItems.forEach((item) => {
            const key = `${item.key === '' ? 'totalExecution' : item.key}Count`;
            item.count = data.dashboardStats?.[key] || 0;
          });
          return newPivotItems;
        });
      } catch (err) {
        const message = `Failed to fetch dashboard stats: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoading(false);
      }
    }, [ACTIVE_WORKSPACE_NAME, orchestratorService, addToast]),
    onPivotFilterChange = React.useCallback((filter: string) => {
      setPivotFilter(filter);
    }, []);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) void fetchDashboardStats();
  }, [fetchDashboardStats, ACTIVE_WORKSPACE_NAME]);

  return (
    <NavigationWrapper>
      <div className={classNames.dasboard}>
        <h1 className={classNames.title}>Dashboard</h1>
        <div className={classNames.widgetsContainer}>
          {permissions.canRunWorkflows ? (
            loading ? (
              <div className={classNames.centerItems}>
                <Loader label="Preparing the dasboard..." />
              </div>
            ) : (
              <>
                <div className={mergeStyles(classNames.widget, classNames.workflowRunsWidget)}>
                  <div className={classNames.chartWrapper}>
                    <WorkflowRunChart />
                  </div>
                  <PivotFilter items={pivotItems} onFilterChange={onPivotFilterChange}>
                    <Executions filterBy={pivotFilter} noItemsViewShadowed={false} />
                  </PivotFilter>
                </div>
                <div className={mergeStyles(classNames.widget, classNames.executorPoolsWidget)}>
                  <ActivePools />
                </div>
              </>
            )
          ) : (
            NoItemView({
              title: 'Nothing to show here',
              description: 'For accessing the dashboard you need to have permissions to run workflows.',
            })
          )}
        </div>
      </div>
    </NavigationWrapper>
  );
};

export default Dashboard;
