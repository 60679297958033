import { type ITextStyles } from '@fluentui/react';
import { FontSizes, FontWeights, type IH2OTheme, type IStyles } from '@h2oai/ui-kit';

export const labelStyles = (theme: IH2OTheme): ITextStyles => ({
  root: {
    color: theme.semanticColors?.textQuaternary,
    fontSize: FontSizes.small,
    fontWeight: 600,
  },
});

export const contentStyles = (theme: IH2OTheme): ITextStyles => ({
  root: {
    color: theme.semanticColors?.textQuinary,
    fontSize: FontSizes.small,
  },
});

type InstancesAccordionKey = 'root' | 'accordionRowHeader';
export type InstancesAccordion = IStyles<InstancesAccordionKey>;

export const instancesAccordion = (theme: IH2OTheme): InstancesAccordion => ({
  root: {
    '.h2o-Accordion-root': {
      background: '#fff',
      marginBottom: 8,
    },
    '.h2o-Accordion-root.is-close': { height: '74px !important' },

    '.h2o-Accordion-content': { padding: 10 },

    '.h2o-Accordion-icon': {
      top: '-14px',
    },

    '.accordion-header-title strong, .accordion-header-subtitle, .metadata-cell-contents, .h2o-Accordion-body': {
      fontSize: FontSizes.medium,
      lineHeight: FontSizes.xlarge,
      fontWeight: FontWeights.normal,
    },
    '.accordion-header-title': { flexFlow: 'row' },
    '.accordion-header-title > div': { marginRight: 12 },
    '.accordion-header-title strong': {
      display: 'block',
      marginBottom: 5,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '.accordion-header-title .accordion-header-title-labels': { display: 'flex' },
    '.accordion-header-title-container': { overflow: 'hidden' },

    '.h2o-Accordion-body': {
      padding: '15px 0 0',
      color: theme.palette?.gray500,
    },
    '.accordion-header-subtitle': {
      marginBottom: 12,
      paddingRight: '11%',
    },
    '.accordion-header-subtitle .h2o-TextWithCopy-root': { maxWidth: '100%' },

    '.metadata-cell-contents': {
      paddingRight: '11%',
      marginBottom: 5,
    },
    '.metadata-cell-contents > span': {
      margin: '0 0 9px',
      width: '100% !important',
    },
    '.metadata-cell-contents .row-details-container': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '.metadata-cell-contents .row-details-label': { color: theme.palette?.gray900 },

    '.action-cell-contents': {
      margin: '0 !important',
      justifyContent: 'initial !important',
    },
    '.action-cell-contents .ms-Stack': {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    '.action-cell-contents .h2o-ButtonContainer-root': {
      display: 'block',
      borderRadius: 4,
      fontSize: FontSizes.medium,
      lineHeight: FontSizes.xlarge,
      fontWeight: FontWeights.semiBold,

      '> div': {
        height: '100%',
        width: '100%',
      },
      button: {
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        fontSize: FontSizes.medium,
        lineHeight: FontSizes.xlarge,
      },
      'button + button': { width: 56 },
    },
    '.action-cell-contents .primary-button-wrapper': { width: '100%' },
    '.action-cell-contents .primary-button-wrapper .h2o-ButtonContainer-root': { width: '100%' },
    '.action-cell-contents .ms-SplitButton-divider': {
      right: 53,
      top: 0,
      bottom: 0,
    },
  },
});
