import type { IImageStyles, ITextStyles } from '@fluentui/react';
import {
  FontSizes,
  FontWeights,
  type IH2OTheme,
  LineHeights,
  mediaDesktop,
  mediaMobile,
  mediaNoDesktop,
} from '@h2oai/ui-kit';

import { subpageStyles } from '../Subpage/subpage.styles';

export const appDetailHeaderStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    ...(subpageStyles(theme).root as object),

    ...mediaNoDesktop({ padding: 8 }),
    '.ms-Image': {
      ...mediaMobile({
        flex: '0 0 88px',
        width: 88,
        height: 88,
        minWidth: 0,
      }),
    },
    '.AppDetailHeader-body': {
      ...mediaMobile({
        flex: '1 1',
        marginLeft: '0.7rem',
        minWidth: '60%',
      }),
    },
    '.AppAttributeBadge-container': {
      ...mediaMobile({
        order: -1,
        width: '100%',
        marginLeft: '0 !important',
      }),
    },
    '.AppDetailHeader-footer': {
      ...mediaMobile({
        maxWidth: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginLeft: 0,
        flex: '1 1',
        margin: 'auto 0',
      }),
      '> *:not(.app-details-action-button)': { ...mediaMobile({ maxWidth: '49%' }) },
    },
    '.AppDetailHeader-body-footer': {
      ...mediaMobile({
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }),
      '.AppSocialInfoContainer': { ...mediaMobile({ marginLeft: 'auto' }) },
    },

    // Buttons
    'div.app-details-action-button': {
      ...mediaMobile({
        position: 'fixed',
        bottom: 30,
        right: 22,
        zIndex: 1,
        borderRadius: 4,
      }),
      '> div': { ...mediaMobile({ height: 48 }) },
    },
    'button.app-details-action-button': {
      ...mediaMobile({
        width: '40vw',
        height: '100%',
        fontSize: 18,
        lineHeight: 22,
        fontWeight: 600,
      }),
    },
    '.ms-Button-label': { ...mediaMobile({ textAlign: 'center' }) },
    'button.app-details-action-button + button': { ...mediaMobile({ width: 52 }) },
    '.ms-SplitButton-divider': {
      ...mediaMobile({
        right: 53,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette?.black,
      }),
    },
  },
});

export const textStylesTitle = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    color: theme.semanticColors?.textQuaternary,
    ...mediaDesktop({
      fontSize: FontSizes.xlarge,
      fontWeight: FontWeights.semiBold,
    }),
    ...mediaNoDesktop({
      fontSize: FontSizes.small,
      fontWeight: FontWeights.semiBold,
    }),
  },
});

export const textStylesDescription = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    color: theme.semanticColors?.textPrimary,
    ...mediaDesktop({
      fontSize: FontSizes.small,
      lineHeight: LineHeights.medium,
      fontWeight: FontWeights.semiBold,
    }),
    ...mediaMobile({
      order: 1,
      marginLeft: -98,
      fontSize: FontSizes.xsmall,
      lineHeight: LineHeights.xsmall,
    }),
  },
});

export const imageStylesAppDetailsHeader: Partial<IImageStyles> = {
  root: {
    width: 137,
    minWidth: 137,
    height: 132,
    borderRadius: 8,
  },
  image: {
    width: 'auto',
  },
};
