import {
  GTagProvider,
  GTagProviderProps,
  Loader,
  getAppStoreMenuInfo,
  loaderStylesSpinnerXLarge,
  setupGtm,
} from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

import { GetEnvironmentDataResponse, User } from '../../ai.h2o.cloud.appstore';
import { AutoLogout } from '../../components/AutoLogout/AutoLogout';
import { AuthService, EnvService } from '../../services/api';
import { EnvProvider, EnvironmentAndMenu, UserProvider } from '../../utils/contexts';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { fetchAppStoreVersion } from '../../utils/utils';
import { Protected } from '../Protected/Protected';
import { Routes } from '../Routes';

function AuthPage() {
  const auth = useAuth();
  const history = useHistory();
  const platformDiscovery = useCloudPlatformDiscovery();
  const [haveCookie, setHaveCookie] = useState<boolean>(false);
  const [gTag, setGTag] = useState<GTagProviderProps>({ isGtmReady: false, user: {} });
  const [user, setUser] = useState<User | null>(null);
  const [env, setEnv] = useState<EnvironmentAndMenu | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }
    // now that we are loaded, be sure to add id_token_hint for automatic logouts:
    auth.events.addAccessTokenExpired(async () => {
      const token = auth.user?.id_token;
      await auth.removeUser();
      await auth.signoutRedirect({ id_token_hint: token });
    });
    const fetchEnv = async () => {
      try {
        setLoading(true);
        const [fetchEnvResponse, fetchUserResponse] = await Promise.all([
          EnvService.getEnvironmentData({}),
          AuthService.checkAuth({}),
        ]);
        const { user } = fetchUserResponse;
        if (!user) {
          const token = auth.user?.id_token;
          await auth.removeUser();
          await auth.signoutRedirect({ id_token_hint: token });
        }
        setUser(user);

        if (user && fetchEnvResponse) {
          const { navLinks = [], bookTimeLink, homePage, federationEnabled, environmentName } = fetchEnvResponse;
          setEnv({
            ...fetchEnvResponse,
            menu: getAppStoreMenuInfo(
              !!platformDiscovery?.aiEngineManagerApiUrl,
              user,
              navLinks,
              bookTimeLink,
              homePage.enabled,
              federationEnabled,
              '',
              true,
              undefined,
              environmentName,
              !!fetchEnvResponse.platformUsageEnabled,
              !!(fetchEnvResponse.publicModeEnabled && !user.hasFullAccess)
            ),
          });
        }
      } catch (error: any) {
        console.error(error);

        if (error.code === 'permission_denied' && history.location.pathname !== '/403') {
          history.push('403');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEnv();
  }, [auth.isLoading]);

  /* Set up Google Tag Manager after user and env responses are ready */
  useEffect(() => {
    const loadGtm = async (_envResponse: GetEnvironmentDataResponse, _user: User) => {
      try {
        const { tagManagerId } = _envResponse;
        const haicVersion = tagManagerId ? (await fetchAppStoreVersion()) || 'Unknown version' : '';
        setGTag(await setupGtm(TagManager, tagManagerId, _user, haicVersion));
      } catch (error) {
        console.error('Google tag manager initialization failed');
        console.error(error);
      }
    };
    if (user && env) loadGtm(env, user);
  }, [env, user]);

  /* Sign-in if unauthenticated and not in public mode */
  useEffect(() => {
    if (loading || env?.publicModeEnabled) return;
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [loading, env, auth.isLoading, auth.isAuthenticated]);

  /* Set cookie for Wave app authentication */
  useEffect(() => {
    if (auth.user?.id_token) {
      const controller = new AbortController();
      const signal = controller.signal;
      fetch('/auth/session', {
        method: 'GET',
        signal,
      })
        .then(() => {
          setHaveCookie(true);
        })
        .catch((error) => {
          console.error('There was an error obtaining the session cookie:', error);
        });
      return () => {
        controller.abort();
      };
    }

    return;
  }, [auth.user?.id_token]);

  // TODO: how to handle logout on one tab, while other tab is still open?

  const isAuthenticated = auth.user?.access_token && haveCookie;
  const dataReady = user && env;
  const showApp = dataReady && (isAuthenticated || env?.publicModeEnabled);

  if (history.location.pathname.includes('403')) {
    return <Routes />;
  }

  return showApp ? (
    <GTagProvider {...gTag}>
      <EnvProvider env={env}>
        <UserProvider user={user}>
          {auth.user && <AutoLogout />}
          <Protected />
        </UserProvider>
      </EnvProvider>
    </GTagProvider>
  ) : (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  );
}

export default AuthPage;
